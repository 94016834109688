import React, { Component } from 'react'
import IconIMG from "../Header/Img/IconIMG.jpg";
import { Image, Tab, Tabs   } from 'react-bootstrap';
import './Profile.css'
import { toast } from "react-toastify";
import configData from "./config.json";
import CryptoJS from "crypto-js";



export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
       repassword: "",
      newpassword: "",
      password: "",
      error: false,
      show: false,
      showPassword: false,
      forgotPasswordCondition: false,

        };
    };
      showToast = (msg, type) => {
    let tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  onSubmit = async (e) => {
    e.preventDefault();

    const { repassword, newpassword,oldpassword } = this.state;

    
    if (!this.validatePassword(newpassword)) {
      this.showToast(
        "New Password must contain at least one special character!",
        "error"
      );
      return;
    }
    if (newpassword !== repassword) {
      this.showToast("Password mismatch!", "error");
      return;
    }
    if (newpassword === oldpassword) {
      this.showToast("Old & New passwords are same!", "error");
      return;
    }
    const formData = new FormData();
    formData.append("sessionId", sessionStorage.getItem("sessionId"));
    formData.append(
      "newpassword",
      CryptoJS.HmacMD5(newpassword, configData.secret).toString()
    );
    formData.append(
      "oldpassword",
      CryptoJS.HmacMD5(oldpassword, configData.secret).toString()
    );
    fetch(configData.api_url + "login/changepwd", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
      
      });
  };
      validatePassword = (password) => {
    const passwordPattern = /^(?=.*[!@#$%^&*])/;
    return passwordPattern.test(password);
  };
  
    render() {
        const uName = sessionStorage.getItem("userName");
        const uLName = sessionStorage.getItem("userLastName");
        const uNumber = sessionStorage.getItem("userNumber");
        const emailId = sessionStorage.getItem("emailId");
        const uAddress = sessionStorage.getItem("userAddress");

        return (
            <div className='container'>
                <div className='row'>
                    <h5>Profile</h5>
                </div>


                <div className='row companiesView'>
                    <div className='col-md-4 col-sm-12 col-xs-12 companiesViewleft ' >
                        <div className="x_panel" style={{ "marginTop": "10px" }}>
                            <div className="x_title">
                                <input type="hidden" id="eId" value="1" />
                                <h3>{uName}</h3>
                                <h5>Super Admin</h5>
                                <div className="clearfix"></div>
                            </div>
                            <div className="companiesLogo">
                                <Image className='img' src={IconIMG} alt="" />
                            </div>
                            <p><i className="bi bi-phone" aria-hidden="true"></i> &nbsp;&nbsp;{uNumber}</p>
                            <p><i className="bi bi-envelope" aria-hidden="true" value={emailId}></i> &nbsp;&nbsp;{emailId}</p>
                            <br />
                        </div>
                    </div>
                    <div className='col-md-8 col-sm-12 col-xs-12 companysliderright'>
                        <div className="x_panel">
                            <div class role="tabpanel" data-example-id="togglable-tabs">
                                <Tabs id="uncontrolled-tab-example"
                                    className="mb-3" defaultActiveKey="Account">
                                    <Tab
                                        eventKey="Account"
                                        title="Account"

                                    >
                                        <div className='col-md-12 col-sm-12 col-xs-12 form-group has-feedback'>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="fName" placeholder="First Name" value={uName} fdprocessedid="jkfvqe" />
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="lName" placeholder="Last Name" value={uLName} fdprocessedid="bzxitr" />
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group has-feedback">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                                    <textarea className="form-control" id="address" placeholder="Address" value={uAddress} ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                            <button type="button" className="btn btn-primary" id="updateProfile" fdprocessedid="9zsf3">Save</button>
                                        </div>

                                        {/* Account Tab Content */}

                                    </Tab>
                                    <Tab
                                        eventKey="Change Password"
                                        title="Change Password"

                                    >
                                        <div className="row">
                                              <div className="form-group mt-3">
                                                 <div className='password-input'>
                                                     <input
                                                    className="form-control mt-1"
                                                    type={this.state.oldpassword ? "text" : "password"}
                                                    onChange={(e) =>
                                                        this.setState({ oldpassword: e.target.value })
                                                    }
                                                    value={this.state.oldpassword}
                                                    placeholder="Old password"
                                                    />
                                                        <span
                                                        className="password-toggle-icon bi bi-eye"
                                                        onClick={() =>
                                                            this.setState((prevState) => ({
                                                                oldpassword: !prevState.oldpassword,
                                                            }))
                                                        }
                                                    />
                                                   
                                                 </div>
                                                
                                                </div>
                                                <div className="form-group mt-3">
                                                <div className='password-input'>
                                                    <input
                                                        className="form-control mt-1"
                                                        type={this.state.showPassword ? "text" : "password"}
                                                        onChange={(e) =>
                                                            this.setState({ newpassword: e.target.value })
                                                        }
                                                        value={this.state.newpassword}
                                                        placeholder="New password"
                                                    />
                                                    <span
                                                        className="password-toggle-icon bi bi-eye"
                                                        onClick={() =>
                                                            this.setState((prevState) => ({
                                                                showPassword: !prevState.showPassword,
                                                            }))
                                                        }
                                                    />
                                                </div>
                                                </div>

                                                    <div className="form-group mt-3">
                                                             <div className='password-input'>
                                                        <input
                                                        className="form-control mt-1"
                                                        type={this.state.repassword ? "text" : "password"}
                                                        onChange={(e) =>
                                                            this.setState({ repassword: e.target.value })
                                                        }
                                                        value={this.state.repassword}
                                                        placeholder="Retype new password"
                                                        />
                                                        <span
                                                        className="password-toggle-icon bi bi-eye"
                                                        onClick={() =>
                                                            this.setState((prevState) => ({
                                                                repassword: !prevState.repassword,
                                                            }))
                                                        }
                                                    />
                                                    </div>
                                                    </div>
                                                    <div
                                                        className="d-grid gap-2 mt-3 "
                                                        style={{ justifyContent: "center" }}
                                                    >
                                                        <button
                                                        // type="button"
                                                        onClick={this.onSubmit}
                                                        className="btn btn-light"
                                                        style={{ width: "100px", borderRadius: "0" }}
                                                        >
                                                        Update
                                                        </button>
                                                    </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
