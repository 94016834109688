import React, { Component } from "react";
import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import { Button, Image } from "react-bootstrap";
import Apnapolicy from "./Header/Img/Apnapolicy.png";
import "./Login.css";
import { LoginUser } from "./Header/LoginComponent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Forgotpsd from "./Header/Forgotpsd";

export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: false,
      show: false,
      showPassword: false,
      forgotPasswordCondition: false,
    };
  }
  showToast = (msg, type) => {
    let tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = this.state;
    if (email === "" || password === "") {
      this.showToast("Enter Email and Password!", "error");
      return;
    }
  
    if (email.length <= 6) {
      this.showToast("Invalid Email or Username!", "error");
      return;
    }
    if (!this.validatePassword(password)) {
      this.showToast(
        "Password must contain at least one special character!",
        "error"
      );
      return;
    }
    LoginUser(email, password);
  };

  validateEmail = (email) => {
    var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  };

  validatePassword = (password) => {
    const passwordPattern = /^(?=.*[!@#$%^&*])/;
    return passwordPattern.test(password);
  };
  handleForgotPasswordClick = () => {
    this.setState({ forgotPasswordCondition: true });
  };
  render() {
    const { forgotPasswordCondition } = this.state;
 if (forgotPasswordCondition) {
      return <Forgotpsd />;
    }
    return (
      <div style={{ overflow: "hidden" }}>
        <center className="mt-5">
          <div style={{ textAlign: "center" }}>
            <div className="Auth-form-container">
              <form className="Auth-form">
                <div className="Auth-form-content">
                  <Image
                    src={Apnapolicy}
                    className="navbar-brand pe-3 ps-4 ps-lg-2"
                    alt=""
                    style={{
                      width: "auto",
                      height: "44px",
                      marginBottom: "5px",
                    }}
                  />
                  <h4 style={{ color: "#25335b" }}> Login</h4>

                  <div className="form-group mt-3">
                    <input
                      className="form-control mt-1"
                      onChange={(e) => this.setState({ email: e.target.value })}
                      value={this.state.email}
                      placeholder="Email"
                    />
                  </div>

                  <div className="form-group mt-3">
                    <div className="password-input">
                      <input
                        className="form-control mt-1"
                        type={this.state.showPassword ? "text" : "password"}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                        value={this.state.password}
                        placeholder="Password"
                      />
                      <span
                        className="password-toggle-icon bi bi-eye"
                        onClick={() =>
                          this.setState((prevState) => ({
                            showPassword: !prevState.showPassword,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="row form-group mt-3 ">
                    <div className="col">
                      <Button
                        onClick={this.handleForgotPasswordClick}
                        color="link"
                        variant="light"
                        style={{ float: "left", width: "max-content" }}
                      >
                        Forgot password
                      </Button>
                    </div>
                    <div className="col">
                      <button
                        onClick={this.onSubmit}
                        className="btn btn-#44986f"
                        style={{
                          width: "100px",
                          border: "0px",

                          color: "#fff",
                          backgroundColor: "#44986f",
                          float: "right",
                        }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </center>
      </div>
    );
  }
}
