import React, { Component } from "react";
import "./DashBoards.css";
import { Col, Row, Dropdown } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

export default class DashBoard extends Component {
  render() {
    return (
      <div>
        <Row className="dashboard d-flex justify-content-between">
          <Col lg="2" md="2" sm="2" className="d-flex">
            <Dropdown className="d-inline mx-2 overview">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="btn btn-light"
                style={{ marginTop: "5px", border: "1px solid #23ae73" }}
              >
                Overview
              </Dropdown.Toggle>
              <Dropdown.Menu>
            </Dropdown.Menu>
            </Dropdown>
            <span className="bi bi-lock"></span>
          </Col>

          <Col
            lg={{ span: 6, offset: 4 }}
            md={{ span: 6, offset: 4 }}
            sm={{ span: 6, offset: 4 }}
            className="d-flex justify-content-end"
          >
            <span className="bi bi-arrow-clockwise"></span>
            <Dropdown className="d-inline mx-2 component">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="btn btn-light"
                style={{ marginTop: "5px", border: "1px solid #23ae73" }}
              >
                + Component
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to="">KPI</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="Dashboardcharts">Charts</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="d-inline mx-2 component">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="btn btn-light"
                style={{ marginTop: "5px", border: "1px solid #23ae73" }}
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to="">View in Full Screen</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="">Delete</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <br />
        <Row>
          <Outlet />
        </Row>
      </div>
    );
  }
}
