import React, { Component } from "react";
import "./ICICIProposeiinfo.css";
import { Button, Col, Row, Card, Table } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";

export default class ICICIAddons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      members: [],
      mandatoryaddons: [],
      optionaladdons: [],
      defalutaddons: [],
      showExistingSince: {},
      isMandatoryState: {},
      isDefaultState: {},
      isdisableState: {},
      isZipcode: "",
      dateValues: {},
      selectedAddons: {},
      isButtonDisabled: false,
      sumInsured: 0,
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleSubmitClick = (e) => {
    window.location.href = "/icicinomineedetails";
  };
  componentDidMount() {
    this.apisfetchHandler();
  }
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "Il_Leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ members: data.lead.members });
          this.setState({ isZipcode: data.lead.zipcode });
          this.CaresupremeaddonsfetchHandler();
        }
      });
  };
  CaresupremeaddonsfetchHandler = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "Il_Leads/getproductdetails", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ mandatoryaddons: data.product.mandatory_addons });
          this.setState({ optionaladdons: data.product.optional_addons });
          this.setState({ sumInsured: data.product.suminsured });

          this.getDefalutAddOns();
        }
      });
  };

  getDefalutAddOns = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "Il_Leads/getdefaultAddons", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // var defalutaddons = data.defalutaddons;
          // this.setState({ defalutaddons: defalutaddons });
          this.setState({ defalutaddons: data.defalutaddons });
          // var disabledAddons = [];
          var optionaladdons = this.state.optionaladdons;
          var defalutaddons = this.state.defalutaddons;
          optionaladdons.forEach((quote) => {
            quote.isDefault = "no";
            if (defalutaddons.includes(quote.addONCode)) {
              quote.isMandatory = "yes";
              quote.isDefault = "yes";
              quote.isdisable = "yes";
              // disabledAddons = quote.disabledAddons.split(",");
            }
          });
          this.setState({ optionaladdons: optionaladdons });
          this.setAddons();
        }
      });
  };
  setAddons = () => {
    const initialState = {};
    const isMandatoryState = {};
    const isDefaultState = {};
    const isdisableState = {};
    const buffetcState = {};
    const buffetdState = {};
    var buffetadons = [];
    const isZipcode = this.state.isZipcode;
    var disadons = [];
    this.state.optionaladdons?.forEach((addons) => {
      if (!initialState[addons?.caresupremeaddons_Id]) {
        initialState[addons?.caresupremeaddons_Id] = {};
        isdisableState[addons?.caresupremeaddons_Id] = {};
        isDefaultState[addons?.caresupremeaddons_Id] = {};
        isMandatoryState[addons?.caresupremeaddons_Id] = {};
      }
      var addonCode = "";
      this.state.members?.forEach((member) => {
        var isChecked = "No";
        var isMandatory = "No";
        var isDefault = "No";
        var isdisable = "No";

        if (
          addons.addONCode === "AddOn79" ||
          addons.addONCode === "AddOn80" ||
          addons.addONCode === "AddOn81"
        ) {
          if (
            member.age < 18 ||
            member.age > 45 ||
            member.gender !== "FEMALE"
          ) {
            isdisable = "Yes";
          }
        }

        if (addons.addONCode === "AddOn78") {
          if (member.age < 18 || member.age > 50) {
            isdisable = "Yes";
          }
        }
        if (addons.addONCode === "AddOn82") {
          if (member.age < 18 || member.age > 65) {
            isdisable = "Yes";
          }
        }
        if (addons.addONCode === "AddOn84") {
          if (this.state.sumInsured >= 1500000) {
            isChecked = "Yes";
            isdisable = "Yes";
          }
        }
        if (addons.addONCode === "AddOn85" || addons.addONCode === "AddOn86") {
          if (this.state.sumInsured >= 10000000) {
            isChecked = "Yes";
            isdisable = "Yes";
          }
        }
        if (
          addons.addONCode === "AddOn87" ||
          addons.addONCode === "AddOn88" ||
          addons.addONCode === "AddOn89" ||
          addons.addONCode === "AddOn90" ||
          addons.addONCode === "AddOn91" ||
          addons.addONCode === "AddOn92" ||
          addons.addONCode === "AddOn93" ||
          addons.addONCode === "AddOn94"
        ) {
          if (
            buffetcState[addons?.caresupremeaddons_Id] &&
            addonCode === addons.addONCode
          ) {
            isdisable = buffetcState[addons.caresupremeaddons_Id];
            isdisableState[addons.caresupremeaddons_Id][member.lhmId] =
              isdisable;
            isChecked = buffetcState[addons.caresupremeaddons_Id];
            initialState[addons.caresupremeaddons_Id][member.lhmId] = isChecked;
          } else {
            addonCode = addons.addONCode;
            if (addons.addONCode === "AddOn87") {
              if (buffetadons.length <= 0)
                buffetadons = addons.dummyThree.split(",");
              if (buffetadons.indexOf(isZipcode) > -1 && disadons.length <= 0) {
                disadons = addons.disabledAddons.split(",");
                isChecked = "Yes";
                isdisable = "Yes";
              }
            } else if (addons.addONCode === "AddOn88") {
              if (buffetadons.length <= 0)
                buffetadons = addons.dummyThree.split(",");
              if (buffetadons.indexOf(isZipcode) > -1 && disadons.length <= 0) {
                disadons = addons.disabledAddons.split(",");
                isChecked = "Yes";
                isdisable = "Yes";
              }
            } else if (addons.addONCode === "AddOn89") {
              if (buffetadons.length <= 0)
                buffetadons = addons.dummyThree.split(",");
              if (buffetadons.indexOf(isZipcode) > -1 && disadons.length <= 0) {
                disadons = addons.disabledAddons.split(",");
                isChecked = "Yes";
                isdisable = "Yes";
              }
            } else if (addons.addONCode === "AddOn90") {
              if (buffetadons.length <= 0)
                buffetadons = addons.dummyThree.split(",");
              if (buffetadons.indexOf(isZipcode) > -1 && disadons.length <= 0) {
                disadons = addons.disabledAddons.split(",");
                isChecked = "Yes";
                isdisable = "Yes";
              }
            } else if (addons.addONCode === "AddOn91") {
              if (buffetadons.length <= 0)
                buffetadons = addons.dummyThree.split(",");
              if (buffetadons.indexOf(isZipcode) > -1 && disadons.length <= 0) {
                disadons = addons.disabledAddons.split(",");
                isChecked = "Yes";
                isdisable = "Yes";
              }
            } else if (addons.addONCode === "AddOn92") {
              if (buffetadons.length <= 0)
                buffetadons = addons.dummyThree.split(",");
              if (buffetadons.indexOf(isZipcode) > -1 && disadons.length <= 0) {
                disadons = addons.disabledAddons.split(",");
                isChecked = "Yes";
                isdisable = "Yes";
              }
            } else if (addons.addONCode === "AddOn93") {
              if (buffetadons.length <= 0)
                buffetadons = addons.dummyThree.split(",");
              if (buffetadons.indexOf(isZipcode) > -1 && disadons.length <= 0) {
                disadons = addons.disabledAddons.split(",");
                isChecked = "Yes";
                isdisable = "Yes";
              }
            } else if (addons.addONCode === "AddOn94") {
              if (buffetadons.length <= 0)
                buffetadons = addons.dummyThree.split(",");
              if (buffetadons.indexOf(isZipcode) > -1 && disadons.length <= 0) {
                disadons = addons.disabledAddons.split(",");
                isChecked = "Yes";
                isdisable = "Yes";
              }
            }
            buffetcState[addons.caresupremeaddons_Id] = isChecked;
            buffetdState[addons.caresupremeaddons_Id] = isdisable;
          }
        }
        if (disadons.length > 0) {
          if (disadons.indexOf(addons.addONCode) > -1) {
            if (!isdisableState[addons?.caresupremeaddons_Id]) {
              isdisableState[addons?.caresupremeaddons_Id] = {};
            }

            isdisableState[addons?.caresupremeaddons_Id][member?.lhmId] = "Yes";
          }
        }
        initialState[addons.caresupremeaddons_Id][member.lhmId] = isChecked;
        isMandatoryState[addons.caresupremeaddons_Id][member.lhmId] =
          isMandatory;
        isDefaultState[addons.caresupremeaddons_Id][member.lhmId] = isDefault;
        if (!isdisableState[addons.caresupremeaddons_Id][member.lhmId])
          isdisableState[addons.caresupremeaddons_Id][member.lhmId] = isdisable;
      });
    });
    this.setState({
      showExistingSince: initialState,
      isMandatoryState: isMandatoryState,
      isDefaultState: isDefaultState,
      isdisableState: isdisableState,
    });
    // if (disadons.length > 0) {
    //   this.setDisableAddons(disadons);
    // }
  };
  setDisableAddons = (addonId, disadons, isdisable, isChecked) => {
    const initialState = this.state.showExistingSince;
    const isdisableState = this.state.isdisableState;
    this.state.optionaladdons?.forEach((addons) => {
      this.state.members?.forEach((member) => {
        var dateValue1 = "No";
        if (addonId === addons.caresupremeaddons_Id) {
          if (!isChecked) {
            dateValue1 = "Yes";
          }

          isdisableState[addons.caresupremeaddons_Id][member.lhmId] =
            dateValue1;
          initialState[addons.caresupremeaddons_Id][member.lhmId] = dateValue1;
        }
        if (disadons.indexOf(addons.addONCode) > -1) {
          if (!isdisableState[addons.caresupremeaddons_Id]) {
            isdisableState[addons.caresupremeaddons_Id] = {};
          }
          if (!initialState[addons.caresupremeaddons_Id]) {
            initialState[addons.caresupremeaddons_Id] = {};
          }

          isdisableState[addons.caresupremeaddons_Id][member.lhmId] = isdisable;
          initialState[addons.caresupremeaddons_Id][member.lhmId] = dateValue1;
        }
      });
    });
    this.setState({
      showExistingSince: initialState,
      isdisableState: isdisableState,
    });
  };
  handleOptionalCheckboxChange = (addonId, memberId, isChecked, dateValue) => {
    this.setState((prevState) => {
      var newShowExistingSince = { ...prevState.showExistingSince };
      if (!newShowExistingSince[addonId]) {
        newShowExistingSince[addonId] = {};
      }
      // Determine the date value based on the isChecked parameter
      var dateValue1 = "No";
      if (isChecked) {
        dateValue1 = "Yes";
      }
      // Set the date value in the newShowExistingSince object
      newShowExistingSince[addonId][memberId] = dateValue1;

      return {
        showExistingSince: newShowExistingSince,
      };
    });
    var disadons = [];
    var dateValue2 = "No";
    if (isChecked) {
      dateValue2 = "Yes";
    }
    this.state.optionaladdons?.forEach((addons) => {
      if (addons?.caresupremeaddons_Id === addonId) {
        disadons = addons.disabledAddons.split(",");
      }
    });

    if (disadons.length > 1) {
      this.setDisableAddons(addonId, disadons, dateValue2, !isChecked);
    }
  };


  handleInputChange = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };
  handleSubmitBackClick = (e) => {
    window.location.href = "/icicinomineedetails";
  };

  handleComplete = (e) => {
    this.setState({ isButtonDisabled: true });
    let allSelectedAddonsList = [];
    const { showExistingSince } = this.state;

    this.state.optionaladdons.forEach((addon) => {
      this.state.members.forEach((member) => {
        let ans = showExistingSince[addon.caresupremeaddons_Id]?.[member.lhmId];

        allSelectedAddonsList.push({
          ans: ans,
          lhmId: member.lhmId,
          aoId: addon.caresupremeaddons_Id,
          aoCode: addon.addONCode,
          isChecked: ans,
        });
      });
    });
    const formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("cqrId", this.state.cqrId);
    formData.append("addons", JSON.stringify(allSelectedAddonsList));
    fetch(configData.api_url + "Il_Leads/updateleadaddons/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.updateQuote();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  createPolicy() {
    const formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("chpiId", this.state.chpiId);
    formData.append("cqrId", this.state.cqrId);
    fetch(configData.api_url + "Il_Leads/createpolicy/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        // alert(JSON.stringify(data.policy));
        if (data.status === "success") {
          sessionStorage.setItem("proposalNum", data.policy.proposalNumber);
          window.location.href = "/iciciproposalsummary";
        }
      })
    
  }
  updateQuote() {
    const formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("chpiId", this.state.chpiId);
    formData.append("cqrId", this.state.cqrId);
    fetch(configData.api_url + "Il_Leads/updateQuote/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // this.showToast(data.message, data.status);
        // alert(JSON.stringify(data.policy));
        if (data.status === "success") {
          this.createPolicy();
        }
      })
    
  }
  render() {
    const { members } = this.state;

    return (
      <div>
        <h3 className="logoskybluecolor">ICICI Addons</h3>
        <br />
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-vcard-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Member Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-heart-pulse"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Medicals</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-pencil-square"
                        onClick={this.handleSubmitClick}
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6
                        style={{ color: "#3d5a95" }}
                        onClick={this.handleSubmitClick}
                      >
                        Nominee Details
                      </h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-clipboard-plus"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Addon's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>
        <div>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <h3>Addons</h3>
                  <Table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">S No.</th>
                        <th scope="col">Addon Name</th>
                        {members.map((member) => (
                          <th key={member.relation}>
                            {member.fname} {member.lname}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.optionaladdons.map((quote, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{quote.addOn}</td>
                          {this.state.members.map((member) => (
                            <td key={`${quote.addONCode}-${member.lhmId}`}>
                              <input
                                id={`${quote.addONCode}-${member.lhmId}`}
                                name={`${quote.addONCode}-${member.lhmId}`}
                                type="checkbox"
                                checked={
                                  this.state.showExistingSince[
                                    quote.caresupremeaddons_Id
                                  ]?.[member.lhmId] === "Yes"
                                }
                                onChange={(e) =>
                                  this.handleOptionalCheckboxChange(
                                    quote.caresupremeaddons_Id,
                                    member.lhmId,
                                    e.target.checked,
                                    !(quote.isMandatory === "Yes"
                                      ? true
                                      : false)
                                  )
                                }
                                disabled={
                                  this.state.isdisableState[
                                    quote.caresupremeaddons_Id
                                  ]?.[member.lhmId] === "Yes"
                                }
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
        </div>
        <div className="row">
          <div className="col">
            <Button
              id="getQuotes4"
              onClick={this.handleSubmitClick}
              type="submit"
              style={{ float: "left" }}
            >
              Back
            </Button>
          </div>
          <div className="col" style={{ float: "right" }}>
            <Button
              id="getQuotes4"
              onClick={this.handleComplete}
              type="submit"
              style={{ float: "right" }}
              //disabled={this.state.isButtonDisabled}
            >
              Finish
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
