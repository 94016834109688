import React, { Component } from "react";
import { Card, Row, Col, Form, Button, Offcanvas } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import configData from "../config.json";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default class PossscOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      data: [],
      currentPage: 1,
      itemsPerPage: 10,
      pfName: "",
      plName: "",
      pmobilenumber: "",
      pannumber: "",
      aadharnumber: "",
      emailid: "",
      panPath: "",
      aadharPath: "",
      sscPath: "",
      error: "",
      pospId: "",
      pDob: "",
      pPhoto: "",
      isButtonDisabled: false,
      tableClms: [
        {
          name: "S.NO",
          width: "75px",
          selector: (row, i) => row.pospId,
          sortable: true,
          cell: (row) => <span>{row.pospId}</span>,
        },
        {
          name: "First Name",
          id: "pfName",
          selector: (row, i) => row.pfName,
          sortable: true,
          cell: (row) => <span>{row.pfName}</span>,
        },
        {
          name: "Last Name",
          id: "plName",
          selector: (row, i) => row.plName,
          sortable: true,
          cell: (row) => <span>{row.plName}</span>,
        },

        {
          name: "Mobile Number",
          id: "pmobilenumber",
          selector: (row, i) => row.pmobilenumber,
          sortable: true,
          cell: (row) => <span>{row.pmobilenumber}</span>,
        },
        {
          name: "PAN",
          id: "pannumber",
          selector: (row, i) => row.pannumber,
          sortable: true,
          cell: (row) => <span>{row.pannumber}</span>,
        },
        {
          name: "Aadhar Number",
          id: "aadharnumber",
          selector: (row, i) => row.aadharnumber,
          sortable: true,
          cell: (row) => <span>{row.aadharnumber}</span>,
        },
        {
          name: "Email-Id",
          id: "emailid",
          selector: (row, i) => row.emailid,
          sortable: true,
          cell: (row) => <span>{row.emailid}</span>,
        },
        {
          name: "Remarks",
          id: "remarks",
          selector: (row, i) => row.remarks,
          sortable: true,
          cell: (row) => (
            <span style={{ color: "red" }}>
              <b>{row.remarks}</b>
            </span>
          ),
        },

        // {
        //   name: "Actions",
        //   sortable: false,
        //   cell: (row) => (
        //     <div>
        //       {/* edit button and delete button */}
        //       {/* <Button
        //         variant="primary"
        //         onClick={() => this.handleEnterViewMode(row)}
        //         className="bi bi-p"
        //         style={{ marginRight: "3px" }}
        //       ></Button> */}
        //       <Button
        //         variant="danger"
        //         onClick={() => this.handleDelete(row)}
        //         className="bi bi-trash danger"
        //       ></Button>
        //     </div>
        //   ),
        // },
      ],
    };
  }

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };

  

  handleDChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
     
      // Assuming you want to update the 'pDob' state with the selected date
      this.setState({
        pDob: formattedDate,
        startDate: new Date(),
      });
    }
  };

  //10 class marksteet

  handleTcChange = (e) => {
    const files = e.target.files[0];
    this.setState({ sscPath: files });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();
    this.setState({
      [name]: name === "pannumber" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });
    if (name === "pfName") {
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");

        return;
      }
    }
    if (name === "plName") {
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");

        return;
      }
    }
    if (
      name === "pmobilenumber" &&
      value.length > 0 &&
      value.charAt(0) === "0"
    ) {
      // Display an error toast message or handle the error as needed
      toast.error("Mobile Number cannot start with the number 0.");
      return; // Stop further processing for this field
    }

    if (name === "pmobilenumber" && value.length > 0) {
      const firstDigit = parseInt(value.charAt(0), 10);

      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please enter valid mobile number.");
        return; // Stop further processing for this field
      }
    }

    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: "", // Clear the corresponding error when the field value changes
      },
    });
  };

  handlePANNumberChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();

    this.setState({
      [name]: name === "pannumber" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });
  };

  componentDidMount = () => {
    this.PospfetchHandler();
  };

  PospfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "posps/lsitbyuId/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((rsdata) => {
         this.showToast(rsdata.message, rsdata.status);
        if (rsdata.status === "success") {
          const list = rsdata.list;
          const data = [];
          list.forEach((element) => {
            if (element.pStatus === "1") {
                           data.push(element);
            }

            if (element.pStatus === "2") {
                           data.push(element);
            }
          });
          this.setState({ data: data });
        }
      });
  };

  handleChequeChange = (e) => {
    // Get the selected file(s) from the e object
    const files = e.target.files[0];

    // Update the state with the selected file(s)
    this.setState({ chequePath: files });
  };

  handlepsPhotoChange = (e) => {
    // Get the selected file(s) from the e object
    const files = e.target.files[0];

    // Update the state with the selected file(s)
    this.setState({ pPhoto: files });
  };
  handleSubmit = (e, row) => {
    this.setState({ isButtonDisabled: true });
    e.preventDefault();
    const {
      pfName,
      plName,
      pannumber,
      pDob,
      pGender,
      aadharnumber,
      emailid,
      panPath,
      aadharPath,
      sscPath,
      chequePath,
      pPhoto,
    } = this.state;

    if (!pfName || pfName.trim() === "") {
      this.showToast("Please Enter First Name", "error");
      return;
    } else if (!plName || plName.trim() === "") {
      this.showToast("Please Enter Last Name", "error");
      return;
    } else if (!emailid || emailid.trim() === "") {
      this.showToast("Please Enter Your Email Id", "error");
      return;
    } else if (!pDob || pDob.length === 0) {
      this.showToast("Please Select D.O.B", "error");
      return;
    } else if (!pGender || pGender.length === 0) {
      this.showToast("Please Select Gender", "error");
      return;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailid)) {
      this.showToast("Please Enter a Valid Email Id", "error");
      return;
    } else if (!pannumber || pannumber.trim() === "") {
      this.showToast("Please Enter Your Phone Number", "error");
      return;
    } else if (pannumber.length !== 10) {
      this.showToast("Please Enter Your Valid PAN", "error");
      return;
    } else if (!aadharnumber || aadharnumber.trim() === "") {
      this.showToast("Please Enter Your Aadhar Number", "error");
      return;
    } else if (aadharnumber.length !== 12) {
      this.showToast("Please Enter Your 12 Digit Aadhar Number", "error");
      return;
    } else if (!panPath || panPath.length === 0) {
      this.showToast("Please Upload PAN *Pdf file", "error");
      return;
    } else if (!aadharPath || aadharPath.length === 0) {
      this.showToast("Please Upload Aadhar *Pdf file", "error");
      return;
    } else if (!sscPath || sscPath.length === 0) {
      this.showToast("Please Upload Tenth Certificate", "error");
      return;
    } else if (!chequePath || chequePath.length === 0) {
      this.showToast("Please Upload Cheque", "error");
      return;
    } else if (!pPhoto || pPhoto.length === 0) {
      this.showToast("Please Upload Passport Size Color Photo", "error");
      return;
    }

    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pfName", this.state.pfName);
    formData.append("plName", this.state.plName);
    formData.append("aadharnumber", this.state.aadharnumber);
    formData.append("pmobilenumber", this.state.pmobilenumber);
    formData.append("pannumber", this.state.pannumber);
    formData.append("emailid", this.state.emailid);
    formData.append("panPath", this.state.panPath);
    formData.append("aadharPath", this.state.aadharPath);
    formData.append("sscPath", this.state.sscPath);
    formData.append("chequePath", this.state.chequePath);
    formData.append("pPhoto", this.state.pPhoto);
    formData.append("pGender", this.state.pGender);
    formData.append("pDob", this.state.pDob);

    // Assuming you want to make an API call here
    fetch(configData.api_url + "posps/add/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
         this.showToast(data.message, data.status);
        if (data.status === "success") {
          // Reset form fields upon successful submission
          this.setState({
            pfName: "",
            plName: "",
            pannumber: "",
            pmobilenumber: "",
            aadharnumber: "",
            emailid: "",
            pDob: "",
            pGender: "",
            panPath: "",
            aadharPath: "",
            sscPath: "",
            pPhoto: "",
          });
          sessionStorage.setItem("pospId", data.pospId);
          this.handleClose();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      })
      
  };

  handlePANChange = (e) => {
    // Get the selected file(s) from the event object
    const files = e.target.files[0];
    this.setState({ panPath: files });
  };

  handleAadharChange = (e) => {
    // Get the selected file(s) from the event object
    const files = e.target.files[0];

    // Update the state with the selected file(s)
    this.setState({ aadharPath: files });
  };

  onKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      toast.error("Spaces are not allowed.");
    }
  };
  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1); // Set the date to the first day of January
    // Example end year
    const endYear = new Date();
    endYear.setFullYear(endYear.getFullYear() + 10);
    return (
      <div>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="x_panel">
              <div className="x_content">
                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                  <Row>
                    <div className="" style={{ float: "left" }}>
                      <h3 className="logoskybluecolor">Create POSP</h3>
                    </div>
                    <Col>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <h3 className="logoskybluecolor">Create POSP</h3>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Card>
                            <Card.Body>
                              <Row>
                                <Col lg="12" md="12" sm="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        First Name
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleChange}
                                        className="form-control"
                                        name="pfName"
                                        value={this.state.pfName}
                                        required="required"
                                        placeholder="Enter First Name"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg="12" md="12" sm="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Last Name
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleChange}
                                        className="form-control"
                                        name="plName"
                                        value={this.state.plName}
                                        required="required"
                                        placeholder="Enter Last Name"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>

                                <Col lg="6" md="6" sm="12">
                                  <Form>
                                    <Form.Group
                                      className=" mb-3"
                                      controlId="formBasicEmail"
                                      name="pGender"
                                      id="pGender"
                                      onChange={this.handleChange}
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Gender
                                      </Form.Label>
                                      <Form.Select
                                        name="pGender"
                                        className="form-control"
                                      >
                                        <option>Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="tgender">Other</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg="6" md="6" sm="12">
                                  <Form>
                                    <Form.Group
                                      className=" mb-3"
                                      controlId="formBasicEmail"
                                      name="irdabranch"
                                      onChange={this.handleChange}
                                    >
                                      <Form.Label>D.O.B</Form.Label>

                                      <DatePicker
                                        dropdownMode="select"
                                        dateFormat="dd/MM/yyyy"
                                        showTimeSelect={false}
                                        selected={this.state.pDob}
                                        onChange={(date) =>
                                          this.handleDChange(date)
                                        }
                                        className="form-control"
                                        id="pDob"
                                        placeholderText="Select Date of Birth"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        minDate={previousYearStart} // Set the minimum selectable date
                                        maxDate={endYear} // Set the maximum selectable date
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>

                                <Col lg="12" md="12" sm="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Mobile Number
                                      </Form.Label>
                                      <Form.Control
                                        onKeyDown={this.onKeyDown}
                                        id="pmobilenumber"
                                        type="text"
                                        name="pmobilenumber"
                                        value={this.state.pmobilenumber}
                                        onChange={this.handleChange}
                                        required="required"
                                        placeholder="Enter Mobile Number"
                                        maxLength={10}
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg="12" md="12" sm="12">
                                  <Form>
                                    <Form.Group
                                      className=" mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Email Id
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="emailid"
                                        value={this.state.emailid}
                                        onChange={this.handleChange}
                                        required="required"
                                        placeholder="Enter Email-Id"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg="12" md="12" sm="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Aadhar Number
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="aadharnumber"
                                        value={this.state.aadharnumber}
                                        required="required"
                                        placeholder="Enter Aadhar Number"
                                        onChange={this.handleChange}
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg="12" md="12" sm="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        PAN Number
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        //  className="form-control"
                                        name="pannumber"
                                        value={this.state.pannumber}
                                        onChange={this.handlePANNumberChange}
                                        required="required"
                                        placeholder="Enter PAN Number"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                              </Row>
                              <hr />
                              <Row>
                                <Col lg="12" md="12" sm="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        PAN Card Upload
                                        <p style={{ color: "red" }}>
                                          (* .pdf only)
                                        </p>
                                      </Form.Label>
                                      <Form.Control
                                        type="file"
                                        accept=".pdf,.jpg,jpeg,.png"
                                        onChange={this.handlePANChange}
                                        className="form-control"
                                        name="panPath"
                                        required="required"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg="12" md="12" sm="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Aadhar Upload{" "}
                                        <p style={{ color: "red" }}>
                                          (* .pdf only)
                                        </p>
                                      </Form.Label>
                                      <Form.Control
                                        accept=".pdf,.jpg,jpeg,.png"
                                        type="file"
                                        onChange={this.handleAadharChange}
                                        className="form-control"
                                        name="aadharPath"
                                        required="required"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <hr />
                                <Col lg="12" md="12" sm="12" xs="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Tenth Certificate Upload
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "10px",
                                          }}
                                        >
                                          (* .pdf only)
                                        </p>
                                      </Form.Label>
                                      <Form.Control
                                        accept=".pdf,.jpg,jpeg,.png"
                                        type="file"
                                        onChange={this.handleTcChange}
                                        className="form-control"
                                        name="sscPath"
                                        required="required"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg="12" md="12" sm="12" xs="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Cheque Upload{" "}
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "10px",
                                          }}
                                        >
                                          (* .pdf only)
                                        </p>
                                      </Form.Label>
                                      <Form.Control
                                        accept=".pdf,.jpg,jpeg,.png"
                                        type="file"
                                        onChange={this.handleChequeChange}
                                        className="form-control"
                                        name="chequePath"
                                        required="required"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                                <Col lg="12" md="12" sm="12" xs="12">
                                  <Form>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Passport Size Color Photo{" "}
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "10px",
                                          }}
                                        >
                                          (* .pdf only)
                                        </p>
                                      </Form.Label>
                                      <Form.Control
                                        accept=".pdf,.jpg,jpeg,.png"
                                        type="file"
                                        onChange={this.handlepsPhotoChange}
                                        className="form-control"
                                        name="pPhoto"
                                        required="required"
                                      />
                                    </Form.Group>
                                  </Form>
                                </Col>
                              </Row>
                              <hr />
                              <Row>
                                <Col
                                  className="d-flex justify-content-end"
                                  style={{
                                    marginRight: "5px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Button
                                    variant="success"
                                    type="submit"
                                    // disabled={this.state.isButtonDisabled}
                                    onClick={this.handleSubmit}
                                  >
                                    Submit
                                  </Button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Offcanvas.Body>
                      </Offcanvas>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <div>
                      {/* searchbar,pagination,filter */}
                      <input
                        style={{ float: "left", marginBottom: "10px" }}
                        type="text"
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <Button
                        style={{ float: "right" }}
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleShow}
                      >
                        New
                      </Button>
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        //  paginationTotalRows={this.state.posp.add}
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
