import React, { Component } from "react";
import { Button, Offcanvas, Form, Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import configData from "../config.json";


export default class Zones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      zones: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      isdefault: false,
      cData: {
        zoneName: "",
      },
      zId: "",
      errors: {},
      showButton: false,
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.zId,
          sortable: true,
          cell: (row) => <span>{row.zId}</span>,
        },
        {
          name: " Name",
          selector: (row, i) => row.zoneName,
          sortable: true,
          cell: (row) => <span>{row.zoneName}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <button
                // style={{ marginLeft: "5px" }}
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen btn btn-primary m-1"
              />{" "}
              <button
                variant="danger"
                onClick={() => this.handleDelete(row, index)}
                className="bi bi-trash btn btn-danger m-1"
              />
              <button
                className="bi bi-check-square btn btn-danger m-1"
                onClick={() => this.apisfetchHandlerupdatestatus()}
                style={{
                  backgroundColor: "#26B99A",
                  border: " 1px solid #169F85",
                  color: "#fff",
                }}
              />
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //editing data
  handleEnterEditMode = (row) => {
    // Check if there are unsaved changes in the edit offcanvas
    if (this.state.isEditing) {
      // Show a message or take action to handle unsaved changes
      // For example, you can display a confirmation dialog.
      if (
        window.confirm("You have unsaved changes. Do you want to discard them?")
      ) {
        // If the user confirms, close the existing edit offcanvas and continue.
        this.setState({ isEditing: false, editingRowData: null }, () => {
          this.openEditOffcanvasWithRowData(row);
        });
      }
    } else {
      // If there are no unsaved changes, simply open the edit offcanvas.
      this.openEditOffcanvasWithRowData(row);
    }
  };

  openEditOffcanvasWithRowData = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);

    this.setState({
      cData: { zoneName: row.zoneName }, // Update cData with row data here
      zId: row.zId,
      isEditing: true,
      editingRowData: row, // Store the editing row data
    });
  };

  handleEnterNewEntryMode = () => {
    this.setState({ isNewEntry: true });
  };

  // Function to save edited data
  handleEditSave = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
     this.setState({ cData: { ...row }, isEditing: true }, () => {
      });
  };
  handleNewEntrySave = () => {
    // const newEntryData = this.state.insuranceData;
    this.setState({
      cData: {
        zoneName: "",
      },
      isNewEntry: false,
    });
  };
  // offcanvas Open
  handleShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleRemoveSpecific = (idx) => {
    const tempRows = this.state.zones; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ zones: tempRows });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.zones.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  inputChange = (e, id) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.cData;
    newFormData[fieldName] = fieldValue;
    this.setState({ cData: newFormData });
  };
  validateForm(e) {
    let zones = this.state.cData;
    let errors = {};
    let formIsValid = true;

    if (!zones["zoneName"]) {
      toast.info("Please Pass Name");
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleAddClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("abStatus", this.state.cData.abStatus);
      formData.append("zoneName", this.state.cData.zoneName);
      fetch(configData.api_url + "/zones/all/", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.apisfetchHandler();
            //       
            this.setState({
              show: false,
              cData: { zoneName: "" },
            });
          }
        });
    }
    //console.log(this.state.cData);
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("zId", row.zId);
    fetch(configData.api_url + "/zones/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
        }
      });
    //console.log(row.cId);
  };

  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/zones/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ zones: data.list });
        }
      });
  };
  apisHandlerupdate = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("zoneName", this.state.cData.zoneName);
    formData.append("zId", this.state.zId);
    fetch(configData.api_url + "/zones/update/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
          this.handleClose();
          this.setState({ zoneName: "" });
        }
        ////console.log(this.state.insurance);
      });
  };
  apisfetchHandlerupdatestatus = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("zId", this.state.zId);
    fetch(configData.api_url + "/zones/updatestatus/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success" || data.status === "true") {
          // this.setState({ insurance: data.list });
          this.handleClose();
          this.apisfetchHandler();
        }
        //console.log(this.state.plantypes);
      });
  };
  componentDidMount() {
    this.apisfetchHandler();
    this.apisfetchHandlerupdatestatus();
  }
  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.zones.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="title_left">
                <h2>Zones</h2>
              </div>
            </div>
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                >
                  <div className="row">
                    <div
                      className=" d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <div className="dataTables_length" id="datatable_length">
                        <Button
                          variant="primary"
                          className="bi bi-plus"
                          onClick={this.handleShow}
                        >
                          New
                        </Button>
                        <Offcanvas
                          show={this.state.show}
                          onHide={this.handleClose}
                          {...this.props}
                          style={{ width: "500px", height: "auto" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Add Zones</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container>
                              <div className="divstyle">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                  <Form.Group className="mt-3">
                                    <Form.Control
                                      type="text"
                                      className="form-control"
                                      id="zoneName"
                                      name="zoneName"
                                      value={this.state.cData.zoneName}
                                      placeholder="Name"
                                      onChange={this.inputChange}
                                    />
                                  </Form.Group>
                                </div>
                                <br />
                                <div className="d-flex  col-md-12 col-sm-12 col-xs-12 mt-5">
                                  <button
                                    className=" btn btn-warning me-2"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      borderColor: "#ccc",
                                      float: "right",
                                    }}
                                    onClick={this.handleClose}
                                  >
                                    Close
                                  </button>{" "}
                                  <button
                                    className="btn btn-primary me-2"
                                    style={{
                                      backgroundColor: "#23547e",
                                      color: "#fff",
                                      borderColor: "#2e6da4",
                                      float: "right",
                                    }}
                                    onClick={this.handleAddClick}
                                  >
                                    save{" "}
                                  </button>
                                </div>
                              </div>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                      <div>
                        {" "}
                        {this.state.isEditing && (
                          // Edit Offcanvas
                          <Offcanvas
                            show={this.state.isEditing}
                            onHide={() => this.setState({ isEditing: false })}
                            {...this.props}
                            style={{ width: "500px", height: "auto" }}
                            placement="end"
                            backdrop="true"
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>Edit Zones</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <div className="form-horizontal form-label-left input_mask">
                                <form className="form-horizontal form-label-left input_mask">
                                  <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Form.Group className="mt-3">
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="zoneName"
                                        name="zoneName"
                                        value={this.state.cData.zoneName}
                                        placeholder="Name"
                                        onChange={this.inputChange}
                                      />
                                    </Form.Group>
                                  </div>
                                  <br />
                                  <div style={{ float: "right" }}>
                                    <button
                                      className="btn btn-primary me-2 mt-5"
                                      style={{
                                        backgroundColor: "#23547e",
                                        color: "#fff",
                                        borderColor: "#2e6da4",
                                      }}
                                      onClick={this.apisHandlerupdate}
                                    >
                                      Update
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Offcanvas.Body>
                          </Offcanvas>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        style={{ float: "left", marginBottom: "10px" }}
                        type="text"
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.zones.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
