import React, { Component } from "react";
import jsPDF from "jspdf";
import './ThankU.css'
import { Link } from 'react-router-dom'
export default class PoliciesPagepdf extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDownload = () => {
    // Create a new jsPDF instance
    const pdfDoc = new jsPDF();

    // Add text to the PDF
    pdfDoc.text("Your payment is done ", 10, 10);

    // Save the PDF to a Blob
    const blob = pdfDoc.output("blob");

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank"; // Open in a new tab if you want
    link.download = "custom_text.pdf"; // Specify the desired file name
    link.click();
  };

  render() {
    return (
      <div>
        <div className="thank-you-container">
          <h1 className="thank-you-header">Thank You!</h1>
          <p className="thank-you-text">
            We appreciate your business and value you as our customer.
          </p>
          <br />
          <div>
            <p>Generate Pdf <Link onClick={this.handleDownload}>Click here</Link></p>
          </div>
        </div>


      </div>
    );
  }
}
