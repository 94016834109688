import React, { Component } from "react";
import { Button, Row, Col, Offcanvas, Form, Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "./Agebands.css";
import configData from "../config.json";

export default class Agebands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      agebands: [],
      isdefault: false,
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      abId: "",
      cData: {
        abName: "",
        minAge: "",
        maxAge: "",
      },
      errors: {},
      showButton: false,
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.abId,
          sortable: true,
          cell: (row) => <span>{row.abId}</span>,
        },
        {
          name: "Name",
          selector: (row, i) => row.abName,
          sortable: true,
          cell: (row) => <span>{row.abName}</span>,
        },
        {
          name: "Min Age",
          selector: (row, i) => row.minAge,
          sortable: true,
          cell: (row) => <span>{row.minAge}</span>,
        },

        {
          name: "Max Age",
          selector: (row, i) => row.maxAge,
          sortable: true,
          cell: (row) => <span>{row.maxAge}</span>,
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <button
                className="bi bi-pen btn btn-primary m-1"
                onClick={() => this.handleEnterEditMode(row)}
                // style={{
                //   backgroundColor: "#169F85",
                //   border: " 1px solid #169F85",
                //   color: "#000",
                // }}
              />  
              <button
                style={{
                  backgroundColor: "#169F85",
                  border: " 1px solid #169F85",
                  color: "#000",
                }}
                //variant="primary"
                onClick={() => this.apisfetchHandlerupdatestatus()}
                className="bi bi-check-square-fill btn btn btn-success status_ageband m-1"
              />{" "}
              <button
                variant="danger"
                onClick={() => this.handleDelete(row, index)}
                className="bi bi-trash btn btn-danger m-1"
              />
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //editing data
  handleEnterEditMode = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      cData: {
        abName: row.abName,
        minAge: row.minAge,
        maxAge: row.maxAge,
        abId: row.abId,
      },
      isEditing: true,
    });
     };

  handleEnterNewEntryMode = () => {
    this.setState({ isNewEntry: true });
  };

  // Function to save edited data
  handleEditSave = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
     this.setState({ cData: { ...row }, isEditing: true }, () => {
      });
  };
  handleNewEntrySave = () => {
    // const newEntryData = this.state.insuranceData;
    this.setState({
      cData: {
        abName: "",
        minAge: "",
        maxAge: "",
      },
      isNewEntry: false,
    });
  };
  // offcanvas Open
  handleShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleRemoveSpecific = (idx) => {
    const tempRows = this.state.agebands; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ agebands: tempRows });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.agebands.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  inputChange = (e, id) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.cData;
    newFormData[fieldName] = fieldValue;
    this.setState({ cData: newFormData });
  };

  validateForm(e) {
    let agebands = this.state.cData;
    let errors = {};
    let formIsValid = true;

    if (!agebands["abName"]) {
      toast.info("Please pass Name");
      formIsValid = false;
    }
    if (!agebands["minAge"]) {
      toast.info("Please pass Min Age");
      formIsValid = false;
    }
    if (!agebands["maxAge"]) {
      toast.info("Please pass Max Age");
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleAddClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("abStatus", this.state.cData.abStatus);
      formData.append("abName", this.state.cData.abName);
      formData.append("minAge", this.state.cData.minAge);
      formData.append("maxAge", this.state.cData.maxAge);
      fetch(configData.api_url + "agebands/all/", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.apisfetchHandler();
            //       
            this.setState({
              show: false,
              cData: { abName: "", minAge: "", maxAge: "" },
            });
          }
        });
    }
    //console.log(this.state.cData);
  };
  handleDelete = (row, index) => {
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("abId", row.abId);
    // fetch("https://admin.apnapolicy.co.in/agebands/delete",
    fetch(configData.api_url + "agebands/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
        }
      });
    //console.log(row.abId);
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "agebands/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ agebands: data.list });
        }
      });
  };
  apisHandlerupdate = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("abId", this.state.abId);
    formData.append("abName", this.state.cData.abName);
    formData.append("minAge", this.state.cData.minAge);
    formData.append("maxAge", this.state.cData.maxAge);
    fetch(configData.api_url + "agebands/update/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.apisfetchHandler();
          this.handleClose();
          // this.setState({ insurance: data.list });
          this.setState({
            cData: {
              abName: "",
              minAge: "",
              maxAge: "",
            },
            isEditing: true,
          });
          // this.setState({ insurance: data.list });
        }
        //console.log(this.state.insurance);
      });
  };
  apisfetchHandlerupdatestatus = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("abId", this.state.abId);
    formData.append("abName", this.state.cData.abName);
    formData.append("minAge", this.state.cData.minAge);
    formData.append("maxAge", this.state.cData.maxAge);
    fetch(configData.api_url + "agebands/updatestatus/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.apisfetchHandler();
          this.handleClose();
          // this.setState({ insurance: data.list });
          this.setState({
            cData: {
              abName: "",
              minAge: "",
              maxAge: "",
            },
            isEditing: true,
          });
          // this.setState({ insurance: data.list });
        }
        //console.log(this.state.insurance);
      });
  };
  componentDidMount() {
    this.apisfetchHandler();
    this.apisfetchHandlerupdatestatus();
  }
  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.agebands.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>Age Bands</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                     dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <div className="dataTables_length" id="datatable_length">
                        <Button
                          variant="primary"
                          className="bi bi-plus"
                          onClick={this.handleShow}
                        >
                          New
                        </Button>
                        <Offcanvas
                          show={this.state.show}
                          onHide={this.handleClose}
                          {...this.props}
                          style={{ width: "500px", height: "auto" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Add agebands</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group className="mt-3">
                                    <Form.Control
                                      type="text"
                                      name="abName"
                                      value={this.state.cData.abName}
                                      placeholder="Name"
                                      aria-describedby="inputGroupPrepend"
                                      onChange={this.inputChange}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Form.Group className="mt-3">
                                    <Form.Control
                                      type="number"
                                      name="minAge"
                                      min="0"
                                      max="80"
                                      id="minAge"
                                      value={this.state.cData.minAge}
                                      placeholder="Min Age"
                                      aria-describedby="inputGroupPrepend"
                                      onChange={this.inputChange}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Form.Group className="mt-3">
                                    <Form.Control
                                      type="number"
                                      name="maxAge"
                                      min="0"
                                      max="100"
                                      id="maxAge"
                                      value={this.state.cData.maxAge}
                                      placeholder="Max Age"
                                      aria-describedby="inputGroupPrepend"
                                      onChange={this.inputChange}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="d-flex  mt-3">
                                <Col className="d-flex justify-content-end">
                                  <button
                                    className=" btn btn-warning me-2"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      borderColor: "#ccc",
                                    }}
                                    onClick={this.handleClose}
                                  >
                                    Close
                                  </button>{" "}
                                  <button
                                    className="btn btn-primary me-2"
                                    style={{
                                      backgroundColor: "#23547e",
                                      color: "#fff",
                                      borderColor: "#2e6da4",
                                    }}
                                    onClick={this.handleAddClick}
                                  >
                                    save{" "}
                                  </button>
                                </Col>
                              </Row>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    {" "}
                    {this.state.isEditing && (
                      // Edit Offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "500px", height: "auto" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>Edit Age Bands</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Form className="form-horizontal form-label-left input_mask">
                            <Row className="divstyle">
                              <Col>
                                <Form.Group className="mt-3">
                                  <Form.Control
                                    type="text"
                                    name="abName"
                                    value={this.state.cData.abName}
                                    placeholder="Name"
                                    aria-describedby="inputGroupPrepend"
                                    onChange={this.inputChange}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col>
                                <Form.Group className="mt-3">
                                  <Form.Control
                                    type="number"
                                    name="minAge"
                                    min="0"
                                    max="80"
                                    id="minAge"
                                    value={this.state.cData.minAge}
                                    placeholder="Min Age"
                                    aria-describedby="inputGroupPrepend"
                                    onChange={this.inputChange}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col>
                                <Form.Group className="mt-3">
                                  <Form.Control
                                    type="number"
                                    name="maxAge"
                                    min="0"
                                    max="100"
                                    id="maxAge"
                                    value={this.state.cData.maxAge}
                                    placeholder="Max Age"
                                    aria-describedby="inputGroupPrepend"
                                    onChange={this.inputChange}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <br />
                            <div style={{ float: "right" }}>
                              <button
                                className=" btn btn-warning me-2"
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#000",
                                  borderColor: "#ccc",
                                }}
                                onClick={() =>
                                  this.setState({ isEditing: false })
                                }
                              >
                                Close
                              </button>{" "}
                              <button
                                className="btn btn-primary me-2"
                                style={{
                                  backgroundColor: "#23547e",
                                  color: "#fff",
                                  borderColor: "#2e6da4",
                                }}
                                onClick={this.apisHandlerupdate}
                              >
                                Update
                              </button>
                            </div>
                          </Form>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <Row>
                    <div>
                      <input
                        style={{ float: "left", marginBottom: "10px" }}
                        type="text"
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.agebands.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
