import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Healthreliancepins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.healthreliancepins_Id,
          sortable: true,
          cell: (row) => <span>{row.healthreliancepins_Id}</span>,
        },
        {
          name: "Area_ID",
          selector: (row, i) => row.areaId,
          sortable: true,
          cell: (row) => <span>{row.areaId}</span>,
        },
        {
          name: "Area_Name",
          selector: (row, i) => row.areaName,
          sortable: true,
          cell: (row) => <span>{row.areaName}</span>,
        },
        {
          name: "City_or_Village_ID",
          selector: (row, i) => row.cityId,
          sortable: true,
          cell: (row) => <span>{row.cityId}</span>,
        },
        {
          name: "City_or_Village_Name",
          selector: (row, i) => row.cityName,
          sortable: true,
          cell: (row) => <span>{row.cityName}</span>,
        },
        {
          name: "District_ID",
          selector: (row, i) => row.districtId,
          sortable: true,
          cell: (row) => <span>{row.districtId}</span>,
        },
        {
          name: "District_Name",
          selector: (row, i) => row.districtName,
          sortable: true,
          cell: (row) => <span>{row.districtName}</span>,
        },
        {
          name: "State_ID",
          selector: (row, i) => row.stateId,
          sortable: true,
          cell: (row) => <span>{row.stateId}</span>,
        },
        {
          name: "State_Name",
          selector: (row, i) => row.stateName,
          sortable: true,
          cell: (row) => <span>{row.stateName}</span>,
        },
        {
          name: "Pincode",
          selector: (row, i) => row.pincode,
          sortable: true,
          cell: (row) => <span>{row.pincode}</span>,
        },
        {
          name: "Zone",
          selector: (row, i) => row.zone,
          sortable: true,
          cell: (row) => <span>{row.zone}</span>,
        },
        {
          name: "Dummy One",
          selector: (row, i) => row.dummyOne,
          sortable: true,
          cell: (row) => <span>{row.dummyOne}</span>,
        },
        {
          name: "Dummy Two",
          selector: (row, i) => row.dummyTwo,
          sortable: true,
          cell: (row) => <span>{row.dummyTwo}</span>,
        },
        {
          name: "Dummy Three",
          selector: (row, i) => row.dummyThree,
          sortable: true,
          cell: (row) => <span>{row.dummyThree}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={()=>this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      healthreliancepins_Id:"",
      currentPage: 1,
      itemsPerPage: 10,
      areaId: "",
      areaName: "",
      cityId: "",
      cityName: "",
      districtId: "",
      districtName: "",
      stateId: "",
      stateName: "",
      pincode: "",
      zone: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      isEditing:false,
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
    // Edit mode
      handleEnterEditMode = (row) => {
       var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Healthreliancepins/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            areaId:row.areaId,
             areaName:row.areaName,
            healthreliancepins_Id:row.healthreliancepins_Id,
            cityId:row.cityId,
            cityName: row.cityName,
            districtId: row.districtId,
            districtName: row.districtName,
            stateId: row.stateId,
            stateName: row.stateName,
            pincode: row.pincode,
            zone: row.zone,
            dummyOne: row.dummyOne,
            dummyTwo: row.dummyTwo,
            dummyThree:row.dummyThree,
            isEditing: true,
          });
        }
      });
  };
  // hrls update
hrpinsupdate=(e)=>{
        e.preventDefault();
     var formData = new FormData();
      formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("healthreliancepins_Id", this.state.healthreliancepins_Id);
    formData.append("areaId", this.state.areaId);
    formData.append("areaName", this.state.areaName);
    formData.append("cityId", this.state.cityId);
    formData.append("cityName", this.state.cityName);
    formData.append("districtId", this.state.districtId);
    formData.append("districtName", this.state.districtName);
    formData.append("stateId", this.state.stateId);
    formData.append("stateName", this.state.stateName);
    formData.append("pincode", this.state.pincode);
    formData.append("zone", this.state.zone);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);

      
          fetch(configData.api_url +'C_Healthreliancepins/update', {  
            method: 'POST', 
            mode: 'cors', 
            body: formData
          }).then(response => response.json())
            .then((data) => {
              this.showToast(data.message, data.status);
            if (data.status === "success") {
              this.GodigithealthpinmasterfetchHandler();
              this.handleOffcanvasClose();
            }
          });
         }



  HealthreliancepinseditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Healthreliancepins_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  HealthreliancepinsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Healthreliancepins/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  HealthreliancepinssubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("areaId", this.state.areaId);
    formData.append("areaName", this.state.areaName);
    formData.append("cityId", this.state.cityId);
    formData.append("cityName", this.state.cityName);
    formData.append("districtId", this.state.districtId);
    formData.append("districtName", this.state.districtName);
    formData.append("stateId", this.state.stateId);
    formData.append("stateName", this.state.stateName);
    formData.append("pincode", this.state.pincode);
    formData.append("zone", this.state.zone);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);

    fetch(configData.api_url + "C_Healthreliancepins/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthreliancepinsfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            areaId: "",
            areaName: "",
            cityId: "",
            cityName: "",
            districtId: "",
            districtName: "",
            stateId: "",
            stateName: "",
            pincode: "",
            zone: "",
            dummyOne: "",
            dummyTwo: "",
            dummyThree: "",
          });
        }
      });
  };
  HealthreliancepinsupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("areaId", this.state.areaId);
    formData.append("areaName", this.state.areaName);
    formData.append("cityId", this.state.cityId);
    formData.append("cityName", this.state.cityName);
    formData.append("districtId", this.state.districtId);
    formData.append("districtName", this.state.districtName);
    formData.append("stateId", this.state.stateId);
    formData.append("stateName", this.state.stateName);
    formData.append("pincode", this.state.pincode);
    formData.append("zone", this.state.zone);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);

    fetch(configData.api_url + "C_Healthreliancepins/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthreliancepinsfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.HealthreliancepinsfetchHandler();
  }
  handleareaIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ areaId: fieldValue });
  };
  handleareaNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ areaName: fieldValue });
  };
  handlecityIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ cityId: fieldValue });
  };
  handlecityNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ cityName: fieldValue });
  };
  handledistrictIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ districtId: fieldValue });
  };
  handledistrictNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ districtName: fieldValue });
  };
  handlestateIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ stateId: fieldValue });
  };
  handlestateNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ stateName: fieldValue });
  };
  handlepincodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ pincode: fieldValue });
  };
  handlezoneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ zone: fieldValue });
  };
  handledummyOneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyOne: fieldValue });
  };
  handledummyTwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyTwo: fieldValue });
  };
  handledummyThreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyThree: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
     // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("healthreliancepins_Id", row.healthreliancepins_Id);
    fetch(configData.api_url + "C_Healthreliancepins/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthreliancepinsfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>Health Reliance Pins</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Healthreliancepins page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Area_ID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleareaIdChange}
                                        className="form-control"
                                        name="areaId"
                                        value={this.state.areaId}
                                        required="required"
                                        placeholder="Enter areaId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.areaId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Area_Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleareaNameChange}
                                        className="form-control"
                                        name="areaName"
                                        value={this.state.areaName}
                                        required="required"
                                        placeholder="Enter areaName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.areaName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        City_or_Village_ID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecityIdChange}
                                        className="form-control"
                                        name="cityId"
                                        value={this.state.cityId}
                                        required="required"
                                        placeholder="Enter cityId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.cityId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        City_or_Village_Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecityNameChange}
                                        className="form-control"
                                        name="cityName"
                                        value={this.state.cityName}
                                        required="required"
                                        placeholder="Enter cityName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.cityName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        District_ID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledistrictIdChange}
                                        className="form-control"
                                        name="districtId"
                                        value={this.state.districtId}
                                        required="required"
                                        placeholder="Enter districtId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.districtId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        District_Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledistrictNameChange}
                                        className="form-control"
                                        name="districtName"
                                        value={this.state.districtName}
                                        required="required"
                                        placeholder="Enter districtName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.districtName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        State_ID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlestateIdChange}
                                        className="form-control"
                                        name="stateId"
                                        value={this.state.stateId}
                                        required="required"
                                        placeholder="Enter stateId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.stateId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        State_Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlestateNameChange}
                                        className="form-control"
                                        name="stateName"
                                        value={this.state.stateName}
                                        required="required"
                                        placeholder="Enter stateName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.stateName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Pincode
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepincodeChange}
                                        className="form-control"
                                        name="pincode"
                                        value={this.state.pincode}
                                        required="required"
                                        placeholder="Enter pincode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.pincode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Zone
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlezoneChange}
                                        className="form-control"
                                        name="zone"
                                        value={this.state.zone}
                                        required="required"
                                        placeholder="Enter zone"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.zone}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy One
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyOneChange}
                                        className="form-control"
                                        name="dummyOne"
                                        value={this.state.dummyOne}
                                        required="required"
                                        placeholder="Enter dummyOne"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyOne}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyTwo"
                                        value={this.state.dummyTwo}
                                        required="required"
                                        placeholder="Enter dummyTwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyThreeChange}
                                        className="form-control"
                                        name="dummyThree"
                                        value={this.state.dummyThree}
                                        required="required"
                                        placeholder="Enter dummyThree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyThree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.HealthreliancepinssubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  {/*  edit off canvas */}
                  {
                    this.state.isEditing&&(
                          <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                  
                      <Offcanvas
                        show={this.state.isEditing}
                     onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                           Edit Healthreliancepins page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Area_ID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleareaIdChange}
                                        className="form-control"
                                        name="areaId"
                                        value={this.state.areaId}
                                        required="required"
                                        placeholder="Enter areaId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.areaId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Area_Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleareaNameChange}
                                        className="form-control"
                                        name="areaName"
                                        value={this.state.areaName}
                                        required="required"
                                        placeholder="Enter areaName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.areaName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        City_or_Village_ID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecityIdChange}
                                        className="form-control"
                                        name="cityId"
                                        value={this.state.cityId}
                                        required="required"
                                        placeholder="Enter cityId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.cityId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        City_or_Village_Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecityNameChange}
                                        className="form-control"
                                        name="cityName"
                                        value={this.state.cityName}
                                        required="required"
                                        placeholder="Enter cityName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.cityName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        District_ID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledistrictIdChange}
                                        className="form-control"
                                        name="districtId"
                                        value={this.state.districtId}
                                        required="required"
                                        placeholder="Enter districtId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.districtId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        District_Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledistrictNameChange}
                                        className="form-control"
                                        name="districtName"
                                        value={this.state.districtName}
                                        required="required"
                                        placeholder="Enter districtName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.districtName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        State_ID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlestateIdChange}
                                        className="form-control"
                                        name="stateId"
                                        value={this.state.stateId}
                                        required="required"
                                        placeholder="Enter stateId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.stateId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        State_Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlestateNameChange}
                                        className="form-control"
                                        name="stateName"
                                        value={this.state.stateName}
                                        required="required"
                                        placeholder="Enter stateName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.stateName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Pincode
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepincodeChange}
                                        className="form-control"
                                        name="pincode"
                                        value={this.state.pincode}
                                        required="required"
                                        placeholder="Enter pincode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.pincode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Zone
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlezoneChange}
                                        className="form-control"
                                        name="zone"
                                        value={this.state.zone}
                                        required="required"
                                        placeholder="Enter zone"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.zone}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy One
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyOneChange}
                                        className="form-control"
                                        name="dummyOne"
                                        value={this.state.dummyOne}
                                        required="required"
                                        placeholder="Enter dummyOne"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyOne}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyTwo"
                                        value={this.state.dummyTwo}
                                        required="required"
                                        placeholder="Enter dummyTwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyThreeChange}
                                        className="form-control"
                                        name="dummyThree"
                                        value={this.state.dummyThree}
                                        required="required"
                                        placeholder="Enter dummyThree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyThree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.hrpinsupdate}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                    )
                  }
                  {""}
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                          paginationRowsPerPageOptions={[10, 20, 30, 50,60,70,80,90, 100]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
