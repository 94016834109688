import React, { Component } from "react";
import "./Familydefinitions.css";
import { Offcanvas, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import configData from "../config.json";

export default class Familydefinitions extends Component {
  constructor(props) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      familydefinations: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      fdId: "",
      cData: {
        fdName: "",
      },
      show: false,
      filteredData: null,
      tableClms: [
        {
          name: "S.No",
          selector: (row, i) => row.fdId,
          sortable: true,
          cell: (row) => <span>{row.fdId}</span>,
        },
        {
          name: "User Name",
          selector: (row, i) => row.fdName,
          sortable: true,
          cell: (row) => <span>{row.fdName}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <button
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen btn btn-primary m-1"
              ></button>
              <button
                style={{
                  backgroundColor: "#169F85",
                  border: " 1px solid #169F85",
                  color: "#000",
                }}
                // variant="primary"
                onClick={() => this.apisfetchHandlerupdatestatus()}
                className="bi bi-check-square-fill btn btn btn-success status_ageband m-1"
              />{" "}
              <button
                variant="danger"
                onClick={() => this.handleDelete(row, index)}
                className="bi bi-trash btn btn-danger m-1"
              />
              {/* <Button
                variant="secondary"
                onClick={() => this.apisfetchHandlerupdatestatus()}
                className="bi bi-check-square btn btn-secondary"
              ></Button> */}
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //editing data
  handleEnterEditMode = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      fdId: row.fdId,
      cData: {
        fdName: row.fdName,
      },
      isEditing: true,
    });
     };

  handleEnterNewEntryMode = () => {
    this.setState({ isNewEntry: true });
  };

  // Function to save edited data
  handleEditSave = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
     this.setState({ cData: { ...row }, isEditing: true }, () => {
      });
  };
  handleNewEntrySave = () => {
    // const newEntryData = this.state.insuranceData;
    this.setState({
      cData: {
        fdName: "",
      },
      isNewEntry: false,
    });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  // offcanvas Open
  handleShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };
  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.familydefinations.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  inputChange = (e, id) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.cData;
    newFormData[fieldName] = fieldValue;
    this.setState({ cData: newFormData });
  };

  validateForm(e) {
    let familydefinations = this.state.cData;
    let errors = {};
    let formIsValid = true;

    if (!familydefinations["fdName"]) {
      toast.info("Please Pass Name");
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleAddClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("abStatus", this.state.cData.abStatus);
      formData.append("fdName", this.state.cData.fdName);
      fetch(configData.api_url + "/familydefinations/all/", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.apisfetchHandler();
            this.setState({
              show: false,
              cData: { fdName: "" },
            });
          }
        });
    }
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("fdId", row.fdId);
    fetch(configData.api_url + "/familydefinations/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
        }
      });
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/familydefinations/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ familydefinations: data.list });
        }
      });
  };
  apisHandlerupdate = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("fdName", this.state.cData.fdName);
    formData.append("fdId", this.state.fdId);
    fetch(configData.api_url + "/familydefinations/update/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success" || data.status === "true") {
          this.apisfetchHandler();
          this.handleClose();
          this.setState({
            cData: {
              fdName: "",
            },
          });
        }
       });
  };
  apisfetchHandlerupdatestatus = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("fdName", this.state.cData.fdName);
    formData.append("fdId", this.state.fdId);
    fetch(configData.api_url + "/familydefinations/updatestatus/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.apisfetchHandler();
          this.handleClose();
          this.setState({
            cData: {
              fdName: "",
            },
          });
        }
       });
  };
  componentDidMount() {
    this.apisfetchHandler();
    this.apisfetchHandlerupdatestatus();
  }

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.familydefinations.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div className="row">
        <div className="">
          <h5>Family Definations</h5>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_content">
              <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                <div className="row">
                  <div className="d-flex justify-content-end">
                    <div className="dataTables_length" id="datatable_length">
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleShow}
                        style={{ float: "right" }}
                      >
                        New
                      </Button>
                    </div>
                    <Offcanvas
                      show={this.state.show}
                      onHide={this.handleClose}
                      {...this.props}
                      style={{ width: "500px" }}
                      placement="end"
                      backdrop="true"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Add Family Defination</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <div className="row">
                          <div className="col-md-12 col-xs-12">
                            <form className="form-horizontal form-label-left input_mask">
                              <div className="col-md-12 col-sm-12 col-xs-12 form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="fdName"
                                  name="fdName"
                                  value={this.state.cData.fdName}
                                  placeholder="Name"
                                  onChange={this.inputChange}
                                />
                              </div>
                            </form>
                            <br />
                            <div className="col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                              <button
                                className=" btn btn-warning me-2"
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#000",
                                  borderColor: "#ccc",
                                }}
                                onClick={this.handleClose}
                              >
                                Close
                              </button>{" "}
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.handleAddClick}
                              >
                                Save{""}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </div>
                </div>
                <div>
                  {" "}
                  {this.state.isEditing && (
                    // Edit Offcanvas
                    <Offcanvas
                      show={this.state.isEditing}
                      onHide={() => this.setState({ isEditing: false })}
                      {...this.props}
                      style={{ width: "500px", height: "auto" }}
                      placement="end"
                      backdrop="true"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Edit FamilyDefinition</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <div className="form-horizontal form-label-left input_mask">
                          <form className="form-horizontal form-label-left input_mask">
                            <div className="col-md-12 col-sm-12 col-xs-12 form-group ">
                              <input
                                type="text"
                                className="form-control"
                                id="fdName"
                                name="fdName"
                                value={this.state.cData.fdName}
                                placeholder="Name"
                                onChange={this.inputChange}
                              />
                            </div>
                            <br />
                            <div style={{ float: "right" }}>
                              <button
                                className="btn btn-primary me-2"
                                style={{
                                  backgroundColor: "#23547e",
                                  color: "#fff",
                                  borderColor: "#2e6da4",
                                }}
                                onClick={this.apisHandlerupdate}
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>
                  )}
                </div>
                <div>
                  <div>
                    {/* searchbar,pagination,filter */}
                    <input
                      style={{ float: "left" }}
                      type="text"
                      onChange={this.handleFilter}
                      placeholder="Search..."
                    />
                    <DataTable
                      data={paginatedData}
                      columns={this.state.tableClms}
                      pagination
                      paginationServer
                      paginationTotalRows={
                        this.state.filteredData
                          ? this.state.filteredData.length
                          : this.state.familydefinations.length
                      }
                      // paginationTotalRows={this.state.insurance.length}
                      onChangeRowsPerPage={this.handlePerRowsChange}
                      onChangePage={this.handlePageChange}
                      paginationPerPage={this.state.itemsPerPage}
                      keyField="id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
