import React, { Component } from "react";
import "./Zipcodes.css";
import { Offcanvas, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import configData from "../config.json";

export default class Zipcodes extends Component {
  constructor(props) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      zipcode: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      zcId: "",
      cData: {
        zId: "",
        city: "",
        zcName: "",
      },
      zipData: {
        zIdm: "",
        zcNameFile: null,
      },
      show: false,
      showOne: false,
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.zcId,
          sortable: true,
          cell: (row) => <span>{row.zcId}</span>,
        },
        {
          name: "Name",
          selector: (row, i) => row.zcName,
          sortable: true,
          cell: (row) => <span>{row.zcName}</span>,
        },
        {
          name: "city",
          selector: (row, i) => row.city,
          sortable: true,
          cell: (row) => <span>{row.city}</span>,
        },
        {
          name: "Zone",
          selector: (row, i) => row.zId,
          sortable: true,
          cell: (row) => <span>{row.zId}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
              ></Button>
              <Button
                style={{
                  backgroundColor: "#169F85",
                  border: " 1px solid #169F85",
                  color: "#000",
                }}
                onClick={() => this.apisfetchHandlerupdatestatus()}
                className="bi bi-check-square btn btn-secondary"
              ></Button>
              <button
                variant="danger"
                onClick={() => this.handleDelete(row, index)}
                className="bi bi-trash btn btn-danger m-1"
              />
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //editing data
  handleEnterEditMode = (row) => {
       var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      zcId: row.zcId,
      cData: {
        zId: row.zId,
        city: row.city,
        zcName: row.zcName,
      },
      zipData: {
        zIdm: row.zIdm,
        zcNameFile: null,
      },
      isEditing: true,
    });
  };
  handleEnterNewEntryMode = () => {
    this.setState({ isNewEntry: true });
  };

  // Function to save edited data
  handleEditSave = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({ cData: { ...row }, isEditing: true }, () => {
    });
  };
  handleNewEntrySave = () => {
    this.setState({
      cData: {
        zId: "",
        city: "",
        zcName: "",
      },
      isNewEntry: false,
    });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  // offcanvas Open
  handleShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };
  //second offcanvas
  handleShowOne = () => {
    this.setState({ showOne: true });
  };

  handleCloseOne = () => {
    this.setState({ showOne: false });
  };
  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.zipcode.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  inputChange = (e, id) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.cData;
    newFormData[fieldName] = fieldValue;
    this.setState({ cData: newFormData });
  };
  inputUploadChange = (e, id) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.zipData;
    newFormData[fieldName] = fieldValue;
    this.setState({ zipData: newFormData });
  };
  inputChangeImage = (event) => {
    const file = event.target.files[0];
    const newFormData = this.state.zipData;
    newFormData["zcNameFile"] = file;
    this.setState({ zipData: newFormData });
  };

  validateForm(e) {
    let zipcode = this.state.cData;
    let errors = {};
    let formIsValid = true;

    if (!zipcode["zId"]) {
      toast.info("Please Pass Zone");
      formIsValid = false;
    }
    if (!zipcode["city"]) {
      toast.info("Please Pass City");
      formIsValid = false;
    }
    if (!zipcode["zcName"]) {
      toast.info("Please Pass Name");
      formIsValid = false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleAddClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("abStatus", this.state.cData.abStatus);
      formData.append("zId", this.state.cData.zId);
      formData.append("city", this.state.cData.city);
      formData.append("zcName", this.state.cData.zcName);

      fetch(configData.api_url + "zipcodes/all/", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.apisfetchHandler();
            this.setState({
              show: false,
              cData: {
                zId: "",
                city: "",
                zcName: "",
              },
            });
          }
        });
    }
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("zcId", row.zcId);
    fetch(configData.api_url + "zipcodes/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
        }
      });
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "zipcodes/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ zipcode: data.list });
        }
      });
  };
  apisHandlerupdate = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("abStatus", this.state.cData.abStatus);
    formData.append("zId", this.state.cData.zId);
    formData.append("city", this.state.cData.city);
    formData.append("zcName", this.state.cData.zcName);
    formData.append("zcId", this.state.zcId);
    fetch(configData.api_url + "zipcodes/update/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success" || data.status === "true") {
          this.handleClose();
          this.apisfetchHandler();
          this.setState({
            show: false,
            cData: {
              zId: "",
              city: "",
              zcName: "",
            },
          });
        }
      });
  };
  apisfetchHandlerupdatestatus = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("zcId", this.state.zcId);
    fetch(configData.api_url + "zipcodes/updatestatus/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success" || data.status === "true") {
          this.handleClose();
          this.apisfetchHandler();
          this.setState({
            show: false,
            zcId: "",
          });
        }
      });
  };
  componentDidMount() {
    this.apisfetchHandler();
    this.apisfetchHandlerupdatestatus();
  }
  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.zipcode.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div className="row">
        <div className="row">
          <div className="title_left">
            <h2>Zipcodes</h2>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_content">
              <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                <div className="row">
                  <div className="col-sm-6 col-lg-12 col-md-12">
                    <div
                      className="dataTables_length justify-content-end"
                      id="datatable_length"
                      style={{
                        marginRight: "5px",
                        marginTop: "20px",
                        float: "right",
                      }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleShow}
                      >
                        New
                      </Button>
                      &nbsp;
                      <Button
                        variant="primary"
                        className="bi bi-upload"
                        onClick={this.handleShowOne}
                      ></Button>
                    </div>
                    <Offcanvas
                      show={this.state.show}
                      onHide={this.handleClose}
                      {...this.props}
                      style={{ width: "500px" }}
                      placement="end"
                      backdrop="true"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Add Zipcode</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <div className="row">
                          <div className="col-md-12 col-xs-12">
                            <form className="form-horizontal form-label-left input_mask">
                              <div className="row d-flex">
                                <div className="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                                  <select
                                    id="zId"
                                    name="zId"
                                    value={this.state.cData.zId}
                                    onChange={this.inputChange}
                                    className="form-control"
                                    required=""
                                  >
                                    <option value="">Select Zone</option>
                                    <option value="1">Zone1</option>
                                    <option value="2">Zone2</option>
                                    <option value="3">Zone3</option>
                                  </select>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                                  <div className="input-group mb-3">
                                    <span
                                      className="input-group-text bi bi-pin-map-fill form-control-feedback left"
                                      id="basic-addon1"
                                    ></span>
                                    <input
                                      type="text"
                                      className="form-control has-feedback-left ui-autocomplete-input"
                                      id="city"
                                      name="city"
                                      value={this.state.cData.city}
                                      onChange={this.inputChange}
                                      placeholder="city"
                                      autocomplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 col-sm-12 col-xs-12 form-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="zcName"
                                  name="zcName"
                                  value={this.state.cData.zcName}
                                  onChange={this.inputChange}
                                  placeholder="Name"
                                />
                              </div>
                            </form>
                            <br />
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this.handleAddClick}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>
                    <div>
                      {" "}
                      {this.state.isEditing && (
                        // Edit Offcanvas
                        <Offcanvas
                          show={this.state.isEditing}
                          onHide={() => this.setState({ isEditing: false })}
                          {...this.props}
                          style={{ width: "700px", height: "auto" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Edit Zipcode</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <div className="form-horizontal form-label-left input_mask">
                              <form className="form-horizontal form-label-left input_mask">
                                <div className="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                                  <select
                                    id="zId"
                                    name="zId"
                                    value={this.state.cData.zId}
                                    onChange={this.inputChange}
                                    className="form-control"
                                    required=""
                                  >
                                    <option value="">Select Zone</option>
                                    <option value="1">Zone1</option>
                                    <option value="2">Zone2</option>
                                    <option value="3">Zone3</option>
                                  </select>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12 form-group has-feedback">
                                  <div className="input-group mb-3">
                                    <span
                                      className="input-group-text bi bi-pin-map-fill form-control-feedback left"
                                      id="basic-addon1"
                                    ></span>
                                    <input
                                      type="text"
                                      className="form-control has-feedback-left ui-autocomplete-input"
                                      id="city"
                                      name="city"
                                      value={this.state.cData.city}
                                      onChange={this.inputChange}
                                      placeholder="city"
                                      autocomplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12 form-group ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="zcName"
                                    name="zcName"
                                    value={this.state.cData.zcName}
                                    onChange={this.inputChange}
                                    placeholder="Name"
                                  />
                                </div>
                                <br />
                                <div style={{ float: "right" }}>
                                  <button
                                    className=" btn btn-warning me-2"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      borderColor: "#ccc",
                                    }}
                                    onClick={() =>
                                      this.setState({ isEditing: false })
                                    }
                                  >
                                    Close
                                  </button>{" "}
                                  <button
                                    className="btn btn-primary me-2"
                                    style={{
                                      backgroundColor: "#23547e",
                                      color: "#fff",
                                      borderColor: "#2e6da4",
                                    }}
                                    onClick={this.apisHandlerupdate}
                                  >
                                    Update
                                  </button>
                                </div>
                              </form>
                            </div>
                          </Offcanvas.Body>
                        </Offcanvas>
                      )}
                    </div>
                    <div className="row">
                      <div className="d-flex justify-content-end">
                        <div
                          className="dataTables_length"
                          id="datatable_length"
                        >
                          <Offcanvas
                            show={this.state.showOne}
                            onHide={this.handleCloseOne}
                            {...this.props}
                            style={{ width: "500px" }}
                            placement="end"
                            backdrop="true"
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>Add ZipCodes</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <div className="row">
                                <div className="col-md-12 col-xs-12">
                                  <form className="form-horizontal form-label-left input_mask">
                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group has-feedback">
                                      <select
                                        id="zIdm"
                                        name="zIdm"
                                        value={this.state.zipData.zIdm}
                                        onChange={this.inputUploadChange}
                                        className="form-control"
                                        required=""
                                      >
                                        <option value="">Select Zone</option>
                                        <option value="1">Zone1</option>
                                        <option value="2">Zone2</option>
                                        <option value="3">Zone3</option>
                                      </select>
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group ">
                                      <input
                                        type="file"
                                        className="form-control"
                                        accept=".xlsx,.xls,.csv"
                                        id="zcNameFile"
                                        name="zcNameFile"
                                        value={this.state.zcNameFile}
                                        onChange={this.inputChangeImage}
                                        placeholder="File"
                                      />
                                    </div>
                                  </form>
                                  <br />
                                  <div className="col-md-12 col-sm-12 col-xs-12 d-flex justify-content-end">
                                    <button
                                      className="btn btn-primary me-2"
                                      style={{
                                        backgroundColor: "#23547e",
                                        color: "#fff",
                                        borderColor: "#2e6da4",
                                      }}
                                      // onClick={this.handleAddClick}
                                    >
                                      Update{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Offcanvas.Body>
                          </Offcanvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    {/* searchbar,pagination,filter */}
                    <input
                      style={{ float: "left", marginBottom: "10px" }}
                      type="text"
                      onChange={this.handleFilter}
                      placeholder="Search..."
                    />
                    <DataTable
                      data={paginatedData}
                      columns={this.state.tableClms}
                      pagination
                      paginationServer
                      paginationTotalRows={
                        this.state.filteredData
                          ? this.state.filteredData.length
                          : this.state.zipcode.length
                      }
                      paginationRowsPerPageOptions={[10, 20, 30, 50,60,70,80,90, 100]}
                      // paginationTotalRows={this.state.insurance.length}
                      onChangeRowsPerPage={this.handlePerRowsChange}
                      onChangePage={this.handlePageChange}
                      paginationPerPage={this.state.itemsPerPage}
                      keyField="id"
                      className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
