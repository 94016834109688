import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class Leaverequestapprovals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      minDate: new Date(),
      startDate: new Date(),
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.leaverequestapprovals_Id,
          sortable: true,
          cell: (row) => <span>{row.leaverequestapprovals_Id}</span>,
        },
        {
          name: "From Date",
          selector: (row, i) => row.FromDate,
          sortable: true,
          cell: (row) => <span>{row.FromDate}</span>,
        },
        {
          name: "To Date",
          selector: (row, i) => row.ToDate,
          sortable: true,
          cell: (row) => <span>{row.ToDate}</span>,
        },
        {
          name: "No. of Leaves",
          selector: (row, i) => row.NoOfLeaves,
          sortable: true,
          cell: (row) => <span>{row.NoOfLeaves}</span>,
        },
        {
          name: "Leave Type",
          selector: (row, i) => row.LeaveType,
          sortable: true,
          cell: (row) => <span>{row.LeaveType}</span>,
        },
        {
          name: "Description",
          selector: (row, i) => row.remarks,
          sortable: true,
          cell: (row) => <span>{row.remarks}</span>,
        },
      ],
      currentPage: 1,
      itemsPerPage: 40,
      RequestedBy: "",
      LeaveBalance: "",
      FromDate: null,
      //FromDate: this.getCurrentDate(),
      ToDate: null,
      NoOfLeaves: 0,
      leavetypes: [],
      Holidays: [],
      LeaveType: "",
      remarks: "",
      leaverequestapprovals_Id: "",
      isEditing: false,
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      uName: row.uName,
      LeaveBalance: row.LeaveBalance,
      FromDate: row.FromDate,
      ToDate: row.ToDate,
      NoOfLeaves: row.NoOfLeaves,
      LeaveType: row.LeaveType,
      remarks: row.remarks,
      leaverequestapprovals_Id: row.leaverequestapprovals_Id,
      isEditing: true,
    });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  LeaverequestapprovalseditHandler = (e) => {
    e.preventDefault();
  };

  LeaverequestapprovalsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Leaverequestapprovals/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;
    if (!data["FromDate"]) {
      formIsValid = false;
      toast.info("*Please Enter FromDate.");
    } else if (!data["ToDate"]) {
      formIsValid = false;
      toast.info("*Please Enter ToDate.");
    } else if (!data["LeaveType"]) {
      formIsValid = false;
      toast.info("*Please Enter LeaveType.");
    } else if (!data["remarks"]) {
      formIsValid = false;
      toast.info("*Please Enter Remarks.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  LeaverequestapprovalssubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    if (this.validateForm()) {
      if (this.state.NoOfLeaves <= this.state.LeaveBalance) {
        var formData = new FormData();
        formData.append("sId", this.state.sId);
        formData.append("uId", this.state.uId);
        formData.append("RequestedBy", this.state.uName);
        formData.append("LeaveBalance", this.state.LeaveBalance);
        formData.append("FromDate", this.state.FromDate);
        formData.append("ToDate", this.state.ToDate);
        formData.append("NoOfLeaves", this.state.NoOfLeaves);
        formData.append("LeaveType", this.state.LeaveType);
        formData.append("remarks", this.state.remarks);
        formData.append(
          "leaverequestapprovals_Id",
          this.state.leaverequestapprovals_Id
        );
        fetch(configData.api_url + "C_Leaverequestapprovals/add", {
          method: "POST",
          mode: "cors",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            this.showToast(data.message, data.status);
            if (data.status === "success") {
              this.LeaverequestapprovalsfetchHandler();
              this.handleOffcanvasClose();
              this.setState({
                FromDate: "",
                ToDate: "",
                NoOfLeaves: "",
                LeaveType: "",
                remarks: "",
                DecisionRemarks: "",
                ApproverID: "",
                ApproverType: "",
                leaverequestapprovals_Id: "",
              });
            }
          });
      }
    }
  };
  LeaverequestapprovalsupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("RequestedBy", this.state.uName);
    formData.append("LeaveBalance", this.state.LeaveBalance);
    formData.append("FromDate", this.state.FromDate);
    formData.append("ToDate", this.state.ToDate);
    formData.append("NoOfLeaves", this.state.NoOfLeaves);
    formData.append("LeaveType", this.state.LeaveType);
    formData.append("remarks", this.state.remarks);
    formData.append("DecisionRemarks", this.state.DecisionRemarks);
    formData.append("ApproverID", this.state.ApproverID);
    formData.append("ApproverType", this.state.ApproverType);
    formData.append(
      "leaverequestapprovals_Id",
      this.state.leaverequestapprovals_Id
    );
    fetch(configData.api_url + "C_Leaverequestapprovals/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.LeaverequestapprovalsfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            uName: "",
            LeaveBalance: "",
            FromDate: "",
            ToDate: "",
            NoOfLeaves: "",
            leavetypes: [],
            LeaveType: "",
            remarks: "",
          });
        }
      });
  };
  getleaveBalance = () => {
    // e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("employees_Id", this.state.uId);
    fetch(configData.api_url + "C_Employees/retrive", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ LeaveBalance: data.detail.leaves });
        }
      });
  };
  HolidayslistfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Holidayslist/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ Holidays: data.list });
        }
      });
  };
  componentDidMount() {
    this.LeaverequestapprovalsfetchHandler();
    this.leavetypesfetchHandler();
    this.getleaveBalance();
    this.HolidayslistfetchHandler();
  }
  handleRequestedByChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ RequestedBy: fieldValue });
  };
  handleLeaveBalanceChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ LeaveBalance: fieldValue });
  };

  getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    // let month = currentDate.getMonth() + 1;
    // let day = currentDate.getDate();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  handleFromDateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState(
        { FromDate: formattedDate, startDate: new Date() },
        this.calculateLeaveBalance
      );
    }
  };

  // Handle To Date Change
  handleToDateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState(
        { ToDate: formattedDate, startDate: new Date() },
        this.calculateLeaveBalance
      );
    }
  };
  calculateLeaveBalance = () => {
    const { FromDate, ToDate } = this.state;

    if (!FromDate || !ToDate) {
      return;
    }

    const fromDate = new Date(FromDate);
    const toDate = new Date(ToDate);
    let totalLeaveDays = 0;

    for (
      let date = new Date(fromDate);
      date <= toDate;
      date.setDate(date.getDate() + 1)
    ) {
      if (this.isWorkingDay(date) && !this.isHoliday(date)) {
        totalLeaveDays++;
      }
    }

    console.log(`Total leave days: ${totalLeaveDays}`);
    this.setState({ NoOfLeaves: totalLeaveDays });
  };

  isWorkingDay = (date) => {
    const dayOfWeek = date.getDay();
    const lastSaturday = this.getLastSaturdayOfMonth(date);
    const isWorking =
      dayOfWeek !== 0 && (dayOfWeek !== 6 || date.getDate() === lastSaturday);
    return isWorking;
  };

  isHoliday = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    return this.state.Holidays.some(
      (holiday) => holiday.Date === formattedDate
    );
  };

  getLastSaturdayOfMonth = (date) => {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastSaturday = lastDay.getDate() - ((lastDay.getDay() + 1) % 7);
    return lastSaturday;
  };

  handleNoOfLeavesChange = (e) => {
    const fieldValue = parseInt(e.target.value, 10); // Parse input as integer
    if (!isNaN(fieldValue) && fieldValue >= 0) {
      // If fieldValue is a non-negative number
      this.setState({ NoOfLeaves: fieldValue });
    } else {
      // If fieldValue is negative or NaN, set NoOfLeaves to 0
      this.setState({ NoOfLeaves: 0 });
    }
  };

  handleLeaveTypeChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ LeaveType: fieldValue });
  };
  leavetypesfetchHandler = () => {
    fetch(configData.api_url + "C_Leavetypes/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ leavetypes: data.list });
        }
      });
  };
  handleDecisionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ remarks: fieldValue });
  };
  handleDecisionRemarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ DecisionRemarks: fieldValue });
  };
  handleApproverIDChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ApproverID: fieldValue });
  };
  handleApproverTypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ApproverType: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("leaverequestapprovals_Id", row.leaverequestapprovals_Id);
    fetch(configData.api_url + "C_Leaverequestapprovals/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.LeaverequestapprovalsfetchHandler();
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    const uName = sessionStorage.getItem("userName");
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1);
    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col>
                      <div className="title_left">
                        <h3
                          className="logoskybluecolor"
                          style={{ textAlign: "start" }}
                        >
                          Leaves
                        </h3>
                      </div>

                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>Leaves page</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Requested By
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        {uName}
                                      </Form.Label>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.RequestedBy}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Paid Leaves Avaliable
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        {this.state.LeaveBalance}
                                      </Form.Label>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.LeaveBalance}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        From Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        name="FromDate"
                                        //onChange={this.handledobChange}
                                        dateFormat="dd/MM/yyyy"
                                        showTimeSelect={false}
                                        selected={this.state.FromDate}
                                        // onChange={(date) =>
                                        //   this.handleDChange(date)
                                        // }
                                        onChange={this.handleFromDateChange}
                                        className="form-control"
                                        id="FromDate"
                                        placeholderText="Select Current Date"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        // minDate={previousYearStart}

                                        dropdownMode="select"
                                        minDate={new Date()}
                                        //maxDate={new Date()}
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.FromDate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        To Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        name="ToDate"
                                        dateFormat="dd/MM/yyyy"
                                        showTimeSelect={false}
                                        selected={this.state.ToDate}
                                        // onChange={(date) =>
                                        //   this.handleDOJChange(date)
                                        // }
                                        onChange={this.handleToDateChange}
                                        className="form-control"
                                        id="ToDate"
                                        placeholderText="Select Date"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        //minDate={previousYearStart}
                                        dropdownMode="select"
                                        minDate={this.state.FromDate}
                                        //maxDate={new Date()}
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.ToDate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        No. of Leaves
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handleNoOfLeavesChange}
                                        className="form-control"
                                        name="NoOfLeaves"
                                        value={this.state.NoOfLeaves}
                                        required="required"
                                        placeholder="Enter NoOfLeaves"
                                        readOnly
                                        disabled
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.NoOfLeaves}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Leave Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <select
                                        className="form-select"
                                        name="LeaveType"
                                        value={this.state.LeaveType}
                                        style={{ borderRadius: "0px" }}
                                        onChange={this.handleLeaveTypeChange}
                                      >
                                        <option disabled selected value="">
                                          Select leave Type
                                        </option>
                                        {this.state.leavetypes.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={api.LeaveType}
                                            >
                                              {" "}
                                              {api.LeaveType}{" "}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.LeaveType}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Reason
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        as="textarea"
                                        onChange={this.handleDecisionChange}
                                        className="form-control"
                                        name="remarks"
                                        value={this.state.remarks}
                                        required="required"
                                        placeholder="Enter Remarks"
                                        rows={3}
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.remarks}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.LeaverequestapprovalssubmitHandler
                                  }
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <Button
                        style={{ float: "right" }}
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
