import React, { Component } from "react";
import "./InsuranceTypes.css";
import { Col, Row, Button, Container, Form } from "react-bootstrap";
import { Offcanvas } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import configData from "../config.json";

export default class InsuranceTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insurance: [],
      data: [],
      show: false,
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      itId: "",
      insuranceData: {
        itName: "",
        itDescription: "",
        itText: "",
        icId: "",
        sOrder: "",
      },
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.itId,
          sortable: true,
          cell: (row) => <span>{row.itId}</span>,
        },
        {
          name: "Name",
          selector: (row, i) => row.itName,
          sortable: true,
          cell: (row) => <span>{row.itName}</span>,
        },
        {
          name: "Description",
          selector: (row, i) => row.itDescription,
          sortable: true,
          cell: (row) => <span>{row.itDescription}</span>,
        },
        {
          name: "Sorting Order",
          selector: (row, i) => row.sOrder,
          sortable: true,
          cell: (row) => <span>{row.sOrder}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <button
                style={{ marginLeft: "10px" }}
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen btn btn-primary"
              ></button>

              <button
                variant="danger"
                onClick={() => this.handleDelete(row, index)}
                className="bi bi-trash btn btn-danger"
              ></button>
            
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //editing data
  handleEnterEditMode = (row) => {
       var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      itId: row.itId,
      insuranceData: {
        itName: row.itName,
        itDescription: row.itDescription,
        itText: row.itText,
        icId: row.icId,
        sOrder: row.sOrder,
      },
      isEditing: true,
    });
  };

  handleEnterNewEntryMode = () => {
    this.setState({ isNewEntry: true });
  };

  // Function to save edited data
  handleEditSave = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
     this.setState({ insuranceData: { ...row }, isEditing: true }, () => {
      });
  };
  handleNewEntrySave = () => {
    this.setState({
      insuranceData: {
        itName: "",
        itDescription: "",
        itText: "",
        icId: "",
        sOrder: "",
      },
      isNewEntry: false,
    });
  };

  // offcanvas Open
  handleShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };
  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.insurance.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  inputChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.insuranceData;
    newFormData[fieldName] = fieldValue;
    this.setState({ insuranceData: newFormData });
  };
  inputChangeImage = (event) => {
    const file = event.target.files[0];
    const newFormData = this.state.insuranceData;
    newFormData["itPath"] = file;
    this.setState({ insuranceData: newFormData });
  };
  validateForm() {
    let insurnces = this.state.insuranceData;
    let errors = {};
    let formIsValid = true;

    if (!insurnces["itName"]) {
      formIsValid = false;
      toast.info("*Please Enter  Name.");
    } else if (!insurnces["itDescription"]) {
      formIsValid = false;
      toast.info("*Please Enter Description.");
    } else if (!insurnces["itText"]) {
      formIsValid = false;
      toast.info("*Please Enter It Text.");
    } else if (!insurnces["icId"]) {
      formIsValid = false;
      toast.info("*Please Select Categories.");
    } else if (!insurnces["sOrder"]) {
      formIsValid = false;
      toast.info("*Please Enter Sorting Order");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleAddInsurance = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("itName", this.state.insuranceData.itName);
      formData.append("itDescription", this.state.insuranceData.itDescription);
      formData.append("itText", this.state.insuranceData.itText);
      formData.append("icId", this.state.insuranceData.icId);
      formData.append("sOrder", this.state.insuranceData.sOrder);
      fetch(configData.api_url + "/insurancetypes/add/", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.apisfetchHandler();
            this.setState({
              show: false,
              insuranceData: {
                itName: "",
                itDescription: "",
                itText: "",
                icId: "",
                sOrder: "",
              },
            });
          }
        });
    }
  };

  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("itId", row.itId);
    fetch(configData.api_url + "/insurancetypes/delete/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
        }
      });
  };

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  InsuranceTypesupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("itName", this.state.insuranceData.itName);
    formData.append("itDescription", this.state.insuranceData.itDescription);
    formData.append("itText", this.state.insuranceData.itText);
    formData.append("icId", this.state.insuranceData.icId);
    formData.append("sOrder", this.state.insuranceData.sOrder);
    formData.append("itId", this.state.itId);
    fetch(configData.api_url + "/insurancetypes/update/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({
            show: false,
            insuranceData: {
              itName: "",
              itDescription: "",
              itText: "",
              icId: "",
              sOrder: "",
            },
          });
        }
      });
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/insurancetypes/list/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ insurance: data.list });
        }
      });
  };
  InsuranceCategoriesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_InsuranceCategories/list", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ data: data.list });
        }
      });
  };

  componentDidMount() {
    this.apisfetchHandler();
    this.InsuranceCategoriesfetchHandler();
  }
  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.insurance.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div>
        <Row>
          <Col>
            <div className="" style={{ float: "left" }}>
              <h2>Insurance Types</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="x_panel">
              <div className="x_content">
                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                  <Row>
                    <Col>
                      <Button
                        style={{ float: "right" }}
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>Add Insurancetypes</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container>
                            <Form className="form-horizontal form-label-left input_mask">
                              <Row>
                                <Col lg="12" md="12" sm="12" xs="12">
                                  <Form.Group className="form-group">
                                    <Form.Control
                                      value={this.state.insuranceData.itName}
                                      onChange={this.inputChange}
                                      required="required"
                                      name="itName"
                                      type="text"
                                      placeholder="Name"
                                    />
                                  </Form.Group>
                                </Col>
                                <div
                                  className="errorMsg text-center"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors.itName}
                                </div>
                              </Row>
                              <Row className="mt-3">
                                <Col lg="12" md="12" sm="12" xs="12">
                                  <Form.Group className="form-group">
                                    <Form.Control
                                      value={
                                        this.state.insuranceData.itDescription
                                      }
                                      onChange={this.inputChange}
                                      name={"itDescription"}
                                      required="required"
                                      as="textarea"
                                      rows={5}
                                      placeholder="Description"
                                    />
                                  </Form.Group>
                                </Col>
                                <div
                                  className="errorMsg text-center"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors.itDescription}
                                </div>
                              </Row>
                              <Row className="mt-3">
                                <Col lg="12" md="12" sm="12" xs="12">
                                  <Form.Group className="form-group">
                                    <Form.Control
                                      value={this.state.insuranceData.itText}
                                      onChange={this.inputChange}
                                      required="required"
                                      name="itText"
                                      type="text"
                                      className="form-control"
                                      placeholder="Icon Text"
                                    />
                                  </Form.Group>
                                </Col>
                                <div
                                  className="errorMsg text-center"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors.itText}
                                </div>
                              </Row>
                              <br />
                              <Row className="mt-3">
                                <Col lg="12" md="12" sm="12" xs="12">
                                  <Form.Select
                                    onChange={this.inputChange}
                                    className="form-control"
                                    aria-label="Default select example"
                                    name="icId"
                                    value={this.state.insuranceData.icId}
                                  >
                                    <option defaultValue>Select</option>
                                    {this.state.data.map(
                                      (users, index, idx) => (
                                        <option
                                          key={index}
                                          value={users.InsuranceCategories_Id}
                                        >
                                          {users.InsuranceCategory}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                </Col>
                                <div
                                  className="errorMsg text-center"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors.icId}
                                </div>
                              </Row>
                              <Row className="mt-3">
                                <Col lg="12" md="12" sm="12" xs="12">
                                  <Form.Group className="form-group">
                                    <Form.Control
                                      value={this.state.insuranceData.sOrder}
                                      onChange={this.inputChange}
                                      required="required"
                                      name="sOrder"
                                      type="number"
                                      className="form-control"
                                      placeholder="Sorting Order"
                                    />
                                  </Form.Group>
                                </Col>
                                <div
                                  className="errorMsg text-center"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors.sOrder}
                                </div>
                              </Row>
                              <br />
                              <button
                                style={{ float: "right" }}
                                type="button"
                                className="btn btn-primary"
                                onClick={this.handleAddInsurance}
                              >
                                Save
                              </button>
                            </Form>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <div>
                      {/* searchbar,pagination,filter */}
                      <input
                        style={{ float: "left", marginBottom: "10px" }}
                        type="text"
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.insurance.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                  <div>
                    {" "}
                    {this.state.isEditing && (
                      // Edit Offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "500px", height: "auto" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>Edit insuranceTypes</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Form className="form-horizontal form-label-left input_mask">
                            <Row>
                              <Col lg="12" md="12" sm="12" xs="12">
                                <Form.Group className="form-group">
                                  <Form.Control
                                    value={this.state.insuranceData.itName}
                                    onChange={this.inputChange}
                                    required="required"
                                    name="itName"
                                    type="text"
                                    placeholder="Name"
                                  />
                                </Form.Group>
                              </Col>
                              <div
                                className="errorMsg text-center"
                                style={{ color: "red" }}
                              >
                                {this.state.errors.itName}
                              </div>
                            </Row>
                            <Row className="mt-3">
                              <Col lg="12" md="12" sm="12" xs="12">
                                <Form.Group className="form-group">
                                  <Form.Control
                                    value={
                                      this.state.insuranceData.itDescription
                                    }
                                    onChange={this.inputChange}
                                    name={"itDescription"}
                                    required="required"
                                    as="textarea"
                                    rows={5}
                                    placeholder="Description"
                                  />
                                </Form.Group>
                              </Col>
                              <div
                                className="errorMsg text-center"
                                style={{ color: "red" }}
                              >
                                {this.state.errors.itDescription}
                              </div>
                            </Row>
                            <br />
                            <Row className="mt-3">
                              <Col lg="12" md="12" sm="12" xs="12">
                                <Form.Group className="form-group">
                                  <Form.Control
                                    value={this.state.insuranceData.itText}
                                    onChange={this.inputChange}
                                    required="required"
                                    name="itText"
                                    type="text"
                                    className="form-control"
                                    placeholder="Icon Text"
                                  />
                                </Form.Group>
                              </Col>
                              <div
                                className="errorMsg text-center"
                                style={{ color: "red" }}
                              >
                                {this.state.errors.itText}
                              </div>
                            </Row>
                            <br />
                            <Row className="mt-3">
                              <Col lg="12" md="12" sm="12" xs="12">
                                <Form.Select
                                  onChange={this.inputChange}
                                  className="form-control"
                                  aria-label="Default select example"
                                  name="icId"
                                  value={this.state.insuranceData.icId}
                                >
                                  <option defaultValue>Select</option>
                                  {this.state.data.map((users, index, idx) => (
                                    <option
                                      key={index}
                                      value={users.InsuranceCategories_Id}
                                    >
                                      {users.InsuranceCategory}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                              <div
                                className="errorMsg text-center"
                                style={{ color: "red" }}
                              >
                                {this.state.errors.itPath}
                              </div>
                            </Row>
                            <Row className="mt-3">
                              <Col lg="12" md="12" sm="12" xs="12">
                                <Form.Group className="form-group">
                                  <Form.Control
                                    value={this.state.insuranceData.sOrder}
                                    onChange={this.inputChange}
                                    required="required"
                                    name="sOrder"
                                    type="number"
                                    className="form-control"
                                    placeholder="Sorting Order"
                                  />
                                </Form.Group>
                              </Col>
                              <div
                                className="errorMsg text-center"
                                style={{ color: "red" }}
                              >
                                {this.state.errors.sOrder}
                              </div>
                            </Row>
                            <br />
                            <div style={{ float: "right" }}>
                              <button
                                className="btn btn-primary me-2"
                                style={{
                                  backgroundColor: "#23547e",
                                  color: "#fff",
                                  borderColor: "#2e6da4",
                                }}
                                onClick={this.InsuranceTypesupdateHandler}
                              >
                                Update
                              </button>
                            </div>
                          </Form>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
