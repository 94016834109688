import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";
import "./Customers.css";
export default class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      errors: {},
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.customers_Id,
          sortable: true,
          cell: (row) => <span>{row.customers_Id}</span>,
        },
        {
          name: "First Name",
          selector: (row, i) => row.fName,
          sortable: true,
          cell: (row) => <span>{row.fName}</span>,
        },
        {
          name: "Last Name",
          selector: (row, i) => row.lName,
          sortable: true,
          cell: (row) => <span>{row.lName}</span>,
        },
        {
          name: "Gender",
          selector: (row, i) => row.gender,
          sortable: true,
          cell: (row) => <span>{row.gender}</span>,
        },
        {
          name: "DOB",
          selector: (row, i) => row.dob,
          sortable: true,
          cell: (row) => <span>{row.dob}</span>,
        },
        {
          name: "Mobile Number",
          selector: (row, i) => row.mobile,
          sortable: true,
          cell: (row) => <span>{row.mobile}</span>,
        },
        {
          name: "Email Id",
          selector: (row, i) => row.emailId,
          sortable: true,
          cell: (row) => <span>{row.emailId}</span>,
        },
        {
          name: "State",
          selector: (row, i) => row.state,
          sortable: true,
          cell: (row) => <span>{row.state}</span>,
        },
        {
          name: "City",
          selector: (row, i) => row.city,
          sortable: true,
          cell: (row) => <span>{row.city}</span>,
        },
        {
          name: "Town",
          selector: (row, i) => row.town,
          sortable: true,
          cell: (row) => <span>{row.town}</span>,
        },
        {
          name: "Zip Code",
          selector: (row, i) => row.zipcode,
          sortable: true,
          cell: (row) => <span>{row.zipcode}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={this.handleOffcanvasShow}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      fName: "",
      lName: "",
      gender: "",
      dob: "",
      mobile: "",
      emailId: "",
      state: "",
      city: "",
      town: "",
      zipcode: "",
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  CustomerseditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Customers_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  CustomersfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Customers/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  CustomerssubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("fName", this.state.fName);
    formData.append("lName", this.state.lName);
    formData.append("gender", this.state.gender);
    formData.append("dob", this.state.dob);
    formData.append("mobile", this.state.mobile);
    formData.append("emailId", this.state.emailId);
    formData.append("state", this.state.state);
    formData.append("city", this.state.city);
    formData.append("town", this.state.town);
    formData.append("zipcode", this.state.zipcode);

    fetch(configData.api_url + "C_Customers/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.CustomersfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };
  CustomersupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);

    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("fName", this.state.fName);
    formData.append("lName", this.state.lName);
    formData.append("gender", this.state.gender);
    formData.append("dob", this.state.dob);
    formData.append("mobile", this.state.mobile);
    formData.append("emailId", this.state.emailId);
    formData.append("state", this.state.state);
    formData.append("city", this.state.city);
    formData.append("town", this.state.town);
    formData.append("zipcode", this.state.zipcode);

    fetch(configData.api_url + "C_Customers/update", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.CustomersfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.CustomersfetchHandler();
  }
  handlefNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ fName: fieldValue });
  };
  handlelNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ lName: fieldValue });
  };
  handlegenderChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ gender: fieldValue });
  };
  handledobChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dob: fieldValue });
  };
  handlemobileChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ mobile: fieldValue });
  };
  handleemailIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ emailId: fieldValue });
  };
  handlestateChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ state: fieldValue });
  };
  handlecityChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ city: fieldValue });
  };
  handletownChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ town: fieldValue });
  };
  handlezipcodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ zipcode: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
        // implement your edit logic here
  };
  handleDelete = (row, index) => {
    const newData = this.state.data;
    newData.splice(index, 1);
    this.setState({ data: newData });
 
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const customers_Id = row.customers_Id;
      formData.append("customers_Id", customers_Id);
    fetch(configData.api_url + "C_Customers/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.CustomersfetchHandler();
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
        (this.state.currentPage - 1) * this.state.itemsPerPage,
        this.state.currentPage * this.state.itemsPerPage
      )
      : this.state.data.slice(
        (this.state.currentPage - 1) * this.state.itemsPerPage,
        this.state.currentPage * this.state.itemsPerPage
      );

    return (
      <div>
        <Row>
          <Col lg="12" md="12" sm="12">
            <h2 style={{ float: "left" }}>Customers</h2>
          </Col>
        </Row>
        <div className="x_panel">
          <Col
            className="d-flex justify-content-end"
            style={{ marginRight: "5px", marginTop: "20px" }}
          >
            <Button
              variant="primary"
              className="bi bi-plus "
              onClick={this.handleOffcanvasShow}
            ></Button>
            <Offcanvas
              show={this.state.show}
              onHide={this.handleOffcanvasClose}
              {...this.props}
              style={{ width: "960px" }}
              placement="end"
              backdrop="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Customers page</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container style={{ overflowY: "auto", height: "auto" }}>
                  <Row className="divstyle">
                    <Row>
                      <Form>
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>First Name</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="text"
                              onChange={this.handlefNameChange}
                              className="form-control"
                              name="fName"
                              value={this.state.fName}
                              required="required"
                              placeholder="Enter fName"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.fName}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>Last Name</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="text"
                              onChange={this.handlelNameChange}
                              className="form-control"
                              name="lName"
                              value={this.state.lName}
                              required="required"
                              placeholder="Enter lName"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.lName}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>Gender</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="text"
                              onChange={this.handlegenderChange}
                              className="form-control"
                              name="gender"
                              value={this.state.gender}
                              required="required"
                              placeholder="Enter gender"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.gender}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>DOB</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="date"
                              onChange={this.handledobChange}
                              className="form-control"
                              name="dob"
                              value={this.state.dob}
                              required="required"
                              placeholder="Enter dob"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.dob}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>Mobile Number</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="text"
                              onChange={this.handlemobileChange}
                              className="form-control"
                              name="mobile"
                              value={this.state.mobile}
                              required="required"
                              placeholder="Enter mobile"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.mobile}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>Email Id</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="mail"
                              onChange={this.handleemailIdChange}
                              className="form-control"
                              name="emailId"
                              value={this.state.emailId}
                              required="required"
                              placeholder="Enter emailId"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.emailId}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>State</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="text"
                              onChange={this.handlestateChange}
                              className="form-control"
                              name="state"
                              value={this.state.state}
                              required="required"
                              placeholder="Enter state"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.state}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>City</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="text"
                              onChange={this.handlecityChange}
                              className="form-control"
                              name="city"
                              value={this.state.city}
                              required="required"
                              placeholder="Enter city"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.city}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>Town</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="text"
                              onChange={this.handletownChange}
                              className="form-control"
                              name="town"
                              value={this.state.town}
                              required="required"
                              placeholder="Enter town"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.town}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="3" md="2" sm="1" xs="4">
                            <Form.Label>Zip Code</Form.Label>
                          </Col>
                          <Col lg="7" md="4" sm="3" xs="8">
                            <Form.Control
                              type="text"
                              onChange={this.handlezipcodeChange}
                              className="form-control"
                              name="zipcode"
                              value={this.state.zipcode}
                              required="required"
                              placeholder="Enter zipcode"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.zipcode}{" "}
                          </div>
                        </Row>
                        <br />
                      </Form>
                    </Row>
                  </Row>
                  <Row>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "50px" }}
                    >
                      <Button
                        className="button"
                        variant="success"
                        onClick={this.CustomerssubmitHandler}
                        style={{
                          borderRadius: "100px 100px 100px 100px",
                          marginTop: "20px",
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
            {/*offcanvas is over */}
          </Col>
          <br />

          <div style={{ marginRight: "5px" }}>
            {/* searchbar,pagination,filter */}
            <input
              type="text"
              onChange={this.handleFilter}
              placeholder="Search..."
              style={{ float: "left", marginBottom: "10px" }}
            />
            <DataTable
              data={paginatedData}
              columns={this.state.tableClms}
              pagination
              paginationServer
              paginationTotalRows={
                this.state.filteredData
                  ? this.state.filteredData.length
                  : this.state.data.length
              }
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              paginationPerPage={this.state.itemsPerPage}
              keyField="id"
              className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
            />
          </div>
        </div>
      </div>
    );
  }
}
