import React, { Component } from "react";
import "./RGIPoliciesKYC.css";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "../config.json";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class RGIPoliciesKYC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      policieskyc: [],
      idNum: "",
      dob: "",
      aadharno: "",
      gender: "",
      name: "",
      ckyc: "",
      mobile: "",
      pincode: "",
      returnurl: "http://localhost:3031/rgiproposerinfo",
      uniqueid: "1000",
      cin: "",
      voterid: "",
      dlno: "",
      passport: "",
      birthyear: "",
      jsonDisplay: null,
      errorMessage: "",
      showAadharForm: false,
      noPanCard: false,
      startDate: new Date(),
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();

    this.setState({
      [name]: name === "idNum" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });
  };
  handleDChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
   
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        dob: formattedDate,
        startDate: new Date(),
      });
    }
  };
  validateForm() {
    let policykyc = this.state;
    let errors = {};
    let formIsValid = true;

    if (!policykyc["idTYPE"]) {
      formIsValid = false;
      toast.info("*Please Enter Document Type.");
    } else if (!policykyc["idNum"]) {
      formIsValid = false;
      toast.info("*Please Enter PAN Number.");
    } else if (
      policykyc["idTYPE"] === "PAN" &&
      !/^[A-Z]{3}P[A-Z]{1}[0-9]{4}[A-Z]{1}$/.test(policykyc["idNum"])
    ) {
      // Check PAN validation only when idTYPE is "PAN"
      formIsValid = false;
      toast.info("*Invalid PAN Number format.");
    } else if (policykyc["idNum"].length !== 10) {
      // Check if PAN Number has exactly 10 characters
      formIsValid = false;
      toast.info("*PAN Number should be 10 characters long.");
    } else if (!policykyc["dob"]) {
      formIsValid = false;
      toast.info("*Please Enter Date of Birth.");
    } else if (!policykyc["name"]) {
      formIsValid = false;
      toast.info("*Please Enter Full Name.");
    } else if (!policykyc["gender"]) {
      formIsValid = false;
      toast.info("*Please Select Gender.");
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  handleSubmitClick = (e) => {
    e.preventDefault();
    // if (this.validateForm()) {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("proposalId", this.state.proposalId);
    formData.append("idNum", this.state.idNum);
    formData.append("idTYPE", this.state.idTYPE);
    formData.append("dob", this.state.dob);
    formData.append("gender", this.state.gender);
    formData.append("name", this.state.name);
    formData.append("uniqueid", this.state.uniqueid);
    formData.append("returnurl", this.state.returnurl);
    formData.append("consent_purpose", this.state.consent_purpose);
    fetch(configData.api_url + "rg_Leads/ckyc/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({
            dob: "",
            idTYPE: "",
            idNum: "",
            id_number: "",
          });
          window.location.href = "/rgiproposerinfo";
        } else {
          this.setState({ showAadharForm: true });
        }
      });

    // }
  };
  //Aadhar
  validateAadhaarForm() {
    let policykyc = this.state;
    let errors = {};
    let formIsValid = true;

    if (!policykyc["aadharno"]) {
      formIsValid = false;
      toast.info("*Please Enter Aadhaar Number.");
    } else if (policykyc["aadharno"].length !== 4) {
      // Check if PAN Number has exactly 10 characters
      formIsValid = false;
      toast.info("Adhar Number should be 4 characters long.");
    } else if (!policykyc["name"]) {
      formIsValid = false;
      toast.info("*Please Enter Name.");
    } else if (!policykyc["dob"]) {
      formIsValid = false;
      toast.info("*Please Enter Date of Birth.");
    } else if (!policykyc["gender"]) {
      formIsValid = false;
      toast.info("*Please Select Gender.");
    } else if (!policykyc["name"]) {
      formIsValid = false;
      toast.info("*Please Enter Full Name.");
    } else if (!policykyc["gender"]) {
      formIsValid = false;
      toast.info("*Please Select Gender.");
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  handleAadhaarSubmitClick = (e) => {
    e.preventDefault();
    if (this.validateAadhaarForm()) {
      var formData = new FormData();
      formData.append("chpiId", this.state.chpiId);
      formData.append("proposalId", this.state.proposalId);
      formData.append("aadharno", this.state.aadharno);
      formData.append("idTYPE", this.state.idTYPE);
      formData.append("gender", this.state.gender);
      formData.append("name", this.state.name);
      formData.append("dob", this.state.dob);
      formData.append("uniqueid", this.state.uniqueid);
      formData.append("returnurl", this.state.returnurl);
   
      fetch(configData.api_url + "rg_Leads/akyc", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.success);
          if (data.status === "success") {
            this.setState({
              dob: "",
              idTYPE: "",
              aadharno: "",
              gender: "",
              name: "",
            });
            window.location.href = "/rgiproposerinfo";
          }
        });
    }
  };
  onKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      toast.error("Spaces are not allowed.");
    }
  };
  handleNoPANClick = () => {
    // Function to handle click on "I don't have PAN card"
    this.setState({
      noPanCard: true,
      idTYPE: "Aadhaar NO", // Set default ID type to Aadhar
      showAadharForm: true,
    });
  };
  render() {
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1); // Set the date to the first day of January
    // Example end year
    const endYear = new Date();
    endYear.setFullYear(endYear.getFullYear() + 10);
    return (
      <div>
        <div>
          <h3 className="logoskybluecolor">Reliance KYC </h3>
          <Container className="bgalphacolor">
            <Card>
              <Card.Body>
                <div className="row">
                  <h4>PAN Verification : </h4>
                  <br />
                  <div className="col-md-3 col-lg-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Identity Proof:
                    </label>
                    <Form.Select
                      name="idTYPE"
                      value={this.state.idTYPE}
                      onChange={this.handleChange}
                      aria-label="Default select example"
                      className="form-control"
                      id="idTYPE"
                      style={{ height: "47px" }}
                      disabled={this.state.showAadharForm}
                    >
                      <option selected>select Identity Type</option>
                      <option value="PAN">PAN</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <label htmlFor="idNum" className="form-label">
                      Identity No:
                    </label>
                    <input
                      type="text"
                      name="idNum"
                      value={this.state.idNum}
                      className="form-control"
                      id="idNum"
                      placeholder="Enter PAN Number"
                      onChange={this.handleChange}
                      disabled={this.state.showAadharForm}
                    />
                    <Link id="noPANBtn" onClick={this.handleNoPANClick}>
                      I don't have PAN card
                    </Link>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <label htmlFor="dob" className="form-label">
                      DOB:
                    </label>
                    <div calendarClassName="custom-calendar">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        showTimeSelect={false}
                        selected={this.state.dob}
                        onChange={(date) => this.handleDChange(date)}
                        className="form-control"
                        id="dob"
                        placeholderText="Select Date of Birth"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={previousYearStart} // Set the minimum selectable date
                        maxDate={endYear}
                        disabled={this.state.showAadharForm}
                      />
                    </div>
                  </div>

                  <div className="col-md-3 col-lg-3">
                    <label htmlFor="idNum" className="form-label">
                      Full Name:
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={this.state.name}
                      className="form-control"
                      id="name"
                      placeholder="Enter Full Name"
                      onChange={this.handleChange}
                      disabled={this.state.showAadharForm}
                    />
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Gender:
                    </label>
                    <Form.Select
                      name="gender"
                      value={this.state.gender}
                      onChange={this.handleChange}
                      aria-label="Default select example"
                      className="form-control"
                      id="gender"
                      disabled={this.state.showAadharForm}
                    >
                      <option selected>select Identity Type</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </div>
                </div>
                <br />
                <Row className="justify-content-between">
                  <Col lg="12" md="12">
                    <Button
                      id="getQuotes4"
                      type="submit"
                      onClick={this.handleSubmitClick}
                      style={{ float: "right" }}
                      disabled={this.state.showAadharForm}
                    >
                      Submit{" "}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <br />
            {this.state.showAadharForm && (
              <Card>
                <Card.Body>
                  <div className="">
                    <h4>Aadhar Verification: </h4>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-lg-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label"
                      >
                        Identity Proof:
                      </label>
                      <Form.Select
                        name="idTYPE"
                        value={this.state.idTYPE}
                        aria-label="Default select example"
                        className="form-control"
                        id="idTYPE"
                        style={{ height: "47px" }}
                        onChange={this.handleChange}
                      >
                        <option selected>select Identity Type</option>
                        <option value="Aadhaar NO">Aadhaar NO</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-3 col-lg-3">
                      <label htmlFor="idNum" className="form-label">
                        Aadhar Card No:
                      </label>
                      <input
                        onKeyDown={this.onKeyDown}
                        type="text"
                        name="aadharno"
                        value={this.state.aadharno}
                        className="form-control"
                        id="aadharno"
                        placeholder="Enter last 4 digit Aadhar Number"
                        onChange={this.handleChange}
                        maxLength={4}
                      />
                      <Link
                        id="noPANBtn"
                        //onClick={this.handleNoPANClick}
                      >
                        I don't have Aadhar card
                      </Link>
                    </div>
                    <div className="col-md-3 col-lg-3">
                      <label htmlFor="dob" className="form-label">
                        DOB:
                      </label>

                      <div calendarClassName="custom-calendar">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          showTimeSelect={false}
                          selected={this.state.dob}
                          onChange={(date) => this.handleDChange(date)}
                          className="form-control"
                          id="dob"
                          placeholderText="Select Date of Birth"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          minDate={previousYearStart} // Set the minimum selectable date
                          maxDate={endYear}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-3">
                      <label htmlFor="dob" className="form-label">
                        Full Name:
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        className="form-control"
                        id="name"
                        placeholder="Enter Name"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="col-md-3 col-lg-3">
                      <label htmlFor="dob" className="form-label">
                        Gender:
                      </label>
                      <Form.Select
                        name="gender"
                        value={this.state.gender}
                        onChange={this.handleChange}
                        aria-label="Default select example"
                        className="form-control"
                        id="gender"
                        style={{ height: "47px" }}
                      >
                        <option selected>select</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Other</option>
                      </Form.Select>
                    </div>
                  </div>
                  <br />
                  <Row className="justify-content-between">
                    <Col lg="12" md="12">
                   
                      <Button
                        id="getQuotes4"
                        type="submit"
                        onClick={this.handleAadhaarSubmitClick}
                        style={{ float: "right" }}
                      >
                        Submit{" "}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Container>
        </div>
      </div>
    );
  }
}
