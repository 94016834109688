import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Commissionmasternonlife extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.commissionmasternonlife_Id,
          sortable: true,
          cell: (row) => <span>{row.commissionmasternonlife_Id}</span>,
        },
        {
          name: "Insurer",
          selector: (row, i) => row.Insurer,
          sortable: true,
          cell: (row) => <span>{row.Insurer}</span>,
        },
        {
          name: "Basic OD Premium(%)",
          selector: (row, i) => row.BasicODPremium,
          sortable: true,
          cell: (row) => <span>{row.BasicODPremium}</span>,
        },
        {
          name: "TP Premium",
          selector: (row, i) => row.TPPremium,
          sortable: true,
          cell: (row) => <span>{row.TPPremium}</span>,
        },
        {
          name: "Terrorism Premium",
          selector: (row, i) => row.TerrorismPremium,
          sortable: true,
          cell: (row) => <span>{row.TerrorismPremium}</span>,
        },
        {
          name: "Net Premium Commission(%)",
          selector: (row, i) => row.NetPremiumCommission,
          sortable: true,
          cell: (row) => <span>{row.NetPremiumCommission}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      nonlifeinsurancecompanies: [],
      Insurer: "",
      BasicODPremium: "",
      TPPremium: "",
      TerrorismPremium: "",
      NetPremiumCommission: "",
      commissionmasternonlife_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  handleEnterEditMode = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      Insurer: row.Insurer,
      BasicODPremium: row.BasicODPremium,
      TPPremium: row.TPPremium,
      TerrorismPremium: row.TerrorismPremium,
      IRDACategorization: row.IRDACategorization,
      NetPremiumCommission: row.NetPremiumCommission,
      commissionmasternonlife_Id: row.commissionmasternonlife_Id,
      isEditing: true,
    });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  CommissionmasternonlifeeditHandler = (e) => {
    e.preventDefault();
 };
  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;
    if (!data["Insurer"]) {
      formIsValid = false;
      toast.info("*Please Enter  Insurer.");
    } else if (!data["BasicODPremium"]) {
      formIsValid = false;
      toast.info("*Please Enter Basic OD Premium.");
    } else if (!data["TPPremium"]) {
      formIsValid = false;
      toast.info("*Please Enter TPPremium.");
    } else if (!data["TerrorismPremium"]) {
      formIsValid = false;
      toast.info("*Please Enter TerrorismPremium.");
    } else if (!data["NetPremiumCommission"]) {
      formIsValid = false;
      toast.info("*Please Enter NetPremiumCommission.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  CommissionmasternonlifefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Commissionmasternonlife/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };

  CommissionmasternonlifesubmitHandler = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
     var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("Insurer", this.state.Insurer);
      formData.append("BasicODPremium", this.state.BasicODPremium);
      formData.append("TPPremium", this.state.TPPremium);
      formData.append("TerrorismPremium", this.state.TerrorismPremium);
      formData.append("NetPremiumCommission", this.state.NetPremiumCommission);

      fetch(configData.api_url + "C_Commissionmasternonlife/add", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.CommissionmasternonlifefetchHandler();
            this.handleOffcanvasClose();
          }
        });
    }
  };
  CommissionmasternonlifeupdateHandler = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("Insurer", this.state.Insurer);
      formData.append("BasicODPremium", this.state.BasicODPremium);
      formData.append("TPPremium", this.state.TPPremium);
      formData.append("TerrorismPremium", this.state.TerrorismPremium);
      formData.append("NetPremiumCommission", this.state.NetPremiumCommission);
      formData.append(
        "commissionmasternonlife_Id",
        this.state.commissionmasternonlife_Id
      );

      fetch(configData.api_url + "C_Commissionmasternonlife/update", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.CommissionmasternonlifefetchHandler();
            this.handleOffcanvasClose();
            this.setState({
              Insurer: "",
              BasicODPremium: "",
              TPPremium: "",
              TerrorismPremium: "",
              NetPremiumCommission: "",
              commissionmasternonlife_Id: "",

              isEditing: true,
            });
          }
        });
    }
  };

  componentDidMount() {
    this.CommissionmasternonlifefetchHandler();
    this.nonlifeinsurancecompaniesfetchHandler();
  }
  handleInsurerChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ Insurer: fieldValue });
  };
  nonlifeinsurancecompaniesfetchHandler = () => {
    fetch(configData.api_url + "C_Nonlifeinsurancecompanies/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ nonlifeinsurancecompanies: data.list });
        }
      });
  };
  handleBasicODPremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ BasicODPremium: fieldValue });
  };
  handleTPPremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ TPPremium: fieldValue });
  };
  handleTerrorismPremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ TerrorismPremium: fieldValue });
  };
  handleNetPremiumCommissionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ NetPremiumCommission: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
     // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const commissionmasternonlife_Id = row.commissionmasternonlife_Id;
       formData.append("commissionmasternonlife_Id", commissionmasternonlife_Id);
    fetch(configData.api_url + "C_Commissionmasternonlife/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CommissionmasternonlifefetchHandler();
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>Commission master non-life</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Commission master non-life
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <select
                                        className="form-select"
                                        name="tableName"
                                        onChange={this.handleInsurerChange}
                                      >
                                        <option defaultValue>
                                          {" "}
                                          Select Table{" "}
                                        </option>
                                        {this.state.nonlifeinsurancecompanies.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={
                                                api.NonLifeInsuranceCompanies
                                              }
                                            >
                                              {" "}
                                              {
                                                api.NonLifeInsuranceCompanies
                                              }{" "}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.Insurer}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Basic OD Premium(%)
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleBasicODPremiumChange
                                        }
                                        className="form-control"
                                        name="BasicODPremium"
                                        value={this.state.BasicODPremium}
                                        required="required"
                                        placeholder="Enter BasicODPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.BasicODPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        TP Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleTPPremiumChange}
                                        className="form-control"
                                        name="TPPremium"
                                        value={this.state.TPPremium}
                                        required="required"
                                        placeholder="Enter TPPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.TPPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Terrorism Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleTerrorismPremiumChange
                                        }
                                        className="form-control"
                                        name="TerrorismPremium"
                                        value={this.state.TerrorismPremium}
                                        required="required"
                                        placeholder="Enter TerrorismPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.TerrorismPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Net Premium Commission(%)
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={
                                          this.handleNetPremiumCommissionChange
                                        }
                                        className="form-control"
                                        name="NetPremiumCommission"
                                        value={this.state.NetPremiumCommission}
                                        required="required"
                                        placeholder="Enter NetPremiumCommission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.NetPremiumCommission
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.CommissionmasternonlifesubmitHandler
                                  }
                                  style={{
                                    borderRadius: "100px 100px 100px 100px",
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <div>
                    {" "}
                    {this.state.isEditing && (
                      //  Edit Offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Edit Commission master non-life
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <select
                                        className="form-select"
                                        name="tableName"
                                        value={this.state.Insurer}
                                        onChange={this.handleInsurerChange}
                                      >
                                        <option defaultValue>
                                          {" "}
                                          Select Table{" "}
                                        </option>
                                        {this.state.nonlifeinsurancecompanies.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={
                                                api.NonLifeInsuranceCompanies
                                              }
                                            >
                                              {" "}
                                              {
                                                api.NonLifeInsuranceCompanies
                                              }{" "}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.Insurer}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Basic OD Premium(%)
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleBasicODPremiumChange
                                        }
                                        className="form-control"
                                        name="BasicODPremium"
                                        value={this.state.BasicODPremium}
                                        required="required"
                                        placeholder="Enter BasicODPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.BasicODPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        TP Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleTPPremiumChange}
                                        className="form-control"
                                        name="TPPremium"
                                        value={this.state.TPPremium}
                                        required="required"
                                        placeholder="Enter TPPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.TPPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Terrorism Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleTerrorismPremiumChange
                                        }
                                        className="form-control"
                                        name="TerrorismPremium"
                                        value={this.state.TerrorismPremium}
                                        required="required"
                                        placeholder="Enter TerrorismPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.TerrorismPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Net Premium Commission(%)
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={
                                          this.handleNetPremiumCommissionChange
                                        }
                                        className="form-control"
                                        name="NetPremiumCommission"
                                        value={this.state.NetPremiumCommission}
                                        required="required"
                                        placeholder="Enter NetPremiumCommission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.NetPremiumCommission
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.CommissionmasternonlifeupdateHandler
                                  }
                                  style={{
                                    borderRadius: "100px 100px 100px 100px",
                                    marginTop: "20px",
                                  }}
                                >
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        paginationRowsPerPageOptions={[10, 20, 30, 50,60,70,80,90, 100]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
