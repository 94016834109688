import React, { Component } from "react";
import "./EmployeesProfile.css";
import { Col, Form, Row, Tab, Tabs, Button, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import configData from "../config.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class EmployeesProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 1,
      data: [],
      editedDocType: "",
      editedDocPath: "",
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      startDate: new Date(),
      employees_Id: sessionStorage.getItem("employees_Id"),
      errors: {},
      filteredData: null,
      currentPage: 1,
      itemsPerPage: 10,
      fName: "",
      lName: "",
      gender: [],
      gId: "",
      dob: "",
      doj: "",
      dor: "",
      bloodGroup: "",
      pan: "",
      panProof: "",
      adhar: "",
      adharProof: "",
      imageProof: "",
      departments: [],
      dId: "",
      designations: [],
      dsId: "",
      mobile: "",
      aMobile: "",
      cPerson: "",
      relationships: [],
      rId: "",
      address: "",
      aProof: "",
      roles: [],
      roId: "",
      rmId: "",
      rvmId: "",
      roffice: "",
      employeetype: [],
      etId: "",
      eId: "",
      password: "",
      emailId: "",
      etName: "",
      accountnumber: "",
      bankname: "",
      bankcode: "",
      branchname: "",
      ifsccode: "",
      docType: "",
      docPath: "",
      selectedPDF: null,
      documents: [],
      editIndex: null,
      editDocId: "",
      editDocType: "",
      editDocPath: "",
      leaves: "",
      externaldesignation: "",
    };
  }

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  componentDidMount() {
    this.EmployeesfetchHandler();
    this.genderfetchHandler();
    this.departmentsfetchHandler();
    this.designationsfetchHandler();
    this.relationshipsfetchHandler();
    this.rolesfetchHandler();
    this.employeetypefetchHandler();
    this.EmployeesreportingfetchHandler();
  }
  rolesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "roles/all", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ roles: data.list });
        }
      });
  };
  relationshipsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Relationships/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ relationships: data.list });
        }
      });
  };
  genderfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Gender/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ gender: data.list });
        }
      });
  };
  departmentsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Departments/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ departments: data.list });
        }
      });
  };
  designationsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);

    fetch(configData.api_url + "C_Designations/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ designations: data.list });
        }
      });
  };
  EmployeesreportingfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Employees/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  EmployeesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("employees_Id", this.state.employees_Id);
    fetch(configData.api_url + "C_Employees/retrive", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ documents: data.detail.docs });
          var dob = "";
          if (data.detail.dob !== "0000-00-00") dob = data.detail.dob;
          var doj = "";
          if (data.detail.doj !== "0000-00-00") doj = data.detail.doj;
          var dor = "";
          if (data.detail.dor !== "0000-00-00") dor = data.detail.dor;
          this.setState({
            eId: data.detail.eId,
            fName: data.detail.fName,
            lName: data.detail.lName,
            gId: data.detail.gender,
            dob: dob,
            doj: doj,
            dor: dor,
            bloodGroup: data.detail.bloodGroup,
            pan: data.detail.pan,
            adhar: data.detail.adhar,
            dId: data.detail.dId,
            dsId: data.detail.dsId,
            mobile: data.detail.mobile,
            aMobile: data.detail.aMobile,
            cPerson: data.detail.cPerson,
            rId: data.detail.rId,
            address: data.detail.address,
            roId: data.detail.roId,
            rmId: data.detail.rmId,
            rvmId: data.detail.rvmId,
            etId: data.detail.etId,
            etName: data.detail.etName,
            emailId: data.detail.emailId,
            leaves: data.detail.leaves,
            accountnumber: data.detail.accountnumber,
            bankname: data.detail.bankname,
            branchname: data.detail.branchname,
            roffice: data.detail.roffice,
            externaldesignation: data.detail.externaldesignation,
            ifsccode: data.detail.ifsccode,
          });
        }
      });
  };
  employeetypefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Employeetype/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ employeetype: data.list });
        }
      });
  };
  handleecodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ecode: fieldValue });
  };
  handlefNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ fName: fieldValue });
  };
  handlelNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ lName: fieldValue });
  };
  handleDChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log(date);
      console.log(formattedDate);
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        dob: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handledobChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dob: fieldValue });
  };
  handleDOJChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log(date);
      console.log(formattedDate);
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        doj: formattedDate,
        startDate: new Date(),
      });
    }
  };
  // handledojChange = (e) => {
  //   e.preventDefault();

  //   const fieldValue = e.target.value;
  //   this.setState({ doj: fieldValue });
  // };
  // handledorChange = (e) => {
  //   e.preventDefault();

  //   const fieldValue = e.target.value;
  //   this.setState({ dor: fieldValue });
  // };
  handledorChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log(date);
      console.log(formattedDate);
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        dor: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handledIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dId: fieldValue });
  };
  handledsIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dsId: fieldValue });
  };
  handlrofficeChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ roffice: fieldValue });
  };
  handlepanChange = (e) => {
    // e.preventDefault();
    // const fieldValue = e.target.value;
    // this.setState({ pan: fieldValue });
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();

    this.setState({
      [name]: name === "pan" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });
  };
  handlebankChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ accountnumber: fieldValue });
  };
  handleBanknameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ branchname: fieldValue });
  };
  handleNameeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ etId: fieldValue });
  };
  handleifscChange = (e) => {
    e.preventDefault();
    //const fieldValue = e.target.value;
    //this.setState({ ifsccode: fieldValue });
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();
    this.setState({
      [name]: name === "ifsccode" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });
  };
  handlestatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ status: fieldValue });
  };
  handlermIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ rmId: fieldValue });
  };
  handlervmIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ rvmId: fieldValue });
  };
  handleAccountChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ accountnumber: fieldValue });
  };

  handleeBankChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ bankname: fieldValue });
  };

  handleemailIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ emailId: fieldValue });
  };
  handleddoctypeChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ docType: fieldValue });
  };
  handledLeaveseChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ leaves: fieldValue });
  };

  handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    // const newFormData = this.state.panProof;
    // newFormData["panProof"] = file;
    //this.setState({ docPath: file });
    // Check if the selected file type is allowed
    if (file && allowedTypes.includes(file.type)) {
      this.setState({ docPath: file });
    } else {
      // Display a message or handle the invalid file type
      console.error("Invalid file type. Please select a PDF or image file.");
      // Optionally, you can also display a toast message or any other feedback to the user
    }
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  onKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      toast.error("Spaces are not allowed.");
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fName") {
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not Allowed Special Characters And Numbers.");

        return;
      }
    }
    if (name === "lName") {
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not Allowed Special Characters And Numbers.");

        return;
      }
    }
    if (name === "cPerson") {
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not Allowed Special Characters And Numbers.");

        return;
      }
    }
    if (name === "zipcode" && value.length > 0 && value.charAt(0) === "0") {
      // Display an error toast message or handle the error as needed
      toast.error("Zipcode cannot start with the number 0.");
      return; // Stop further processing for this field
    }

    if (name === "mobile" && value.length > 0) {
      const firstDigit = parseInt(value.charAt(0), 10);

      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please Enter Valid Mobile Number.");
        return; // Stop further processing for this field
      }
    }
    if (name === "aMobile" && value.length > 0) {
      const firstDigit = parseInt(value.charAt(0), 10);

      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please Enter Valid Mobile Number.");
        return; // Stop further processing for this field
      }
    }
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: "", // Clear the corresponding error when the field value changes
      },
    });
  };

  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;
    const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    const adharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    const mobileRegex = /^[6-9]\d{9}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //const ifscRegex = /^[A-Z]{4}\d{7}$/;

    if (
      !data["mobile"] ||
      !mobileRegex.test(data["mobile"]) ||
      data["mobile"].length !== 10
    ) {
      formIsValid = false;
      toast.info("*Please Enter a Valid 10-digit Mobile Number.");
    } else if (
      !data["aMobile"] ||
      !mobileRegex.test(data["aMobile"]) ||
      data["aMobile"].length !== 10
    ) {
      formIsValid = false;
      toast.info("*Please Enter Emergency Mobile Number.");
    } else if (data["emailId"] === "" || !emailRegex.test(data["emailId"])) {
      formIsValid = false;
      toast.info("*Please Enter a Valid Email Id.");
    } else if (!data["pan"] || !panRegex.test(data["pan"])) {
      formIsValid = false;
      toast.info("*Please Enter a Valid PAN Card Number.");
    } else if (
      !data["adhar"] ||
      !adharRegex.test(data["adhar"]) ||
      data["adhar"].length !== 12
    ) {
      formIsValid = false;
      toast.info("*Please Enter a Valid 12-digit Aadhaar Number.");
    }
    // else if (!data["accountnumber"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Valid Account Number.");
    // } else if (!data["bankname"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Bank Name.");
    // } else if (!data["branchname"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Branch Name.");
    // } else if (!data["ifsccode"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter IFSC Code.");
    // }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
EmployeesBackHandler=(e)=>{
   window.location.href = "/employees";
}
  EmployeesupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("eId", this.state.eId);
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("fName", this.state.fName);
      formData.append("lName", this.state.lName);
      formData.append("gender", this.state.gId);
      formData.append("dob", this.state.dob);
      formData.append("doj", this.state.doj);
      formData.append("dor", this.state.dor);
      formData.append("bloodGroup", this.state.bloodGroup);
      formData.append("pan", this.state.pan);
      formData.append("adhar", this.state.adhar);
      formData.append("dId", this.state.dId);
      formData.append("dsId", this.state.dsId);
      formData.append("mobile", this.state.mobile);
      formData.append("aMobile", this.state.aMobile);
      formData.append("cPerson", this.state.cPerson);
      formData.append("rId", this.state.rId);
      formData.append("address", this.state.address);
      formData.append("aProof", this.state.aProof);
      formData.append("roId", this.state.roId);
      formData.append("rmId", this.state.rmId);
      formData.append("rvmId", this.state.rvmId);
      formData.append("etId", this.state.etId);
      formData.append("eId", this.state.eId);
      formData.append("pan", this.state.pan);
      formData.append("adhar", this.state.adhar);
      formData.append("accountnumber", this.state.accountnumber);
      formData.append("bankname", this.state.bankname);
      formData.append("branchname", this.state.branchname);
      formData.append("ifsccode", this.state.ifsccode);
      formData.append("emailId", this.state.emailId);
      formData.append("roffice", this.state.roffice);
      formData.append("leaves", this.state.leaves);
      formData.append("externaldesignation", this.state.externaldesignation);
      formData.append("employees_Id", this.state.employees_Id);

      fetch(configData.api_url + "C_Employees/update", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            // this.EmployeesfetchHandler();
            this.handleOffcanvasClose();
            this.setState({
              fName: "",
              lName: "",
              gId: "",
              dob: "",
              doj: "",
              dor: "",
              bloodGroup: "",
              pan: "",
              panProof: "",
              adhar: "",
              adharProof: "",
              imageProof: "",
              dId: "",
              dsId: "",
              mobile: "",
              aMobile: "",
              cPerson: "",
              rId: "",
              address: "",
              aProof: "",
              roId: "",
              rmId: "",
              rvmId: "",
              etId: "",
              eId: "",
              password: "",
              emailId: "",
            });
          }
        });
    }
  };

  handlegenderChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ gId: fieldValue });
  };
  handlebloodGroupChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ bloodGroup: fieldValue });
  };
  handleReportChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ roffice: fieldValue });
  };
  handleExternalChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ externaldesignation: fieldValue });
  };
  handleAadharChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ adhar: fieldValue });
  };
  handlemobileChange = (e) => {
    // e.preventDefault();
    // const fieldValue = e.target.value;
    // this.setState({ mobile: fieldValue });
    const fieldValue = parseInt(e.target.value, 10); // Parse input as integer
    if (!isNaN(fieldValue) && fieldValue >= 0) {
      // If fieldValue is a non-negative number
      this.setState({ mobile: fieldValue });
    } else {
      // If fieldValue is negative or NaN, set NoOfLeaves to 0
      this.setState({ mobile: 0 });
    }
  };
  handleaMobileChange = (e) => {
    // e.preventDefault();
    // const fieldValue = e.target.value;
    // this.setState({ aMobile: fieldValue });
    const fieldValue = parseInt(e.target.value, 10); // Parse input as integer
    if (!isNaN(fieldValue) && fieldValue >= 0) {
      // If fieldValue is a non-negative number
      this.setState({ aMobile: fieldValue });
    } else {
      // If fieldValue is negative or NaN, set NoOfLeaves to 0
      this.setState({ aMobile: 0 });
    }
  };
  handlecPersonChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ cPerson: fieldValue });
  };
  handlerIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ rId: fieldValue });
  };

  handleaddressChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ address: fieldValue });
  };
  handleroIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ roId: fieldValue });
  };
  handleeIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ eId: fieldValue });
  };
  handlepasswordChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ password: fieldValue });
  };

  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

 

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  handleUpload = (e) => {
    e.preventDefault();
    // Check if a file is selected
    if (!this.state.docPath) {
      console.error("No file selected.");
      toast.error("Please select a pdf file.");
      return;
    }

    // Check if the selected file type is allowed (PDF or image)
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (!allowedTypes.includes(this.state.docPath.type)) {
      console.error("Invalid file type. Please select a PDF or image file.");
      toast.error("Invalid file type. Please select a PDF or image file.");
      return;
    }
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("eId", this.state.eId);
    formData.append("docType", this.state.docType);
    formData.append("docPath", this.state.docPath);
    formData.append("employees_Id", this.state.employees_Id);
    fetch(configData.api_url + "C_Employees/insertDocuments", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            docType: "",
            docPath: "",
          });
          this.fetchDocuments();
        }
      })
      .catch((error) => {
        console.error("Error uploading document:", error);
      });
  };
  openPDF = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
    this.setState({ selectedPDF: pdfUrl });
  };
  handlePdfClick = (docPath) => {
    window.open(configData.api_url + docPath, "_blank");
  };
  handleDeletedoc = (employee) => {
    // console.log(employee);
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("edId", employee.edId);
    formData.append("employees_Id", employee.eId);
    fetch(configData.api_url + "C_Employees/deletedocs", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          //this.EmployeesfetchHandler();
        }
      })
      .catch((error) => {
        console.error("Error deleting document:", error);
      });
  };

  handleUpdate = (e, employee) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("edId", this.state.edId);
    formData.append("employees_Id", this.state.employees_Id);
    formData.append("docType", this.state.docType);
    formData.append("docPath", this.state.docPath);
    fetch(configData.api_url + "C_Employees/updateDocuments", {
      method: "POST", // or "PUT"
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          //this.EmployeesfetchHandler();
        }
      })
      .catch((error) => {
        console.error("Error updating document:", error);
      });
  };

  fetchDocuments() {
    // Fetch documents and update documents state
  }

  render() {
    const { currentTab } = this.state;
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1); // Set the date to the first day of January

    // Example end year
    const endYear = new Date();
    endYear.setFullYear(endYear.getFullYear() + 10);
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);
    return (
      <div>
        <h3>Employees Details</h3>
        <div className="container thm-container bgColorContainer">
          <div>
            <div className="row compareDetailssection">
              <div className="col-md-12 compareDetailssectionleft">
                <br />
                <Tabs
                  activeKey={currentTab}
                  onSelect={(key) => this.setState({ currentTab: key })}
                  className="TabsOne"
                >
                  <Tab eventKey={1} title="Employee Details" select="select">
                    <br />
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade active show"
                        id="proposerDetails"
                        role="tabpanel"
                        aria-labelledby="proposerDetails-tab"
                      >
                        <Row>
                          <div>
                            <h4>Employee Details </h4>
                            <hr style={{ width: "150px" }} />
                          </div>

                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Employee Code
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handleeIdChange}
                                  className="form-control"
                                  name="eId"
                                  value={this.state.eId}
                                  required="required"
                                  placeholder="Enter Code"
                                  disabled
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  First Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  //onChange={this.handlefNameChange}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  name="fName"
                                  value={this.state.fName}
                                  required="required"
                                  placeholder="Enter First Name"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Last Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  // onChange={this.handlelNameChange}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  name="lName"
                                  value={this.state.lName}
                                  required="required"
                                  placeholder="Enter Last Name"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Gender
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="gId"
                                  id="gId"
                                  value={this.state.gId}
                                  onChange={this.handlegenderChange}
                                >
                                  <option defaultValue>Select Gender</option>
                                  {this.state.gender.map((api, index, idx) => (
                                    <option key={index} value={api.gender_Id}>
                                      {api.gender}
                                    </option>
                                  ))}
                                </select>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  D.O.B
                                </Form.Label>
                                <div>
                                  {" "}
                                  <DatePicker
                                    name="dob"
                                    //onChange={this.handledobChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    selected={this.state.dob}
                                    onChange={(date) =>
                                      this.handleDChange(date)
                                    }
                                    className="form-control"
                                    id="dob"
                                    placeholderText="Select Date of Birth"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    minDate={previousYearStart} // Set the minimum selectable date
                                    maxDate={new Date()}
                                  />
                                </div>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  D.O.J
                                </Form.Label>
                                <div>
                                  <DatePicker
                                    name="doj"
                                
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    selected={this.state.doj}
                                    onChange={(date) =>
                                      this.handleDOJChange(date)
                                    }
                                    className="form-control"
                                    id="doj"
                                    placeholderText="Select DOJ"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    minDate={previousYearStart} // Set the minimum selectable date
                                    // // maxDate={endYear} // Set the maximum selectable date

                                    // max={
                                    //   new Date().toISOString().split("T")[0]
                                    // }
                                    // minDate={minDate}
                                    maxDate={new Date()}
                                  />
                                </div>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  D.O.R
                                </Form.Label>
                                <div>
                                  <DatePicker
                                    name="dor"
                                    //value={this.state.dor}
                                    //onChange={this.handledojChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    selected={this.state.dor}
                                    onChange={(date) =>
                                      this.handledorChange(date)
                                    }
                                    className="form-control"
                                    id="dor"
                                    placeholderText="Enter DOR"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    minDate={previousYearStart} // Set the minimum selectable date
                                    // // maxDate={endYear} // Set the maximum selectable date

                                    // max={
                                    //   new Date().toISOString().split("T")[0]
                                    // }
                                    // minDate={minDate}
                                    maxDate={new Date()}
                                  />
                                </div>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Blood Group
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handlebloodGroupChange}
                                  className="form-control"
                                  name="bloodGroup"
                                  value={this.state.bloodGroup}
                                  required="required"
                                  placeholder="Enter BloodGroup"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Reporting office
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handleReportChange}
                                  className="form-control"
                                  name="roffice"
                                  value={this.state.roffice}
                                  required="required"
                                  placeholder="Reporting office"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Department
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="dId"
                                  value={this.state.dId}
                                  onChange={this.handledIdChange}
                                >
                                  <option defaultValue>
                                    Select Department
                                  </option>
                                  {this.state.departments.map(
                                    (api, index, idx) => (
                                      <option
                                        key={index}
                                        value={api.departments_Id}
                                      >
                                        {api.Department}
                                      </option>
                                    )
                                  )}
                                </select>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Designation
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="dsId"
                                  value={this.state.dsId}
                                  onChange={this.handledsIdChange}
                                >
                                  <option defaultValue>
                                    Select Designation
                                  </option>
                                  {this.state.designations.map(
                                    (api, index, idx) => (
                                      <option
                                        key={index}
                                        value={api.designations_Id}
                                      >
                                        {api.Designation}
                                      </option>
                                    )
                                  )}
                                </select>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  External Designation
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handleExternalChange}
                                  className="form-control"
                                  name="externaldesignation"
                                  value={this.state.externaldesignation}
                                  required="required"
                                  placeholder="External Designation"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Role
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="roId"
                                  value={this.state.roId}
                                  onChange={this.handleroIdChange}
                                >
                                  <option defaultValue>Select Role</option>
                                  {this.state.roles.map((api, index, idx) => (
                                    <option key={index} value={api.rId}>
                                      {api.rName}
                                    </option>
                                  ))}
                                </select>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Reporting Manager
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="rmId"
                                  value={this.state.rmId}
                                  onChange={this.handlermIdChange}
                                >
                                  <option defaultValue>
                                    Select Reporting Manager
                                  </option>
                                  {this.state.data.map((api, index, idx) => (
                                    <option
                                      key={index}
                                      value={api.employees_Id}
                                    >
                                      {api.fName + " " + api.lName}
                                    </option>
                                  ))}
                                </select>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Reviewing Manager
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="rvmId"
                                  value={this.state.rvmId}
                                  onChange={this.handlervmIdChange}
                                >
                                  <option defaultValue>
                                    Select Reviewing Manager
                                  </option>
                                  {this.state.data.map((api, index, idx) => (
                                    <option
                                      key={index}
                                      value={api.employees_Id}
                                    >
                                      {api.fName + " " + api.lName}
                                    </option>
                                  ))}
                                </select>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Employee Status
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="etId"
                                  value={this.state.etId}
                                  onChange={this.handleNameeChange}
                                >
                                  <option defaultValue>Employee Status </option>
                                  {this.state.employeetype.map(
                                    (api, index, idx) => (
                                      <option
                                        key={index}
                                        value={api.employeetype_Id}
                                      >
                                        {api.EmployeeType}
                                      </option>
                                    )
                                  )}
                                </select>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Leave Balance
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handledLeaveseChange}
                                  className="form-control"
                                  name="leaves"
                                  value={this.state.leaves}
                                  required="required"
                                  placeholder="Leave Balance"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <hr />
                          <div>
                            <h4>Emergency Contact Details</h4>
                            <hr style={{ width: "260px" }} />
                          </div>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Mobile No
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  //onChange={this.handlemobileChange}
                                  onChange={this.handleChange}
                                  onKeyDown={this.onKeyDown}
                                  className="form-control"
                                  name="mobile"
                                  value={this.state.mobile}
                                  required="required"
                                  placeholder="Enter Mobile Number"
                                  maxLength={10}
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Emergency Number
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  // onChange={this.handleaMobileChange}
                                  onChange={this.handleChange}
                                  onKeyDown={this.onKeyDown}
                                  className="form-control"
                                  name="aMobile"
                                  value={this.state.aMobile}
                                  required="required"
                                  placeholder="Enter Emergency Mobile Number"
                                  maxLength={10}
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Emergency Contact Person
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  //onChange={this.handlecPersonChange}
                                  onChange={this.handleChange}
                                  // onKeyDown={this.onKeyDown}
                                  className="form-control"
                                  name="cPerson"
                                  value={this.state.cPerson}
                                  required="required"
                                  placeholder="Enter Emergency Contact Person"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Email Id
                                </Form.Label>
                                <Form.Control
                                  type="mail"
                                  onChange={this.handleemailIdChange}
                                  className="form-control"
                                  name="emailId"
                                  value={this.state.emailId}
                                  required="required"
                                  placeholder="Enter email Id"
                                />
                              </Form.Group>
                            </Form>
                          </Col>

                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Relationship
                                </Form.Label>
                                <select
                                  className="form-control"
                                  name="rId"
                                  value={this.state.rId}
                                  onChange={this.handlerIdChange}
                                >
                                  <option defaultValue>Relationship</option>
                                  {this.state.relationships.map(
                                    (api, index, idx) => (
                                      <option
                                        key={index}
                                        value={api.relationships_Id}
                                      >
                                        {api.Relationship}
                                      </option>
                                    )
                                  )}
                                </select>
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Address
                                </Form.Label>
                                <textarea
                                  onChange={this.handleaddressChange}
                                  className="form-control"
                                  name="address"
                                  value={this.state.address}
                                  required="required"
                                  placeholder="Enter address"
                                  id="floatingTextarea"
                                ></textarea>
                              </Form.Group>
                            </Form>
                          </Col>
                          <hr />
                          <div>
                            <h4>Persional Details</h4>
                            <hr style={{ width: "230px" }} />
                          </div>

                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Pan Card
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handlepanChange}
                                  className="form-control"
                                  name="pan"
                                  value={this.state.pan}
                                  required="required"
                                  placeholder="Enter Pan Number"
                                />
                              </Form.Group>
                            </Form>
                          </Col>

                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Aadhar Number
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handleAadharChange}
                                  className="form-control"
                                  name="adhar"
                                  value={this.state.adhar}
                                  required="required"
                                  placeholder="Enter Aadhar Number"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <hr />
                          <div>
                            <h4>Bank details</h4>
                            <hr style={{ width: "230px" }} />
                          </div>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Bank A/C Number
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handlebankChange}
                                  className="form-control"
                                  name="accountnumber"
                                  value={this.state.accountnumber}
                                  required="required"
                                  placeholder="Enter Bank A/C"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Bank Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handleeBankChange}
                                  className="form-control"
                                  name="bankname"
                                  value={this.state.bankname}
                                  required="required"
                                  placeholder="Enter Bank Name"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  Branch Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handleBanknameChange}
                                  className="form-control"
                                  name="branchname"
                                  value={this.state.branchname}
                                  required="required"
                                  placeholder="Enter branch name"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                          <Col lg="4" md="4">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                  IFSC Code
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={this.handleifscChange}
                                  className="form-control"
                                  name="ifsccode"
                                  value={this.state.ifsccode}
                                  required="required"
                                  placeholder="Enter ifsc code"
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                        </Row>
                        <br />

                        <hr />
                        <div className="row">
                          <div className="col-md-6">
                                <Button
                                variant="danger"
                                style={{ float: "left" }}
                                //className="btn btn-primary"
                                id="savecDetails"
                                onClick={this.EmployeesBackHandler}
                              >
                                Back
                              </Button>
                          </div>
                       
                        </div>
                        <br />
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey={2} title="Employee Document Details">
                    {/* <Tab eventKey="Insured Details" > */}
                    <div className="tab-content" id="myTabContent">
                      <Row>
                        <Col lg="4" md="4">
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label style={{ paddingTop: "8px" }}>
                                Document Type
                              </Form.Label>
                              <select
                                className="form-control"
                                name="docType"
                                value={this.state.docType}
                                onChange={this.handleddoctypeChange}
                              >
                                <option defaultValue>Documents List</option>
                                <option value="panProof">Pan Card</option>
                                <option value="AadharProof">Aadhar Card</option>
                                <option value="Passport Photos">
                                  Passport Photos
                                </option>
                                <option value="Educational certificate ">
                                  Educational certificate
                                </option>
                                <option value="Offer Letter">
                                  Offer Letter
                                </option>
                                <option value="Previous salary Slip">
                                  Previous salary Slip
                                </option>
                                <option value="Previous company relieving Letter">
                                  Previous company relieving Letter
                                </option>
                                <option value="Bank A/c Copy">
                                  Bank A/c Copy
                                </option>
                                <option value="Appointment Letter ">
                                  Appointment Letter
                                </option>
                                <option value="Confirmation Letter">
                                  Confirmation Letter
                                </option>
                                <option value="Others">Others</option>
                              </select>
                            </Form.Group>
                          </Form>
                        </Col>
                        <Col lg="4" md="4">
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label style={{ paddingTop: "8px" }}>
                                File
                              </Form.Label>
                              <Form.Control
                                accept=".pdf"
                                type="file"
                                onChange={this.handleFileChange}
                                className="form-control"
                                name="docPath"
                                // value={
                                //   editIndex !== null ? editDocPath : docPath
                                // }
                                //value={this.state.docPath}
                                required="required"
                                placeholder="Enter Aadhar Number"
                              />
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                   
                      <br />
                      <Row className="mt-5">
                        <Col lg="12" md="12" sm="12">
                          <Table
                            className="table table-bordered table-hover mt-3"
                            style={{ marginLeft: "0px" }}
                          >
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Document Type</th>
                                <th>File</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.documents.map((employee, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{employee.docType}</td>
                                  <td>{employee.docPath}</td>

                                  <td>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        this.handlePdfClick(employee.docPath)
                                      }
                                      className="bi bi-eye"
                                      style={{ marginRight: "3px" }}
                                    ></Button>{" "}
                                    &nbsp;
                                    <Button
                                      variant="danger"
                                      onClick={() =>
                                        this.handleDeletedoc(employee)
                                      }
                                      className="bi bi-trash danger"
                                    ></Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <div className="col-md-6">
                                <Button
                                variant="danger"
                                style={{ float: "left" }}
                                //className="btn btn-primary"
                                id="savecDetails"
                                onClick={this.EmployeesBackHandler}
                              >
                                Back
                              </Button>
                          </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
