import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Offcanvas,
  Image,
  Form,
  InputGroup,
  Container,
  Table,
} from "react-bootstrap";
import "./Companies.css";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import configData from "../config.json";

export default class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      companies: [],
      rows: [],
      isdefault: false,
      features: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      cId: "",
      cData: {
        cName: "",
        cPerson: "",
        cemailId: "",
        cDesignation: "",
        cMobile: "",
        cAddress: "",
        cPath: null,
        additionalText: "",
        showAdditionalTextarea: false,
      },
      featuresData: { feature: "", featureDescription: "" },

      errors: {},
      showButton: false,
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.cId,
          sortable: true,
          cell: (row) => <span>{row.cId}</span>,
        },
        {
          name: "Company Name",
          selector: (row, i) => row.cName,
          sortable: true,
          cell: (row) => <span>{row.cName}</span>,
        },

        {
          name: "Address",
          selector: (row, i) => row.cAddress,
          sortable: true,
          cell: (row) => <span>{row.cAddress}</span>,
        },

        {
          name: "Company Logo",
          selector: (row, i) => row.cPath,
          sortable: true,
          cell: (row) => <span>{row.cPath}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <button
                className="bi bi-eye btn btn-warning m-1"
                style={{
                  backgroundColor: "#169F85",
                  border: " 1px solid #169F85",
                  color: "#000",
                }}
              ></button>
              <button
                // style={{ marginLeft: "5px" }}
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen btn btn-primary m-1"
              ></button>{" "}
              <button
                variant="danger"
                onClick={() => this.handleDelete(row, index)}
                className="bi bi-trash btn btn-danger m-1"
              ></button>
              <button
                className="bi bi-check-square btn btn-danger m-1"
                style={{
                  backgroundColor: "#26B99A",
                  border: " 1px solid #169F85",
                  color: "#fff",
                }}
              ></button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      cId: row.cId,
      cData: {
        cName: row.cName,
        cPerson: row.cPerson,
        cemailId: row.cemailId,
        cDesignation: row.cDesignation,
        cMobile: row.cMobile,
        cAddress: row.cAddress,
        cPath: null,
        additionalText: row.additionalText,
        showAdditionalTextarea: false,
      },
      featuresData: {
        feature: row.feature,
        featureDescription: row.featureDescription,
      },
      isEditing: true,
    });
  };

  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("cId", row.cId);
    fetch(configData.api_url + "companies/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
        }
      });
  };

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleRemoveSpecific = (idx) => {
    const tempRows = this.state.features; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ features: tempRows });
  };
  handleAdd = (e) => {
    e.preventDefault();
    if (this.featurevalidateForm()) {
      this.setState((prevState) => ({
        features: [...prevState.features, this.state.featuresData],
      }));
      this.setState({
        featuresData: { feature: "", featureDescription: "" },
      });
    }
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.companies.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  inputChange = (e, id) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.cData;
    newFormData[fieldName] = fieldValue;
    this.setState({ cData: newFormData });
    const newRows = [...this.state.rows]; // Create a copy of rows array
    const index = newRows.findIndex((row) => row.id === id); // Find the index of the row
    if (index !== -1) {
      newRows[index][fieldName] = fieldValue; // Update the specific field value of the row
      this.setState({ rows: newRows });
    }
  };

  inputChangeSelect = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = { ...this.state.featuresData };
    newFormData[fieldName] = fieldValue;
    this.setState({ featuresData: newFormData });
  };

  handleImageUpload = (event) => {
    const file = event.target.files[0];
    const newFormData = this.state.cData;
    newFormData["cPath"] = file;
    this.setState({ cData: newFormData });
  };

  validateForm(e) {
    let companies = this.state.cData;
    let errors = {};
    let formIsValid = true;

    if (!companies["cName"]) {
      toast.info("Please pass Company name");
      formIsValid = false;
    } else if (!companies["cPerson"]) {
      formIsValid = false;
      toast.info("Please pass Contact person");
    } else if (!companies["cemailId"]) {
      formIsValid = false;
      toast.info("Please pass Mail Id");
    } else if (!companies["cDesignation"]) {
      formIsValid = false;
      toast.info("Please pass Desigantion");
    } else if (!companies["cMobile"]) {
      formIsValid = false;
      toast.info("Please pass Mobile");
    } else if (!companies["cAddress"]) {
      formIsValid = false;
      toast.info("Please pass Address");
    } else if (!companies["cPath"]) {
      formIsValid = false;
      toast.info("Please choose file");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  featurevalidateForm() {
    let features = this.state.featuresData;
    let errors = {};
    let formIsValid = true;

    if (!features["feature"]) {
      formIsValid = false;
      toast.info("Please pass feature");
    } else if (!features["featureDescription"]) {
      formIsValid = false;
      toast.info("Please pass featureDescription");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  handleAddClick = (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      const { cData, featuresData, rows } = this.state;

      const newRow = {
        id: new Date().getTime(),
        cName: cData.cName,
        cPerson: cData.cPerson,
        cemailId: cData.cemailId,
        cDesignation: cData.cDesignation,
        cMobile: cData.cMobile,
        cAddress: cData.cAddress,
        cPath: cData.cPath,
        additionalText: cData.additionalText,
        showAdditionalTextarea: cData.showAdditionalTextarea,
        features: [...rows, featuresData], // Push the featuresData object to the features array
      };
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("cName", newRow.cName);
      formData.append("cPerson", newRow.cPerson);
      formData.append("cemailId", newRow.cemailId);
      formData.append("cDesignation", newRow.cDesignation);
      formData.append("cAddress", newRow.cAddress);
      formData.append("cMobile", newRow.cMobile);
      formData.append("cPath", newRow.cPath);
      formData.append("features", JSON.stringify(newRow.features));

      fetch(configData.api_url + "companies/save/", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);

          if (data.status === "success") {
            this.apisfetchHandler();
            this.setState(() => ({
              features: [],
              cData: {
                cName: "",
                cPerson: "",
                cemailId: "",
                cDesignation: "",
                cMobile: "",
                cAddress: "",
                cPath: null,
                additionalText: "",
                showAdditionalTextarea: false,
              },
              featuresData: { feature: "", featureDescription: "" }, // Clear the featuresData array
            }));
          }
        });
    }
  };
  apisHandlerupdate = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("cName", this.state.cData.cName);
    formData.append("cPerson", this.state.cData.cPerson);
    formData.append("cemailId", this.state.cData.cemailId);
    formData.append("cDesignation", this.state.cData.cDesignation);
    formData.append("cAddress", this.state.cData.cAddress);
    formData.append("cMobile", this.state.cData.cMobile);
    formData.append("cPath", this.state.cData.cPath);
    formData.append("cId", this.state.cId);
    fetch(configData.api_url + "/companies/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success" || data.status === "true") {
          this.apisfetchHandler();
          this.handleClose();
          this.setState(() => ({
            features: [],
            cData: {
              cName: "",
              cPerson: "",
              cemailId: "",
              cDesignation: "",
              cMobile: "",
              cAddress: "",
              cPath: null,
              additionalText: "",
              showAdditionalTextarea: false,
            },
            featuresData: { feature: "", featureDescription: "" }, // Clear the featuresData array
          }));
        }
      });
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "companies/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ companies: data.list });
        }
      });
  };
  componentDidMount() {
    this.apisfetchHandler();
  }
  render() {
 
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.companies.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2>Companies</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                    >
                      <div className="dataTables_length" id="datatable_length">
                        <Button
                          variant="primary"
                          className="bi bi-plus"
                          onClick={this.handleShow}
                        >
                          New
                        </Button>
                        <Offcanvas
                          show={this.state.show}
                          onHide={this.handleClose}
                          {...this.props}
                          style={{ width: "700px", height: "auto" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Companies Add</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group className="mt-3">
                                    <InputGroup hasValidation>
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-file-person-fill"
                                      ></InputGroup.Text>
                                      <Form.Control
                                        type="text"
                                        name="cName"
                                        value={this.state.cName}
                                        placeholder="Company Name"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cName}
                                  </div>
                                </Col>
                                <Col>
                                  <Form.Group
                                    controlId="validationFormikUsername"
                                    className="mt-3"
                                  >
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="text"
                                        name="cPerson"
                                        value={this.state.cPerson}
                                        placeholder="Contact Person"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-person"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cPerson}
                                  </div>
                                </Col>
                              </Row>
                              <Row></Row>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group className="mt-3">
                                    <InputGroup hasValidation>
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-envelope"
                                      ></InputGroup.Text>
                                      <Form.Control
                                        type="text"
                                        name="cemailId"
                                        value={this.state.cemailId}
                                        placeholder="Mail Id"
                                        onChange={this.inputChange}
                                        aria-describedby="inputGroupPrepend"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cemailId}
                                  </div>
                                </Col>
                                <Col>
                                  <Form.Group
                                    controlId="validationFormikUsername"
                                    className="mt-3"
                                  >
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="text"
                                        name="cDesignation"
                                        value={this.state.cDesignation}
                                        placeholder="Desigantion"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-telephone"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cDesignation}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group
                                    controlId="validationFormikUsername"
                                    className="mt-3"
                                  >
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="text"
                                        name="cMobile"
                                        value={this.state.cMobile}
                                        placeholder="Mobile Number"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-phone"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cMobile}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group
                                    controlId="validationFormikUsername"
                                    className="mt-3"
                                  >
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="text"
                                        name="cAddress"
                                        value={this.state.cAddress}
                                        placeholder="Address"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-phone"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cAddress}
                                  </div>
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Table
                                    className="table table-bordered table-hover"
                                    id="tab_logic"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="text-center">S.NO</th>
                                        <th className="text-center">Feature</th>
                                        <th className="text-center">
                                          Description
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.features.length > 0 ? (
                                        this.state.features.map(
                                          (api, index, idx) => (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{api.feature}</td>
                                              <td>
                                                {api.featureDescription}
                                                <button
                                                  variant="danger"
                                                  className="bi bi-trash-fill btn btn-danger"
                                                  onClick={() =>
                                                    this.handleRemoveSpecific(
                                                      index
                                                    )
                                                  }
                                                 
                                                ></button>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="3"> No Records</td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>--</td>
                                        <td>
                                          {" "}
                                          <Form.Select
                                            name="feature"
                                            value={this.state.feature}
                                            onChange={this.inputChangeSelect}
                                            aria-label="Default select example"
                                            className="mt-3"
                                          >
                                            <option default>
                                              Select Feature
                                            </option>
                                            <option value="Health">
                                              Health
                                            </option>
                                            <option value="Medical">
                                              Medical
                                            </option>
                                          </Form.Select>
                                          <div
                                            className="errorMsg text-center"
                                            style={{ color: "red" }}
                                          >
                                            {this.state.errors.feature}
                                          </div>
                                        </td>
                                        <td>
                                          <Row className="d-flex mt-3">
                                            <Col md="9">
                                              <Form.Group>
                                                <Form.Control
                                                  as="textarea"
                                                  name="featureDescription"
                                                  value={
                                                    this.state
                                                      .featureDescription
                                                  }
                                                  onChange={
                                                    this.inputChangeSelect
                                                  }
                                                  rows={3}
                                                  placeholder="Feature Description"
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col
                                              md="3"
                                              className="d-flex pr-0 pl-0"
                                            >
                                              <Button
                                                variant="warning"
                                                onClick={this.handleAdd}
                                                className="bi bi-file-plus "
                                              />
                                            </Col>
                                            <div
                                              className="errorMsg"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                this.state.errors
                                                  .featureDescription
                                              }
                                            </div>
                                          </Row>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>

                              <Row className="divstyle">
                                <Col>
                                  <Form.Group className="mt-3">
                                    <Form.Control
                                      type="file"
                                      name="cPath"
                                      value={this.state.cPath}
                                      onChange={this.handleImageUpload}
                                    />
                                    {this.state.imageUrl && (
                                      <Image
                                        src={this.state.imageUrl}
                                        style={{
                                          width: "200px",
                                          height: "200px",
                                        }}
                                        alt="Uploaded"
                                      />
                                    )}
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cPath}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="d-flex mt-3">
                                <Col>
                                  {" "}
                                  <Button
                                    style={{ float: "right" }}
                                    variant="success"
                                    onClick={this.handleAddClick}
                                  >
                                    save{" "}
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <div>
                    {" "}
                    {this.state.isEditing && (
                      // Edit Offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "700px", height: "auto" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>Edit Companies</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="form-horizontal form-label-left input_mask">
                            <Form className="form-horizontal form-label-left input_mask">
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group className="mt-3">
                                    <InputGroup hasValidation>
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-file-person-fill"
                                      ></InputGroup.Text>
                                      <Form.Control
                                        type="text"
                                        name="cName"
                                        value={this.state.cData.cName}
                                        placeholder="Company Name"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cName}
                                  </div>
                                </Col>
                                <Col>
                                  <Form.Group
                                    controlId="validationFormikUsername"
                                    className="mt-3"
                                  >
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="text"
                                        name="cPerson"
                                        value={this.state.cData.cPerson}
                                        placeholder="Contact Person"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-person"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cPerson}
                                  </div>
                                </Col>
                              </Row>
                              <Row></Row>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group className="mt-3">
                                    <InputGroup hasValidation>
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-envelope"
                                      ></InputGroup.Text>
                                      <Form.Control
                                        type="text"
                                        name="cemailId"
                                        value={this.state.cData.cemailId}
                                        placeholder="Mail Id"
                                        onChange={this.inputChange}
                                        aria-describedby="inputGroupPrepend"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cemailId}
                                  </div>
                                </Col>
                                <Col>
                                  <Form.Group
                                    controlId="validationFormikUsername"
                                    className="mt-3"
                                  >
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="text"
                                        name="cDesignation"
                                        value={this.state.cData.cDesignation}
                                        placeholder="Desigantion"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-telephone"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cDesignation}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group
                                    controlId="validationFormikUsername"
                                    className="mt-3"
                                  >
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="text"
                                        name="cMobile"
                                        value={this.state.cData.cMobile}
                                        placeholder="Mobile Number"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-phone"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cMobile}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group
                                    controlId="validationFormikUsername"
                                    className="mt-3"
                                  >
                                    <InputGroup hasValidation>
                                      <Form.Control
                                        type="text"
                                        name="cAddress"
                                        value={this.state.cData.cAddress}
                                        placeholder="Address"
                                        aria-describedby="inputGroupPrepend"
                                        onChange={this.inputChange}
                                      />
                                      <InputGroup.Text
                                        id="inputGroupPrepend"
                                        className="bi bi-phone"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cAddress}
                                  </div>
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Table
                                    className="table table-bordered table-hover"
                                    id="tab_logic"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="text-center">S.NO</th>
                                        <th className="text-center">Feature</th>
                                        <th className="text-center">
                                          Description
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.features.length > 0 ? (
                                        this.state.features.map(
                                          (api, index, idx) => (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{api.feature}</td>
                                              <td>
                                                {api.featureDescription}
                                                <button
                                                  variant="danger"
                                                  className="bi bi-trash-fill btn btn-danger"
                                                  onClick={() =>
                                                    this.handleRemoveSpecific(
                                                      index
                                                    )
                                                  }
                                                 
                                                ></button>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="3"> No Records</td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>--</td>
                                        <td>
                                          {" "}
                                          <Form.Select
                                            name="feature"
                                            value={
                                              this.state.featuresData.feature
                                            }
                                            onChange={this.inputChangeSelect}
                                            aria-label="Default select example"
                                            className="mt-3"
                                          >
                                            <option default>
                                              Select Feature
                                            </option>
                                            <option value="Health">
                                              Health
                                            </option>
                                            <option value="Medical">
                                              Medical
                                            </option>
                                          </Form.Select>
                                          <div
                                            className="errorMsg text-center"
                                            style={{ color: "red" }}
                                          >
                                            {this.state.errors.feature}
                                          </div>
                                        </td>
                                        <td>
                                          <Row className="d-flex mt-3">
                                            <Col md="9">
                                              <Form.Group>
                                                <Form.Control
                                                  as="textarea"
                                                  name="featureDescription"
                                                  value={
                                                    this.state.featuresData
                                                      .featureDescription
                                                  }
                                                  onChange={
                                                    this.inputChangeSelect
                                                  }
                                                  rows={3}
                                                  placeholder="Feature Description"
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col
                                              md="3"
                                              className="d-flex pr-0 pl-0"
                                            >
                                              <Button
                                                variant="warning"
                                                onClick={this.handleAdd}
                                                className="bi bi-file-plus "
                                              />
                                            </Col>
                                            <div
                                              className="errorMsg"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                this.state.errors
                                                  .featureDescription
                                              }
                                            </div>
                                          </Row>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>

                              <Row className="divstyle">
                                <Col>
                                  <Form.Group className="mt-3">
                                    <Form.Control
                                      type="file"
                                      name="cPath"
                                      value={this.state.cPath}
                                      onChange={this.handleImageUpload}
                                    />
                                    {this.state.imageUrl && (
                                      <Image
                                        src={this.state.imageUrl}
                                        style={{
                                          width: "200px",
                                          height: "200px",
                                        }}
                                        alt="Uploaded"
                                      />
                                    )}
                                  </Form.Group>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cPath}
                                  </div>
                                </Col>
                              </Row>
                              <br />
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-primary me-2 mt-5"
                                  style={{
                                    backgroundColor: "#23547e",
                                    color: "#fff",
                                    borderColor: "#2e6da4",
                                  }}
                                  onClick={this.apisHandlerupdate}
                                >
                                  Update
                                </button>
                              </div>
                            </Form>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <br />
                  <Row>
                    <div>
                      <input
                        style={{ float: "left", marginBottom: "10px" }}
                        type="text"
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.companies.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
