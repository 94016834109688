import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../../config.json";
import DatePicker from "react-datepicker";

export default class Codedeployment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.codedeployment_Id,
          sortable: true,
          cell: (row) => <span>{row.codedeployment_Id}</span>,
        },
        {
          name: "Project Name",
          selector: (row, i) => row.projectname,
          sortable: true,
          cell: (row) => <span>{row.projectname}</span>,
        },
        {
          name: "Environment",
          selector: (row, i) => row.environment,
          sortable: true,
          cell: (row) => <span>{row.environment}</span>,
        },
        {
          name: "Requested By",
          selector: (row, i) => row.requestedby,
          sortable: true,
          cell: (row) => <span>{row.requestedby}</span>,
        },
        {
          name: "Request Date",
          selector: (row, i) => row.requestdate,
          sortable: true,
          cell: (row) => <span>{row.requestdate}</span>,
        },
        {
          name: "Request Type",
          selector: (row, i) => row.requesttype,
          sortable: true,
          cell: (row) => <span>{row.requesttype}</span>,
        },
        {
          name: "Files Details",
          selector: (row, i) => row.filedetails,
          sortable: true,
          cell: (row) => <span>{row.filedetails}</span>,
        },
        {
          name: "Issues Resolved",
          selector: (row, i) => row.issuesresolved,
          sortable: true,
          cell: (row) => <span>{row.issuesresolved}</span>,
        },
        {
          name: "Effort Time",
          selector: (row, i) => row.efforttime,
          sortable: true,
          cell: (row) => <span>{row.efforttime}</span>,
        },
        {
          name: "Unit Testing Done By",
          selector: (row, i) => row.unittestingdoneby,
          sortable: true,
          cell: (row) => <span>{row.unittestingdoneby}</span>,
        },
        {
          name: "Last Git Pull Date",
          selector: (row, i) => row.lastgitpulldate,
          sortable: true,
          cell: (row) => <span>{row.lastgitpulldate}</span>,
        },
        {
          name: "Last Git Commit Date",
          selector: (row, i) => row.lastgitcommitdate,
          sortable: true,
          cell: (row) => <span>{row.lastgitcommitdate}</span>,
        },
        {
          name: "Remarks",
          selector: (row, i) => row.remarks,
          sortable: true,
          cell: (row) => <span>{row.remarks}</span>,
        },
        {
          name: "Verified Remarks",
          selector: (row, i) => row.verifyremarks,
          sortable: true,
          cell: (row) => (
            <span style={{ color: "red" }}>{row.verifyremarks}</span>
          ),
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      projectname: "",
      environment: "",
      requestedby: "",
      requestdate: "",
      requesttype: "",
      filedetails: "",
      issuesresolved: "",
      efforttime: "",
      unittestingdoneby: "",
      lastgitpulldate: "",
      lastgitcommitdate: "",
      codecommitmentdate: "",
      remarks: "",
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  CodedeploymenteditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Codedeployment_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  CodedeploymentfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Codedeployment/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((rsdata) => {
        if (rsdata.status === "success") {
          const list = rsdata.list;
          const data = [];
          list.forEach((element) => {
            if (element.status === "1") {
             data.push(element);
            }

            if (element.status === "2") {
              data.push(element);
            }
          });
          this.setState({ data: data });
        }
      });
  };
  CodedeploymentsubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("projectname", this.state.projectname);
    formData.append("environment", this.state.environment);
    formData.append("requestedby", this.state.requestedby);
    formData.append("requestdate", this.state.requestdate);
    formData.append("requesttype", this.state.requesttype);
    formData.append("filedetails", this.state.filedetails);
    formData.append("issuesresolved", this.state.issuesresolved);
    formData.append("efforttime", this.state.efforttime);
    formData.append("unittestingdoneby", this.state.unittestingdoneby);
    formData.append("lastgitpulldate", this.state.lastgitpulldate);
    formData.append("lastgitcommitdate", this.state.lastgitcommitdate);
    formData.append("codecommitmentdate", this.state.codecommitmentdate);
    formData.append("remarks", this.state.remarks);

    fetch(configData.api_url + "C_Codedeployment/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CodedeploymentfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            projectname: "",
            environment: "",
            requestedby: "",
            requestdate: "",
            requesttype: "",
            filedetails: "",
            codecommitmentdate: "",
            issuesresolved: "",
            efforttime: "",
            unittestingdoneby: "",
            lastgitpulldate: "",
            lastgitcommitdate: "",
            remarks: "",
          });
        }
      });
  };
  CodedeploymentupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("projectname", this.state.projectname);
    formData.append("environment", this.state.environment);
    formData.append("requestedby", this.state.requestedby);
    formData.append("requestdate", this.state.requestdate);
    formData.append("requesttype", this.state.requesttype);
    formData.append("filedetails", this.state.filedetails);
    formData.append("issuesresolved", this.state.issuesresolved);
    formData.append("efforttime", this.state.efforttime);
    formData.append("unittestingdoneby", this.state.unittestingdoneby);
    formData.append("lastgitpulldate", this.state.lastgitpulldate);
    formData.append("lastgitcommitdate", this.state.lastgitcommitdate);
    formData.append("codecommitmentdate", this.state.codecommitmentdate);
    formData.append("remarks", this.state.remarks);
    fetch(configData.api_url + "C_Codedeployment/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CodedeploymentfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.CodedeploymentfetchHandler();
  }
  handleprojectnameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ projectname: fieldValue });
  };
  handleenvironmentChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ environment: fieldValue });
  };
  handlerequestedbyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ requestedby: fieldValue });
  };
  handlerequestdateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        requestdate: formattedDate,
        startDate: new Date(),
      });
    }
  };

  handlerequesttypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ requesttype: fieldValue });
  };
  handlefiledetailsChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ filedetails: fieldValue });
  };
  handleissuesresolvedChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ issuesresolved: fieldValue });
  };
  handleefforttimeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ efforttime: fieldValue });
  };
  handleunittestingdonebyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ unittestingdoneby: fieldValue });
  };

  handlelastgitpulldateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        lastgitpulldate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handlelastgitcommitdateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        lastgitcommitdate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handlecodecommitmentdateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        codecommitmentdate: formattedDate,
        startDate: new Date(),
      });
    }
  };

  handleremarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ remarks: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };


  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("codedeployment_Id", row.codedeployment_Id);
    fetch(configData.api_url + "C_Codedeployment/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CodedeploymentfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <div className="title_left">
                      <h3
                        className="logoskybluecolor"
                        style={{ textAlign: "start" }}
                      >
                        Code Deployment
                      </h3>
                    </div>
                    <Col>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>Code Deployment</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Project Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="projectname"
                                        value={this.state.projectname}
                                        onChange={this.handleprojectnameChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="projectname"
                                      >
                                        <option selected>
                                          Select Project Type
                                        </option>
                                        <option value="apwebsite">
                                          Ap-Website
                                        </option>
                                        <option value="playright">
                                          Playright
                                        </option>
                                        <option value="cattleapp">
                                          Cattle App
                                        </option>
                                      </Form.Select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.projectname}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Environment
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="environment"
                                        value={this.state.environment}
                                        onChange={this.handleenvironmentChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="environment"
                                      >
                                        <option selected>
                                          Select Environment Type
                                        </option>
                                        <option value="dev">Development</option>
                                        <option value="production">
                                          Production
                                        </option>
                                      </Form.Select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.environment}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Requested By
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlerequestedbyChange}
                                        className="form-control"
                                        name="requestedby"
                                        value={this.state.requestedby}
                                        required="required"
                                        placeholder="Enter requestedby"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.requestedby}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Request Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        selected={this.state.requestdate}
                                        dropdownMode="select"
                                        onChange={(date) =>
                                          this.handlerequestdateChange(date)
                                        }
                                        className="form-control"
                                        name="requestdate"
                                        id="requestdate"
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        placeholderText="DD/MM/YYYY"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.requestdate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Request Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="requesttype"
                                        value={this.state.requesttype}
                                        onChange={this.handlerequesttypeChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="requesttype"
                                      >
                                        <option selected>
                                          Select Request Type
                                        </option>
                                        <option value="bugfixing">
                                          Bug-fixing
                                        </option>
                                        <option value="newfinctionality">
                                          New Finctionality
                                        </option>
                                        <option value="changerequest">
                                          Change Request
                                        </option>
                                      </Form.Select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.requesttype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Files Details
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        onChange={this.handlefiledetailsChange}
                                        className="form-control"
                                        name="filedetails"
                                        value={this.state.filedetails}
                                        required="required"
                                        placeholder="Enter filedetails"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.filedetails}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Issues Resolved
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        onChange={
                                          this.handleissuesresolvedChange
                                        }
                                        className="form-control"
                                        name="issuesresolved"
                                        value={this.state.issuesresolved}
                                        required="required"
                                        placeholder="Enter issuesresolved"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.issuesresolved}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Effort Time
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="time"
                                        onChange={this.handleefforttimeChange}
                                        className="form-control"
                                        name="efforttime"
                                        value={this.state.efforttime}
                                        required="required"
                                        placeholder="Enter efforttime"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.efforttime}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Unit Testing Done By
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleunittestingdonebyChange
                                        }
                                        className="form-control"
                                        name="unittestingdoneby"
                                        value={this.state.unittestingdoneby}
                                        required="required"
                                        placeholder="Enter unittestingdoneby"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.unittestingdoneby}{" "}
                                    </div>
                                  </Row>
                                  <br />

                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Last Git Pull Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        selected={this.state.lastgitpulldate}
                                        dropdownMode="select"
                                        onChange={(date) =>
                                          this.handlelastgitpulldateChange(date)
                                        }
                                        className="form-control"
                                        name="lastgitpulldate"
                                        id="lastgitpulldate"
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        placeholderText="DD/MM/YYYY"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.lastgitpulldate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Last Git Commit Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        selected={this.state.lastgitcommitdate}
                                        dropdownMode="select"
                                        onChange={(date) =>
                                          this.handlelastgitcommitdateChange(
                                            date
                                          )
                                        }
                                        className="form-control"
                                        name="lastgitcommitdate"
                                        id="lastgitcommitdate"
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        placeholderText="DD/MM/YYYY"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.lastgitcommitdate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Code Commitment Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        selected={this.state.codecommitmentdate}
                                        dropdownMode="select"
                                        onChange={(date) =>
                                          this.handlecodecommitmentdateChange(
                                            date
                                          )
                                        }
                                        className="form-control"
                                        name="codecommitmentdate"
                                        id="codecommitmentdate"
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        placeholderText="DD/MM/YYYY"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.codecommitmentdate
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Remarks
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        onChange={this.handleremarksChange}
                                        className="form-control"
                                        name="remarks"
                                        value={this.state.remarks}
                                        required="required"
                                        placeholder="Enter remarks"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.remarks}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.CodedeploymentsubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <Button
                        style={{ float: "right" }}
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
