import React, { Component } from "react";
import { Card, Row, Col, Form, Button, Offcanvas } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import configData from "../config.json";

export default class PosscThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      data: [],
      currentPage: 1,
      itemsPerPage: 10,
      pfName: "",
      plName: "",
      pmobilenumber: "",
      panPath: "",
      aadharPath: "",
      pospId: "",
      addressLine: "",
      pincode: "",
      areaCd: "",
      cityCd: "",
      stateCd: "",
      irdabranch: "",
      pannumber: "",
      aadharnumber: "",
      emailid: "",
      sscPath: "",
      accName: "",
      accNO: "",
      bankName: "",
      ifscCode: "",
      branchCode: "",
      chequePath: "",
      fullName: "",
      amobilenumber: "",
      aemailid: "",
      apincode: "",
      aareaCd: "",
      acityCd: "",
      aAddressLine: "",
      astateCd: "",
      mSheetPath: "",
      endDate: "",
      startDate: null,
      stDate: new Date(),
      edDate: new Date(),
      error: "",
      createdBy: 0,
      tableClms: [
        {
          name: "S.NO",
          width: "75px",
          selector: (row, i) => row.pospId,
          sortable: true,
          cell: (row) => <span>{row.pospId}</span>,
        },
        {
          name: "First Name",
          id: "pfName",
          selector: (row, i) => row.pfName,
          sortable: true,
          cell: (row) => <span>{row.pfName}</span>,
        },
        {
          name: "Last Name",
          id: "plName",
          selector: (row, i) => row.plName,
          sortable: true,
          cell: (row) => <span>{row.plName}</span>,
        },

        {
          name: "Mobile Number",
          id: "pmobilenumber",
          selector: (row, i) => row.pmobilenumber,
          sortable: true,
          cell: (row) => <span>{row.pmobilenumber}</span>,
        },
        {
          name: "PAN",
          id: "pannumber",
          selector: (row, i) => row.pannumber,
          sortable: true,
          cell: (row) => <span>{row.pannumber}</span>,
        },
        {
          name: "Aadhar Number",
          id: "aadharnumber",
          selector: (row, i) => row.aadharnumber,
          sortable: true,
          cell: (row) => <span>{row.aadharnumber}</span>,
        },
        {
          name: "Email-Id",
          id: "emailid",
          selector: (row, i) => row.emailid,
          sortable: true,
          cell: (row) => <span>{row.emailid}</span>,
        },
        {
          name: "Address",
          id: "addressLine",
          selector: (row, i) => row.addressLine,
          sortable: true,
          cell: (row) => <span>{row.addressLine}</span>,
        },

        {
          name: "Pincode",
          id: "pincode",
          selector: (row, i) => row.pincode,
          sortable: true,
          cell: (row) => <span>{row.pincode}</span>,
        },
        {
          name: "Area",
          id: "areaCd",
          selector: (row, i) => row.areaCd,
          sortable: true,
          cell: (row) => <span>{row.areaCd}</span>,
        },

        {
          name: "State",
          id: "stateCd",
          selector: (row, i) => row.stateCd,
          sortable: true,
          cell: (row) => <span>{row.stateCd}</span>,
        },
        {
          name: "Irda Branch",
          id: "irdabranch",
          selector: (row, i) => row.irdabranch,
          sortable: true,
          cell: (row) => <span>{row.irdabranch}</span>,
        },
        {
          name: "Bank Name",
          id: "bankName",
          selector: (row, i) => row.bankName,
          sortable: true,
          cell: (row) => <span>{row.bankName}</span>,
        },
        {
          name: "Account Number",
          id: "accNO",
          selector: (row, i) => row.accNO,
          sortable: true,
          cell: (row) => <span>{row.accNO}</span>,
        },
        {
          name: "IFSC Code",
          id: "ifscCode",
          selector: (row, i) => row.ifscCode,
          sortable: true,
          cell: (row) => <span>{row.ifscCode}</span>,
        },
        {
          name: "Branch Code",
          id: "branchCode",
          selector: (row, i) => row.branchCode,
          sortable: true,
          cell: (row) => <span>{row.branchCode}</span>,
        },
        {
          name: "Alernate Person Full Name",
          id: "fullName",
          selector: (row, i) => row.fullName,
          sortable: true,
          cell: (row) => <span>{row.fullName}</span>,
        },
        {
          name: "IFSC Code",
          id: "ifscCode",
          selector: (row, i) => row.ifscCode,
          sortable: true,
          cell: (row) => <span>{row.ifscCode}</span>,
        },
        {
          name: "Alternate Person Mobile Number",
          id: "amobilenumber",
          selector: (row, i) => row.amobilenumber,
          sortable: true,
          cell: (row) => <span>{row.amobilenumber}</span>,
        },
        {
          name: "Alternate Person Email Id",
          id: "aemailid",
          selector: (row, i) => row.aemailid,
          sortable: true,
          cell: (row) => <span>{row.aemailid}</span>,
        },
        {
          name: "Alternate Person Address",
          id: "aAddressLine",
          selector: (row, i) => row.aAddressLine,
          sortable: true,
          cell: (row) => <span>{row.aAddressLine}</span>,
        },
        {
          name: "Alternate Person Pincode",
          id: "apincode",
          selector: (row, i) => row.apincode,
          sortable: true,
          cell: (row) => <span>{row.apincode}</span>,
        },
        {
          name: "Alternate Person Area",
          id: "aareaCd",
          selector: (row, i) => row.aareaCd,
          sortable: true,
          cell: (row) => <span>{row.aareaCd}</span>,
        },
        {
          name: "Alternate Person City",
          id: "acityCd",
          selector: (row, i) => row.acityCd,
          sortable: true,
          cell: (row) => <span>{row.acityCd}</span>,
        },

        {
          name: "IRDA Office",
          id: "irdabranch",
          selector: (row, i) => row.irdabranch,
          sortable: true,
          cell: (row) => <span>{row.irdabranch}</span>,
        },
        {
          name: "Remarks",
          id: "remarks1",
          selector: (row, i) => row.remarks1,
          sortable: true,
          cell: (row) => <span>{row.remarks1}</span>,
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-box-arrow-in-up"
                style={{ marginRight: "3px" }}
              ></Button>
              {/* <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button> */}
            </div>
          ),
        },
      ],
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var pDob = "";
    if (row.pDob !== "0000-00-00") pDob = row.pDob;
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      createdBy: row.createdBy,
      pfName: row.pfName,
      plName: row.plName,
      pDob: pDob,
      pGender: row.pGender,
      pmobilenumber: row.pmobilenumber,
      pannumber: row.pannumber,
      aadharnumber: row.aadharnumber,
      emailid: row.emailid,
      panPath: row.panPath,
      aadharPath: row.aadharPath,
      addressLine: row.addressLine,
      pincode: row.pincode,
      branchCode: row.branchCode,
      areaCd: row.areaCd,
      stateCd: row.stateCd,
      cityCd: row.cityCd,
      irdabranch: row.irdabranch,
      sscPath: row.sscPath,
      chequePath: row.chequePath,
      pPhoto: row.pPhoto,
      accName: row.accName,
      apincode: row.apincode,
      aemailid: row.aemailid,
      fullName: row.amobilenumber,
      amobilenumber: row.amobilenumber,
      aareaCd: row.aareaCd,
      accNO: row.accNO,
      bankName: row.bankName,
      ifscCode: row.ifscCode,
      acityCd: row.acityCd,
      aAddressLine: row.aAddressLine,
      astateCd: row.astateCd,
      pospId: row.pospId,

      isEditing: true,
    });
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const pospId = row.pospId;
   
    formData.append("pospId", pospId);
    fetch(configData.api_url + "posps/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.PospfetchHandler();
        }
      });
    //implement your delete logic here
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: "", // Clear the corresponding error when the field value changes
      },
    });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  componentDidMount() {
    this.PospfetchHandler();
  }


  handleSubmit = (e, status) => {
    e.preventDefault();

    const { endDate, startDate, mSheetPath } = this.state;

    if (!mSheetPath || mSheetPath.length === 0) {
      this.showToast("Please Upload Marksheet *Pdf file", "error");
      return;
    }

    const minDaysDiff = 4;
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const daysDiff = Math.ceil(
      (endDateObj - startDateObj) / (1000 * 3600 * 24)
    );

    if (daysDiff < minDaysDiff) {
      this.showToast(`Minimum training period is ${minDaysDiff} days`, "error");
      return;
    }

    if (startDateObj.getTime() === endDateObj.getTime()) {
      this.showToast("Start and end dates cannot be the same", "error");
      return;
    }

    // Assuming you want to make an API call here
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);
    formData.append("pStatus", status);
    formData.append("mSheetPath", mSheetPath);
    // Convert the date objects to ISO strings
    formData.append("startDate", startDateObj.toISOString());
    formData.append("endDate", endDateObj.toISOString());
    formData.append("createdBy", this.state.createdBy);

    fetch(configData.api_url + "posps/updateMarkSheet", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          // Reset form fields upon successful submission
          this.setState({
            endDate: "",
            startDate: "",
            mSheetPath: "",
          });
          this.handleOffcanvasClose();
        }
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      
  };

  handleFromDateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    
      // Assuming you want to update the 'pDob' state with the selected date
      this.setState({
        startDate: formattedDate,
        stDate: new Date(),
      });
    }
  };

  handleToDateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    
      // Assuming you want to update the 'pDob' state with the selected date
      this.setState({
        endDate: formattedDate,
        edDate: new Date(),
      });
    }
  };
  calculateTraningPeriod = () => {
    const { startDate, endDate } = this.state;

    if (!startDate || !endDate) {
      return;
    }

    // Calculate the difference in days
    const fromDateTime = startDate.getTime();
    const toDateTime = endDate.getTime();
    const differenceInDays = (toDateTime - fromDateTime) / (1000 * 3600 * 24);

    // Update the state with the calculated leave balance
    this.setState({ NoOfLeaves: differenceInDays + 1 });
  };
  handleOffcanvasClose = () => {
    this.setState({ isEditing: false });
  };
  handleMarkChange = (e) => {
    // Get the selected file(s) from the event object
    const files = e.target.files[0];

    // Update the state with the selected file(s)
    this.setState({ mSheetPath: files });
  };
  PospfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);
    formData.append("remarks1", this.state.remarks1);

    fetch(configData.api_url + "posps/lsitbyuId", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((rsdata) => {
        this.showToast(rsdata.message, rsdata.status);
        if (rsdata.status === "success") {
          const list = rsdata.list;
          const data = [];
          list.forEach((element) => {
            if (element.pStatus === "6" || element.pStatus === "8") {
              data.push(element);
            }
          });
          this.setState({ data: data });
        }
      });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="x_panel">
              <div className="x_content">
                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                  <Row>
                    <div className="" style={{ float: "left" }}>
                      <h3 className="logoskybluecolor">
                        {" "}
                        Verify POSP Marksheet{" "}
                      </h3>
                    </div>
                    <Col>
                      {this.state.isEditing && (
                        <Offcanvas
                          show={this.state.isEditing}
                          onHide={() => this.setState({ isEditing: false })}
                          {...this.props}
                          style={{ width: "500px" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              {" "}
                              <h3>Verify POSP Marksheet</h3>
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Card>
                              <Card.Body>
                                <Row>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          First Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          //  onChange={this.handleChange}
                                          className="form-control"
                                          name="pfName"
                                          value={this.state.pfName}
                                          required="required"
                                          placeholder="Enter First Name"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Last Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          //  onChange={this.handleChange}
                                          className="form-control"
                                          name="plName"
                                          value={this.state.plName}
                                          required="required"
                                          placeholder="Enter Last Name"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Gender
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          //  onChange={this.handleChange}
                                          className="form-control"
                                          name="pGender"
                                          value={this.state.pGender}
                                          required="required"
                                          placeholder="Enter Last Name"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                        name="irdabranch"
                                        onChange={this.handleChange}
                                      >
                                        <Form.Label>Date Of Birth</Form.Label>

                                        <DatePicker
                                          dateFormat="dd/MM/yyyy"
                                          showTimeSelect={false}
                                          selected={this.state.pDob}
                                          // onChange={(date) => this.handleDChange(date)}
                                          className="form-control"
                                          id="pDob"
                                          disabled={true}
                                          placeholderText="Select Date of Birth"
                                          peekNextMonth
                                          showMonthD
                                          ropdown
                                          showYearDropdown
                                          // minDate={previousYearStart} // Set the minimum selectable date
                                          // maxDate={endYear} // Set the maximum selectable date
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Mobile Number
                                        </Form.Label>
                                        <Form.Control
                                          onKeyDown={this.onKeyDown}
                                          id="pmobilenumber"
                                          type="text"
                                          name="pmobilenumber"
                                          value={this.state.pmobilenumber}
                                          //  onChange={this.handleChange}
                                          required="required"
                                          placeholder="Enter Mobile Number"
                                          maxLength={10}
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Email Id
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="emailid"
                                          value={this.state.emailid}
                                          //  onChange={this.handleChange}
                                          required="required"
                                          placeholder="Enter Email-Id"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Aadhar Number
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="aadharnumber"
                                          value={this.state.aadharnumber}
                                          required="required"
                                          placeholder="Enter Aadhar Number"
                                          // onChange={this.handleChange}
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          PAN Number
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          //  className="form-control"
                                          name="pannumber"
                                          value={this.state.pannumber}
                                          // onChange={this.handlePANNumberChange}
                                          required="required"
                                          disabled={true}
                                          placeholder="Enter PAN Number"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          PAN Card Upload
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          accept=".pdf,.jpg,jpeg,.png"
                                          disabled={true}
                                          value={this.state.panPath}
                                          // onChange={this.handlePANChange}
                                          className="form-control"
                                          name="panPath"
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Aadhar Upload
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          disabled={true}
                                          value={this.state.aadharPath}
                                          type="text"
                                          // onChange={this.handleAadharChange}
                                          className="form-control"
                                          name="aadharPath"
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Passport Size Color Photo{" "}
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            (* .pdf only)
                                          </p>
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          type="text"
                                          value={this.state.pPhoto}
                                          // onChange={this.handlepsPhotoChange}
                                          className="form-control"
                                          name="pPhoto"
                                          required="required"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Tenth Certificate Upload
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            (* .pdf only)
                                          </p>
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          disabled={true}
                                          className="form-control"
                                          name="sscPath"
                                          value={this.state.sscPath}
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Account Holder Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="accName"
                                          value={this.state.accName}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Account Holder Name"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Account Number
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="accNO"
                                          value={this.state.accNO}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Account Number"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Bank Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="bankName"
                                          value={this.state.bankName}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Bank Name"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          IFSC Code
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="ifscCode"
                                          value={this.state.ifscCode}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter IFSC Code"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Branch Code
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="branchCode"
                                          value={this.state.branchCode}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Branch Code"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Cheque Upload{" "}
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            (* .pdf only)
                                          </p>
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          value={this.state.chequePath}
                                          disabled={true}
                                          className="form-control"
                                          name="chequePath"
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <h6>Communication Details</h6>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Address Line{" "}
                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          disabled={true}
                                          className="form-control"
                                          name="addressLine"
                                          id="addressLine"
                                          value={this.state.addressLine}
                                          required="required"
                                          maxLength={60}
                                          placeholder="Enter Your Address Line"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col sm="6" md="6" lg="6">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Pin Code
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="pincode"
                                        id="pincode"
                                        value={this.state.pincode}
                                        disabled={true}
                                        className="form-control"
                                        aria-describedby="pincode"
                                        placeholder="Pin Code"
                                        maxLength={6}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Area
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="areaCd"
                                        id="areaCd"
                                        value={this.state.areaCd}
                                        disabled={true}
                                        aria-describedby="area"
                                        placeholder="Enter Area"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col sm="3" lg="6" md="6">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="cityCd"
                                        id="cityCd"
                                        value={this.state.cityCd}
                                        disabled={true}
                                        aria-describedby="cityCd"
                                        placeholder="City"
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        State
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="stateCd"
                                        aria-describedby="State"
                                        name="stateCd"
                                        value={this.state.stateCd}
                                        disabled={true}
                                        placeholder="State"
                                      />
                                    </Form.Group>
                                  </Col>
                                  {/*   <Col lg="12" md="12" sm="12" xs="12">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                Country Name                                
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  //  className="form-control"
                                   name="CtName"
                                  value={this.state.CtName}
                                  onChange={this.handlePANNumberChange}
                                  required="required"
                                  placeholder="Enter Country Name"
                                />
                              </Form.Group>
                            </Form>
                          </Col> */}
                                </Row>
                                <hr />
                                <Row>
                                  <h6>Alternate Person Details</h6>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Full Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          disabled={true}
                                          className="form-control"
                                          name="fullName"
                                          value={this.state.fullName}
                                          required="required"
                                          placeholder="Enter Full Name"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Mobile Number
                                        </Form.Label>
                                        <Form.Control
                                          onKeyDown={this.onKeyDown}
                                          id="amobilenumber"
                                          type="text"
                                          name="amobilenumber"
                                          value={this.state.amobilenumber}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Mobile Number"
                                          maxLength={10}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Email Id
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="aemailid"
                                          value={this.state.aemailid}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Email-Id"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Address Line{" "}
                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          disabled={true}
                                          className="form-control"
                                          name="aAddressLine"
                                          id="aAddressLine"
                                          value={this.state.aAddressLine}
                                          required="required"
                                          maxLength={60}
                                          placeholder="Enter Your Address Line"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col sm="3" lg="6" md="6">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Pin Code
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="apincode"
                                        id="apincode"
                                        value={this.state.apincode}
                                        disabled={true}
                                        className="form-control"
                                        aria-describedby="apincode"
                                        placeholder="Pin Code"
                                        maxLength={6}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Area
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="aareaCd"
                                        id="aareaCd"
                                        value={this.state.aareaCd}
                                        disabled={true}
                                        aria-describedby="aareaCd"
                                        placeholder="Enter Area"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col sm="3" lg="6" md="6">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="acityCd"
                                        id="acityCd"
                                        value={this.state.acityCd}
                                        disabled={true}
                                        aria-describedby="acityCd"
                                        placeholder="City"
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        State
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="astateCd"
                                        aria-describedby="State"
                                        name="astateCd"
                                        value={this.state.astateCd}
                                        disabled={true}
                                        placeholder="State"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <h6>Branch Details</h6>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                        name="irdabranch"
                                        disabled={true}
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          IRDA Branch Details
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          id="irdabranch"
                                          aria-describedby="State"
                                          name="irdabranch"
                                          value={this.state.irdabranch}
                                          disabled={true}
                                          placeholder="State"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Training Date Start - End Date
                                      </Form.Label>
                                      <Row>
                                        {" "}
                                        <Col>
                                          <DatePicker
                                            selected={this.state.startDate}
                                            onChange={(date) =>
                                              this.handleFromDateChange(date)
                                            }
                                            className="form-control"
                                            dateFormat="dd-MM-yyyy"
                                            id="startDate"
                                            name="startDate"
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select a start date"
                                            minDate={new Date(2023, 0, 1)}
                                          />
                                        </Col>
                                        <Col>
                                          <DatePicker
                                            selected={this.state.endDate}
                                            onChange={(date) =>
                                              this.handleToDateChange(date)
                                            }
                                            className="form-control"
                                            name="endDate"
                                            dateFormat="dd-MM-yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select an end date"
                                            minDate={new Date(2023, 0, 1)}
                                            dropdownMode="select"
                                          />
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Marksheet Upload
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          type="file"
                                          onChange={this.handleMarkChange}
                                          className="form-control"
                                          name="mSheetPath"
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="d-flex justify-content-end"
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Button
                                      variant="success"
                                      type="submit"
                                      onClick={(e) => this.handleSubmit(e, "7")}
                                    >
                                      Submit
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Offcanvas.Body>
                        </Offcanvas>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <div>
                      {/* searchbar,pagination,filter */}
                      <input
                        style={{ float: "left", marginBottom: "10px" }}
                        type="text"
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        // paginationTotalRows={this.state.data.length}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
