import React, { Component } from "react";
import { Row, Col, Form, Container, Button, Table } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";

export default class Altertable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      tables: [],
      columns: [],
      columnsone: [],
      rcolumns: [],
      columnsWT: [],
      contacts: [],
      conditions: [],
      tableName: "",
      columnName: "",
      addFormData: {
        displayName: "",
        operator: "",
        fieldName: "",
        inputType: "",
        dataType: "",
        number: "0",
        reftableName: "",
        refcolumnName: "",
        iseditable: false,
        isvisible: false,
        isunique: false,
        isnull: false,
        isAddButtonDisabled: false,
        errors: {},
      },
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  onKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      toast.error("Spaces are not allowed.");
    } else if (/[\s!@#$%^&*()_+\-=,.?":{}|<>/\\[\];""']/.test(e.key)) {
      e.preventDefault();
      toast.error("Special characters are not allowed.");
    } else if (/[\d]/.test(e.key)) {
      e.preventDefault();
      toast.error("Numbers are not allowed.");
    }
  };
  onFirstNamePaste(e) {
    e.preventDefault();
    const text = e.clipboardData.getData("Text");
    const value = text.split(" ").join("");
    e.target.value = value;
  }
  componentDidMount() {
    this.getTables();
  }
  handlefaculty = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormDatas = this.state.addFormData;
    newFormDatas[fieldName] = fieldValue;
    this.setState({ addFormData: newFormDatas });
    this.addFormData = `${fieldValue} `;
    this.setState({ addFormData: newFormDatas });
    this.getColumns(fieldValue, "columnsone");
    this.addFormData =
      this.state.addFormData.reftableName +
      "." +
      this.state.addFormData.refcolumnName;
  };
  HandleChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    let fieldValue = false;
    if (e.target.checked) {
      fieldValue = true;
    }
    const newFormData = this.state.addFormData;
    newFormData[fieldName] = fieldValue;
    this.setState({ addFormData: newFormData });
  };
  handletypechange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.addFormData;
    newFormData[fieldName] = fieldValue;
    this.setState({ addFormData: newFormData });
    this.setState({ visibletype: e.target.value });
    const selectedValue = e.target.value;
    if (selectedValue === "Autofill") {
      this.setState({ visibletype: "Autofill" });
    } else if (selectedValue === "Select") {
      this.setState({ visibletype: "Select" });
    }
  };
  getTables = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/masters/gettables", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ tables: data.tables });
          //console.log(this.state.tables);
        }
      });
  };

  getColumnsWT = (tableName, rcolumn) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", tableName);
    fetch(configData.api_url + "/masters/getcolumns", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ [rcolumn]: data.columnsWT });
        }
      });
  };

  getColumns = (tableName, rcolumn) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", tableName);
    fetch(configData.api_url + "/masters/getcolumns", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ [rcolumn]: data.columns });
        }
      });
  };
  inputtableSChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ tableName: fieldValue });
    this.getColumnsWT(fieldValue, "columns");
  };
  handleColumnChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnName: fieldValue });
  };
  FormChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name").trim();
    const fieldValue = e.target.value;
    const newFormData = this.state.addFormData;
    newFormData[fieldName] = fieldValue;
    this.setState({ addFormData: newFormData });
  };
  validateForm() {
    const { currentStep, addFormData } = this.state;
    let formIsValid = true;
    if (!this.state.tableName) {
      formIsValid = false;
      toast.info("*Please select a table.");
    }
    if (!this.state.columnName) {
      formIsValid = false;
      toast.info("*Please select a column.");
    }

    if (!addFormData["displayName"]) {
      formIsValid = false;
      toast.info("*Please enter your display Name.");
    } else if (!addFormData["fieldName"]) {
      formIsValid = false;
      toast.info("*Please enter field Name.");
    } else if (!addFormData["inputType"]) {
      formIsValid = false;
      toast.info("*Please select input field.");
    } else if (!addFormData["dataType"]) {
      formIsValid = false;
      toast.info("*Please select data Type.");
    } else if (!addFormData["number"]) {
      formIsValid = false;
      toast.info("*Please enter length value.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }
    return formIsValid;
  }
  FormSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", this.state.tableName);
    formData.append("columnName", this.state.columnName);

    if (this.validateForm()) {
      const newRow = {
        ...this.state.addFormData,
        tableName: this.state.tableName,
        columnName: this.state.columnName,
        isnull: false,
      };
      this.setState((prevState) => ({
        contacts: [...prevState.contacts, newRow],
      }));
      //console.log(this.state.contacts);
      this.setState({
        addFormData: {
          displayName: "",
          fieldName: "",
          inputType: "",
          dataType: "",
          number: "0",
          operator: "",
          reftableName: "",
          iseditable: false,
          isvisible: false,
          isunique: false,
          isnull: false,
        },
      });
      this.setState({ isdefault: false });
      this.showToast("Row Added correctly. ", "Info");
    }
  };

  handleEditRow = (index) => {
    const updatedTableData = this.state.contacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, isEdit: true };
      } else {
        return { ...contact, isEdit: false }; // Ensure other rows are not in edit mode
      }
    });
    const selectedRow = this.state.contacts[index];
    this.setState({
      addFormData: { ...selectedRow, isEdit: true },
      contacts: updatedTableData,
      isEditing: true,
      isAddButtonDisabled: false,
    });
    this.setState({ isAddButtonDisabled: true });
  };
  handleUpdateRow = (index) => {
    const updatedContacts = [...this.state.contacts];
    const updatedRow = updatedContacts[index];
    const updatedTableData = this.state.contacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, isEdit: true };
      } else {
        return { ...contact, isEdit: false };
      }
    });
    this.setState({ isAddButtonDisabled: false });
    this.setState({
      contacts: updatedTableData,
      isEditing: false,
      isAddButtonDisabled: false,
    });

    if (updatedRow.isEdit) {
      // Update the row data
      updatedContacts[index] = {
        ...this.state.addFormData,
        isEdit: false,
      };

      this.setState({
        contacts: updatedContacts,
        addFormData: {
          // Clear the form fields
          displayName: "",
          fieldName: "",
          inputType: "",
          dataType: "",
          number: "0",
          isnull: false,
          reftableName: "",
          columnName: "",
          ...this.state.addFormData,
          isEdit: false,
        },
      });
      this.setState({
        addFormData: {
          displayName: "",
          fieldName: "",
          inputType: "",
          dataType: "",
          number: "0",
          isnull: false,

          reftableName: "",
          columnName: "",
        },
      });
    }
  };
  submitButton = (e) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("fields", JSON.stringify(this.state.contacts));
    formData.append("tableName", this.state.tableName);
    formData.append("columnName", this.state.columnName);

    fetch(configData.api_url + "masters/alterTable", {
      // Enter your IP address here
      method: "POST",
      //   headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ isdefault: false });
        }
      });
  };
  handleRemoveSpecificRow = (idx) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const { contacts } = this.state;
    const tempRows = [...contacts];
    tempRows.splice(idx, 1);
    this.setState({ contacts: tempRows });
  };
  render() {
    const DefaultData = this.state.addFormData;
    const TableData = this.state.contacts;
    const tbleLength = TableData.length;
    return (
      <div>
        <h3 className="logoskybluecolor" style={{ textAlign: "start" }}>
          Alter Table
        </h3>
        <Container className="bgalphacolor">
          <div>
            <Form className="mt-5 mb-5">
              <Row>
                <Col lg="6">
                  <Row>
                    <Col lg="6" md="4" className="text-end">
                      <Form.Label>Select Table </Form.Label>
                    </Col>
                    <Col lg="6" md="4">
                      <Form.Control
                        as="select"
                        className="form-select"
                        value={this.state.tableName}
                        name="tableName"
                        onChange={this.inputtableSChange}
                      >
                        <option value="">Select Table</option>
                        {this.state.tables.map((api, index, idx) => (
                          <option key={index} value={api}>
                            {api}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col lg="6" md="4" className="text-end">
                      <Form.Label>
                        {" "}
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Select Column
                        </Form.Label>
                      </Form.Label>
                    </Col>
                    <Col lg="6" md="4">
                      <Form.Control
                        as="select"
                        className="form-select"
                        aria-label="Default select example"
                        value={this.state.columnName}
                        name="columnName"
                        onChange={this.handleColumnChange}
                      >
                        <option value="">Select Columns</option>
                        {this.state.columns.map((api, index) => (
                          <option key={api.DATA_TYPE} value={api.COLUMN_NAME}>
                            {api.COLUMN_NAME}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>
              <hr />
              <Row className="mt-1">
                <Col lg="2" md="2">
                  <Form.Label>Field Name</Form.Label>
                </Col>
                <Col lg="4" md="4">
                  <Form.Group controlId="textBox">
                    <Form.Control
                      type="text"
                      onKeyDown={this.onKeyDown}
                      onPaste={(e) => this.onFirstNamePaste(e)}
                      onChange={this.FormChange}
                      value={DefaultData.fieldName}
                      name="fieldName"
                      required="required"
                      placeholder="Enter Field Name"
                    />
                  </Form.Group>
                </Col>
                <Col lg="2" md="2">
                  <Form.Label>Display Name:</Form.Label>
                </Col>
                <Col lg="4" md="4">
                  <Form.Group controlId="textBox">
                    <Form.Control
                      type="text"
                      onChange={this.FormChange}
                      value={DefaultData.displayName}
                      name="displayName"
                      required="required"
                      placeholder="Enter Display Name"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg="2" md="2">
                  <Form.Label>Input Type:</Form.Label>
                </Col>
                <Col lg="4" md="4">
                  <Form.Group controlId="textBox">
                    <Form.Control
                      as="select"
                      className="form-select"
                      aria-label="Default Select Example"
                      value={DefaultData.inputType}
                      onChange={this.handletypechange}
                      name="inputType"
                    >
                      <option>Select Item</option>
                      <option>Text</option>
                      <option>Telephone</option>
                      <option>Number</option>
                      <option>Password</option>
                      <option>File</option>
                      <option>Mail</option>
                      <option>Date</option>
                      <option>Checkbox</option>
                      <option value="Select">Select</option>
                      <option value="Autofill">Autofill</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg="2" md="2">
                  <Form.Label>Add Operator</Form.Label>
                </Col>
                <Col lg="4" md="4">
                  <Form.Control
                    as="select"
                    className="form-select"
                    aria-label="Default Select Example"
                    value={this.state.operator}
                    onChange={this.FormChange}
                    name="operator"
                  >
                    <option default>After/Before</option>
                    <option value="before">Before</option>
                    <option value="after">After</option>
                  </Form.Control>
                </Col>
                <Col lg="6" md="4">
                  <Row>
                    {this.state.visibletype === "Select" && (
                      <Col lg="4" md="4">
                        <Form.Group controlId="textBox">
                          <Form.Label>Table Name</Form.Label>
                          <Form.Control
                            as="select"
                            className="form-select"
                            value={DefaultData.reftableName}
                            name="reftableName"
                            required="required"
                            aria-label="Default select example"
                            onChange={this.handlefaculty}
                          >
                            {" "}
                            <option defaultValue hidden>
                              Select Table Name
                            </option>
                            {this.state.tables.map((api, index, idx) => (
                              <option key={index} value={api}>
                                {api}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                    {this.state.visibletype === "Select" && (
                      <Col lg="4" md="4">
                        <Form.Group controlId="textBox">
                          <Form.Label>Column Name</Form.Label>
                          <Form.Control
                            as="select"
                            className="form-select"
                            value={DefaultData.refcolumnName}
                            name="refcolumnName"
                            required="required"
                            aria-label="Default select example"
                            onChange={this.handlefaculty}
                          >
                            {" "}
                            <option defaultValue>Select Column</option>
                            {this.state.columnsone.map((api, index, idx) => (
                              <option key={index} value={api}>
                                {api}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                    {this.state.visibletype === "Select" && (
                      <Col lg="4" md="4">
                        {" "}
                        <Form.Group>
                          <Form.Label>Table-Column Details</Form.Label>
                          <Form.Control
                            type="text"
                            value={this.addFormData}
                            // name="addFormdata"
                            required="required"
                            placeholder=""
                            // onChange={this.handlefaculty}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col lg="2" md="2">
                  <Form.Label>Data Type:</Form.Label>
                </Col>
                <Col lg="4" md="4">
                  <Form.Control
                    as="select"
                    className="form-select"
                    aria-label="Default Select Example"
                    value={this.state.dataType}
                    onChange={this.FormChange}
                    name="dataType"
                  >
                    <option>Choose here</option>
                    <option>INT</option>
                    <option>VARCHAR</option>
                    <option>TEXT</option>
                    <option>DATE</option>
                    <option>Boolean</option>
                  </Form.Control>
                </Col>
                <Col lg="2" md="2">
                  <Form.Label>Length</Form.Label>
                </Col>
                <Col lg="4" md="4">
                  <Form.Group controlId="textBox">
                    <Form.Control
                      type="number"
                      onChange={this.FormChange}
                      value={DefaultData.number}
                      name="number"
                      required="required"
                      placeholder="Enter number"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg="2" md="2">
                  <Form.Label>Is Editable :</Form.Label>
                </Col>
                <Col lg="2" md="2">
                  <input
                    type={"checkbox"}
                    name="iseditable"
                    required="required"
                    onChange={this.HandleChange}
                    checked={DefaultData.iseditable}
                  />
                </Col>

                <Col lg="2" md="2">
                  <Form.Label>Is Visible :</Form.Label>
                </Col>
                <Col lg="2" md="2">
                  <input
                    type={"checkbox"}
                    name="isvisible"
                    required="required"
                    onChange={this.HandleChange}
                    checked={DefaultData.isvisible}
                  />
                </Col>
                <Col lg="2" md="2">
                  <Form.Label>Is Unique :</Form.Label>
                </Col>
                <Col lg="2" md="2">
                  <input
                    type={"checkbox"}
                    name="isunique"
                    required="required"
                    onChange={this.HandleChange}
                    checked={DefaultData.isunique}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    style={{ float: "right" }}
                    className="btn btn-success "
                    onClick={this.FormSubmit}
                    disabled={this.state.isAddButtonDisabled}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              className="table table-bordered table-hover"
              style={{ marginLeft: "0px" }}
            >
              <thead>
                <tr>
                  <th>Display Name</th>
                  <th>Field Name</th>
                  <th>Input Type</th>
                  <th>After/Before Operator</th>
                  <th>Data Type</th>
                  <th>Length</th>
                  <th>Is Editable</th>
                  <th>Is Visible</th>
                  <th>Is Unique</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tbleLength > 0 ? (
                  TableData.map((contact, index, idx) => (
                    <tr key={index}>
                      <td>{contact.displayName}</td>
                      <td>{contact.fieldName}</td>
                      <td>{contact.inputType}</td>
                      <td>{contact.operator}</td>
                      <td>{contact.dataType}</td>
                      <td>{contact.number}</td>
                      <td>{String(contact.iseditable)}</td>
                      <td>{String(contact.isvisible)}</td>
                      <td>{String(contact.isunique)}</td>

                      <td>
                        {contact.isEdit ? (
                          <div>
                            <Button
                              style={{ marginBottom: "1px", float: "right" }}
                              className="btn btn-primary"
                              onClick={() => this.handleUpdateRow(index)}
                            >
                              Update
                            </Button>
                            <Button
                              style={{ marginBottom: "1px", float: "right" }}
                              className="btn btn-primary"
                              onClick={() => this.handleEditRow(index)}
                            >
                              Edit
                            </Button>
                          </div>
                        ) : (
                          <Button
                            style={{ marginBottom: "1px", float: "right" }}
                            className="btn btn-primary"
                            onClick={() => this.handleEditRow(index)}
                          >
                            Edit
                          </Button>
                        )}
                        <Button
                          variant="danger"
                          onClick={() => this.handleRemoveSpecificRow(index)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12"> No Records</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col>
              <Button
                className="btn btn-success "
                style={{ float: "right" }}
                value={this.state.calling}
                onClick={this.submitButton}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
