import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Holidayslist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      errors: {},
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.holidayslist_Id,
          sortable: true,
          cell: (row) => <span>{row.holidayslist_Id}</span>,
        },
        {
          name: "Festival Name",
          selector: (row, i) => row.Festival,
          sortable: true,
          cell: (row) => <span>{row.Festival}</span>,
        },
        {
          name: "Date",
          selector: (row, i) => row.Date,
          sortable: true,
          cell: (row) => <span>{row.Date}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      Festival: "",
      Date: "",
      holidayslist_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      Festival: row.Festival,
      Date: row.Date,
      holidayslist_Id: row.holidayslist_Id,
      isEditing: true,
    });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  HolidayslisteditHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    // const updateFormData = {...editFormData};
    // updateFormData['Holidayslist_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  HolidayslistfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Holidayslist/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;
    if (!data["Festival"]) {
      formIsValid = false;
      toast.info("*Please Enter Festival Type.");
    } else if (!data["Date"]) {
      formIsValid = false;
      toast.info("*Please Enter Date.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  HolidayslistsubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("Festival", this.state.Festival);
      formData.append("Date", this.state.Date);

      fetch(configData.api_url + "C_Holidayslist/add", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.HolidayslistfetchHandler();
            this.handleOffcanvasClose();
            this.setState({
              Festival: "",
              Date: "",
              holidayslist_Id: "",
            });
          }
        });
    }
  };
  HolidayslistupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);

    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("Festival", this.state.Festival);
    formData.append("Date", this.state.Date);
    formData.append("holidayslist_Id", this.state.holidayslist_Id);
    fetch(configData.api_url + "C_Holidayslist/update", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HolidayslistfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            Festival: "",
            Date: "",
            holidayslist_Id: "",
          });
        }
      });
  };

  componentDidMount() {
    this.HolidayslistfetchHandler();
  }
  handleFestivalChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ Festival: fieldValue });
  };
  handleDateChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ Date: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    //console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("holidayslist_Id", row.holidayslist_Id);
    fetch(configData.api_url + "C_Holidayslist/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HolidayslistfetchHandler();
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div>
        <div className="x_panel">
          <div className="row">
            <h3 className="logoskybluecolor" style={{ textAlign: "start" }}>
              Holidays list
            </h3>
          </div>
          <Col
            className="d-flex justify-content-end"
            style={{ marginRight: "5px", marginTop: "20px" }}
          >
            <Offcanvas
              show={this.state.show}
              onHide={this.handleOffcanvasClose}
              {...this.props}
              style={{ width: "500px" }}
              placement="end"
              backdrop="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Holidayslist page</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container style={{ overflowY: "auto", height: "auto" }}>
                  <Row className="divstyle">
                    <Row>
                      <Form>
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>Festival Name</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="text"
                              onChange={this.handleFestivalChange}
                              className="form-control"
                              name="Festival"
                              value={this.state.Festival}
                              required="required"
                              placeholder="Enter Festival"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.Festival}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>Date</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="date"
                              onChange={this.handleDateChange}
                              className="form-control"
                              name="Date"
                              value={this.state.Date}
                              required="required"
                              placeholder="Enter Date"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {" "}
                            {this.state.errors.Date}{" "}
                          </div>
                        </Row>
                        <br />
                      </Form>
                    </Row>
                  </Row>
                  <Row>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "50px" }}
                    >
                      <Button
                        className="button"
                        variant="success"
                        onClick={this.HolidayslistsubmitHandler}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
            {/*offcanvas is over */}
          </Col>
          <div>
            {" "}
            {this.state.isEditing && (
              // Edit Offcanvas
              <Offcanvas
                show={this.state.isEditing}
                onHide={() => this.setState({ isEditing: false })}
                {...this.props}
                style={{ width: "500px", height: "auto" }}
                placement="end"
                backdrop="true"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Edit Holidayslist</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="form-horizontal form-label-left input_mask">
                    <Form className="form-horizontal form-label-left input_mask">
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Festival Name</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="text"
                            onChange={this.handleFestivalChange}
                            className="form-control"
                            name="Festival"
                            value={this.state.Festival}
                            required="required"
                            placeholder="Enter Festival"
                          />
                        </Col>
                        <div className="errorMsg" style={{ color: "red" }}>
                          {" "}
                          {this.state.errors.Festival}{" "}
                        </div>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Date</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="date"
                            onChange={this.handleDateChange}
                            className="form-control"
                            name="Date"
                            value={this.state.Date}
                            required="required"
                            placeholder="Enter Date"
                          />
                        </Col>
                        <div className="errorMsg" style={{ color: "red" }}>
                          {" "}
                          {this.state.errors.Date}{" "}
                        </div>
                      </Row>
                      <br />
                      <div style={{ float: "right" }}>
                        <button
                          className="btn btn-primary me-2 mt-5"
                          style={{
                            backgroundColor: "#23547e",
                            color: "#fff",
                            borderColor: "#2e6da4",
                          }}
                          onClick={this.HolidayslistupdateHandler}
                        >
                          Update
                        </button>
                      </div>
                    </Form>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            )}
          </div>
          <div style={{ marginRight: "5px" }}>
            {/* searchbar,pagination,filter */}
            <input
              type="text"
              onChange={this.handleFilter}
              placeholder="Search..."
              style={{ marginBottom: "10px" }}
            />
            <Button
              style={{ float: "right" }}
              variant="primary"
              className="bi bi-plus "
              onClick={this.handleOffcanvasShow}
            >
              New
            </Button>
            <DataTable
              data={paginatedData}
              columns={this.state.tableClms}
              pagination
              paginationServer
              paginationTotalRows={
                this.state.filteredData
                  ? this.state.filteredData.length
                  : this.state.data.length
              }
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              paginationPerPage={this.state.itemsPerPage}
              keyField="id"
              paginationRowsPerPageOptions={[
                10, 20, 30, 50, 60, 70, 80, 90, 100,
              ]}
              className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
            />
          </div>
        </div>
      </div>
    );
  }
}
