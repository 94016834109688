import React, { Component } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./AttendencePage.css";
import { toast } from "react-toastify";
import configData from "../config.json";
// import moment from "moment";
import {
  Button,
  Modal,
  Form,
  Tooltip,
  OverlayTrigger,
  Badge,
  Row,
  Col,
} from "react-bootstrap";
import moment from "moment";

export default class AttendancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      data: [],
      leavedata: [],
      errors: {},
      markAttendanceDate: new Date(),
      note: "",
      regularizeAttendanceDate: new Date(),
      attended: [],
      LeaveBalance: "",
      filteredData: null,
      Holidays: [],
      groupedData: [],
      disabledDates: this.loadDisabledDatesFromStorage() || [],
      time: new Date().toLocaleTimeString(),
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getFullYear(),
      totalWorkingDays: 0,
      presentDays: 0,
      absentDays: 0,
      halfDays: 0,
      lateDays: 0,
      holidays: 0,
      regularizedDays: 0,
      regularizedLeaveDates: [],
    };
  }
  handleButtonClick = () => {
    this.inputFileRef.current.click();
  };
  handleCloseOne = () => {
    this.setState({ showOne: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  handleShowOne = () => {
    this.setState({ showOne: true });
  };
  //Offcanvas
  handleClick = () => {
    this.setState({ showButton: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleReguralizeDateChange = (date) => {
    this.setState({ regularizeAttendanceDate: date });
  };

  handleNoteChange = (event) => {
    this.setState({ note: event.target.value });
  };

  isDateDisabledOne = ({ date }) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const currentDay = currentDate.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    const dayOfWeek = date.getDay();
    let isDisabled = true;
    if (year === currentYear && month === currentMonth && day <= currentDay) {
      isDisabled = false;
    }
    const formattedDate = `${year}-${(month + 1)
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    const isMarkedAttendance = this.state.data.includes(formattedDate);
    if (isMarkedAttendance) {
      isDisabled = true;
    }
    const dateString = `${year}-${
      month + 1 < 10 ? "0" + (month + 1) : month + 1
    }-${day < 10 ? "0" + day : day}`;
    const holiday = this.state.Holidays.find(
      (holiday) => holiday.Date === dateString
    );
    if (holiday) {
      isDisabled = true;
    }
    if (date.getDay() === 0) {
      isDisabled = true;
    }
    if (dayOfWeek === 0 || holiday || isMarkedAttendance) {
      return true;
    }
    return isDisabled;
  };
  tileClassNameOne = ({ date }) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dateString = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    const holiday = this.state.Holidays.find(
      (holiday) => holiday.Date === dateString
    );
    const isHoliday = holiday !== undefined;
    const isSaturday = date.getDay() === 6;
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    const isLastSaturday = isSaturday && day > lastDayOfMonth - 7;
    const selectedDate = this.state.regularizeAttendanceDate
      .toISOString()
      .split("T")[0];
    const isSelectedDisabledDate = dateString === selectedDate;

    const isRegularized = this.state.regularizedDates.includes(dateString);
    const isDisabledDate = this.state.disabledDates.includes(dateString);

    if (isRegularized) {
      return "regularized";
    } else if (isHoliday) {
      return "holiday";
    } else if (isLastSaturday) {
      return "last-saturday";
    } else if (isSelectedDisabledDate) {
      return "selected-disabled-date";
    } else if (isDisabledDate) {
      return "regularization-request";
    } else {
      return null;
    }
  };

  loadDisabledDatesFromStorage() {
    const disabledDates = JSON.parse(localStorage.getItem("disabledDates"));
    return disabledDates;
  }
  saveDisabledDatesToStorage(disabledDates) {
    localStorage.setItem("disabledDates", JSON.stringify(disabledDates));
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const selectedDate = new Date(this.state.regularizeAttendanceDate);
    const formattedDate = `${selectedDate.getFullYear()}-${(
      selectedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${selectedDate.getDate().toString().padStart(2, "0")}`;
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("reason", this.state.note);
    formData.append("date", formattedDate);
    formData.append("status", this.state.note);

    fetch(configData.api_url + "C_Employees/regulariseAttendance", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const updatedDisabledDates = [
            ...this.state.disabledDates,
            formattedDate,
          ];
          this.setState({
            showOne: false,
            disabledDates: updatedDisabledDates,
            regularizeAttendanceDate: new Date(),
            note: "",
          });
          localStorage.setItem(
            "disabledDates",
            JSON.stringify(updatedDisabledDates)
          );
        }
      });
  };

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  componentDidMount() {
    this.AttendencefetchHandler();
    this.HolidayslistfetchHandler();
    this.fetchDisabledDates();
    this.updateTime();
    this.getleaveBalance();
    this.fetchDataForMonth(this.state.currentMonth, this.state.currentYear);
    //this.timerID = setInterval(() => this.updateTime(), 1000);
    const disabledDates =
      JSON.parse(localStorage.getItem("disabledDates")) || [];
    this.setState({ disabledDates });
    this.LeaverequestapprovalsfetchHandler();
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  updateTime = () => {
    setInterval(() => {
      this.setState({ time: new Date().toLocaleTimeString() });
    }, 1000);
  };

  handleClockInOut = () => {
    this.setState((prevState) => ({ clockedIn: !prevState.clockedIn }));
  };
  fetchDisabledDates() {
    const disabledDates = [];
    this.setState({ disabledDates });
  }
  fetchDataForMonth = (month, year) => {
    this.HolidayslistfetchHandler(month, year);
    this.AttendencefetchHandler(month, year);
  };
  LeaverequestapprovalsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Leaverequestapprovals/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const leaveDates = [];
          data.list.forEach((leave) => {
            const fromDate = new Date(leave.FromDate);
            const toDate = new Date(leave.ToDate);
            for (
              let date = new Date(fromDate);
              date <= toDate;
              date.setDate(date.getDate() + 1)
            ) {
              leaveDates.push(
                `${date.getFullYear()}-${
                  date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1
                }-${
                  date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
                }`
              );
            }
          });
          this.setState({ leaveRequestDates: leaveDates });
        }
      });
  };
  getleaveBalance = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("employees_Id", this.state.uId);
    fetch(configData.api_url + "C_Employees/retrive", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ LeaveBalance: data.detail.leaves });
        }
      });
  };
  HolidayslistfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Holidayslist/list", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ Holidays: data.list }, this.calculateStats);
        }
      });
  };
  AttendencefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Employees/getattendance", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const normalizedAttendance = data.list.attended.map(
            (dateTime) => dateTime.split(" ")[0]
          );
          this.setState(
            {
              // data: data.list.attended,
              data: normalizedAttendance,
              leavedata: data.list.leave || [],
            },
            () => {
              this.groupDataByYearAndMonth();
            }
          );
        }
      });
  };
  groupDataByYearAndMonth = () => {
    const { data } = this.state;
    let groupedData = {};
    data.forEach((attendance) => {
      const date = new Date(attendance);
      const year = date.getFullYear();
      const month = date.getMonth();
      if (!groupedData[year]) {
        groupedData[year] = [];
      }
      if (!groupedData[year][month]) {
        groupedData[year][month] = [];
      }
      groupedData[year][month].push(attendance);
    });
    const formattedGroupedData = Object.keys(groupedData).map((year) => ({
      year,
      months: groupedData[year].map((monthData, monthIndex) => ({
        month: monthIndex + 1,
        data: monthData,
      })),
    }));

    this.setState({ groupedData: formattedGroupedData });
  };

  calculateStats = () => {
    const { data, leavedata, Holidays, currentMonth, currentYear } = this.state;
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
    let presentDays = 0;
    let absentDays = 0;
    let holidays = 0;
    let totalWorkingDays = 0;
    let regularizedDays = 0;
    let regularizedLeaveDates = [];
   Holidays.forEach((holiday) => {
      const holidayDate = new Date(holiday.Date);
      if (
        holidayDate.getMonth() + 1 === currentMonth &&
        holidayDate.getFullYear() === currentYear
      ) {
        holidays++;
      }
    });
    for (let i = 1; i <= daysInMonth; i++) {
      let j = i + 1;
      const date = new Date(currentYear, currentMonth - 1, j)
        .toISOString()
        .split("T")[0];

      const day = new Date(date).getDay();
      const isHoliday = Holidays.some((holiday) => holiday.Date === date);
       const isWeekend = day === 0 || day === 0;
      if (isHoliday || isWeekend) continue;

      totalWorkingDays++;
    }
   for (let i = 1; i <= daysInMonth; i++) {
    
      const date = moment(
        currentYear + "-" + currentMonth + "-" + i,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      const day = new Date(date).getDay();
      const isHoliday = Holidays.some((holiday) => holiday.Date === date);
      const isWeekend = day === 0;
      if (isHoliday || isWeekend) continue;
      const attended = data.includes(date);
      const onLeave = leavedata.includes(date);
      if (attended) {
        presentDays++;
      } else if (!attended && !onLeave) {
        absentDays++;
      }
      if (onLeave) {
        regularizedDays++;
        regularizedLeaveDates.push(date);
      }
    }
    this.setState({
      totalWorkingDays,
      presentDays,
      absentDays,
      holidays,
      regularizedDays,
      regularizedLeaveDates,
    });
  };
  handleMonthYearChange = (event) => {
    const [year, month] = event.target.value.split("-").map(Number);
    this.setState({ currentYear: year, currentMonth: month }, () =>
      this.fetchDataForMonth(month, year)
    );
  };

  AttendencesubmitHandler = (e) => {
    e.preventDefault();
    const selectedDate = this.state.markAttendanceDate;
    const currentDate = new Date().toISOString().split("T")[0];
    const formattedSelectedDate = `${selectedDate.getFullYear()}-${(
      selectedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${selectedDate.getDate().toString().padStart(2, "0")}`;
    const isCurrentDateMarked = this.state.data.includes(currentDate);
    const isCurrentDate = formattedSelectedDate === currentDate;
    if (!isCurrentDateMarked && !isCurrentDate) {
      this.showToast(
        "Please Mark Attendance For Current Date Only. For Old Dates Use Regralize Attendance.",
        "error"
      );
      return;
    }
    if (isCurrentDateMarked && !isCurrentDate) {
      this.showToast("Attendance Already Marked For Current Date", "error");
      return;
    }
    if (this.isDateDisabled({ date: selectedDate })) {
      this.showToast(
        "Cannot Mark Attendance On Holidays, Sundays, Or Non-Last Saturdays",
        "error"
      );
      return;
    }
    const now = new Date();
    const markTime = now.getHours() * 60 + now.getMinutes();
    const lateThreshold = 10 * 60;
    const isLate = markTime > lateThreshold;

    if (isLate) {
      this.showToast("Attendance Marked as Late.", "warning");
    }
    var formData = new FormData();
    const uId = sessionStorage.getItem("uId");
    const uName = sessionStorage.getItem("userName");
    //const selectedDate = this.state.markAttendanceDate.toISOString();
    const attendanceData = {
      uId,
      uName,
      markAttendanceDate: selectedDate,
    };
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("markAttendanceDate", this.state.markAttendanceDate);
    formData.append("isLate", isLate);
    fetch(configData.api_url + "C_Employees/markattendance", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.AttendencefetchHandler();
          this.setState(
            (prevState) => ({
              attended: [...prevState.attended, attendanceData],
            }),
            () => {
              this.calculateStats();
              this.HolidayslistfetchHandler();
              this.AttendencefetchHandler();
            }
          );
        }
      });
  };

  tileClassName = ({ date }) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dateString = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    const isRegularized = this.state.attended.includes(dateString);
    const holiday = this.state.Holidays.find(
      (holiday) => holiday.Date === dateString
    );
    const isLeave = this.state.leavedata.includes(dateString);
    const isAttendance = this.state.data.find(
      (attendance) => attendance === dateString
    );
    const isHoliday = holiday !== undefined;
    // const isSaturday = date.getDay() === 6;
    // const lastDayOfMonth = new Date(year, month, 0).getDate();
    // const isLastSaturday = isSaturday && day > lastDayOfMonth - 7;
    const isSunday = date.getDay() === 0;
    const isWorkingDay = !isHoliday && !isSunday;
    const isUnmarkedWorkingDay = isWorkingDay && !isAttendance && !isLeave;
    const isLeaveRequest = this.state.leaveRequestDates?.includes(dateString);
    if (isRegularized) {
      return "regularized";
    } else if (isHoliday) {
      return "holiday";
      // } else if (isLastSaturday) {
      //   return "last-saturday";
    } else if (isAttendance) {
      return "attendance-date";
    } else if (isLeaveRequest) {
      return "leave-request";
    } else if (isLeave) {
      return "leave-date";
    } else if (isUnmarkedWorkingDay) {
      return "unmarked-working-day";
    } else {
      return null;
    }
  };
  isDateDisabled = ({ date }) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayOfWeek = date.getDay();
    const dateString = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    const holiday = this.state.Holidays.find(
      (holiday) => holiday.Date === dateString
    );
   if (dayOfWeek === 0 || holiday) {
      return true;
    }
    return false;
  };
  handleDateChange = (date) => {
    this.setState({ markAttendanceDate: date });
  };
  formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  render() {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 10);
   
    return (
      <div>
        <div>
          <h3 className="logoskybluecolor">Mark Attendance</h3>
        </div>
        <div>
          <Row>
            <Col></Col>
            <Col style={{ paddingTop: "8px" }} lg="10">
              <Badge className="custom-badges1" style={{ marginRight: "10px" }}>
                Marked
              </Badge>
              <Badge className="custom-badges2" style={{ marginRight: "10px" }}>
                Holidays
              </Badge>
              <Badge className="custom-badges3" style={{ marginRight: "10px" }}>
                Leaves
              </Badge>
              <Badge className="custom-badges4" style={{ marginRight: "10px" }}>
                Attendance Request
              </Badge>

              <Badge className="custom-badges6" style={{ marginRight: "10px" }}>
                Not Marked
              </Badge>
              <Badge className="custom-badges7" style={{ marginRight: "10px" }}>
                Sundays
              </Badge>
            </Col>
          </Row>
        </div>
        <div className="row text-center mt-3">
          <div className="col">
            <button
              type="button"
              style={{ marginRight: "20px" }}
              className="btn btn-primary"
              onClick={this.AttendencesubmitHandler}
            >
              Mark Attendance
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleShowOne}
            >
              Regularize Attendance
            </button>
          </div>
        </div>
        <div>
          <Modal show={this.state.showOne} onHide={this.handleCloseOne}>
            <Modal.Header closeButton>
              <Modal.Title>Regularize note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Calendar
                onChange={this.handleReguralizeDateChange}
                value={this.state.regularizeAttendanceDate}
                maxDate={new Date()}
                // minDate={past60Days}
                defaultValue={this.state.date}
                tileDisabled={this.isDateDisabledOne}
                tileClassNameOne={this.tileClassNameOne}
                tileContent={({ date, view }) => {
                  if (view === "month") {
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();
                    const dateString = `${year}-${
                      month < 10 ? "0" + month : month
                    }-${day < 10 ? "0" + day : day}`;
                    const holiday = this.state.Holidays.find(
                      (holiday) => holiday.Date === dateString
                    );
                    if (holiday) {
                      return (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">{holiday.Festival}</Tooltip>
                          }
                        >
                          <span className="d-none holiday-tooltip">
                            {holiday.Festival}
                          </span>
                        </OverlayTrigger>
                      );
                    } else {
                      const lastDayOfMonth = new Date(year, month, 0).getDate();
                      const isSaturday = date.getDay() === 6;
                      const isLastSaturday =
                        isSaturday && day > lastDayOfMonth - 7;

                      if (isLastSaturday) {
                        return <span></span>;
                      }
                    }
                  }
                }}
              />
              <br />
              <Form.Group className="form-group">
                <Form.Label className="control-label">
                  Please Enter Regularize note
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="note"
                  value={this.state.note}
                  rows={5}
                  onChange={this.handleNoteChange}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseOne}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="mt-3">
          <div className="row text-center  justify-content-center">
            <div className="col-md-4 col-sm-12">
              <div>
                <Calendar
                  onChange={this.handleDateChange}
                  value={this.state.markAttendanceDate}
                  tileClassName={this.tileClassName}
                  tileDisabled={this.isDateDisabled}
                  tileContent={({ date, view }) => {
                    if (view === "month") {
                      const year = date.getFullYear();
                      const month = date.getMonth() + 1;
                      const day = date.getDate();
                      const dateString = `${year}-${
                        month < 10 ? "0" + month : month
                      }-${day < 10 ? "0" + day : day}`;
                      const holiday = this.state.Holidays.find(
                        (holiday) => holiday.Date === dateString
                      );

                      if (holiday) {
                        return (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip">{holiday.Festival}</Tooltip>
                            }
                          >
                            <span className="d-inline-block holiday-tooltip">
                              {holiday.Festival}
                            </span>
                          </OverlayTrigger>
                        );
                      } else {
                        const lastDayOfMonth = new Date(
                          year,
                          month,
                          0
                        ).getDate();
                        const isSaturday = date.getDay() === 6;
                        const isLastSaturday =
                          isSaturday && day > lastDayOfMonth - 7;

                        if (isLastSaturday) {
                          return <span></span>;
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="dashboards">
          <Row>
            <Col lg="4" md="4">
              {" "}
              <div className="time-section">
                <h1>{this.state.time}</h1>
                <p>Current Time</p>
                <button
                  type="button"
                  style={{ marginRight: "20px" }}
                  className="btn btn-primary"
                  onClick={this.AttendencesubmitHandler}
                >
                  Mark Attendance
                </button>
              </div>
            </Col>
            <Col>
              <div className="overview-section">
                <Row>
                  <Col>
                    <div className="date-selectors">
                      <select
                        value={`${this.state.currentYear}-${this.state.currentMonth}`}
                        onChange={this.handleMonthYearChange}
                      >
                        {Array.from(
                          { length: (2028 - 2024 + 1) * 12 },
                          (_, i) => {
                            const startYear = 2024; // Starting year
                            const date = new Date(startYear, 0); // January of the starting year
                            date.setMonth(i); // Increment month by index
                            const month = date.getMonth() + 1; // Months are 0-indexed
                            const year = date.getFullYear();
                            return (
                              <option
                                key={`${year}-${month}`}
                                value={`${year}-${month}`}
                              >
                                {date.toLocaleString("default", {
                                  month: "long",
                                })}{" "}
                                {year}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </Col>
                  <Col lg="7" md="7">
                    <h2>
                      Days Overview for {this.state.currentMonth}/
                      {this.state.currentYear}
                    </h2>
                  </Col>
                </Row>
                <div className="overview-stats">
                  <div className="stat">
                    <span className="number">
                      {this.state.totalWorkingDays}
                    </span>
                    <p>Total Working Days</p>
                  </div>
                  <div className="stat">
                    <span className="number present">
                      {this.state.presentDays}
                    </span>
                    <p>Present Days</p>
                  </div>
                  <div className="stat">
                    <span className="number absent">
                      {this.state.absentDays}
                    </span>
                    <p>Not Marked Days</p>
                  </div>
                  <div className="stat">
                    <span className="number half">
                      {this.state.regularizedDays}
                    </span>
                    <p>Request Dates</p>
                  </div>
                  <div className="stat">
                    <span className="number late">{this.state.lateDays}</span>
                    <p>Late Days</p>
                  </div>
                  <div className="stat">
                    <span className="number lates">
                      {this.state.LeaveBalance}
                    </span>
                    <p>Leave Balance</p>
                  </div>
                  <div className="stat">
                    <span className="number holidays">
                      {this.state.holidays}
                    </span>
                    <p>Holidays</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <br />
      
      </div>
    );
  }
}
