import React, { Component } from "react";
import "./Header.css";
import configData from "../config.json";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { Image, Dropdown, Row, Col } from "react-bootstrap";
import Apnapolicy from "./Img/Apnapolicy.png";
import IconIMG from "./Img/IconIMG.jpg";
import { BrowserRouter, Link, Routes, Route } from "react-router-dom";
import MasterTable from "../Sidebar/MasterTable";
import Templates from "../Sidebar/Templates";
import APIPage from "../Sidebar/APIPage";
import DashboardCharts from "../Sidebar/Dashboardcharts";
import RulePage from "../Sidebar/RulePage";
import DashBoard from "../Sidebar/DashBoard";
import { LogOut } from "./LoginComponent";
import Users from "../Sidebar/Users";
import Companies from "../Sidebar/Companies";
import Customers from "../Sidebar/Customers";
import InsuranceTypes from "../Sidebar/InsuranceTypes";
import Settings from "../Sidebar/Settings";
import Zones from "../Sidebar/Zones";
import Agebands from "../Sidebar/Agebands";
import Familydefinitions from "../Sidebar/Familydefinitions";
import Relationships from "../Sidebar/Relationships";
import Plantypes from "../Sidebar/Plantypes";
import Zipcodes from "../Sidebar/Zipcodes";
import Profile from "../Sidebar/Profile";
import Draftpolicies from "../Sidebar/Draftpolicies";
import Sourcepolicies from "../Sidebar/Sourcepolicies";
import Roles from "../Sidebar/Roles";
import Gender from "../Sidebar/Gender";
import InsuranceCategories from "../Sidebar/InsuranceCategories";
import Employeetype from "../Sidebar/Employeetype";
import Departments from "../Sidebar/Departments";
import Designations from "../Sidebar/Designations";
import Leavetypes from "../Sidebar/Leavetypes";
import Holidayslist from "../Sidebar/Holidayslist";
import Offices from "../Sidebar/Offices";
import Irdainsurancecategories from "../Sidebar/Irdainsurancecategories";
import Roleitems from "../Sidebar/Roleitems";
import Workdesk from "../Sidebar/Workdesk";
import Employees from "../Sidebar/Employees";
import Leaverequestapprovals from "../Sidebar/Leaverequestapprovals";
import Lifeinsurancecompanies from "../Sidebar/Lifeinsurancecompanies";
import Nonlifeinsurancecompanies from "../Sidebar/Nonlifeinsurancecompanies";
import Liferidersinsurancecompanies from "../Sidebar/Liferidersinsurancecompanies";
import Irdainsurancetypesnonlife from "../Sidebar/Irdainsurancetypesnonlife";
import Irdainsurancetypeliferiders from "../Sidebar/Irdainsurancetypeliferiders";
import Irdainsurancetypeslife from "../Sidebar/Irdainsurancetypeslife";
import Plantypelinkedunlinked from "../Sidebar/Plantypelinkedunlinked";
import Commissionmasternonlife from "../Sidebar/Commissionmasternonlife";
import Bookedpoliciesnonlife from "../Sidebar/Bookedpoliciesnonlife";
import AttendancePage from "../Sidebar/AttendancePage";
import Chihealthplansintegrated from "../Sidebar/Chihealthplansintegrated";
import Caresupremeaddons from "../Sidebar/Caresupremeaddons";
import Covertype from "../Sidebar/Covertype";
import Caresupremesuminsuredcodes from "../Sidebar/Caresupremesuminsuredcodes";
import Caresupremerelationcodes from "../Sidebar/Caresupremerelationcodes";
import Caresuprememedicalqns from "../Sidebar/Caresuprememedicalqns";
import PoliciesFormPage from "../Sidebar/PoliciesFormPage";
import PoliciesFormFillingPage from "../Sidebar/PoliciesFormFillingPage";
import PoliciesPage from "../Sidebar/PoliciesPage";
import PoliciesComparePage from "../Sidebar/PoliciesComparePage";
import PoliciesKYCForm from "../Sidebar/PoliciesKYCForm";
import PoliciesPagepdf from "../Sidebar/PoliciesPagepdf";
import Proposalsummary from "../Sidebar/Proposalsummary";
import NomineeDetails from "../Sidebar/PlanB/NomineeDetails";
import Medicals from "../Sidebar/PlanB/Medicals";
import Memberinfo from "../Sidebar/PlanB/Memberinfo";
import Proposerinfo from "../Sidebar/PlanB/Proposerinfo";
import AddOns from "../Sidebar/PlanB/AddOns";
import Payments from "../Sidebar/PlanB/Buy";
import Healthcodemasterone from "../Sidebar/Healthcodemasterone";
import Healthproductsfeatures from "../Sidebar/Healthproductsfeatures";
import Healthpincodesmasters from "../Sidebar/Healthpincodesmasters";
import Healthaddonexclusive from "../Sidebar/Healthaddonexclusive";
import Healthageaddons from "../Sidebar/Healthageaddons";
import Healthproducts from "../Sidebar/Healthproducts";
import Healthpins from "../Sidebar/Healthpins";
import Healthagebands from "../Sidebar/Healthagebands";
import Healthfamilydef from "../Sidebar/Healthfamilydef";
import Healthsicodes from "../Sidebar/Healthsicodes";
import PoliciesStatus from "../Sidebar/PoliciesStatus";
import PoliciesThankspage from "../Sidebar/PoliciesThankspage";
import Healthrelationcodes from "../Sidebar/Healthrelationcodes";
import Healthpinaddons from "../Sidebar/Healthpinaddons";
import Healthoccupations from "../Sidebar/Healthoccupations";
import Healthcovertypes from "../Sidebar/Healthcovertypes";
import Healthplantype from "../Sidebar/Healthplantype";
import Healthmaritalstatus from "../Sidebar/Healthmaritalstatus";
import Healthnationality from "../Sidebar/Healthnationality";
import Healthnomineerelations from "../Sidebar/Healthnomineerelations";
import Healthbusinesstype from "../Sidebar/Healthbusinesstype";
import Healthclienttypes from "../Sidebar/Healthclienttypes";
import Healthexternalsystem from "../Sidebar/Healthexternalsystem";
import Healthsiaddons from "../Sidebar/Healthsiaddons";
import Irdaproductname from "../Sidebar/Irdaproductname";
import Healthreliancepins from "../Sidebar/Healthreliancepins";
import Godigithealthpinmaster from "../Sidebar/Godigithealthpinmaster";
import Premiumapprovalentry from "../Sidebar/Premiumapprovalentry"; 
import Icicihealthpincodes from "../Sidebar/Icicihealthpincodes";
import GoAddOns from "../Sidebar/Godigit/GoAddOns";
import GoMedicals from "../Sidebar/Godigit/GoMedicals";
import GoProposerinfo from "../Sidebar/Godigit/GoProposerinfo";
import GoNomineeDetails from "../Sidebar/Godigit/GoNomineeDetails";
import GoMemberinfo from "../Sidebar/Godigit/GoMemberinfo";
import RGIAddOns from "../Sidebar/RelianceGeneral/RGIAddOns";
import RGIBuy from "../Sidebar/RelianceGeneral/RGIBuy";
import RGIProposerinfo from "../Sidebar/RelianceGeneral/RGIProposerinfo";
import RGINomineeDetails from "../Sidebar/RelianceGeneral/RGINomineeDetails";
import RGIMemberinfo from "../Sidebar/RelianceGeneral/RGIMemberinfo";
import RGIProposalsummary from "../Sidebar/RelianceGeneral/RGIProposalsummary";
import RGIPoliciesThankspage from "../Sidebar/RelianceGeneral/RGIPoliciesThankspage";
import RGIPoliciesKYC from "../Sidebar/RelianceGeneral/RGIPoliciesKYC";
import RGIMedicals from "../Sidebar/RelianceGeneral/RGIMedicals";
import GoPolicySummaryPage from "../Sidebar/Godigit/GoPolicySummaryPage";
import EmployeesProfile from "../Sidebar/EmployeesProfile";
import GoPolicyKycPage from "../Sidebar/Godigit/GoPolicyKycPage";
import GoPolicyThanksPage from "../Sidebar/Godigit/GoPolicyThanksPage";
import PossscOne from "../Sidebar/Possystem/PossscOne";
import PossscOnep from "../Sidebar/Possystem/PossscOnep";
import PosscTwo from "../Sidebar/Possystem/PosscTwo";
import Possctwp from "../Sidebar/Possystem/Possctwp";
import PosscThree from "../Sidebar/Possystem/PosscThree";
import PospE from "../Sidebar/Possystem/PospE";
import Posscthres from "../Sidebar/Possystem/Posscthres";
import PremiumRegisterEntry from "../Sidebar/PremiumRegisterEntry";
import PremiumRegisterVerification from "../Sidebar/PremiumRegisterVerification";
import PremiumRegisterApproval from "../Sidebar/PremiumRegisterApproval";
import ICICIPoliciesKYCPage from "../Sidebar/ICICI/ICICIPoliciesKYCPage";
import ICICIProposeiinfo from "../Sidebar/ICICI/ICICIProposeiinfo";
import ICICIMemberinfo from "../Sidebar/ICICI/ICICIMemberinfo";
import ICICIMedicals from "../Sidebar/ICICI/ICICIMedicals";
import ICICINomineeDetails from "../Sidebar/ICICI/ICICINomineeDetails";
import ICICIAddons from "../Sidebar/ICICI/ICICIAddons";
import ICICIProposalsummary from "../Sidebar/ICICI/ICICIProposalsummary";
import Itsupporttickets from "../Sidebar/Tickets/Itsupporttickets";
import Itsupportticketscreate from "../Sidebar/Tickets/Itsupportticketscreate";
import Itsupportticketsverify from "../Sidebar/Tickets/Itsupportticketsverify";
import Requesttypemaster from "../Sidebar/Tickets/Requesttypemaster";
import Codedeployment from "../Sidebar/Codedeployment/Codedeployment";
import CodeDeployVerfication from "../Sidebar/Codedeployment/CodeDeployverfication";
import Posp from "../Sidebar/Possystem/Posp";
import Pospprofile from "../Sidebar/Possystem/Pospprofile";
import EmployeesProfiles from "../Sidebar/EmployeesProfiles";
import EmployeesAttendance from "../Sidebar/EmployeesAttandance/EmployeesAttandance";
import BulkQuoteFileupload from "../Sidebar/BulkQuotes/BulkQuoteFileupload";
import BulkQuote from "../Sidebar/BulkQuotes/BulkQuote";
import BulkQuoteDetails from "../Sidebar/BulkQuotes/BulkQuoteDetails";
import Altertable from "../Sidebar/Altertable/Altertable";
import Peddeclarationdetails from "../Sidebar/Peddeclarationdetails";
import PedDeclarationForm from "../Sidebar/PED/PedDeclarationForm";
import Apnapolicysubscribe from "../Sidebar/Apnapolicysubscribe";

/* replceimport */

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      isSidenavOpen: false,
      isSubMenuOpen: false,
      isSubMenuOpenone: false,
      isSubMenuOpentwo: false,
      isSubMenuOpenthree: false,
      isSubMenuOpenfour: false,
      isSubMenuOpenfive: false,
      isSubMenuOpensix: false,
      isDropdownOpen: false,
      sId: sessionStorage.getItem("sId"),
      uId: sessionStorage.getItem("uId"),
      pospId: sessionStorage.getItem("pospId"),
      data: [],
      data0: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      data7: [],
      data8: [],
      data9: [],
      data10: [],
      data11: [],
      data12: [],
      data13: [],
      data14: [],
      data15: [],
      data16: [],
      count: 0,
      count0: 0,
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
      count6: 0,
      count7: 0,
      count8: 0,
      count9: 0,
      count10: 0,
      count11: 0,
      count12: 0,
      count13: 0,
      count14: 0,
      count15: 0,
      count16: 0,
      count17: 0,
      count18: 0,
      count19: 0,
    };
  }
  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };
  // Function to toggle submenu collapse/expand
  toggleSubMenu = () => {
    this.setState((prevState) => ({
      isSubMenuOpen: !prevState.isSubMenuOpen,
    }));
  };
  toggleSubMenuIT = () => {
    this.setState((prevState) => ({
      isSubMenuOpenIT: !prevState.isSubMenuOpenIT,
    }));
  };
  toggleSubMenuOne = () => {
    this.setState((prevState) => ({
      isSubMenuOpenone: !prevState.isSubMenuOpenone,
    }));
  };
  toggleSubMenuTwo = () => {
    this.setState((prevState) => ({
      isSubMenuOpentwo: !prevState.isSubMenuOpentwo,
    }));
  };
  toggleSubMenuThree = () => {
    this.setState((prevState) => ({
      isSubMenuOpenthree: !prevState.isSubMenuOpenthree,
    }));
  };

  toggleSubMenuFour = () => {
    this.setState((prevState) => ({
      isSubMenuOpenfour: !prevState.isSubMenuOpenfour,
    }));
  };

  toggleSubMenuFive = () => {
    this.setState((prevState) => ({
      isSubMenuOpenfive: !prevState.isSubMenuOpenfive,
    }));
  };

  toggleSubMenuSix = () => {
    this.setState((prevState) => ({
      isSubMenuOpensix: !prevState.isSubMenuOpensix,
    }));
  };
  toggleSubMenuSeven = () => {
    this.setState((prevState) => ({
      isSubMenuOpenseven: !prevState.isSubMenuOpenseven,
    }));
  };
  handleToggleSidenav = () => {
    this.setState((prevState) => ({
      isSidenavOpen: !prevState.isSidenavOpen,
    }));
  };

  handleListItemClick = () => {
    this.setState({
      isSidenavOpen: false,
     })
  };

  handleProfileClick = () => {
    window.location.href = "/profile";
    // Replace '/profile' with the actual URL for the profile page
  };

  decryptData = (roleData) => {
    const bytes = CryptoJS.AES.decrypt(roleData, configData.secret);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType  })
  };
  handleretrivedata = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);
    fetch(configData.api_url + "posps/all/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((rsdata) => {
        if (rsdata.status === "success") {
          const list = rsdata.list;
          const data = [];
          const data0 = [];
          const data1 = [];
          const data2 = [];
          const data3 = [];
          const data4 = [];
          list.forEach((element) => {
            if (element.pStatus === "1") {
              data.push(element);
            } else if (element.pStatus === "2") {
              data0.push(element);
            } else if (element.pStatus === "3" || element.pStatus === "5") {
              data1.push(element);
            } else if (element.pStatus === "4") {
              data2.push(element);
            } else if (element.pStatus === "6" || element.pStatus === "8") {
              data3.push(element);
            } else if (element.pStatus === "7") {
              data4.push(element);
            }
           })
          this.setState({
            data0: data0,
            count0: data0.length,
            data: data,
            count: data.length,
            data1: data1,
            count1: data1.length,
            data2: data2,
            count2: data2.length,
            data3: data3,
            count3: data3.length,
            data4: data4,
            count4: data4.length
           })
        }
      })
  };



  handleItticketretrivedata = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Itsupporttickets/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const list = data.list;
          const data10 = [];
          list.forEach((element) => {
            if (element.status === "1") {
             
              data10.push(element);
            }
           })
          this.setState({
            data10: data10,
            count10: data10.length,
           })
        }
       })
  };
  handlecodedeploymentverification = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Codedeployment/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const list = data.list;
          const data11 = [];
          list.forEach((element) => {
            if (element.status === "1") {
             
              data11.push(element);
            }
           })
          this.setState({
            data11: data11,
            count11: data11.length,
           })
        }
       })
  };
  handlePremiumregister = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Premiumapprovalentry/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((rsdata) => {
        if (rsdata.status === "success") {
          const list = rsdata.list;
          const data13 = [];
          const data14 = [];
          const data15 = [];
          list.forEach((element) => {
            if (
              element.mStatus === "2" ||
              element.mStatus === "3" ||
              element.mStatus === "7"
            ) {
              data13.push(element);
            } else if (element.mStatus === "5" || element.mStatus === "6") {
              data14.push(element);
            } else if (element.mStatus === "1" || element.mStatus === "4") {
              data15.push(element);
            }
           })
          this.setState({
            data13: data13,
            count13: data13.length,
            data14: data14,
            count14: data14.length,
            data15: data15,
            count15: data15.length,
           })
        }
       })
  };

  componentDidMount() {
    this.handleretrivedata();
    this.handleItticketretrivedata();
    this.handlecodedeploymentverification();
    this.handlePremiumregister();
  }

  render() {
    const uName = sessionStorage.getItem("userName");
    const { isSidenavOpen } = this.state;
    const iconStyle = {
      position: "relative",
      display: "inline-block",
      width: "1.5rem",
      height: "1.5rem",
      lineHeight: "1.5rem",
      textAlign: "center",
      borderRadius: "50%",
      backgroundColor: this.state.count > 0 ? "red" : "green",
      color: "white",
    };

    const {
      isSubMenuOpen,
      isSubMenuOpenone,
      isSubMenuOpentwo,
      isSubMenuOpenthree,
      isSubMenuOpenfour,
      isSubMenuOpenfive,
      isSubMenuOpenIT,
      isSubMenuOpenseven,
    } = this.state;
    const sidenavClassName = isSidenavOpen ? "MobileMenuShow" : "";
    const roledata = JSON.parse(sessionStorage.getItem("roleData"));
    return (
      <div className="nav-fixed">
        <BrowserRouter>
          <nav className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white">
            <button
              onClick={this.handleToggleSidenav}
              id="mobilemenu"
              style={{
                border: "none",
                backgroundColor: "#fff",
              }}
            >
              <span
                className="bi bi-list"
                style={{ color: "#536b9f", fontSize: "20px" }}
              ></span>
            </button>
            <Image
              src={Apnapolicy}
              className="navbar-brand pe-3 ps-4 ps-lg-2"
              alt=""
              style={{ width: "192px", height: "44px" }}
            />
            <ul className="navbar-nav align-items-center ms-auto">
            <Link to="workdesk"  className="nav-link collapsed"
                                > <i
                                  className="bi bi-bell">
                                 <div style={{iconStyle}}>{this.state.count11+" "+this.state.count11}</div>
                                    </i>

                                </Link>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-custom-components"
                  className="me-3 "
                  style={{ color: "#3ca8e8" }}
                  variant="light"
                >
                  <Image
                    style={{ width: "30px", height: "30px" }}
                    src={IconIMG}
                    alt=""
                  />
                  &nbsp; {uName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1" onClick={this.handleProfileClick}>
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={LogOut}>
                    Log out &nbsp;
                    <span className="bi bi-box-arrow-right ms-3"></span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </nav>
          <div id="layoutSidenav">
            <div id="layoutSidenav_nav" className={sidenavClassName}>
              <nav className="sidenav sidenav-light shadow-right">
                <div className="sidenav-menu">
                  <div className="row d-flex">
                    <div className=" profile_pic col-4">
                      <Image className="newI" src={IconIMG} alt="" />
                    </div>
                    <div className="profile_info col-8">
                      <span>Welcome</span>
                      <h3>{uName}</h3>
                    </div>
                  </div>
                  <div className="nav accordion" id="accordionSidenav">
                    <ul
                      className="mainsidebarDashboardMenu"
                      style={{
                        fontFamily: "Calibri",
                        fontSize: "20px",
                        color: "red",
                      }}
                    >
                      {roledata.it_view && (
                        <li className="nav-item active ">
                          <div
                            className="nav-link menu-expanded bi bi-pc-display-horizontal"
                            onClick={this.toggleSubMenu}
                          >
                            <span className="menu-title">&nbsp;IT&nbsp;</span>
                            <i
                              className={`bi ${
                                isSubMenuOpen
                                  ? "bi-caret-down"
                                  : "bi-caret-right"
                              }`}
                            ></i>
                            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                          </div>
                          <ul
                            className={`nav flex-column sub-menu ${
                              isSubMenuOpen ? "show" : "collapse"
                            }`}
                          >
                            {roledata.table_creator_view && (
                              <li>
                                <Link
                                  to="/mastertable"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-file-earmark-spreadsheet"></span>
                                  </div>
                                  Table Creator
                                </Link>
                              </li>
                            )}
                              {roledata.alter_table_view && (
                              <li>
                                <Link
                                  to="/altertable"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-file-earmark-spreadsheet"></span>
                                  </div>
                                  Alter Table
                                </Link>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}
                      {roledata.Ped_Details_view && (
                        <li className="nav-item active ">
                          <div
                            className="nav-link menu-expanded bi bi-pc-display-horizontal"
                            onClick={this.toggleSubMenuSeven}
                          >
                            <span
                              className="menu-title"
                            >
                              &nbsp;PED&nbsp;
                            </span>

                            <i
                              className={`bi ${
                                isSubMenuOpenseven
                                  ? "bi-caret-down"
                                  : "bi-caret-right"
                              }`}
                            ></i>

                            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                          </div>

                          <ul
                            className={`nav flex-column sub-menu ${
                              isSubMenuOpenseven ? "show" : "collapse"
                            }`}
                          >
                            {roledata.peddeclarationdetails_view && (
                              <li>
                                <Link
                                  to="peddeclarationform"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Ped Declaration Form
                                </Link>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}
                      {roledata.it_support_view && (
                        <li className="nav-item active ">
                          <div
                            className="nav-link menu-expanded bi bi-pc-display-horizontal"
                            onClick={this.toggleSubMenuIT}
                          >
                            <span className="menu-title">
                              &nbsp;IT Support&nbsp;
                            </span>
                            <i
                              className={`bi ${
                                isSubMenuOpenIT
                                  ? "bi-caret-down"
                                  : "bi-caret-right"
                              }`}
                            ></i>
                            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                          </div>
                          <ul
                            className={`nav flex-column sub-menu ${
                              isSubMenuOpenIT ? "show" : "collapse"
                            }`}
                          >

                            {roledata.itsupportticketscreate_view && (
                              <li>
                                <Link
                                  to="itsupportticketscreate"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                 <div>
                                   Create Ticket
                                 </div>
                                </Link>
                              </li>
                            )}
                            {roledata.itsupportticketsverify_view && (
                              <li>
                                <Link
                                  to="itsupportticketsverify"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  <div className="ellipsis-text">
                                  Verify&nbsp;Ticket
                                  </div>
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <i
                                      className="bi bi-bell"
                                      style={{
                                        fontSize: "14px ",
                                        color: "black", // Adjust color as needed
                                      }}
                                    ></i>
                                    <div
                                   className="absolute-badge"
                                    >
                                      {this.state.count10}
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            )}
                                {roledata.peddeclarationdetails_view && (
                              <li>
                                <Link
                                  to="peddeclarationdetails"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  PED Declaration Details
                                </Link>
                              </li>
                            )}
                            {roledata.requesttypemaster_view && (
                              <li>
                                <Link
                                  to="requesttypemaster"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Request Type Master
                                </Link>
                              </li>
                            )}
                            {roledata.itsupporttickets_view && (
                              <li>
                                <Link
                                  to="itsupporttickets"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  IT Support Tickets
                                </Link>
                              </li>
                            )}
                            {roledata.codedeployment_view && (
                              <li>
                                <Link
                                  to="codedeployment"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Code Deployment
                                </Link>
                              </li>
                            )}
                            {roledata.codeverification_view && (
                              <li>
                                <Link
                                  to="codeverfication"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Code Verification&nbsp;
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                     <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                    <div
                                   className="absolute-badge"
                                    >
                                      {this.state.count11}
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}

                      {roledata.hr_view && (
                        <li className="nav-item active">
                          <div
                            className="nav-link menu-expanded bi bi-people"
                            onClick={this.toggleSubMenuOne}
                          >
                            &nbsp;
                            <span
                              className="menu-title"
                             
                            >
                              &nbsp;HR &nbsp;
                            </span>
                            <i
                             
                              className={`bi ${
                                isSubMenuOpenone
                                  ? "bi-caret-down"
                                  : "bi-caret-right"
                              }`}
                            ></i>
                            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                          </div>

                          <ul
                            className={`nav flex-column sub-menu ${
                              isSubMenuOpenone ? "show" : "collapse"
                            }`}
                          >
                            {roledata.roles_view && (
                              <li>
                                <Link to="roles" className="nav-link collapsed">
                                  <div className="nav-link-icon">
                                    <span className="bi bi-person-fill-gear"></span>
                                  </div>
                                  Roles
                                </Link>
                              </li>
                            )}
                             {roledata.holidayslist_view && (
                              <li>
                                <Link
                                  to="holidayslist"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Holidayslist
                                </Link>
                              </li>
                            )}
                              {roledata.eAttendance_view && (
                              <li>
                                <Link
                                  to="employeeattendance"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Employee Attendance
                                </Link>
                              </li>
                            )}


                            {roledata.departments_view && (
                              <li>
                                <Link
                                  to="departments"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Departments
                                </Link>
                              </li>
                            )}

                            {roledata.designations_view && (
                              <li>
                                <Link
                                  to="designations"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Designations
                                </Link>
                              </li>
                            )}

                            {roledata.employees_view && (
                              <li>
                                <Link
                                  to="employees"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Employees
                                </Link>
                              </li>
                            )}
                            {roledata.offices_view && (
                              <li>
                                <Link
                                  to="offices"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Offices
                                </Link>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}
                      {roledata.attandance_leaves_view && (
                        <li className="nav-item active">
                          <div
                            className="nav-link menu-expanded bi bi-calendar2-week"
                            onClick={this.toggleSubMenuTwo}
                          >
                            &nbsp;
                            <span
                              className="menu-title "
                             
                            >
                              <Row>
                                <Col lg="12">
                                  
                                  &nbsp;Attendance&nbsp;& Leaves
                                </Col>
                              </Row>
                            </span>
                            <i
                              style={{ marginTop: "5px", fontSize: "20px" }}
                              className={`bi ${
                                isSubMenuOpentwo
                                  ? "bi-caret-down"
                                  : "bi-caret-right"
                              }`}
                            ></i>
                            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                          </div>

                          <ul
                            className={`nav flex-column sub-menu ${
                              isSubMenuOpentwo ? "show" : "collapse"
                            }`}
                          >
                            {roledata.attendancepage_view && (
                              <li>
                                <Link
                                  to="attendancepage"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Mark&nbsp;Attendance
                                </Link>
                              </li>
                            )}

                            {roledata.leaverequestapprovals_view && (
                              <li>
                                <Link
                                  to="leaverequestapprovals"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Leaves
                                </Link>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}
                      {roledata.sales_view && (
                        <li className="nav-item active">
                          <div
                            className="nav-link menu-expanded bi bi-diagram-3"
                            onClick={this.toggleSubMenuThree}
                          >
                            &nbsp;
                            <span
                              className="menu-title"
                             
                            >
                              Sales
                            </span>
                            <i
                              style={{ marginTop: "5px", fontSize: "20px" }}
                              className={`bi ${
                                isSubMenuOpenthree
                                  ? "bi-caret-down"
                                  : "bi-caret-right"
                              }`}
                            ></i>
                            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                          </div>

                          <ul
                            className={`nav flex-column sub-menu ${
                              isSubMenuOpenthree ? "show" : "collapse"
                            }`}
                          >
                            {roledata.book_policy_view && (
                              <li>
                                <Link
                                  to="policiesformpage"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Book Policy
                                </Link>
                              </li>
                            )}
                               {roledata.bulkquote_view && (
                              <li>
                                <Link
                                  to="bulkquote"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Bulk Quote
                                </Link>
                              </li>
                            )}
{roledata.premium_register_view && (
                              <li>
                                <Link
                                  to="premiumregisterentry"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-person"></span>
                                  </div>
                                <div   
                               className="ellipsis-text"
                                > Premium Register Entry</div>
                                 <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                        <div
                                         className="absolute-badge"
                                        >
                                          {this.state.count15}
                                        </div>
                                 </div>
                                    
                                </Link>
                              </li>
                            )}
                            {roledata.posp_e_view && (
                              <li>
                                <Link to="posp" className="nav-link collapsed">
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  POSP
                                </Link>
                              </li>
                            )}
                           {roledata.create_posp_view && (
                              <li>
                                <Link to="cposp" className="nav-link collapsed">
                                  <div className="nav-link-icon">
                                    <span className="bi bi-1-square-fill"></span>
                                  </div>
                                  <div
                                   className="ellipsis-text">Create POSP</div>
                                  <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                        <div
                                          className="absolute-badge"
                                        >
                                          {this.state.count0}
                                        </div>
                                 </div>
                                </Link>
                              </li>
                            )}
                            {roledata.posp_additional_details_view && (
                              <li>
                                <Link to="dposp" className="nav-link collapsed">
                                  <div className="nav-link-icon">
                                    <span className="bi bi-2-square-fill"></span>
                                  </div>
                                 <div className="ellipsis-text" >
                                      POSP&nbsp;Additional&nbsp;Details </div>
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                        <div
                                          className="absolute-badge"
                                        >
                                          {this.state.count1}
                                        </div>
                                      </div>
                                   
                                </Link>
                              </li>
                            )}
                            {roledata.verify_posp_marksheet_view && (
                              <li>
                                <Link
                                  to="markup"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-3-square-fill"></span>
                                  </div>
                               <div className="ellipsis-text">
                                      Upload POSP Marksheet</div>
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                        <div
                                          className="absolute-badge"
                                        >
                                          {this.state.count3}
                                        </div>
                                      </div>
                                  
                                </Link>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}
                      {roledata.operation_table_view && (
                        <li className="nav-item active">
                          <div
                            className="nav-link menu-expanded bi bi-house-gear"
                            onClick={this.toggleSubMenuFour}
                          >
                            &nbsp;
                            <span
                              className="menu-title"
                             
                            >
                              Operations
                            </span>
                            <i
                              style={{ marginTop: "5px", fontSize: "20px" }}
                              className={`bi ${
                                isSubMenuOpenfour
                                  ? "bi-caret-down"
                                  : "bi-caret-right"
                              }`}
                            ></i>
                            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                          </div>

                          <ul
                            className={`nav flex-column sub-menu ${
                              isSubMenuOpenfour ? "show" : "collapse"
                            }`}
                          >
                           {roledata.premium_register_verification_view && (
                              <li>
                                <Link
                                  to="premiumregisterverification"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-person"></span>
                                  </div >
                                  <div  style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "120px", // Adjust this based on available space
                                }}>Premium Register Verification</div>
                                   <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                          <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                        <div
                                          className="absolute-badge"
                                        >
                                          {this.state.count13}
                                        </div>
                                      </div>
                                  
                                </Link>
                              </li>
                            )}

                            {roledata.premium_register_approval_view && (
                              <li>
                                <Link
                                  to="premiumregisterapproval"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-person"></span>
                                  </div>
                                  <div 
                                 className="ellipsis-text"
                                >Premium Register Approval</div>
                                  <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      > <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                       
                                        <div
                                         className="absolute-badge"
                                        >
                                          {this.state.count14}
                                        </div>
                                      </div>
                         
                                </Link>
                              </li>
                            )}
                           {roledata.verify_posp_with_irda_view && (
                              <li>
                                <Link
                                  to="ipospv"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-arrow-up-square"></span>
                                  </div>
                                  <div 
                                     className="ellipsis-text">Verify POSP With IRDA</div>
                                  <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                        <div
                                         className="absolute-badge"
                                        >
                                          {this.state.count}
                                        </div>
                                      </div>
                                   
                                </Link>
                              </li>
                            )}
                            
                            {roledata.posp_details_verfication_view && (
                              <li>
                                <Link
                                  to="iposdp"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-arrow-up-square"></span>
                                  </div>
                                 
                                  <div className="ellipsis-text">POSP Details Verfication</div>
                                    <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                        <div
                                         className="absolute-badge"
                                        >
                                          {this.state.count2}
                                        </div>
                                      </div>
                                </Link>
                              </li>
                            )}

{roledata.posp_marksheet_approval_view && (
                              <li>
                                <Link to="markv" className="nav-link collapsed">
                                  <div className="nav-link-icon">
                                    <span className="bi bi-arrow-up-square"></span>
                                  </div>
                                  <div className="ellipsis-text">POSP Marksheet Approval</div>
                                  <div
                                        style={{
                                          position: "relative",
                                          display: "inline-block",
                                        }}
                                      >
                                        <i
                                              className="bi bi-bell"
                                              style={{
                                                fontSize: "14px",
                                                color: "black", // Adjust color as needed
                                              }}
                                            ></i>
                                        <div
                                         className="absolute-badge"
                                        >
                                          {this.state.count4}
                                        </div>
                                  </div>
                              </Link>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}
                      {roledata.un_characterised_view && (
                        <li className="nav-item active">
                          <div
                            className="nav-link menu-expanded bi bi-box-seam"
                            onClick={this.toggleSubMenuFive}
                          >
                            &nbsp;
                            <span
                              className="menu-title"
                             
                            >
                              Uncategorized
                            </span>
                            <i
                              style={{ marginTop: "5px", fontSize: "20px" }}
                              className={`bi ${
                                isSubMenuOpenfive
                                  ? "bi-caret-down"
                                  : "bi-caret-right"
                              }`}
                            ></i>
                            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                          </div>

                          <ul
                            className={`nav flex-column sub-menu ${
                              isSubMenuOpenfive ? "show" : "collapse"
                            }`}
                          >
                          

                            {roledata.dashboard_view && (
                              <li>
                                <Link
                                  to="/dashboard"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-house-door-fill"></span>
                                  </div>
                                  DashBoard
                                </Link>
                              </li>
                            )}
                            {roledata.users_view && (
                              <li>
                                <Link to="users" className="nav-link collapsed">
                                  <div className="nav-link-icon">
                                    <span className="bi bi-person"></span>
                                  </div>
                                  Users
                                </Link>
                              </li>
                            )}
                            {roledata.api_page_view && (
                              <li>
                                <Link
                                  to="/apipage"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-app-indicator"></span>
                                  </div>

                                  <span>API Page</span>
                                </Link>
                              </li>
                            )}
                            {roledata.rule_page_view && (
                              <li>
                                <Link
                                  to="/rulespage"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-receipt-cutoff"></span>
                                  </div>
                                  Rules Page
                                </Link>
                              </li>
                            )}
                            {roledata.templates_view && (
                              <li>
                                <Link
                                  to="templates"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-journal-text"></span>
                                  </div>
                                  Templates
                                </Link>
                              </li>
                            )}
                            {roledata.sourced_policies_view && (
                              <li>
                                <Link
                                  to="sourcedpolicies"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-file-earmark-text-fill"></span>
                                  </div>

                                  <span className="menu_text">
                                    Sourced Policies
                                  </span>
                                </Link>
                              </li>
                            )}
                            {roledata.draft_policies_view && (
                              <li>
                                <Link
                                  to="draftpolicies"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-file-earmark-fill"></span>
                                  </div>

                                  <span className="menu_text">
                                    Draft Policies
                                  </span>
                                </Link>
                              </li>
                            )}
                            {roledata.customers_view && (
                              <li>
                                <Link
                                  to="customers"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Customers
                                </Link>
                              </li>
                            )}
                            {roledata.companies_view && (
                              <li>
                                <Link
                                  to="companies"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-file-earmark-spreadsheet"></span>
                                  </div>

                                  <span className="menu_text">Companies</span>
                                </Link>
                              </li>
                            )}
                            {roledata.insurance_types_view && (
                              <li>
                                <Link
                                  to="insurancetypes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-receipt-cutoff"></span>
                                  </div>

                                  <span className="menu_text">
                                    Insurance types
                                  </span>
                                </Link>
                              </li>
                            )}
                            {roledata.settings_view && (
                              <li>
                                <Link
                                  to="settings"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-gear"></span>
                                  </div>

                                  <span className="menu_text">Settings</span>
                                </Link>
                              </li>
                            )}
                            {roledata.zones_view && (
                              <li>
                                <Link to="zones" className="nav-link collapsed">
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-gear"></span>
                                  </div>

                                  <span className="menu_text">Zones</span>
                                </Link>
                              </li>
                            )}
                            {roledata.zipcodes_view && (
                              <li>
                                <Link
                                  to="zipcodes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-geo-fill"></span>
                                  </div>

                                  <span className="menu_text">Zipcodes</span>
                                </Link>
                              </li>
                            )}
                            {roledata.plan_types_view && (
                              <li>
                                <Link
                                  to="plantypes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-geo-fill"></span>
                                  </div>

                                  <span className="menu_text">Plan Types</span>
                                </Link>
                              </li>
                            )}
                            {roledata.family_definitions_view && (
                              <li>
                                <Link
                                  to="familydefinitions"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-geo-fill"></span>
                                  </div>

                                  <span className="menu_text">
                                    Family definitions
                                  </span>
                                </Link>
                              </li>
                            )}
                            {roledata.age_bands_view && (
                              <li>
                                <Link
                                  to="agebands"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="Icon_size bi bi-geo-fill"></span>
                                  </div>

                                  <span className="menu_text">Age Bands</span>
                                </Link>
                              </li>
                            )}
                            {roledata.gender_view && (
                              <li>
                                <Link
                                  to="gender"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Gender
                                </Link>
                              </li>
                            )}
                            {roledata.insurance_categories_view && (
                              <li>
                                <Link
                                  to="insurancecategories"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Insurance Categories
                                </Link>
                              </li>
                            )}
                            {roledata.relationships_view && (
                              <li>
                                <Link
                                  to="relationships"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Relationships
                                </Link>
                              </li>
                            )}
                            {roledata.employeetype_view && (
                              <li>
                                <Link
                                  to="employeetype"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Employeetype
                                </Link>
                              </li>
                            )}
                            {roledata.leavetypes_view && (
                              <li>
                                <Link
                                  to="leavetypes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Leavetypes
                                </Link>
                              </li>
                            )}
                           
                            {roledata.irda_insurance_categories_view && (
                              <li>
                                <Link
                                  to="irdainsurancecategories"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  IRDA Insurance Categories
                                </Link>
                              </li>
                            )}
                            {roledata.role_items_view && (
                              <li>
                                <Link
                                  to="roleitems"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Role Items
                                </Link>
                              </li>
                            )}
                            {roledata.workdesk_view && (
                              <li>
                                <Link
                                  to="workdesk"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Workdesk&nbsp;
                                  <div style={iconStyle}>
                                    {this.state.count12} &nbsp;
                                    {this.state.count11}
                                  </div>
                                </Link>
                              </li>
                            )}
                            {roledata.lifeinsurancecompanies_view && (
                              <li>
                                <Link
                                  to="lifeinsurancecompanies"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Lifeinsurancecompanies
                                </Link>
                              </li>
                            )}
                            {roledata.nonlifeinsurancecompanies_view && (
                              <li>
                                <Link
                                  to="nonlifeinsurancecompanies"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Nonlifeinsurancecompanies
                                </Link>
                              </li>
                            )}
                            {roledata.liferidersinsurancecompanies_view && (
                              <li>
                                <Link
                                  to="liferidersinsurancecompanies"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Liferidersinsurancecompanies
                                </Link>
                              </li>
                            )}
                            {roledata.irdainsurancetypesnonlife_view && (
                              <li>
                                <Link
                                  to="irdainsurancetypesnonlife"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Irdainsurancetypesnonlife
                                </Link>
                              </li>
                            )}
                            {roledata.irdainsurancetypeliferiders_view && (
                              <li>
                                <Link
                                  to="irdainsurancetypeliferiders"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Irdainsurancetypeliferiders
                                </Link>
                              </li>
                            )}
                            {roledata.irdainsurancetypeslife_view && (
                              <li>
                                <Link
                                  to="irdainsurancetypeslife"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Irdainsurancetypeslife
                                </Link>
                              </li>
                            )}
                            {roledata.plantypelinkedunlinked_view && (
                              <li>
                                <Link
                                  to="plantypelinkedunlinked"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Plantypelinkedunlinked
                                </Link>
                              </li>
                            )}
                            {roledata.commissionmasternonlife_view && (
                              <li>
                                <Link
                                  to="commissionmasternonlife"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Commissionmasternonlife
                                </Link>
                              </li>
                            )}
                            {roledata.bookedpoliciesnonlife_view && (
                              <li>
                                <Link
                                  to="bookedpoliciesnonlife"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Bookedpoliciesnonlife
                                </Link>
                              </li>
                            )}
                            {roledata.chitokensessiontoken_view && (
                              <li>
                                <Link
                                  to="chitokensessiontoken"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  CHI Token Session Token
                                </Link>
                              </li>
                            )}
                            {roledata.chihealthplansintegrated_view && (
                              <li>
                                <Link
                                  to="chihealthplansintegrated"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  CHI Health Plans Integrated
                                </Link>
                              </li>
                            )}
                            {roledata.chitokensessiontokentwo_view && (
                              <li>
                                <Link
                                  to="chitokensessiontokentwo"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  CHI Token Session Token two
                                </Link>
                              </li>
                            )}
                            {roledata.caresupremeaddons_view && (
                              <li>
                                <Link
                                  to="caresupremeaddons"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health AddOns
                                </Link>
                              </li>
                            )}
                            {roledata.covertype_view && (
                              <li>
                                <Link
                                  to="covertype"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Cover Type
                                </Link>
                              </li>
                            )}
                            {roledata.caresupremesuminsuredcodes_view && (
                              <li>
                                <Link
                                  to="caresupremesuminsuredcodes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Care Supreme SumInsured Codes
                                </Link>
                              </li>
                            )}
                            {roledata.caresupremerelationcodes_view && (
                              <li>
                                <Link
                                  to="caresupremerelationcodes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health Relation Codes
                                </Link>
                              </li>
                            )}
                            {roledata.caresuprememedicalqns_view && (
                              <li>
                                <Link
                                  to="caresuprememedicalqns"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Care Supreme Medical Questions
                                </Link>
                              </li>
                            )}
                            {roledata.healthcodemasterone_view && (
                              <li>
                                <Link
                                  to="healthcodemasterone"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health Code Master One
                                </Link>
                              </li>
                            )}
                            {roledata.healthproductsfeatures_view && (
                              <li>
                                <Link
                                  to="healthproductsfeatures"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health Products Features
                                </Link>
                              </li>
                            )}
                            {roledata.healthpincodesmasters_view && (
                              <li>
                                <Link
                                  to="healthpincodesmasters"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health pincodes masters
                                </Link>
                              </li>
                            )}
                            {roledata.healthaddonexclusive_view && (
                              <li>
                                <Link
                                  to="healthaddonexclusive"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health addon exclusive
                                </Link>
                              </li>
                            )}
                            {roledata.healthageaddons_view && (
                              <li>
                                <Link
                                  to="healthageaddons"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health age addons
                                </Link>
                              </li>
                            )}
                            {roledata.healthproducts_view && (
                              <li>
                                <Link
                                  to="healthproducts"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health products
                                </Link>
                              </li>
                            )}
                            {roledata.healthpins_view && (
                              <li>
                                <Link
                                  to="healthpins"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health pins
                                </Link>
                              </li>
                            )}
                            {roledata.healthagebands_view && (
                              <li>
                                <Link
                                  to="healthagebands"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health age bands
                                </Link>
                              </li>
                            )}
                            {roledata.healthfamilydef_view && (
                              <li>
                                <Link
                                  to="healthfamilydef"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health family def
                                </Link>
                              </li>
                            )}
                            {roledata.healthsicodes_view && (
                              <li>
                                <Link
                                  to="healthsicodes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health si codes
                                </Link>
                              </li>
                            )}
                            {roledata.healthpinaddons_view && (
                              <li>
                                <Link
                                  to="healthpinaddons"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health pin addons
                                </Link>
                              </li>
                            )}
                            {roledata.healthoccupations_view && (
                              <li>
                                <Link
                                  to="healthoccupations"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health Occupation
                                </Link>
                              </li>
                            )}
                            {roledata.healthcovertypes_view && (
                              <li>
                                <Link
                                  to="healthcovertypes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health cover types
                                </Link>
                              </li>
                            )}
                            {roledata.healthplantype_view && (
                              <li>
                                <Link
                                  to="healthplantype"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health plan type
                                </Link>
                              </li>
                            )}
                            {roledata.healthmaritalstatus_view && (
                              <li>
                                <Link
                                  to="healthmaritalstatus"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health marital status
                                </Link>
                              </li>
                            )}
                            {roledata.healthnationality_view && (
                              <li>
                                <Link
                                  to="healthnationality"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health nationality
                                </Link>
                              </li>
                            )}
                            {roledata.healthnomineerelations_view && (
                              <li>
                                <Link
                                  to="healthnomineerelations"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health nominee relations
                                </Link>
                              </li>
                            )}
                            {roledata.healthbusinesstype_view && (
                              <li>
                                <Link
                                  to="healthbusinesstype"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health businesstype
                                </Link>
                              </li>
                            )}
                            {roledata.healthclienttypes_view && (
                              <li>
                                <Link
                                  to="healthclienttypes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health client types
                                </Link>
                              </li>
                            )}
                            {roledata.healthexternalsystem_view && (
                              <li>
                                <Link
                                  to="healthexternalsystem"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health external system
                                </Link>
                              </li>
                            )}
                            {roledata.healthrelationcodes_view && (
                              <li>
                                <Link
                                  to="Healthrelationcodes"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health Relation Codes
                                </Link>
                              </li>
                            )}
                            {roledata.healthsiaddons_view && (
                              <li>
                                <Link
                                  to="healthsiaddons"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health si addons
                                </Link>
                              </li>
                            )}
                            {roledata.irdaproductname_view && (
                              <li>
                                <Link
                                  to="irdaproductname"
                                  className="nav-link collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  IRDA Product Name
                                </Link>
                              </li>
                            )}
                            {roledata.healthreliancepins_view && (
                              <li>
                                <Link
                                  to="healthreliancepins"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Health Reliance Pins
                                </Link>
                              </li>
                            )}
                            {roledata.godigithealthpinmaster_view && (
                              <li>
                                <Link
                                  to="godigithealthpinmaster"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Godigit Health Pin Master
                                </Link>
                              </li>
                            )}
                            {roledata.premiumapprovalentry_view && (
                              <li>
                                <Link
                                  to="premiumapprovalentry"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  Premium Approval Entry
                                </Link>
                              </li>
                            )}
                            {roledata.icicihealthpincodes_view && (
                              <li>
                                <Link
                                  to="icicihealthpincodes"
                                  className="nav-link active collapsed"
                                >
                                  <div className="nav-link-icon">
                                    <span className="bi bi-speedometer2"></span>
                                  </div>
                                  ICICI Health Pin Codes
                                </Link>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}
                      {roledata.apnapolicysubscribe_view && (
                          <li>
                            <Link
                              to="apnapolicysubscribe"
                              className="nav-link active collapsed"
                            >
                              <div className="nav-link-icon">
                                <span className="bi bi-speedometer2"></span>
                              </div>
                              Apnapolicy Subscribe
                            </Link>
                          </li>
                        )}
                      {/* <-- replace --> } */}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div id="layoutSidenav_content">
              <main>
                <header className="py-10 mb-4 bg-gradient-primary-to-secondary">
                  <div className="container-fluid-xl px-4">
                    <Routes>
                      <Route path="apipage" element={<APIPage />}></Route>
                      <Route
                        path="mastertable"
                        element={<MasterTable />}
                      ></Route>
                      <Route path="/rulespage" element={<RulePage />}></Route>
                      <Route path="/dashboard" element={<DashBoard />}>
                        <Route
                          path="dashboardcharts"
                          element={<DashboardCharts />}
                        ></Route>
                      </Route>
                      <Route path="agebands" element={<Agebands />} />
                      <Route path="users" element={<Users />}></Route>
                      <Route path="templates" element={<Templates />}></Route>
                      <Route path="customers" element={<Customers />}></Route>
                      <Route path="companies" element={<Companies />} />
                      <Route
                        path="insurancetypes"
                        element={<InsuranceTypes />}
                      />
                      <Route path="settings" element={<Settings />} />
                      <Route path="zones" element={<Zones />} />
                      <Route
                        path="familydefinitions"
                        element={<Familydefinitions />}
                      />
                      <Route path="profile" element={<Profile />} />
                      <Route path="plantypes" element={<Plantypes />} />
                      <Route path="zipcodes" element={<Zipcodes />} />
                      <Route path="draftpolicies" element={<Draftpolicies />} />
                      <Route path="roles" element={<Roles />} />
                      <Route
                        path="sourcedpolicies"
                        element={<Sourcepolicies />}
                      />
                      <Route path="gender" element={<Gender />}></Route>
                      <Route
                        path="insurancecategories"
                        element={<InsuranceCategories />}
                      ></Route>
                      <Route
                        path="relationships"
                        element={<Relationships />}
                      ></Route>
                      <Route
                        path="employeetype"
                        element={<Employeetype />}
                      ></Route>
                      <Route
                        path="departments"
                        element={<Departments />}
                      ></Route>
                      <Route
                        path="designations"
                        element={<Designations />}
                      ></Route>
                      <Route path="leavetypes" element={<Leavetypes />}></Route>
                      <Route
                        path="holidayslist"
                        element={<Holidayslist />}
                      ></Route>
                      <Route path="offices" element={<Offices />}></Route>
                      <Route
                        path="irdainsurancecategories"
                        element={<Irdainsurancecategories />}
                      ></Route>
                      <Route path="roleitems" element={<Roleitems />}></Route>
                      <Route path="employees" element={<Employees />}></Route>
                      <Route path="posp" element={<Posp />}></Route>
                      <Route
                        path="pospprofile"
                        element={<Pospprofile />}
                      ></Route>
                      <Route path="workdesk" element={<Workdesk />}></Route>
                      <Route
                        path="leaverequestapprovals"
                        element={<Leaverequestapprovals />}
                      ></Route>
                      <Route
                        path="lifeinsurancecompanies"
                        element={<Lifeinsurancecompanies />}
                      ></Route>
                      <Route
                        path="nonlifeinsurancecompanies"
                        element={<Nonlifeinsurancecompanies />}
                      ></Route>
                      <Route
                        path="liferidersinsurancecompanies"
                        element={<Liferidersinsurancecompanies />}
                      ></Route>
                      <Route
                        path="irdainsurancetypesnonlife"
                        element={<Irdainsurancetypesnonlife />}
                      ></Route>
                      <Route
                        path="irdainsurancetypeliferiders"
                        element={<Irdainsurancetypeliferiders />}
                      ></Route>
                      <Route
                        path="irdainsurancetypeslife"
                        element={<Irdainsurancetypeslife />}
                      ></Route>
                      <Route
                        path="plantypelinkedunlinked"
                        element={<Plantypelinkedunlinked />}
                      ></Route>
                      <Route
                        path="commissionmasternonlife"
                        element={<Commissionmasternonlife />}
                      ></Route>
                      <Route
                        path="bookedpoliciesnonlife"
                        element={<Bookedpoliciesnonlife />}
                      ></Route>
                      <Route
                        path="attendancepage"
                        element={<AttendancePage />}
                      ></Route>
                      <Route
                        path="chihealthplansintegrated"
                        element={<Chihealthplansintegrated />}
                      ></Route>
                      <Route
                        path="policiesformpage"
                        element={<PoliciesFormPage />}
                      ></Route>
                      <Route
                        path="policiesformfillingpage"
                        element={<PoliciesFormFillingPage />}
                      ></Route>
                      <Route
                        path="/policiespage"
                        element={<PoliciesPage />}
                      ></Route>
                      <Route
                        path="policiescomparepage"
                        element={<PoliciesComparePage />}
                      ></Route>
                      <Route
                        path="policieskycform"
                        element={<PoliciesKYCForm />}
                      ></Route>
                      <Route
                        path="policiespagepdf"
                        element={<PoliciesPagepdf />}
                      ></Route>
                      <Route
                        path="caresupremeaddons"
                        element={<Caresupremeaddons />}
                      ></Route>
                      <Route path="covertype" element={<Covertype />}></Route>
                      <Route
                        path="caresupremesuminsuredcodes"
                        element={<Caresupremesuminsuredcodes />}
                      ></Route>
                      <Route
                        path="caresupremerelationcodes"
                        element={<Caresupremerelationcodes />}
                      ></Route>
                      <Route
                        path="caresuprememedicalqns"
                        element={<Caresuprememedicalqns />}
                      ></Route>
                      <Route
                        path="proposalsummary"
                        element={<Proposalsummary />}
                      ></Route>
                      <Route path="/addons" element={<AddOns />}></Route>
                      <Route
                        path="nomineedetails"
                        element={<NomineeDetails />}
                      ></Route>
                      <Route path="medicals" element={<Medicals />}></Route>
                      <Route path="memberinfo" element={<Memberinfo />}></Route>
                      <Route
                        path="proposerinfo"
                        element={<Proposerinfo />}
                      ></Route>
                      {/* Godigit Routing */}
                      <Route path="goaddons" element={<GoAddOns />}></Route>
                      <Route
                        path="gonomineedetails"
                        element={<GoNomineeDetails />}
                      ></Route>
                      <Route path="gomedicals" element={<GoMedicals />}></Route>
                      <Route
                        path="gomemberinfo"
                        element={<GoMemberinfo />}
                      ></Route>
                      <Route
                        path="goproposerinfo"
                        element={<GoProposerinfo />}
                      ></Route>
                      <Route
                        path="gopolicysummarypage"
                        element={<GoPolicySummaryPage />}
                      ></Route>
                      {/* <Route
                      path="gopolicieskycform"
                        element={<GoPoliciesKYCForm />}
                      ></Route> */}
                      <Route
                        path="gopaymentstatus"
                        element={<GoPolicyKycPage />}
                      ></Route>
                      <Route
                        path="gopolicythankspage"
                        element={<GoPolicyThanksPage />}
                      ></Route>
                      {/*end Godigit Routing */}
                      {/* Reliance Routing */}
                      <Route path="rgiaddOns" element={<RGIAddOns />}></Route>
                      <Route path="rgibuy" element={<RGIBuy />}></Route>
                      <Route
                        path="rgimedicals"
                        element={<RGIMedicals />}
                      ></Route>
                      <Route
                        path="rgimemberinfo"
                        element={<RGIMemberinfo />}
                      ></Route>
                      <Route
                        path="rginomineedetails"
                        element={<RGINomineeDetails />}
                      ></Route>
                      <Route
                        path="rgipolicieskyc"
                        element={<RGIPoliciesKYC />}
                      ></Route>
                      <Route
                        path="rgpaymentstatus"
                        element={<RGIPoliciesThankspage />}
                      ></Route>
                      <Route
                        path="rgiproposalsummary"
                        element={<RGIProposalsummary />}
                      ></Route>
                      <Route
                        path="rgiproposerinfo"
                        element={<RGIProposerinfo />}
                      ></Route>

                      <Route
                        path="healthcodemasterone"
                        element={<Healthcodemasterone />}
                      ></Route>
                      <Route
                        path="employeesprofile"
                        element={<EmployeesProfile />}
                      ></Route>
                      <Route
                        path="employeesprofiles"
                        element={<EmployeesProfiles />}
                      ></Route>
                      <Route
                        path="healthcodemasterone"
                        element={<Healthcodemasterone />}
                      ></Route>
                      <Route
                        path="healthcodemasterone"
                        element={<Healthcodemasterone />}
                      ></Route>
                      <Route
                        path="healthproductsfeatures"
                        element={<Healthproductsfeatures />}
                      ></Route>
                      <Route
                        path="policiesstatus"
                        element={<PoliciesStatus />}
                      ></Route>
                      <Route path="payments" element={<Payments />}></Route>
                      <Route
                        path="healthpincodesmasters"
                        element={<Healthpincodesmasters />}
                      ></Route>
                      <Route
                        path="healthaddonexclusive"
                        element={<Healthaddonexclusive />}
                      ></Route>
                      <Route
                        path="healthageaddons"
                        element={<Healthageaddons />}
                      ></Route>
                      <Route
                        path="healthproducts"
                        element={<Healthproducts />}
                      ></Route>
                      <Route path="healthpins" element={<Healthpins />}></Route>
                      <Route
                        path="healthagebands"
                        element={<Healthagebands />}
                      ></Route>
                      <Route
                        path="healthfamilydef"
                        element={<Healthfamilydef />}
                      ></Route>
                      <Route
                        path="healthsicodes"
                        element={<Healthsicodes />}
                      ></Route>
                      <Route
                        path="healthpinaddons"
                        element={<Healthpinaddons />}
                      ></Route>
                      <Route
                        path="paymentstatus"
                        element={<PoliciesThankspage />}
                      ></Route>
                      <Route
                        path="healthoccupations"
                        element={<Healthoccupations />}
                      ></Route>
                      <Route
                        path="healthcovertypes"
                        element={<Healthcovertypes />}
                      ></Route>
                      <Route
                        path="healthplantype"
                        element={<Healthplantype />}
                      ></Route>
                      <Route
                        path="healthmaritalstatus"
                        element={<Healthmaritalstatus />}
                      ></Route>
                      <Route
                        path="healthnationality"
                        element={<Healthnationality />}
                      ></Route>
                      <Route
                        path="healthnomineerelations"
                        element={<Healthnomineerelations />}
                      ></Route>
                      <Route
                        path="healthbusinesstype"
                        element={<Healthbusinesstype />}
                      ></Route>
                      <Route
                        path="healthclienttypes"
                        element={<Healthclienttypes />}
                      ></Route>
                      <Route
                        path="healthexternalsystem"
                        element={<Healthexternalsystem />}
                      ></Route>
                      <Route
                        path="Healthrelationcodes"
                        element={<Healthrelationcodes />}
                      ></Route>
                      <Route
                        path="healthsiaddons"
                        element={<Healthsiaddons />}
                      ></Route>
                      <Route
                        path="irdaproductname"
                        element={<Irdaproductname />}
                      ></Route>
                      <Route
                        path="healthreliancepins"
                        element={<Healthreliancepins />}
                      ></Route>
                      <Route
                        path="godigithealthpinmaster"
                        element={<Godigithealthpinmaster />}
                      ></Route>
                      <Route path="cposp" element={<PossscOne />}></Route>
                      <Route path="ipospv" element={<PossscOnep />}></Route>
                      <Route path="dposp" element={<PosscTwo />}></Route>
                      <Route path="iposdp" element={<Possctwp />}></Route>
                      <Route path="markup" element={<PosscThree />}></Route>
                      <Route path="markv" element={<Posscthres />}></Route>
                      <Route path="pospcr" element={<PospE />}></Route>
                      <Route
                        path="premiumapprovalentry"
                        element={<Premiumapprovalentry />}
                      ></Route>
                      <Route
                        path="premiumregisterentry"
                        element={<PremiumRegisterEntry />}
                      ></Route>
                      <Route
                        path="premiumregisterverification"
                        element={<PremiumRegisterVerification />}
                      ></Route>
                      <Route
                        path="premiumregisterapproval"
                        element={<PremiumRegisterApproval />}
                      ></Route>
                      <Route
                        path="icicihealthpincodes"
                        element={<Icicihealthpincodes />}
                      ></Route>
                      <Route
                        path="icicipolicieskycpage"
                        element={<ICICIPoliciesKYCPage />}
                      ></Route>
                      <Route
                        path="iciciproposeiinfo"
                        element={<ICICIProposeiinfo />}
                      ></Route>
                      <Route
                        path="icicimemberinfo"
                        element={<ICICIMemberinfo />}
                      ></Route>
                      <Route
                        path="icicimedicals"
                        element={<ICICIMedicals />}
                      ></Route>
                      <Route
                        path="icicinomineedetails"
                        element={<ICICINomineeDetails />}
                      ></Route>
                      <Route
                        path="iciciaddons"
                        element={<ICICIAddons />}
                      ></Route>
                      <Route
                        path="iciciproposalsummary"
                        element={<ICICIProposalsummary />}
                      ></Route>
                         <Route
                        path="iciciproposalsummary"
                        element={<ICICIProposalsummary />}
                      ></Route>
                       <Route
                        path="icicihealthpincodes"
                        element={<Icicihealthpincodes />}
                      ></Route>
                                           <Route
                        path="requesttypemaster"
                        element={<Requesttypemaster />}
                      ></Route>
                      <Route
                        path="itsupporttickets"
                        element={<Itsupporttickets />}
                      ></Route>
                      <Route
                        path="itsupportticketscreate"
                        element={<Itsupportticketscreate />}
                      ></Route>
                      <Route
                        path="itsupportticketsverify"
                        element={<Itsupportticketsverify />}
                      ></Route>
                      <Route
                        path="codedeployment"
                        element={<Codedeployment />}
                      ></Route>
                      <Route
                        path="codeverfication"
                        element={<CodeDeployVerfication />}
                      ></Route>
                      <Route path="employeeattendance" element={<EmployeesAttendance/>}></Route>
                      <Route 
                      path="bqfileupload"
                      element={<BulkQuoteFileupload/>}></Route>
                        <Route 
                      path="bulkquote"
                      element={<BulkQuote/>}></Route>
                      <Route 
                      path="bqdetails"
                      element={<BulkQuoteDetails/>}></Route>
                      <Route 
                      path="altertable"
                      element={<Altertable/>}></Route>
                      {/* Ped */}
                      <Route path="peddeclarationdetails" element={<Peddeclarationdetails/>}></Route>
                      <Route path="peddeclarationform" element={<PedDeclarationForm/>}></Route>
                      <Route path="apnapolicysubscribe" element={<Apnapolicysubscribe/>}></Route>

                      {/* <-- replaceRoute --> } */}
                    </Routes>
                  </div>
                </header>

                {/* <div className="container-xl px-4"></div> */}
              </main>

              <footer className="footer-admin mt-auto footer-light">
                <p> All &#169; Right's Reserved by Apnapolicy.</p> &nbsp;
                <a
                  href={configData.api_url + "uploads/disclaimer.pdf"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Disclaimer
                </a>
                &nbsp;
                <a
                  href={configData.api_url + "uploads/privacy-policy.pdf"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                &nbsp;
                <a
                  href={configData.api_url + "uploads/tou.pdf"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
                &nbsp;
              </footer>
            </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}
