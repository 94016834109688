import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import configData from "../config.json";

export default class Buy extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      proposalNum: sessionStorage.getItem("proposalNum"),
      isLoading: true,
      // ref: React.createRef(),
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
      this.submitHealthForm();
    }, 5000);
  }
  handleSubmit = (event) => {
    event.preventDefault();
    // Your form submission logic here
    // You can access form data using this.formRef.current

    // For example, logging form data
    console.log(this.formRef.current.elements);
  };
  submitHealthForm() {
    this.formRef.current.submit();
    // document.PAYMENTFORM.submit();
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <div className="text-center mt-5">
            <div className="spinner-grow text-primary" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-danger" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-warning" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-info" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-light" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div>Please wait while we are redirecting...</div>
          </div>
        </div>
      );
    }
    // const ref = useRef(null);
    return (
      <div>
        <Row id="MainRoOne">
          <Col>
            <form
              ref={this.formRef}
              action="https://apiuat.religarehealthinsurance.com/portalui/PortalPayment.run"
              name="PAYMENTFORM"
              id="PAYMENTFORM"
              method="post"
              onSubmit={this.handleSubmit}
            // onLoad={this.submitHealthForm}
            >
              <div>
                <input
                  type="hidden"
                  name="proposalNum"
                  value={this.state.proposalNum}
                />
                <input
                  type="hidden"
                  name="returnURL"
                  value={configData.web_url + "/paymentstatus"}
                />
              </div>
            </form>
          </Col>
        </Row>
      </div>
    );
  }
}
