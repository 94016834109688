import React, { Component } from "react";
import "./ICICIProposeiinfo.css";
import { Button, Col, Row, Card, Form } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class ICICIProposeiinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      maritalstatus: [],
      nationality: [],
      occupation: [],
      members: [],
      tab1Data: {
        addresstype: "PERMANENT",
        roleCd: "",
        line1: "",
        line2: "",
        line3: "",
        areaCd: "",
        cityCd: "",
        stateCd: "",
        stateName: "",
        pincode: "",
        countryCd: "100",
        distCd: "",
        paddresstype: "COMMUNICATION",
        pline1: "",
        pline2: "",
        pline3: "",
        pareaCd: "",
        pcityCd: "",
        pdistCd: "",
        pstateCd: "",
        ppincode: "",
        pcountryCd: "",
        mobile: "",
        mobiletype: "",
        emailId: "",
        emailtype: "",
        cname: "",
        clname: "",
        dob: null,
        gender: "",
        titleCd: "",
        cmname: "",
        maritalstatus: "",
        nationality: "",
        occupation: "",
        anualincome: "",
        annualincome: "",
        anumber: "",
        regundergst: "",
        rparty: "",
        rgstn: "",
        ranumber: "",
        panumber: "",
        fax: "",
        phoneno: "",
        panno: "",
        nlmark: "",
        mobilecode: "+91",
      },
      startDate: new Date(),
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  componentDidMount() {
    this.apisfetchHandler();
    this.HealthmaritalstatusfetchHandler();
    this.HealthoccupationsfetchHandler();
  }
  HealthmaritalstatusfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthmaritalstatus/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ maritalstatus: data.list });
        }
      });
  };
  HealthoccupationsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthoccupations/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ occupation: data.list });
        }
      });
  };
  

  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "Il_Leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          const { tab1Data } = this.state;

          // Validate and handle dob
          let dob = null;
          if (
            data.lead.dob !== "0000-00-00" &&
            !isNaN(Date.parse(data.lead.dob))
          ) {
            dob = new Date(data.lead.dob);
          }

          // Update state with fetched data
          this.setState({
            members: data.lead.members,
            tab1Data: {
              ...tab1Data,
              cname: data.lead.fname,
              clname: data.lead.lname,
              occupation: data.lead.occupation,
              mobile: data.lead.mobile,
              emailId: data.lead.emailId,
              pincode: data.lead.zipcode,
              ppincode: data.lead.zipcode,
              titleCd: data.lead.titleCd,
              gender: data.lead.gender,
              dob: dob, // set the valid dob or null
              annualincome: data.lead.annualincome,
              maritalstatus: data.lead.maritalstatus,
              //occupation: data.lead.profession,
              fax: data.lead.contactdetails.fax,
            },
          });

          // Fetch address details
          const addresses = data.lead.address;
          addresses.forEach((address) => {
            if (address.addresstype === "PERMANENT") {
              this.setState((prevState) => ({
                tab1Data: {
                  ...prevState.tab1Data,
                  line1: address.line1,
                  line2: address.line2,
                },
              }));
            }
          });

          // Fetch zipcode details
          this.getZipCodeDetails(data.lead.zipcode);
          this.getZipcodePPDetails(data.lead.zipcode);
        }
      })
   
  };

  handleDateChange = (date) => {
    if (date) {
      // Ensure date is not null or undefined
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

      this.setState((prevState) => ({
        tab1Data: {
          ...prevState.tab1Data,
          dob: formattedDate,
        },
      }));
    } else {
      toast.error("Age should be at least 91 days", "error");
    }
  };
  handleInputChange = (tab, field, value) => {
    if (tab === "tab1Data" && field === "cname") {
      // Allow only letters in the company name (no numbers)
      const onlyLettersRegex = /^[A-Za-z]+$/;

      if (!onlyLettersRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    if (tab === "tab1Data" && field === "clname") {
      // Allow only letters in the company name (no numbers)
      const onlyLettersRegex = /^[A-Za-z]+$/;

      if (!onlyLettersRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }

    if (tab === "tab1Data" && field === "mobile") {
      // Allow only letters in the company name (no numbers)
      const firstDigit = parseInt(value.charAt(0), 10);
      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please enter valid mobile number.");
        return; // Stop further processing for this field
      }
    }
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };
  handleInputPChange1 = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
    if (value.length === 6) {
      this.getZipCodeDetails(value);
    }
  };
  getZipCodeDetails = (zipcode) => {
    var formData = new FormData();
    formData.append("zipcode", zipcode);
    fetch(configData.api_url + "Il_Leads/getzipdetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          const { tab1Data, isSameAsPermanent } = this.state;
          tab1Data.cityCd = data.zipdetails.districtNamecd;
          tab1Data.stateCd = data.zipdetails.stateNamecd;
          tab1Data.distCd = data.zipdetails.districtNamecd;
          tab1Data.areaCd = data.zipdetails.districtNamecd;
          tab1Data.stateName = data.zipdetails.stateName;
          if (isSameAsPermanent) {
            tab1Data.pareaCd = data.zipdetails.districtNamecd;
            tab1Data.pcityCd = data.zipdetails.districtNamecd;
            tab1Data.pdistCd = data.zipdetails.districtNamecd;
            tab1Data.pstateCd = data.zipdetails.stateNamecd;
            tab1Data.stateName = data.zipdetails.stateName;
          }
          this.setState({ tab1Data: tab1Data });
        }
      });
  };
  handleInputPPChange1 = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
    if (value.length === 6) {
      this.getZipcodePPDetails(value);
    }
  };
  getZipcodePPDetails = (zipcode) => {
    var formData = new FormData();
    formData.append("zipcode", zipcode);
    fetch(configData.api_url + "Il_Leads/getzipdetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          const { tab1Data } = this.state;
          tab1Data.pareaCd = data.zipdetails.districtNamecd;
          tab1Data.pcityCd = data.zipdetails.districtNamecd;
          tab1Data.pdistCd = data.zipdetails.districtNamecd;
          tab1Data.pstateCd = data.zipdetails.stateNamecd;
          tab1Data.stateName = data.zipdetails.stateName;
          this.setState({ tab1Data: tab1Data });
        }
      });
  };
  handleSameCheckboxChange = () => {
    const { tab1Data, isSameAsPermanent } = this.state;
    tab1Data.pline1 = tab1Data.line1;
    tab1Data.pline2 = tab1Data.line2;
    tab1Data.pline3 = tab1Data.line3;
    tab1Data.pareaCd = tab1Data.areaCd;
    tab1Data.pcityCd = tab1Data.cityCd;
    tab1Data.pdistCd = tab1Data.distCd;
    tab1Data.pstateCd = tab1Data.stateCd;
    tab1Data.ppincode = tab1Data.pincode;
    //tab1Data.stateName = tab1Data.stateName;
    this.setState({
      isSameAsPermanent: !isSameAsPermanent,
      tab1Data: tab1Data,
    });
  };

  handleInputChange1 = (tab, field, value) => {
    // Existing logic to update the state with the entered value
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));

    // Spot validation for line1
    if (field === "line1" && /[@$%^*~`()_+=?":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line One.");
    }

    // Spot validation for line2
    if (field === "line2" && /[@$%^*()_+=?~`":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line Two.");
    }
    if (value.length >= 60) {
      toast.error("Input should not exceed 60 characters.");
    }
    // Spot validation for line2
    if (field === "line3" && /[@$%^*()_+=?~`":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line Three.");
    }
    if (value.length >= 60) {
      toast.error("Input should not exceed 60 characters.");
    }
    // Spot validation for line1
    if (field === "pline1" && /[@$%^*~`()_+=?":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line One.");
    }

    // Spot validation for line2
    if (field === "pline2" && /[@$%^*()_+=?~`":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line Two.");
    }
    if (value.length >= 60) {
      toast.error("Input should not exceed 60 characters.");
    }
    // Spot validation for line2
    if (field === "pline3" && /[@$%^*()_+=?~`":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line Three.");
    }
    if (value.length >= 60) {
      toast.error("Input should not exceed 60 characters.");
    }
    // Check if the "isSameAsPermanent" flag is enabled
    if (this.state.isSameAsPermanent) {
      // If enabled, update the corresponding field in the "p" category
      const field1 = "p" + field;
      this.setState((prevState) => ({
        [tab]: {
          ...prevState[tab],
          [field1]: value,
        },
      }));
    }
  };

  validateForm() {
    const { tab1Data } = this.state;
    let formIsValid = true;

    if (!tab1Data["addresstype"]) {
      formIsValid = false;
      toast.error("Please Enter Address Type.");
    } else if (!tab1Data["line1"]) {
      formIsValid = false;
      toast.error("Please Enter Address Line One.");
    } else if (tab1Data["line1"].length > 60) {
      formIsValid = false;
      toast.error("Please Enter Address Should 60 Characters.");
    } else if (/[@$%^*~`()_+=?":{}|<>\\[\];""']/.test(tab1Data["line1"])) {
      formIsValid = false;
      toast.error("Enter a valid Address Line One.");
    } else if (!tab1Data["line2"]) {
      formIsValid = false;
      toast.error("Please Enter Address Line Two.");
    } else if (tab1Data["line2"].length > 60) {
      formIsValid = false;
      toast.error("Please Enter Address Should 60 Characters.");
    } else if (/[@$%^*()_+=?~`":{}|<>\\[\];""']/.test(tab1Data["line2"])) {
      formIsValid = false;
      toast.error("Enter a valid Address Line Two.");
    } else if (!tab1Data["areaCd"]) {
      formIsValid = false;
      toast.error("Enter Area.");
    } else if (tab1Data["areaCd"].length > 30) {
      formIsValid = false;
      toast.error("Please Enter Area Should 30 Characters.");
    } else if (!tab1Data["stateCd"]) {
      formIsValid = false;
      toast.error("Enter State Code.");
    } else if (tab1Data["stateCd"].length > 60) {
      formIsValid = false;
      toast.error("Please Enter State Code Should 60 Characters.");
    } else if (/[@$%^*()_+=?":{}|<>\\[\];""']/.test(tab1Data["stateCd"])) {
      formIsValid = false;
      toast.error("Enter a valid State Code.");
    } else if (!tab1Data["pincode"]) {
      formIsValid = false;
      toast.error("Enter Your Pincode.");
    } else if (tab1Data["pincode"].length !== 6) {
      formIsValid = false;
      toast.error("Pincode must be 6 characters long.");
    } else if (!tab1Data["mobile"]) {
      formIsValid = false;
      toast.error("Enter Your Mobile Number .");
    } else if (
      tab1Data["mobile"].length !== 10 &&
      tab1Data["mobiletype"] === "MOBILE"
    ) {
      formIsValid = false;
      toast.error("Mobile Number must be 10 digits long.");
    } else if (!tab1Data["emailId"]) {
      formIsValid = false;
      toast.error("Enter Your Email Id.");
    } else if (tab1Data["emailId"].length > 55) {
      formIsValid = false;
      toast.error("Enter Less Than 55 Characters Email Id.");
    } else if (!/\S+@\S+\.\S+/.test(tab1Data["emailId"])) {
      formIsValid = false;
      toast.error("Enter a valid EmailId.");
    }
    return formIsValid;
  }

  addressApiSubmit = () => {
    const addressesList = [
      {
        addresstype: this.state.tab1Data.addresstype,
        line1: this.state.tab1Data.line1,
        line2: this.state.tab1Data.line2,
        line3: this.state.tab1Data.line3,
        areaCd: this.state.tab1Data.areaCd,
        cityCd: this.state.tab1Data.cityCd,
        districtCd: this.state.tab1Data.distCd,
        stateCd: this.state.tab1Data.stateCd,
        pincode: this.state.tab1Data.pincode,
        countryCd: this.state.tab1Data.countryCd,
        nlmark: this.state.tab1Data.nlmark,
        stateName: this.state.tab1Data.stateName,
      },
      {
        addresstype: this.state.tab1Data.paddresstype,
        line1: this.state.tab1Data.pline1,
        line2: this.state.tab1Data.pline2,
        line3: this.state.tab1Data.pline3,
        areaCd: this.state.tab1Data.pareaCd,
        cityCd: this.state.tab1Data.pcityCd,
        districtCd: this.state.tab1Data.pdistCd,
        stateCd: this.state.tab1Data.pstateCd,
        pincode: this.state.tab1Data.ppincode,
        countryCd: this.state.tab1Data.countryCd,
        nlmark: this.state.tab1Data.nlmark,
        stateName: this.state.tab1Data.stateName,
      },
      // Add more address objects if needed
    ];

    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("address", JSON.stringify(addressesList));
    fd.append("baseproductId", this.state.baseproductId);
    fd.append("cqrId", this.state.cqrId);
    fetch(configData.api_url + "Il_Leads/updateleadaddress/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
  contactApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("mobile", this.state.tab1Data.mobile);
    fd.append("phoneno", this.state.tab1Data.phoneno);
    fd.append("mobiletype", this.state.tab1Data.mobiletype);
    fd.append("mobilecode", this.state.tab1Data.mobilecode);
    fd.append("fax", this.state.tab1Data.fax);
    fd.append("baseproductId", this.state.baseproductId);
    fd.append("cqrId", this.state.cqrId);
    
    fetch(configData.api_url + "Il_Leads/updateleadcontact/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
  emailApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("emailId", this.state.tab1Data.emailId);
    fd.append("emailtype", this.state.tab1Data.emailtype);
    fd.append("occupation", this.state.tab1Data.occupation);
    fd.append("nationality", this.state.tab1Data.nationality);
    fd.append("maritalstatus", this.state.tab1Data.maritalstatus);
    fd.append("baseproductId", this.state.baseproductId);
    fd.append("cqrId", this.state.cqrId);
    fetch(configData.api_url + "Il_Leads/updateleademaildetails/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
  latestdetailsApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("titleCd", this.state.tab1Data.titleCd);
    fd.append("gender", this.state.tab1Data.gender);
    fd.append("annualincome", this.state.tab1Data.annualincome);
    fd.append("maritalstatus", this.state.tab1Data.maritalstatus);
    fd.append("profession", this.state.tab1Data.occupation);
    fd.append("dob", this.state.tab1Data.dob);
    fd.append("cqrId", this.state.cqrId);
    fetch(configData.api_url + "Il_Leads/updatelead/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          window.location.href = "/icicimemberinfo";
        }
      });
  };
  handleSubmitClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.addressApiSubmit();
      this.contactApiSubmit();
      this.emailApiSubmit();
      this.latestdetailsApiSubmit();
    }
  };

  render() {
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1);
    // Example end year
    const endYear = new Date();
    endYear.setFullYear(endYear.getFullYear() + 10);
    return (
      <div>
        <h3 className="logoskybluecolor">ICICI Proposer Info</h3>
        <br />
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill  "
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-person-vcard-fill"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Member Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-heart-pulse"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Medicals</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-pencil-square"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Nominee Details</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-clipboard-plus"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Addon's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card className="card-default">
              <Card.Body>
                {/* <Form> */}
                <Row>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">Title</Form.Label>
                      <Form.Select
                        name="titleCd"
                        value={this.state.tab1Data.titleCd}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "titleCd",
                            e.target.value
                          )
                        }
                        className="form-control"
                        id="titleCd"
                        style={{ height: "47px" }}
                      >
                        <option>Select</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Dr.">Dr.</option>
                        <option value="Master">Master</option>
                        <option value="Mx.">Mx.</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        First Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="cname"
                        id="cname"
                        value={this.state.tab1Data.cname}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "cname",
                            e.target.value
                          )
                        }
                        className="form-control"
                        aria-describedby="enterName"
                        placeholder="Enter Name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="clname"
                        id="clname"
                        value={this.state.tab1Data.clname}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "clname",
                            e.target.value
                          )
                        }
                        className="form-control"
                        aria-describedby="enterName"
                        placeholder="Enter Last Name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">Gender</Form.Label>
                      <Form.Select
                        name="gender"
                        value={this.state.tab1Data.gender}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "gender",
                            e.target.value
                          )
                        }
                        className="form-control"
                        id="gender"
                        style={{ height: "47px" }}
                      >
                        <option>Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Thirdgender">Thirdgender</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">DOB</Form.Label>
                      <div>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          showTimeSelect={false}
                          selected={this.state.tab1Data.dob}
                          onChange={(date) => this.handleDateChange(date)}
                          className="form-control rgidateofbirth"
                          id="dob"
                          placeholderText="Select Date of Birth"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          minDate={previousYearStart} // Set the minimum selectable date
                          maxDate={endYear} // Set the maximum selectable date
                          disabled={this.state.showAadharForm}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Customer Type
                      </Form.Label>
                      <Form.Select
                        name="titleCds"
                        value={this.state.tab1Data.titleCds}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "titleCds",
                            e.target.value
                          )
                        }
                        className="form-control"
                        id="titleCd"
                        style={{ height: "47px" }}
                      >
                        <option>Select</option>
                        <option value="Individual">Individual</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Annual Income
                      </Form.Label>
                      <Form.Select
                        name="annualincome"
                        value={this.state.tab1Data.annualincome}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "annualincome",
                            e.target.value
                          )
                        }
                        className="form-control"
                        id="annualincome"
                        style={{ height: "47px" }}
                      >
                        <option>Select</option>
                        <option value="300000">3 lakh</option>
                        <option value="500000">5 lakh</option>
                        <option value="700000">7 lakh</option>
                        <option value="1000000">10 lakh</option>
                        <option value="1500000">15 lakh</option>
                        <option value="2500000">25 lakh</option>
                        <option value="5000000">50 lakh</option>
                        <option value="10000000">100 lakh</option>
                        <option value="20000000">200 lakh</option>
                        <option value="300000000">300 lakh</option>
                        <option value="600000000">600 lakh</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Occupation
                      </Form.Label>
                      <Form.Select
                        name="occupation"
                        className="form-control"
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "occupation",
                            e.target.value
                          )
                        }
                        id="occupation"
                        value={this.state.tab1Data.occupation}
                        aria-describedby="occupation"
                        aria-label="Default select example"
                      >
                        <option value="">Select</option>
                        {this.state.occupation.map((occupation, index, idx) => (
                          <option key={index} value={occupation.occupation}>
                            {occupation.occupation}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <hr />
                  <Form.Label className="control-label">
                    Permanent Address
                  </Form.Label>
                  <hr />
                  <br />
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Address Line1
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="line1"
                        value={this.state.tab1Data.line1}
                        id="line1"
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "line1",
                            e.target.value
                          )
                        }
                        maxLength={60}
                        placeholder="Address Line1"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Address Line2
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="line2"
                        id="line2"
                        value={this.state.tab1Data.line2}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "line2",
                            e.target.value
                          )
                        }
                        maxLength={60}
                        aria-describedby="line2"
                        placeholder="Address Line2"
                      />
                    </Form.Group>
                  </Col>

                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Pin Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="pincode"
                        id="pincode"
                        value={this.state.tab1Data.pincode}
                        onChange={(e) =>
                          this.handleInputPChange1(
                            "tab1Data",
                            "pincode",
                            e.target.value
                          )
                        }
                        className="form-control"
                        aria-describedby="pincode"
                        placeholder="Pin Code"
                        maxLength={6}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">City</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="cityCd"
                        id="cityCd"
                        value={this.state.tab1Data.cityCd}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "cityCd",
                            e.target.value
                          )
                        }
                        aria-describedby="cityCd"
                        placeholder="City"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">State</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="stateCd"
                        aria-describedby="State"
                        name="stateCd"
                        value={this.state.tab1Data.stateCd}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "stateCd",
                            e.target.value
                          )
                        }
                        placeholder="State"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">Country</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="countryCd"
                        id="countryCd"
                        value={this.state.tab1Data.countryCd}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "countryCd",
                            e.target.value
                          )
                        }
                        aria-describedby="countryCd"
                        placeholder="Area"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Mobile ISD
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="mobilecode"
                        className="form-control"
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "mobilecode",
                            e.target.value
                          )
                        }
                        id="mobilecode"
                        value={this.state.tab1Data.mobilecode}
                        aria-describedby="mobilecode"
                        placeholder="Mobile ISD"
                        maxLength={4}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Phone No.
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="mobile"
                        maxLength={10}
                        className="form-control"
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "mobile",
                            e.target.value
                          )
                        }
                        id="mobile"
                        value={this.state.tab1Data.mobile}
                        aria-describedby="mobile"
                        placeholder="Mobile/Landline"
                      />
                    </Form.Group>
                  </Col>

                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="emailId"
                        value={this.state.tab1Data.emailId}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "emailId",
                            e.target.value
                          )
                        }
                        id="emailId"
                        className="form-control"
                        aria-describedby="emailId"
                        placeholder="Email"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <Button
                      id="getQuotes4"
                      onClick={this.handleSubmitClick}
                      style={{ float: "right" }}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
                {/* </Form> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
