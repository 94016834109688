import React, { Component } from "react";
import { Button, Col, Row, Table, Card } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";
import "./Proposerinfo.css";

export default class Medicals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      show: false,
      policiestab2: [],
      policiesform: [],
      members: [],
      policiesTable: [],
      mandatoryaddons: [],
      optionaladdons: [],
      showExistingSince: {},
      isSameAsPermanent: false,
      dateValues: {},
      tab3Data: {
        medicalQns: "",
        productCode: "",
        questionSetCode: "",
        questionCode: "",
        qnDesc: "",
        newDesc: "",
      },
      consentCheckbox: false,
      declarationCheckbox: false,
      activeTabIndex: 0,
      pStatus: 2,
      firstTabInput: "",
      noPEDChecked: false,
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleSubmitBackClick = (e) => {
    window.location.href = "/memberinfo";
  };
  componentDidMount() {
    this.apisfetchHandler();
  }
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          var members = data.lead.members;
          members.forEach((member) => {
            const minDate1 = new Date(member.dob);
            const cYear = minDate1.getFullYear();
            const cMonth = (minDate1.getMonth() + 1)
              .toString()
              .padStart(2, "0");
            const minDate = `${cYear}-${cMonth}`;
            const currentDate = new Date();
            // currentDate.setMonth(currentDate.getMonth() - 1);
            // console.log(currentDate.getMonth() + 1);
            // console.log(currentDate.getMonth());
            const currentYear = currentDate.getFullYear();
            const currentMonth = (currentDate.getMonth() + 1)
              .toString()
              .padStart(2, "0"); // Adding padding if necessary (e.g., '05' for May)
            const maxDate = `${currentYear}-${currentMonth}`;
            member.minDate = minDate;
            member.maxDate = maxDate;
          });
          this.setState({ members: members });
          this.CaresupremeaddonsfetchHandler();
          // console.log(this.state.members);
        }
      });
  };
  CaresupremeaddonsfetchHandler = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "leads/getproductdetails", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ policiesTable: data.product.medicalqns });
          this.setMedicalQuestions();
        }
      });
  };
  setMedicalQuestions = () => {
    const initialState = {};
    this.state.policiesTable?.forEach((medicals) => {
      var isChecked = "No";
      if (medicals?.dummyTwo === "textbox" || medicals?.dummyTwo === "month") {
        isChecked = "";
      }
      if (
        medicals?.dummyTwo === "checkbox" &&
        medicals.qnDesc ===
          "Does any person(s) to be insured has any Pre-existing diseases?"
      ) {
        isChecked = "Yes";
      }

      this.state.members?.forEach((member) => {
        if (!initialState[medicals?.caresuprememedicalqns_Id]) {
          initialState[medicals?.caresuprememedicalqns_Id] = {};
        }
        initialState[medicals?.caresuprememedicalqns_Id][member?.lhmId] =
          isChecked;
        if (
          medicals?.dummyTwo === "checkbox" &&
          medicals.qnDesc ===
            "Does any person(s) to be insured has any Pre-existing diseases?"
        ) {
          this.setState((prevState) => ({
            noPEDChecked: {
              ...prevState.noPEDChecked,
              [member.lhmId]: isChecked === "Yes" ? true : false,
            },
          }));
          // console.log(member.lhmId);
          // console.log(isChecked);
          // console.log(this.state.noPEDChecked);
        }
      });
    });
    this.setState({
      showExistingSince: initialState,
    });
    // console.log(initialState);
    // console.log(this.state.noPEDChecked);
  };

  handleMedicalCheckboxChange = (fieldName) => {
    this.setState((prevState) => ({
      tab3Data: {
        ...prevState.tab3Data,
        [fieldName]: !prevState.tab3Data[fieldName],
      },
    }));
  };
  handleCheckboxMedChange = (questionId, memberId, isChecked, dateValue) => {
    this.setState((prevState) => {
      var newShowExistingSince = { ...prevState.showExistingSince };
      // console.log(dateValue, "dateValue");
      // console.log(newShowExistingSince[questionId][memberId]);
      var dateValue1 = dateValue;
      if (isChecked === "check") {
        dateValue1 = "No";
        if (dateValue) dateValue1 = "Yes";
      }
      // console.log(dateValue1);
      newShowExistingSince[questionId][memberId] = dateValue1;
      // console.log(newShowExistingSince[questionId][memberId]);
      return {
        showExistingSince: newShowExistingSince,
      };
    });
  };

  handleSubmitNextClick = (e) => {
    e.preventDefault();
    const allQuestionData = [];
    // Loop through all checked checkboxes and add question data to the array
    const { showExistingSince } = this.state;
    this.state.policiesTable.forEach((medicals) => {
      this.state.members.forEach((member) => {
        let ans =
          showExistingSince[medicals.caresuprememedicalqns_Id]?.[member.lhmId];
        // Add question data to the array
        allQuestionData.push({
          csmqId: medicals.caresuprememedicalqns_Id,
          ans: ans,
          lhmId: member.lhmId,
          questionSetCode: medicals.questionSetCode,
          questionCode: medicals.questionCode,
        });
      });
    });

    const fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("baseproductId", this.state.baseproductId);
    fd.append("qns", JSON.stringify(allQuestionData));
    fetch(configData.api_url + "leads/updatemedicalqns/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: fd,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          window.location.href = "/nomineedetails";
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  handleNoPEDChange = (e, member, caresuprememedicalqns_Id) => {
    const isChecked = e.target.checked;

    this.setState((prevState) => ({
      noPEDChecked: {
        ...prevState.noPEDChecked,
        [member.lhmId]: isChecked,
      },
    }));
    console.log(isChecked);
    this.setState((prevState) => {
      const updatedShowExistingSince = { ...prevState.showExistingSince };
      if (!isChecked) {
        this.state.policiesTable.forEach((medicals) => {
          updatedShowExistingSince[medicals.caresuprememedicalqns_Id][
            member.lhmId
          ] = "No";
        });
      } else
        updatedShowExistingSince[caresuprememedicalqns_Id][member.lhmId] =
          "Yes";
      console.log("updatedShowExistingSince:", updatedShowExistingSince);
      return { showExistingSince: updatedShowExistingSince };
    });
  };

  render() {
    const { policiesTable, members } = this.state;
    // const questionOneChecked = this.state.showExistingSince["1"]?.[members[0].lhmId] === "Yes";
    return (
      <div>
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill  "
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-vcard-fill"
                        onClick={this.handleSubmitBackClick}
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6
                        style={{ color: "#3d5a95" }}
                        onClick={this.handleSubmitBackClick}
                      >
                        Member Info
                      </h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-heart-pulse"
                        id="activeIcon1"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Medicals</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-pencil-square"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Nominee Details</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-clipboard-plus"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Add - On's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>
        <div>
          <div className="row">
            <div className="col">
              <Table>
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Question</th>
                    {members.map((member) => (
                      <th key={member.relation}>
                        {member.fname} {member.lname}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(policiesTable) &&
                    policiesTable.map((medicals, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{medicals.qnDesc}</td>
                        {this.state.members.map((member) => (
                          <td key={member.lhmId}>
                            {medicals.dummyTwo === "textbox" ||
                            medicals.dummyTwo === "month" ? (
                              <input
                                type={medicals.dummyTwo}
                                name={`checkboxmonth-${medicals.caresuprememedicalqns_Id}-${member.lhmId}`}
                                value={
                                  this.state.showExistingSince[
                                    medicals.caresuprememedicalqns_Id
                                  ]?.[member.lhmId] || ""
                                }
                                onChange={(e) =>
                                  this.handleCheckboxMedChange(
                                    medicals.caresuprememedicalqns_Id,
                                    member.lhmId,
                                    medicals.dummyTwo,
                                    e.target.value
                                  )
                                }
                                min={
                                  medicals.dummyTwo === "month"
                                    ? member.minDate
                                    : " "
                                }
                                max={
                                  medicals.dummyTwo === "month"
                                    ? member.maxDate
                                    : " "
                                }
                                disabled={
                                  medicals.qnDesc ===
                                  "Does any person(s) to be insured has any Pre-existing diseases?"
                                    ? false
                                    : !this.state.noPEDChecked[member.lhmId]
                                }
                              />
                            ) : medicals.dummyOne === "Yes" ? (
                              <input
                                type="checkbox"
                                checked={true}
                                disabled={true}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                checked={
                                  this.state.showExistingSince[
                                    medicals.caresuprememedicalqns_Id
                                  ]?.[member.lhmId] === "Yes"
                                }
                                onChange={(e) => {
                                  medicals.qnDesc ===
                                  "Does any person(s) to be insured has any Pre-existing diseases?"
                                    ? this.handleNoPEDChange(
                                        e,
                                        member,
                                        medicals.caresuprememedicalqns_Id
                                      )
                                    : this.handleCheckboxMedChange(
                                        medicals.caresuprememedicalqns_Id,
                                        member.lhmId,
                                        "check",
                                        !(
                                          this.state.showExistingSince[
                                            medicals.caresuprememedicalqns_Id
                                          ]?.[member.lhmId] === "Yes"
                                        )
                                      );
                                }}
                                disabled={
                                  medicals.qnDesc ===
                                  "Does any person(s) to be insured has any Pre-existing diseases?"
                                    ? false
                                    : !this.state.noPEDChecked[member.lhmId]
                                }
                              />
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
          <Row className="mt-5">
            <Col>
              {" "}
              <Button
                id="getQuotes4"
                onClick={this.handleSubmitBackClick}
                type="submit"
                style={{ float: "left" }}
              >
                Back
              </Button>
            </Col>
            <Col>
              {" "}
              <Button
                id="getQuotes4"
                onClick={this.handleSubmitNextClick}
                type="submit"
                style={{ float: "right" }}
              >
                Next
              </Button>
            </Col>
            {/* </Form> */}
          </Row>
        </div>
      </div>
    );
  }
}
