import React, { Component } from "react";

export default class RGIBuy extends Component {
  render() {
    return (
      <div>
        <div>RGI_Buy</div>
      </div>
    );
  }
}
