import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Badge,
  Tooltip,
  OverlayTrigger,
  Accordion,
} from "react-bootstrap";
import configData from "../../config.json";
import { toast } from "react-toastify";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Employeeattendance.css";
import moment from "moment";

export default class EmployeesAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      data: [],
      pData: [],
      employeelist: [],
      selectedEmployee: "",
      filteredLeave: [],
      groupedData: [],
      markedAttendanceDate: new Date(),
      Holidays: [],
      disabledDates: this.loadDisabledDatesFromStorage() || [],
      attended: [],
      leavedata: [],
      LeaveBalance: [],
      filteredData: null,
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getFullYear(),
      totalWorkingDays: 0,
      presentDays: 0,
      absentDays: 0,
      halfDays: 0,
      lateDays: 0,
      holidays: 0,
      regularizedDays: 0,
      regularizedLeaveDates: [],
      selectedYearMonth: null,
    };
  }

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  loadDisabledDatesFromStorage() {
    const disabledDates = JSON.parse(localStorage.getItem("disabledDates"));
    return disabledDates;
  }
  handleMonthYearChange = (event) => {
    const [year, month] = event.target.value.split("-").map(Number);
    this.setState({ currentYear: year, currentMonth: month }, () =>
      this.fetchDataForMonth(month, year)
    );
  };
  fetchDataForMonth = (month, year) => {
    this.HolidayslistfetchHandler(month, year);
    this.AttendencefetchHandler(this.state.selectedEmployee); // Pass the selected employee ID explicitly here
  };
  handleEmployeeChange = (e) => {
    const selectedEmployee = e.target.value;
    this.setState({ selectedEmployee, selectedYearMonth: null }, () => {
      this.AttendencefetchHandler(selectedEmployee);
    });
  };
  handleAccordionToggle = (year, month) => {
    this.setState({ selectedYearMonth: `${year}-${month}` });
  };

  componentDidMount() {
    // this.AttendencefetchHandler();
    this.HolidayslistfetchHandler();
    this.getleaveBalance();
    this.fetchDataForMonth(this.state.currentMonth, this.state.currentYear);
    this.EmployeesfetchHandler();
    this.LeaverequestapprovalsfetchHandler();
  }
  LeaverequestapprovalsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Leaverequestapprovals/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const leaveDates = [];
          data.list.forEach((leave) => {
            const fromDate = new Date(leave.FromDate);
            const toDate = new Date(leave.ToDate);
            for (
              let date = new Date(fromDate);
              date <= toDate;
              date.setDate(date.getDate() + 1)
            ) {
              leaveDates.push(
                `${date.getFullYear()}-${
                  date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1
                }-${
                  date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
                }`
              );
            }
          });
          this.setState({ leaveRequestDates: leaveDates });
        }
      });
  };
  getleaveBalance = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("employees_Id", this.state.uId);
    fetch(configData.api_url + "C_Employees/retrive", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ LeaveBalance: data.detail.leaves });
        }
      });
  };
  // Fetch Holidays
  HolidayslistfetchHandler = () => {
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Holidayslist/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ Holidays: data.list }, this.calculateStats);
        }
      });
  };

  // Fetch employee list
  EmployeesfetchHandler = () => {
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Employees/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ employeelist: data.list });
          this.calculateStats();
        }
      })
      .catch((error) => {
        this.showToast("Failed to fetch employees", "error");
      });
  };

  AttendencefetchHandler = (eId) => {
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("employees_Id", eId); // Use eId of selected employee

    fetch(configData.api_url + "C_Employees/getattendancebyId ", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const pData = [];
          data.list.attended.forEach((pdates) => {
            const date = moment(pdates, "YYYY-MM-DD").format("YYYY-MM-DD");
            pData.push(date);
          });
          const validLeaves = data.list.leave.filter(
            (date) => date !== "0000-00-00"
          );
          this.setState(
            {
              data: data.list.attended,
              pData: pData,
              filteredLeave: validLeaves,
              leavedata: data.list.leave || [],
            },
            () => {
              this.calculateStats();
              this.groupDataByYearAndMonth();
            }
          );
        }
      });
  };
  groupDataByYearAndMonth = () => {
    const { data } = this.state;
    let groupedData = {};
    data.forEach((attendance) => {
      const date = new Date(attendance);
      const year = date.getFullYear();
      const month = date.getMonth(); // 0 - 11 for months
      if (!groupedData[year]) {
        groupedData[year] = [];
      }
      if (!groupedData[year][month]) {
        groupedData[year][month] = [];
      }
      groupedData[year][month].push(attendance);
    });
    // Convert the groupedData object into an array of year-month groups
    const formattedGroupedData = Object.keys(groupedData).map((year) => ({
      year,
      months: groupedData[year].map((monthData, monthIndex) => ({
        month: monthIndex + 1, // Display month as 1-12
        data: monthData,
      })),
    }));

    this.setState({ groupedData: formattedGroupedData });
  };
  calculateStats = () => {
    const { leavedata, Holidays, currentMonth, currentYear, pData } =
      this.state;
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
    let presentDays = 0;
    let absentDays = 0;
    let holidays = 0;
    let totalWorkingDays = 0;
    let regularizedDays = 0;
    let regularizedLeaveDates = [];
    Holidays.forEach((holiday) => {
      const holidayDate = new Date(holiday.Date);
      if (
        holidayDate.getMonth() + 1 === currentMonth &&
        holidayDate.getFullYear() === currentYear
      ) {
        holidays++;
      }
    });
    for (let i = 1; i <= daysInMonth; i++) {
      let j = i + 1;
      const date = new Date(currentYear, currentMonth - 1, j)
        .toISOString()
        .split("T")[0];

      const day = new Date(date).getDay();
      const isHoliday = Holidays.some((holiday) => holiday.Date === date);
      // const isWeekend = day === 0 || day === 0 || (day === 6 && date !== lastSaturdayString);
      const isWeekend = day === 0 || day === 0;
      if (isHoliday || isWeekend) continue;

      totalWorkingDays++;
    }
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment(
        currentYear + "-" + currentMonth + "-" + i,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      const day = new Date(date).getDay();
      const isHoliday = Holidays.some((holiday) => holiday.Date === date);
      const isWeekend = day === 0;
      if (isHoliday || isWeekend) continue;
      const attended = pData.includes(date);
      const onLeave = leavedata.includes(date);
      if (attended) {
        presentDays++;
      } else if (!attended && !onLeave) {
        absentDays++;
      }
      if (onLeave) {
        regularizedDays++;
        regularizedLeaveDates.push(date);
      }
    }
    this.setState({
      totalWorkingDays,
      presentDays,
      absentDays,
      holidays,
      regularizedDays,
      regularizedLeaveDates,
    });
  };
  isDateDisabled = ({ date }) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
    const dateString = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    const holiday = this.state.Holidays.find(
      (holiday) => holiday.Date === dateString
    );
    if (dayOfWeek === 0 || holiday) {
      return true;
    }
    return false;
  };
  // Handle date change in calendar
  handleDateChange = (date) => {
    this.setState({ markedAttendanceDate: date });
  };

  // Format date in YYYY-MM-DD format
  formatDate = (dateString) => {
    if (!dateString) return "Invalid Date";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";

    return date.toLocaleString("en-IN", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  isWorkDay = (year, month, day) => {
    const weekDay = new Date(year, month, day).getDay();
    return weekDay === 2 || weekDay === 3;
  };
  tileClassName = ({ date }) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const dateString = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    const isRegularized = this.state.attended.includes(dateString);
    const holiday = this.state.Holidays.find(
      (holiday) => holiday.Date === dateString
    );
    const isLeave = this.state.leavedata.includes(dateString);
    // Check if the current date is in the attendance list
    const isAttendance = this.state.pData.find(
      (attendance) => attendance === dateString
    );
    // Check if the current date is a holiday
    const isHoliday = holiday !== undefined;
    const isSunday = date.getDay() === 0;
    const isWorkingDay = !isHoliday && !isSunday;
    const isUnmarkedWorkingDay = isWorkingDay && !isAttendance && !isLeave;
    const isLeaveRequest = this.state.leaveRequestDates?.includes(dateString);

    if (isAttendance) {
      return "marked-attendance";
    } else if (isHoliday) {
      return "marked-holiday";
    } else if (isAttendance) {
      return "marked-attendance";
    } else if (isRegularized) {
      return "regularized";
    } else if (isUnmarkedWorkingDay) {
      return "unmarked-working-day";
    } else if (isLeaveRequest) {
      return "leave-request";
    } else if (isLeave) {
      return "leave-date";
    }
  };
  render() {
    const { employeelist, groupedData } = this.state;

    return (
      <Container>
        <Row>
          <Col lg="2" md="2">
            <Form.Label style={{ paddingTop: "8px" }}>
              Employees List
            </Form.Label>
          </Col>
          <Col lg="4" md="4">
            <Form.Select
              onChange={this.handleEmployeeChange}
              aria-label="Select Employee"
              className="form-control"
              id="employee"
              name="employee"
            >
              <option value="">Select Employee</option>
              {employeelist.map((employee, index) => (
                <option key={index} value={employee.employees_Id}>
                  {employee.fName} {employee.lName} - {employee.eId}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <div>
          {this.state.selectedEmployee && (
            <Row>
              <Col lg="3"></Col>
              <Col>
                <Col style={{ paddingTop: "8px" }}>
                  <Badge
                    className="custom-badge"
                    style={{ marginRight: "10px" }}
                  >
                    Marked
                  </Badge>
                  <Badge
                    className="custom-badges3"
                    style={{ marginRight: "10px" }}
                  >
                    Leaves
                  </Badge>
                  <Badge
                    className="custom-badge13"
                    style={{ marginRight: "10px" }}
                  >
                    Holidays
                  </Badge>
                  <Badge
                    className="custom-badges6"
                    style={{ marginRight: "10px" }}
                  >
                    Not Marked
                  </Badge>

                  <Badge
                    className="custom-badge14"
                    style={{ marginRight: "10px" }}
                  >
                    Attendance Request
                  </Badge>
                  <Badge
                    className="custom-badge15"
                    style={{ marginRight: "10px" }}
                  >
                    Sundays
                  </Badge>
                </Col>
              </Col>
            </Row>
          )}
        </div>
        {this.state.selectedEmployee && (
          <div className="mt-3">
            <div className="row text-center  justify-content-center">
              <div className="col-md-4 col-sm-12">
                <Calendar
                  onChange={this.handleDateChange}
                  value={this.state.markedAttendanceDate}
                  tileClassName={this.tileClassName}
                  tileDisabled={this.isDateDisabled}
                  tileContent={({ date, view }) => {
                    // Display holiday purpose on hover for month view
                    if (view === "month") {
                      const year = date.getFullYear();
                      const month = date.getMonth() + 1; // Months are zero-based
                      const day = date.getDate();
                      const dateString = `${year}-${
                        month < 10 ? "0" + month : month
                      }-${day < 10 ? "0" + day : day}`;
                      const holiday = this.state.Holidays.find(
                        (holiday) => holiday.Date === dateString
                      );

                      if (holiday) {
                        return (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip">{holiday.Festival}</Tooltip>
                            }
                          >
                            <span className="d-inline-block holiday-tooltip">
                              {holiday.Festival}
                            </span>
                          </OverlayTrigger>
                        );
                      } else {
                        const lastDayOfMonth = new Date(
                          year,
                          month,
                          0
                        ).getDate();
                        const isSaturday = date.getDay() === 6; // Saturday is the 6th day of the week (zero-based)
                        const isLastSaturday =
                          isSaturday && day > lastDayOfMonth - 7;

                        if (isLastSaturday) {
                          return <span></span>;
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <br />
        {this.state.selectedEmployee && (
          <Row>
            <Col>
              <div className="overview-section">
                <Row>
                  <Col>
                    <div className="date-selectors">
                      <select
                        value={`${this.state.currentYear}-${this.state.currentMonth}`}
                        onChange={this.handleMonthYearChange}
                      >
                        {Array.from(
                          { length: (2025 - 2022 + 1) * 12 },
                          (_, i) => {
                            const startYear = 2022; // Starting year
                            const date = new Date(startYear, 0); // January of the starting year
                            date.setMonth(i); // Increment month by index
                            const month = date.getMonth() + 1; // Months are 0-indexed
                            const year = date.getFullYear();
                            return (
                              <option
                                key={`${year}-${month}`}
                                value={`${year}-${month}`}
                              >
                                {date.toLocaleString("default", {
                                  month: "long",
                                })}{" "}
                                {year}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </Col>
                  <Col lg="7" md="7">
                    <h2>
                      Days Overview for {this.state.currentMonth}/
                      {this.state.currentYear}
                    </h2>
                  </Col>
                </Row>
                <div className="overview-stats">
                  <div className="stat">
                    <span className="number">
                      {this.state.totalWorkingDays}
                    </span>
                    <p>Total Working Days</p>
                  </div>
                  <div className="stat">
                    <span className="number present">
                      {this.state.presentDays}
                    </span>
                    <p>Present Days</p>
                  </div>
                  <div className="stat">
                    <span className="number absent">
                      {this.state.absentDays}
                    </span>
                    <p>Not Marked Days</p>
                  </div>
                  <div className="stat">
                    <span className="number half">
                      {this.state.regularizedDays}
                    </span>
                    <p>Request Dates</p>
                  </div>
                  <div className="stat">
                    <span className="number late">{this.state.lateDays}</span>
                    <p>Late Days</p>
                  </div>
                  <div className="stat">
                    <span className="number lates">
                      {this.state.LeaveBalance}
                    </span>
                    <p>Leave Balance</p>
                  </div>
                  <div className="stat">
                    <span className="number holidays">
                      {this.state.holidays}
                    </span>
                    <p>Holidays</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <br />
        {this.state.selectedEmployee && (
          <div className="mt-2">
            <Row>
              <Col lg="3">
                <Accordion defaultActiveKey="0">
                  {groupedData
                    .filter(
                      (yearData) =>
                        parseInt(yearData.year, 10) === this.state.currentYear
                    )
                    .flatMap((yearData) =>
                      yearData.months.filter(
                        (monthData) =>
                          monthData.month === this.state.currentMonth
                      )
                    )
                    .map((monthData, index) => (
                      <Accordion.Item
                        eventKey={`${this.state.currentYear}-${monthData.month}`}
                        key={`${this.state.currentYear}-${monthData.month}`}
                      >
                        <Accordion.Header>
                          {`${new Date(0, monthData.month - 1).toLocaleString(
                            "default",
                            {
                              month: "long",
                            }
                          )} ${this.state.currentYear}`}
                        </Accordion.Header>
                        <Accordion.Body>
                          {monthData.data.length > 0 ? (
                            monthData.data.map((attendance, index) => (
                              <div key={index}>
                                <p>{this.formatDate(attendance)}</p>
                              </div>
                            ))
                          ) : (
                            <p>No attendance for this month.</p>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    );
  }
}
