import React, { Component } from "react";
import {
  Button,
  Container,
  Row,Form,
  Col,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from '../config.json';

export default class Godigithealthpinmaster extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
        isEditing: false, 
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.godigithealthpinmaster_Id,
          sortable: true,
          cell: (row) => <span>{row.godigithealthpinmaster_Id}</span>,
        },
       
        {name:"City",selector:(row, i) => row.city,sortable:true,cell: (row) => <span>{row.city}</span>,},
        {name:"State",selector:(row, i) => row.state,sortable:true,cell: (row) => <span>{row.state}</span>,},{name:"State Code",selector:(row, i) => row.stateCode,sortable:true,cell: (row) => <span>{row.stateCode}</span>,},{name:"Effective Date",selector:(row, i) => row.effectiveDate,sortable:true,cell: (row) => <span>{row.effectiveDate}</span>,},{name:"Expiry Date",selector:(row, i) => row.expiryDate,sortable:true,cell: (row) => <span>{row.expiryDate}</span>,},{name:"Pincode",selector:(row, i) => row.pincode,sortable:true,cell: (row) => <span>{row.pincode}</span>,},{name:"Zone",selector:(row, i) => row.zone,sortable:true,cell: (row) => <span>{row.zone}</span>,},{name:"Dummy One",selector:(row, i) => row.dummyOne,sortable:true,cell: (row) => <span>{row.dummyOne}</span>,},{name:"Dummy Two",selector:(row, i) => row.dummyTwo,sortable:true,cell: (row) => <span>{row.dummyTwo}</span>,},{name:"Dummy Three",selector:(row, i) => row.dummyThree,sortable:true,cell: (row) => <span>{row.dummyThree}</span>,},
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={()=>this.handleEnterEditMode(row)}
                className="bi bi-pen" style={{"marginRight":"3px"}}
              >
                
              </Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              >
                
              </Button>
            </div>
          ),
        },],
      currentPage: 1,
      itemsPerPage: 10,
      godigithealthpinmaster_Id:"",
      city:'',state:'',stateCode:'',effectiveDate:'',expiryDate:'',pincode:'',zone:'',dummyOne:'',dummyTwo:'',dummyThree:'',
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  // Edit mode
      handleEnterEditMode = (row) => {
       var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Godigithealthpinmaster/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            godigithealthpinmaster_Id:row.godigithealthpinmaster_Id,
            city:row.city,
            insurerlogo: row.insurerlogo,
            state: row.state,
            stateCode: row.stateCode,
            effectiveDate: row.effectiveDate,
            expiryDate: row.expiryDate,
            pincode: row.pincode,
            zone: row.zone,
            dummyOne: row.dummyOne,
            dummyTwo: row.dummyTwo,
            dummyThree:row.dummyThree,
            isEditing: true,
          });
        }
      });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  GodigithealthpinmastereditHandler=(e)=>{
      e.preventDefault();
    }
       
GodigithealthpinmasterfetchHandler = () => {
  var formData = new FormData();
  formData.append("sId", this.state.sId);
 formData.append("uId", this.state.uId);
            fetch(configData.api_url +'C_Godigithealthpinmaster/list', {  // Enter your IP address here
        
              method: 'POST', 
              //headers :{ 'Content-Type' : 'application/json'},
              mode: 'cors', 
              body: formData// body data type must match "Content-Type" header
            }).then(response => response.json())
            .then((data) => {
              if (data.status === "success") {
                this.setState({ data: data.list });
              }
            });
           }
           GodigithealthpinmastersubmitHandler=(e)=>{
            e.preventDefault();
              //   setButtons(contacts);
             var formData = new FormData();
             formData.append("sId", this.state.sId);
              formData.append("uId", this.state.uId);
              formData.append('city',this.state.city);
              formData.append('state',this.state.state);
              formData.append('stateCode',this.state.stateCode);
              formData.append('effectiveDate',this.state.effectiveDate);
              formData.append('expiryDate',this.state.expiryDate);
              formData.append('pincode',this.state.pincode);
              formData.append('zone',this.state.zone);
              formData.append('dummyOne',this.state.dummyOne);
              formData.append('dummyTwo',this.state.dummyTwo);
              formData.append('dummyThree',this.state.dummyThree);

             fetch(configData.api_url + 'C_Godigithealthpinmaster/add', {  // Enter your IP address here
          
               method: 'POST',
               //headers :{ 'Content-Type' : 'application/json'},
               mode: 'cors',
               body: formData// body data type must match "Content-Type" header
             }).then(response => response.json())
               .then((data) => {
                 this.showToast(data.message, data.status);
                 if (data.status === "success") {
                   this.GodigithealthpinmasterfetchHandler();
                   this.handleOffcanvasClose();
                   this.setState({city:'',state:'',stateCode:'',effectiveDate:'',expiryDate:'',pincode:'',zone:'',dummyOne:'',dummyTwo:'',dummyThree:'',});
                }
              });
             }
   GodigithealthpinmasterupdateHandler=(e)=>{
        e.preventDefault();
     var formData = new FormData();
     formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
      formData.append("godigithealthpinmaster_Id", this.state.godigithealthpinmaster_Id);  
formData.append('city',this.state.city);
formData.append('state',this.state.state);
formData.append('stateCode',this.state.stateCode);
formData.append('effectiveDate',this.state.effectiveDate);
formData.append('expiryDate',this.state.expiryDate);
formData.append('pincode',this.state.pincode);
formData.append('zone',this.state.zone);
formData.append('dummyOne',this.state.dummyOne);
formData.append('dummyTwo',this.state.dummyTwo);
formData.append('dummyThree',this.state.dummyThree);

      
          fetch(configData.api_url +'C_Godigithealthpinmaster/update', {  
            method: 'POST', 
            mode: 'cors', 
            body: formData
          }).then(response => response.json())
            .then((data) => {
              this.showToast(data.message, data.status);
            if (data.status === "success") {
              this.GodigithealthpinmasterfetchHandler();
              this.handleOffcanvasClose();
            }
          });
         }

  componentDidMount() {
    this.GodigithealthpinmasterfetchHandler();
    
  }
 ;handlecityChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({city: fieldValue });
};
handlestateChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({state: fieldValue });
};
handlestateCodeChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({stateCode: fieldValue });
};
handleeffectiveDateChange = (e) => {
  e.preventDefault();
  
  const fieldValue = e.target.value;
  this.setState({effectiveDate: fieldValue });
};
handleexpiryDateChange = (e) => {
  e.preventDefault();
  
  const fieldValue = e.target.value;
  this.setState({expiryDate: fieldValue });
};

handlepincodeChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({pincode: fieldValue });
};handlezoneChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({zone: fieldValue });
};handledummyOneChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyOne: fieldValue });
};handledummyTwoChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyTwo: fieldValue });
};handledummyThreeChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyThree: fieldValue });
};
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
     // implement your edit logic here
  };
handleDelete = (row, index) => {
  var formData = new FormData();
    formData.append('godigithealthpinmaster_Id', row.godigithealthpinmaster_Id);
    fetch(configData.api_url +'C_Godigithealthpinmaster/delete', {
          method: 'POST', 
        mode: 'cors', 
        body: formData
      }).then(response => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.GodigithealthpinmasterfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
      <div className="row">
        <div className="title_left">
          <h2 style={{ textAlign: "start" }}>Godigit Health Pin Master</h2>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_content">
              <div
                id="datatable_wrapper"
                className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
              >
                <Row className="row">
            <Col
            className="d-flex justify-content-end"
            style={{ marginRight: "5px", marginTop: "20px" }}
          >
            <Button
              variant="primary"
              className="bi bi-plus "
              onClick={this.handleOffcanvasShow}
            >
              New
            </Button>
            <Offcanvas
              show={this.state.show}
              onHide={this.handleOffcanvasClose}
              {...this.props}
              style={{ width: "500px" }}
              placement="end"
              backdrop="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Godigithealthpinmaster page</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container style={{ "overflowY": "auto", height: "auto" }}>
                          <Row className="divstyle">
                              <Row>
                                <Form>
                            <br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>City</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlecityChange} className="form-control" name="city" value={this.state.city} required="required" placeholder="Enter city" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.city} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>State</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlestateChange} className="form-control" name="state" value={this.state.state} required="required" placeholder="Enter state" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.state} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>State Code</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlestateCodeChange} className="form-control" name="stateCode" value={this.state.stateCode} required="required" placeholder="Enter stateCode" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.stateCode} </div>
          </Row><br/><Row>
            <Col lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Effective Date</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="date" onChange={this.handleeffectiveDateChange} className="form-control" name="effectiveDate" value={this.state.effectiveDate} required="required" placeholder="Enter effectiveDate" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.effectiveDate} </div>
          </Row><br/><Row>
            <Col lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Expiry Date</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="date" onChange={this.handleexpiryDateChange} className="form-control" name="expiryDate" value={this.state.expiryDate} required="required" placeholder="Enter expiryDate" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.expiryDate} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Pincode</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlepincodeChange} className="form-control" name="pincode" value={this.state.pincode} required="required" placeholder="Enter pincode" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.pincode} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Zone</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlezoneChange} className="form-control" name="zone" value={this.state.zone} required="required" placeholder="Enter zone" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.zone} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Dummy One</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handledummyOneChange} className="form-control" name="dummyOne" value={this.state.dummyOne} required="required" placeholder="Enter dummyOne" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyOne} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Dummy Two</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handledummyTwoChange} className="form-control" name="dummyTwo" value={this.state.dummyTwo} required="required" placeholder="Enter dummyTwo" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyTwo} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Dummy Three</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handledummyThreeChange} className="form-control" name="dummyThree" value={this.state.dummyThree} required="required" placeholder="Enter dummyThree" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> 
            {this.state.errors.dummyThree} </div>
          </Row>
          <br/>
                  </Form>
                  </Row>
                          </Row>
                  <Row>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "50px" }}
                    >
                      <Button
                        className="button"
                        variant="success"
                        onClick={this.GodigithealthpinmastersubmitHandler}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
        {/*offcanvas is over */}
                  </Col> 
                  </Row>
        <br />
          <div>
                    {""}
                    {this.state.isEditing&&(
                      // edit offcanvas
                       <Offcanvas
                show={this.state.isEditing}
                onHide={() => this.setState({ isEditing: false })}
                {...this.props}
                style={{ width: "700px", height: "auto" }}
                placement="end"
                backdrop="true"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Edit Godigit Health Pin Master
</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Container
                   className="form-horizontal form-label-left input_mask"
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                              <Row className="divstyle">
                              <Row>
                                <Form>
                            <br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>City</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlecityChange} className="form-control" name="city" value={this.state.city} required="required" placeholder="Enter city" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.city} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>State</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlestateChange} className="form-control" name="state" value={this.state.state} required="required" placeholder="Enter state" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.state} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>State Code</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlestateCodeChange} className="form-control" name="stateCode" value={this.state.stateCode} required="required" placeholder="Enter stateCode" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.stateCode} </div>
          </Row><br/><Row>
            <Col lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Effective Date</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="date" onChange={this.handleeffectiveDateChange} className="form-control" name="effectiveDate" value={this.state.effectiveDate} required="required" placeholder="Enter effectiveDate" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.effectiveDate} </div>
          </Row><br/><Row>
            <Col lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Expiry Date</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="date" onChange={this.handleexpiryDateChange} className="form-control" name="expiryDate" value={this.state.expiryDate} required="required" placeholder="Enter expiryDate" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.expiryDate} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Pincode</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlepincodeChange} className="form-control" name="pincode" value={this.state.pincode} required="required" placeholder="Enter pincode" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.pincode} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Zone</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handlezoneChange} className="form-control" name="zone" value={this.state.zone} required="required" placeholder="Enter zone" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.zone} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Dummy One</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handledummyOneChange} className="form-control" name="dummyOne" value={this.state.dummyOne} required="required" placeholder="Enter dummyOne" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyOne} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Dummy Two</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handledummyTwoChange} className="form-control" name="dummyTwo" value={this.state.dummyTwo} required="required" placeholder="Enter dummyTwo" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyTwo} </div>
          </Row><br/><Row>
            <Col  lg="4" md="4">
            <Form.Label style={{ paddingTop: "8px" }}>Dummy Three</Form.Label>
            </Col>
            <Col lg="8" md="8">
            <Form.Control type="text" onChange={this.handledummyThreeChange} className="form-control" name="dummyThree" value={this.state.dummyThree} required="required" placeholder="Enter dummyThree" />
            </Col>  
            <div className="errorMsg" style={{ color: "red" }}> 
            {this.state.errors.dummyThree} </div>
          </Row>
          <br/>
                  </Form>
                  </Row>
                          </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.GodigithealthpinmasterupdateHandler
                                  }
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                </Offcanvas.Body>
              </Offcanvas>
                    )}
                  </div>
        <Row>
<div style={{"marginRight":"5px"}}>
  {/* searchbar,pagination,filter */}
  <input
    type="text"
    style={{ float: "left", marginBottom: "10px" }}
    onChange={this.handleFilter}
    placeholder="Search..."
  />
  <DataTable
    data={paginatedData}
    columns={this.state.tableClms}
    pagination
    paginationServer
    paginationTotalRows={
      this.state.filteredData
        ? this.state.filteredData.length
        : this.state.data.length
    }
    onChangeRowsPerPage={this.handlePerRowsChange}
    onChangePage={this.handlePageChange}
    paginationPerPage={this.state.itemsPerPage}
    keyField="id"
      paginationRowsPerPageOptions={[10, 20, 30, 50,60,70,80,90, 100]}
    className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
  />
</div>
  </Row>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
