import React, { Component } from "react";
import {
  Button,
  Container,
  Row,Form,
  Col,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from '../config.json';

export default class Healthplantype extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.healthplantype_Id,
          sortable: true,
          cell: (row) => <span>{row.healthplantype_Id}</span>,
        },
        {name:"product id",selector:(row, i) => row.productid,sortable:true,cell: (row) => <span>{row.productid}</span>,},{name:"plan ID",selector:(row, i) => row.planid,sortable:true,cell: (row) => <span>{row.planid}</span>,},{name:"plan type",selector:(row, i) => row.plantype,sortable:true,cell: (row) => <span>{row.plantype}</span>,},{name:"dummy one",selector:(row, i) => row.dummyone,sortable:true,cell: (row) => <span>{row.dummyone}</span>,},{name:"dummy two",selector:(row, i) => row.dummytwo,sortable:true,cell: (row) => <span>{row.dummytwo}</span>,},{name:"dummy three",selector:(row, i) => row.dummythree,sortable:true,cell: (row) => <span>{row.dummythree}</span>,},
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={this.handleOffcanvasShow}
                className="bi bi-pen" style={{"marginRight":"3px"}}
              >
                
              </Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              >
                
              </Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      productid:'',planid:'',plantype:'',dummyone:'',dummytwo:'',dummythree:'',
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  HealthplantypeeditHandler=(e)=>{
      e.preventDefault();
      // const updateFormData = {...editFormData};
      // updateFormData['Healthplantype_Id'] = e.target.parentElement.getAttribute("id");
      // setEditFormData(updateFormData);
      // setEdit(true);
  
       }
       
HealthplantypefetchHandler = () => {
  var formData = new FormData();
  formData.append("sId", this.state.sId);
 formData.append("uId", this.state.uId);
            fetch(configData.api_url +'C_Healthplantype/list', {  // Enter your IP address here
        
              method: 'POST', 
              //headers :{ 'Content-Type' : 'application/json'},
              mode: 'cors', 
              body: formData// body data type must match "Content-Type" header
            }).then(response => response.json())
            .then((data) => {
              if (data.status === "success") {
                this.setState({ data: data.list });
              }
            });
           }
           HealthplantypesubmitHandler=(e)=>{
            e.preventDefault();
              //   setButtons(contacts);
             var formData = new FormData();
             formData.append("sId", this.state.sId);
              formData.append("uId", this.state.uId);
             formData.append('productid',this.state.productid);
formData.append('planid',this.state.planid);
formData.append('plantype',this.state.plantype);
formData.append('dummyone',this.state.dummyone);
formData.append('dummytwo',this.state.dummytwo);
formData.append('dummythree',this.state.dummythree);

             fetch(configData.api_url + 'C_Healthplantype/add', {  // Enter your IP address here
          
               method: 'POST',
               //headers :{ 'Content-Type' : 'application/json'},
               mode: 'cors',
               body: formData// body data type must match "Content-Type" header
             }).then(response => response.json())
               .then((data) => {
                 this.showToast(data.message, data.status);
                 if (data.status === "success") {
                   this.HealthplantypefetchHandler();
                   this.handleOffcanvasClose();
                   this.setState({productid:'',planid:'',plantype:'',dummyone:'',dummytwo:'',dummythree:'',});
                }
              });
             }
   HealthplantypeupdateHandler=(e)=>{
        e.preventDefault();
     var formData = new FormData();
     formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
          formData.append('productid',this.state.productid);
formData.append('planid',this.state.planid);
formData.append('plantype',this.state.plantype);
formData.append('dummyone',this.state.dummyone);
formData.append('dummytwo',this.state.dummytwo);
formData.append('dummythree',this.state.dummythree);

      
          fetch(configData.api_url +'C_Healthplantype/update', {  
            method: 'POST', 
            mode: 'cors', 
            body: formData
          }).then(response => response.json())
            .then((data) => {
              this.showToast(data.message, data.status);
            if (data.status === "success") {
              this.HealthplantypefetchHandler();
              this.handleOffcanvasClose();
            }
          });
         }

  componentDidMount() {
    this.HealthplantypefetchHandler();
    
  }
  handleproductidChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({productid: fieldValue });
};handleplanidChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({planid: fieldValue });
};handleplantypeChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({plantype: fieldValue });
};handledummyoneChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyone: fieldValue });
};handledummytwoChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummytwo: fieldValue });
};handledummythreeChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummythree: fieldValue });
};
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
     // implement your edit logic here
  };
handleDelete = (row, index) => {
  var formData = new FormData();
    formData.append('healthplantype_Id', row.healthplantype_Id);
    fetch(configData.api_url +'C_Healthplantype/delete', {
          method: 'POST', 
        mode: 'cors', 
        body: formData
      }).then(response => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthplantypefetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
      <div className="row">
        <div className="title_left">
          <h2 style={{ textAlign: "start" }}>Health plan type</h2>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_content">
              <div
                id="datatable_wrapper"
                className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
              >
                <Row className="row">
            <Col
            className="d-flex justify-content-end"
            style={{ marginRight: "5px", marginTop: "20px" }}
          >
            <Button
              variant="primary"
              className="bi bi-plus "
              onClick={this.handleOffcanvasShow}
            >
              New
            </Button>
            <Offcanvas
              show={this.state.show}
              onHide={this.handleOffcanvasClose}
              {...this.props}
              style={{ width: "500px" }}
              placement="end"
              backdrop="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Healthplantype page</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container style={{ "overflowY": "auto", height: "auto" }}>
                <Row className="divstyle">
                    <Row>
                      <Form>
                  <Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>product id</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleproductidChange} className="form-control" name="productid" value={this.state.productid} required="required" placeholder="Enter productid" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.productid} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>plan ID</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleplanidChange} className="form-control" name="planid" value={this.state.planid} required="required" placeholder="Enter planid" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.planid} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>plan type</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleplantypeChange} className="form-control" name="plantype" value={this.state.plantype} required="required" placeholder="Enter plantype" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.plantype} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>dummy one</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyoneChange} className="form-control" name="dummyone" value={this.state.dummyone} required="required" placeholder="Enter dummyone" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyone} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>dummy two</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummytwoChange} className="form-control" name="dummytwo" value={this.state.dummytwo} required="required" placeholder="Enter dummytwo" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummytwo} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>dummy three</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummythreeChange} className="form-control" name="dummythree" value={this.state.dummythree} required="required" placeholder="Enter dummythree" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummythree} </div>
</Row><br/>
                  </Form>
                  </Row>
                  </Row>
                  <Row>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "50px" }}
                    >
                      <Button
                        className="button"
                        variant="success"
                        onClick={this.HealthplantypesubmitHandler}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
        {/*offcanvas is over */}
                  </Col> 
                  </Row>
        <br />
        <Row>
<div style={{"marginRight":"5px"}}>
  {/* searchbar,pagination,filter */}
  <input
    type="text"
    style={{ float: "left", marginBottom: "10px" }}
    onChange={this.handleFilter}
    placeholder="Search..."
  />
  <DataTable
    data={paginatedData}
    columns={this.state.tableClms}
    pagination
    paginationServer
    paginationTotalRows={
      this.state.filteredData
        ? this.state.filteredData.length
        : this.state.data.length
    }
    onChangeRowsPerPage={this.handlePerRowsChange}
    onChangePage={this.handlePageChange}
    paginationPerPage={this.state.itemsPerPage}
    keyField="id"
    className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
  />
</div>
  </Row>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
