import React from "react";
import { Button, Row, Form, Col, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import configData from "../config.json";

export default class MasterTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      isdefault: false,
      tableName: "",
      formName: "",
      iconText: "",
      isEditing: false,
      addFormData: {
        displayName: "",
        fieldName: "",
        inputType: "",
        dataType: "",
        number: "0",
        isdefault: false,
        isdefaultV: "",
        iseditable: false,
        isvisible: false,
        isunique: false,
        autoincrement: false,
        isunsigned: false,
        isnull: false,
        reftableName: "",
        columnName: "",
        currentStep: 1,
      },
      isAddButtonDisabled: false,
      errors: {},
      tables: [],
      columns: [],
    };
    this.handletypechange = this.handletypechange.bind(this);
    this.handlefaculty = this.handlefaculty.bind(this);
    this.showToast = this.showToast.bind(this);
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  componentDidMount() {
    this.getTables();
  }
  getTables = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "masters/gettables", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ tables: data.tables });
        }
      });
  };
  getColumns = (tableName, rcolumn) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", tableName);
    fetch(configData.api_url + "masters/getcolumns", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ [rcolumn]: data.columns });
        }
      });
  };
  handletypechange(event) {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = this.state.addFormData;
    newFormData[fieldName] = fieldValue;
    this.setState({ addFormData: newFormData });
    this.setState({ visibletype: event.target.value });
    const selectedValue = event.target.value;
    if (selectedValue === "Autofill") {
      this.setState({ visibletype: "Autofill" });
    } else if (selectedValue === "Select") {
      this.setState({ visibletype: "Select" });
    }

  }
  handlefaculty(event) {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormDatas = this.state.addFormData;
    newFormDatas[fieldName] = fieldValue;
    this.setState({ addFormData: newFormDatas });
    this.addFormData = `${fieldValue} `;
    this.setState({ addFormData: newFormDatas });
    this.getColumns(fieldValue, "columns");
    this.addFormData =
      this.state.addFormData.reftableName +
      "." +
      this.state.addFormData.columnName;
  }
  FormChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name").trim();
    const fieldValue = e.target.value;
    const newFormData = this.state.addFormData;
    newFormData[fieldName] = fieldValue;
    this.setState({ addFormData: newFormData });
  };
  HandleChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    let fieldValue = false;
    if (e.target.checked) {
      fieldValue = true;
    }
    const newFormData = this.state.addFormData;
    newFormData[fieldName] = fieldValue;
    this.setState({ addFormData: newFormData });
  };
  FormSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    if (this.validateForm()) {
      this.setState((prevState) => ({
        contacts: [...prevState.contacts, this.state.addFormData],
      }));
      this.setState({
        addFormData: {
          displayName: "",
          fieldName: "",
          inputType: "",
          dataType: "",
          number: "0",
          isdefault: false,
          isdefaultV: "",
          iseditable: false,
          isvisible: false,
          isunique: false,
          autoincrement: false,
          isunsigned: false,
          isnull: false,
          reftableName: "",
          columnName: "",
        },
      });
      this.setState({ isdefault: false });
      this.showToast("Row Added correctly. ", "Info");
    }
  };
  // validations for form
  validateForm() {
    const { currentStep, addFormData } = this.state;
    let formIsValid = true;
    // let contacts = this.state.addFormData;
    // let errors = {};

    if (!addFormData["displayName"]) {
      formIsValid = false;
      toast.info("*Please enter your display Name.");
    }

    // if (typeof contacts["displayName"] !== "undefined") {
    //   if (!contacts["displayName"].match(/^[a-zA-Z ]*$/)) {
    //     formIsValid = false;
    //     errors["displayName"] = "*Please enter alphabet characters only.";
    //   }
    // }
    else if (!addFormData["fieldName"]) {
      formIsValid = false;
      toast.info("*Please enter field Name.");
    }

    // if (typeof contacts["fieldName"] !== "undefined") {
    //   //regular expression for email validation
    //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+))|("[\w-\s]+")([\w-]+(?:\.[\w-]+)))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    //   if (!pattern.test(contacts["fieldName"])) {
    //     formIsValid = false;
    //     errors["fieldName"] = "*Please enter valid email-ID.";
    //   }
    // }
    else if (!addFormData["inputType"]) {
      formIsValid = false;
      toast.info("*Please select input field.");
    }

    // if (typeof contacts["inputType"] !== "undefined") {
    //   if (!contacts["inputType"].match(/^[0-9]{10}$/)) {
    //     formIsValid = false;
    //     errors["inputType"] = "*Please enter valid mobile no.";
    //   }
    // }
    else if (!addFormData["dataType"]) {
      formIsValid = false;
      toast.info("*Please select data Type.");
    }

    // if (typeof contacts["dataType"] !== "undefined") {
    //   if (!contacts["dataType"].match(/^.(?=.{8,})(?=.\d)(?=.[a-z])(?=.[A-Z])(?=.[@#$%&]).$/)) {
    //     formIsValid = false;
    //     errors["dataType"] = "*Please enter secure and strong password.";
    //   }
    // }
    else if (!addFormData["number"]) {
      formIsValid = false;
      toast.info("*Please enter length value.");
    }
    // if (!contacts["tableName"]) {
    //   formIsValid = false;
    //   errors["tableName"] = "*Please enter table Name.";
    // }
    else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }
    return formIsValid;
  }

  onKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      toast.error("Spaces are not allowed.");
    } else if (/[\s!@#$%^&*()_+\-=,.?":{}|<>/\\[\];""']/.test(event.key)) {
      event.preventDefault();
      toast.error("Special characters are not allowed.");
    } else if (/[\d]/.test(event.key)) {
      event.preventDefault();
      toast.error("Numbers are not allowed.");
    }
  };


  onFirstNamePaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("Text");
    const value = text.split(" ").join("");
    event.target.value = value;
  }

  submitButton = (e) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", this.state.tableName);
    formData.append("formName", this.state.formName);
    formData.append("iconText", this.state.iconText);
    formData.append("fields", JSON.stringify(this.state.contacts));

    fetch(configData.api_url + "masters/add", {
      // Enter your IP address here
      method: "POST",
      //   headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ isdefault: false });
        }
      });
  };

  Changehandle = (e) => {
    e.preventDefault();
    var textvalue = e.target.value;
    this.setState({ tableName: textvalue });
  };
  FormChangehandle = (e) => {
    e.preventDefault();
    var textvalue = e.target.value;
    this.setState({ formName: textvalue });
  };
  IconChangehandle = (e) => {
    e.preventDefault();
    var textvalue = e.target.value;
    this.setState({ iconText: textvalue });
  };
  setChecked = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    let fieldValue = false;
    if (e.target.checked) {
      fieldValue = true;
    }
    const newFormData = this.state.addFormData;
    newFormData[fieldName] = fieldValue;
    this.setState({ addFormData: newFormData });
    this.setState({ isdefault: e.target.checked });
  };

  handleRemoveSpecificRow = (idx) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const { contacts } = this.state;
    const tempRows = [...contacts];
    tempRows.splice(idx, 1);
    this.setState({ contacts: tempRows });
  };
  handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  handleEditRow = (index) => {
    const updatedTableData = this.state.contacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, isEdit: true };
      } else {
        return { ...contact, isEdit: false }; // Ensure other rows are not in edit mode
      }
    });
    const selectedRow = this.state.contacts[index];
    this.setState({
      addFormData: { ...selectedRow, isEdit: true },
      contacts: updatedTableData,
      isEditing: true,
      isAddButtonDisabled: false,
    });
    this.setState({ isAddButtonDisabled: true });
  };
  handleUpdateRow = (index) => {
    const updatedContacts = [...this.state.contacts];
    const updatedRow = updatedContacts[index];
    const updatedTableData = this.state.contacts.map((contact, i) => {
      if (i === index) {
        return { ...contact, isEdit: true };
      } else {
        return { ...contact, isEdit: false };
      }
    });
    this.setState({ isAddButtonDisabled: false });
    this.setState({
      contacts: updatedTableData,
      isEditing: false,
      isAddButtonDisabled: false,
    });

    if (updatedRow.isEdit) {
      // Update the row data
      updatedContacts[index] = {
        ...this.state.addFormData,
        isEdit: false,
      };

      this.setState({
        contacts: updatedContacts,
        addFormData: {
          // Clear the form fields
          displayName: "",
          fieldName: "",
          inputType: "",
          dataType: "",
          number: "0",
          isdefault: false,
          isdefaultV: "",
          iseditable: false,
          isvisible: false,
          isunique: false,
          autoincrement: false,
          isunsigned: false,
          isnull: false,
          reftableName: "",
          columnName: "",
          ...this.state.addFormData,
          isEdit: false,
        },
      });
      this.setState({
        addFormData: {
          displayName: "",
          fieldName: "",
          inputType: "",
          dataType: "",
          number: "0",
          isdefault: false,
          isdefaultV: "",
          iseditable: false,
          isvisible: false,
          isunique: false,
          autoincrement: false,
          isunsigned: false,
          isnull: false,
          reftableName: "",
          columnName: "",
        },
      });
    }
  };

  render() {
    const DefaultData = this.state.addFormData;
    const TableData = this.state.contacts;
    const tbleLength = TableData.length;

    return (
      <div
        className="container-fluid"
      // style={{overflow:"hidden"}}
      >
        <section className="display">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12">
              {" "}
              <h2 style={{ padding: "10px", textAlign: "start" }}>
                Table Creator
              </h2>
            </div>
          </div>
          <div>
            <Form noValidate>
              <div
              //  className="text-center "
              >
                <div className="row p-1">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Table Name: </span>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    {/* copy -paste aslo not entered  */}
                    <input
                      type={"text"}
                      onKeyDown={this.onKeyDown}
                      onPaste={(event) => this.onFirstNamePaste(event)}

                      value={this.state.tableName}
                      onChange={this.Changehandle}
                      className="form-control"
                      name="tableName"
                      required="required"
                      placeholder="Enter Table Name"
                    />
                  </div>
                  <div className="errorMsg" style={{ color: "red" }}>
                    {this.state.errors.tableName}
                  </div>
                </div>
                <br />
                <div className="row p-1">
                  <div
                    className="col-md-3 col-lg-3"
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Form Name: </span>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <input
                      type={"text"}
                      value={this.state.formName}
                      onChange={this.FormChangehandle}
                      className="form-control"
                      name="formName"
                      required="required"
                      placeholder="Enter Form Name"
                    />
                  </div>
                  <div className="errorMsg" style={{ color: "red" }}>
                    {this.state.errors.formName}
                  </div>
                </div>
                <br />
                <div className="row p-1">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Icon Text: </span>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <input
                      type={"text"}
                      value={this.state.iconText}
                      onChange={this.IconChangehandle}
                      className="form-control"
                      name="iconText"
                      required="required"
                      placeholder="Enter Icon Text"
                    />
                  </div>
                  <div className="errorMsg" style={{ color: "red" }}>
                    {this.state.errors.iconText}
                  </div>
                </div>
                <br />
                <hr />
                <div className="row ">
                  <div
                    className="col-md-3 col-lg-3"
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Field Name:</span>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <input
                      type={"text"}
                      onKeyDown={this.onKeyDown}
                      onPaste={(event) => this.onFirstNamePaste(event)}
                      onChange={this.FormChange}
                      value={DefaultData.fieldName}
                      className="form-control"
                      name="fieldName"
                      required="required"
                      placeholder="Enter Field Name"
                    />
                  </div>
                  <div className="errorMsg" style={{ color: "red" }}>
                    {this.state.errors.fieldName}
                  </div>
                </div>
                <br />
                <div className="row p-1">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Display Name:</span>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <input
                      type={"text"}
                      value={DefaultData.displayName}
                      onChange={this.FormChange}
                      className="form-control"
                      name="displayName"
                      required="required"
                      placeholder="Enter Display Name"
                    />
                  </div>
                  <div className="errorMsg" style={{ color: "red" }}>
                    {this.state.errors.displayName}
                  </div>
                </div>
                <br />

                <div className="row">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Input Type:</span>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <Row>
                      <Col>
                        <select
                          value={DefaultData.inputType}
                          name="inputType"
                          required="required"
                          className="form-select"
                          aria-label="Default Select Example"
                          onChange={this.handletypechange}
                        >
                          <option>Select Item</option>
                          <option>Text</option>
                          <option>Telephone</option>
                          <option>Number</option>
                          <option>Password</option>
                          <option>File</option>
                          <option>Mail</option>
                          <option>Date</option>
                          <option>Checkbox</option>
                          <option value="Select">Select</option>
                          <option value="Autofill">Autofill</option>
                        </select>
                        <Row className="d-flex">
                          <Col>
                            {this.state.visibletype === "Select" && (
                              <select
                                style={{ width: "130px", marginTop: "20px" }}
                                className="form-select"
                                value={DefaultData.reftableName}
                                name="reftableName"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.handlefaculty}
                              >
                                <option defaultValue hidden>
                                  Select Table Name
                                </option>
                                {this.state.tables.map((api, index, idx) => (
                                  <option key={index} value={api}>
                                    {api}
                                  </option>
                                ))}
                              </select>
                            )}
                          </Col>
                          <Col>
                            {this.state.visibletype === "Select" && (
                              <select
                                style={{ width: "140px", marginTop: "20px" }}
                                className="form-select"
                                value={DefaultData.columnName}
                                name="columnName"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.handlefaculty}
                              >
                                <option defaultValue>Select Column</option>
                                {this.state.columns.map((api, index, idx) => (
                                  <option key={index} value={api}>
                                    {api}
                                  </option>
                                ))}
                              </select>
                            )}
                          </Col>
                          <Col>
                            {this.state.visibletype === "Select" && (
                              <Form.Control
                                type="text"
                                value={this.addFormData}
                                required="required"
                                style={{
                                  width: "160px",
                                  marginTop: "20px",
                                  height: "38px",
                                }}
                                placeholder=""
                              ></Form.Control>
                            )}
                          </Col>
                        </Row>
                        <Row className="d-flex">
                          <Col>
                            {this.state.visibletype === "Autofill" && (
                              <select
                                style={{ width: "130px", marginTop: "20px" }}
                                className="form-select"
                                value={DefaultData.reftableName}
                                name="reftableName"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.handlefaculty}
                              >
                                <option defaultValue hidden>
                                  Select Table Name
                                </option>
                                {this.state.tables.map((api, index, idx) => (
                                  <option key={index} value={api}>
                                    {api}
                                  </option>
                                ))}
                              </select>
                            )}
                          </Col>
                          <Col>
                            {this.state.visibletype === "Autofill" && (
                              <select
                                style={{ width: "140px", marginTop: "20px" }}
                                className="form-select"
                                value={DefaultData.columnName}
                                name="columnName"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.handlefaculty}
                              >
                                <option defaultValue>Select Column</option>
                                {this.state.columns.map((api, index, idx) => (
                                  <option key={index} value={api}>
                                    {api}
                                  </option>
                                ))}
                              </select>
                            )}
                          </Col>
                          <Col>
                            {this.state.visibletype === "Autofill" && (
                              <Form.Control
                                type="text"
                                value={this.addFormData}
                                required="required"
                                style={{
                                  width: "160px",
                                  marginTop: "20px",
                                  height: "38px",
                                }}
                                placeholder=""
                              ></Form.Control>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="errorMsg" style={{ color: "red" }}>
                    {this.state.errors.inputType}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Data Type:</span>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <select
                      className="form-select"
                      name="dataType"
                      required="required"
                      value={DefaultData.dataType}
                      onChange={this.FormChange}
                    >
                      <option>Choose here</option>
                      <option>INT</option>
                      <option>VARCHAR</option>
                      <option>TEXT</option>
                      <option>DATE</option>
                      <option>Boolean</option>
                    </select>
                  </div>
                  <div className="errorMsg" style={{ color: "red" }}>
                    {this.state.errors.dataType}
                  </div>
                </div>
                <br />
                <div className="row ">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Length: </span>
                  </div>
                  <div className="col-md-9 col-lg-9">
                    <input
                      type={"number"}
                      onChange={this.FormChange}
                      value={DefaultData.number}
                      className="form-control"
                      name="number"
                      min="1"
                      max="7000000"
                      required="required"
                      placeholder="Enter a length value"
                    />
                  </div>
                  <div className="errorMsg" style={{ color: "red" }}>
                    {this.state.errors.number}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Is default:</span>
                  </div>
                  <div className="col-md-9 col-lg-9 text-center">
                    <input
                      type={"checkbox"}
                      name="isdefault"
                      required="required"
                      onChange={this.setChecked}
                      checked={DefaultData.isdefault}
                    />
                    {this.state.isdefault ? (
                      ((<span>Default</span>),
                        (
                          <input
                            name="isdefault"
                            required="required"
                            onChange={this.FormChange}
                            type="text"
                            className="form-control"
                          />
                        ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Is Editable:</span>
                  </div>
                  <div className="col-md-9 col-lg-9 text-center">
                    <input
                      type={"checkbox"}
                      name="iseditable"
                      required="required"
                      onChange={this.HandleChange}
                      checked={DefaultData.iseditable}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-3 col-lg-3 ">
                    <span>Is Visible:</span>
                  </div>
                  <div className="col-md-9 col-lg-9 text-center">
                    <input
                      type={"checkbox"}
                      name="isvisible"
                      required="required"
                      onChange={this.HandleChange}
                      checked={DefaultData.isvisible}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Is Unique:</span>
                  </div>
                  <div className="col-md-9 col-lg-9 text-center">
                    <input
                      type={"checkbox"}
                      name="isunique"
                      required="required"
                      onChange={this.HandleChange}
                      checked={DefaultData.isunique}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Auto_increment:</span>
                  </div>
                  <div className="col-md-9 col-lg-9 text-center">
                    <input
                      type={"checkbox"}
                      name="autoincrement"
                      required="required"
                      onChange={this.HandleChange}
                      checked={DefaultData.autoincrement}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div
                    className="col-md-3 col-lg-3 "
                    style={{ paddingTop: "8px" }}
                  >
                    <span>Is_Unsigned:</span>
                  </div>
                  <div className="col-md-9 col-lg-9 text-center">
                    <input
                      type={"checkbox"}
                      name="isunsigned"
                      required="required"
                      onChange={this.HandleChange}
                      checked={DefaultData.isunsigned}
                    />
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-12">
                    <Button
                      style={{ marginBottom: "5px", float: "right" }}
                      className="btn btn-success "
                      onClick={this.FormSubmit}
                      disabled={this.state.isAddButtonDisabled}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </section>
        <section>
          <Table
            className="table table-bordered table-hover"
            style={{ marginLeft: "0px" }}
          >
            <thead>
              <tr>
                <th>Display Name</th>
                <th>Field Name</th>
                <th>Input Type</th>
                <th>Data Type</th>
                <th>Length</th>
                <th>Is default</th>
                <th>Is Editable</th>
                <th>Is Visible</th>
                <th>Is Unique</th>
                <th>Auto Increment</th>
                <th>Un_Signed</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tbleLength > 0 ? (
                TableData.map((contact, index, idx) => (
                  <tr key={index}>
                    <td>{contact.displayName}</td>
                    <td>{contact.fieldName}</td>
                    <td>{contact.inputType}</td>
                    <td>{contact.dataType}</td>
                    <td>{contact.number}</td>
                    <td>{String(contact.isdefault)}</td>
                    <td>{String(contact.iseditable)}</td>
                    <td>{String(contact.isvisible)}</td>
                    <td>{String(contact.isunique)}</td>
                    <td>{String(contact.autoincrement)}</td>
                    <td>{String(contact.isunsigned)}</td>
                    <td>
                      {contact.isEdit ? (
                        <div>
                          <Button
                            style={{ marginBottom: "5px", float: "right" }}
                            className="btn btn-primary"
                            onClick={() => this.handleUpdateRow(index)}
                          >
                            Update
                          </Button>
                          <Button
                            style={{ marginBottom: "5px", float: "right" }}
                            className="btn btn-primary"
                            onClick={() => this.handleEditRow(index)}
                          >
                            Edit
                          </Button>
                        </div>
                      ) : (
                        <Button
                          style={{ marginBottom: "5px", float: "right" }}
                          className="btn btn-primary"
                          onClick={() => this.handleEditRow(index)}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        variant="danger"
                        onClick={() => this.handleRemoveSpecificRow(index)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12"> No Records</td>
                </tr>
              )}
            </tbody>
          </Table>

          <div>
            <Button
              className="btn btn-success "
              style={{ float: "right" }}
              value={this.state.calling}
              onClick={this.submitButton}
            >
              Submit
            </Button>
          </div>
        </section>
      </div>
    );
  }
}
