import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Form,
  Col,
  Offcanvas,
  Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../../config.json";
import DatePicker from "react-datepicker";

export default class CodeDeployVerfication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      codedeployment_Id: "",
      filteredData: null,
      isEditing: false,
      verifyremarks: "",
      codeverifiedby: "",
      bugreoccured: "",
      requeststatus: "",
      codedeploymentdate: "",
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.codedeployment_Id,
          sortable: true,
          cell: (row) => <span>{row.codedeployment_Id}</span>,
        },
        {
          name: "Project Name",
          selector: (row, i) => row.projectname,
          sortable: true,
          cell: (row) => <span>{row.projectname}</span>,
        },
        {
          name: "Environment",
          selector: (row, i) => row.environment,
          sortable: true,
          cell: (row) => <span>{row.environment}</span>,
        },
        {
          name: "Requested By",
          selector: (row, i) => row.requestedby,
          sortable: true,
          cell: (row) => <span>{row.requestedby}</span>,
        },
        {
          name: "Request Date",
          selector: (row, i) => row.requestdate,
          sortable: true,
          cell: (row) => <span>{row.requestdate}</span>,
        },
        {
          name: "Request Type",
          selector: (row, i) => row.requesttype,
          sortable: true,
          cell: (row) => <span>{row.requesttype}</span>,
        },
        {
          name: "Files Details",
          selector: (row, i) => row.filedetails,
          sortable: true,
          cell: (row) => <span>{row.filedetails}</span>,
        },

        {
          name: "Issues Resolved",
          selector: (row, i) => row.issuesresolved,
          sortable: true,
          cell: (row) => <span>{row.issuesresolved}</span>,
        },
        {
          name: "Effort Time",
          selector: (row, i) => row.efforttime,
          sortable: true,
          cell: (row) => <span>{row.efforttime}</span>,
        },
        {
          name: "Unit Testing Done By",
          selector: (row, i) => row.unittestingdoneby,
          sortable: true,
          cell: (row) => <span>{row.unittestingdoneby}</span>,
        },
        {
          name: "Code Commitment Date",
          selector: (row, i) => row.codecommitmentdate,
          sortable: true,
          cell: (row) => <span>{row.codecommitmentdate}</span>,
        },
        {
          name: "Last Git Pull Date",
          selector: (row, i) => row.lastgitpulldate,
          sortable: true,
          cell: (row) => <span>{row.lastgitpulldate}</span>,
        },
        {
          name: "Last Git Commit Date",
          selector: (row, i) => row.lastgitcommitdate,
          sortable: true,
          cell: (row) => <span>{row.lastgitcommitdate}</span>,
        },
        {
          name: "Remarks",
          selector: (row, i) => row.remarks,
          sortable: true,
          cell: (row) => <span>{row.remarks}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-eye"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      projectname: "",
      environment: "",
      requestedby: "",
      requestdate: "",
      requesttype: "",
      filedetails: "",
      changesdoneby: "",
      issuesresolved: "",
      efforttime: "",
      unittestingdoneby: "",
      codecommitmentdate: "",
      lastgitpulldate: "",
      lastgitcommitdate: "",
      testcase: "",
      remarks: "",
    };
  }
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var lastgitcommitdate = "";
    if (row.lastgitcommitdate !== "0000-00-00")
      lastgitcommitdate = row.lastgitcommitdate;
    var lastgitpulldate = "";
    if (row.lastgitpulldate !== "0000-00-00")
      lastgitpulldate = row.lastgitpulldate;
    var codecommitmentdate = "";
    var codedeploymentdate = "";
    if (row.codecommitmentdate !== "0000-00-00")
      codecommitmentdate = row.codecommitmentdate;
    if (row.codedeploymentdate !== "0000-00-00")
      codedeploymentdate = row.codedeploymentdate;
    this.setState({
      codedeployment_Id: row.codedeployment_Id,
      projectname: row.projectname,
      environment: row.environment,
      requestedby: row.requestedby,
      requestdate: row.requestdate,
      filedetails: row.filedetails,
      issuesresolved: row.issuesresolved,
      efforttime: row.efforttime,
      unittestingdoneby: row.unittestingdoneby,
      codecommitmentdate: codecommitmentdate,
      lastgitpulldate: lastgitpulldate,
      lastgitcommitdate: lastgitcommitdate,
      remarks: row.remarks,
      codedeploymentdate: codedeploymentdate,
      codeverifiedby: row.codeverifiedby,
      bugreoccured: row.bugreoccured,
      requeststatus: row.requeststatus,
      verifyremarks: row.verifyremarks,
      isEditing: true,
    });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  CodedeploymenteditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Codedeployment_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  CodedeploymentfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Codedeployment/list", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  CodedeploymentUpdateHandler = (e, status) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("status", status);
    formData.append("codedeploymentdate", this.state.codedeploymentdate);
    formData.append("codeverifiedby", this.state.codeverifiedby);
    formData.append("bugreoccured", this.state.bugreoccured);
    formData.append("requeststatus", this.state.requeststatus);
    formData.append("verifyremarks", this.state.verifyremarks);
    formData.append("codedeployment_Id", this.state.codedeployment_Id);
    fetch(configData.api_url + "C_Codedeployment/update", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CodedeploymentfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            verifyremarks: "",
            codeverifiedby: "",
            bugreoccured: "",
            requeststatus: "",
            codedeploymentdate: "",
          });
        }
      });
  };
  handleReject = (e, status) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("status", status);
    formData.append("codedeploymentdate", this.state.codedeploymentdate);
    formData.append("codeverifiedby", this.state.codeverifiedby);
    formData.append("bugreoccured", this.state.bugreoccured);
    formData.append("requeststatus", this.state.requeststatus);
    formData.append("verifyremarks", this.state.verifyremarks);
    formData.append("codedeployment_Id", this.state.codedeployment_Id);
    fetch(configData.api_url + "C_Codedeployment/update", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CodedeploymentfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            verifyremarks: "",
            codeverifiedby: "",
            bugreoccured: "",
            requeststatus: "",
            codedeploymentdate: "",
          });
        }
      });
  };

  componentDidMount() {
    this.CodedeploymentfetchHandler();
  }
  handleprojectnameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ projectname: fieldValue });
  };
  handlecodeverifiedbyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ codeverifiedby: fieldValue });
  };

  handleenvironmentChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ environment: fieldValue });
  };
  handlebugreoccuredChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ bugreoccured: fieldValue });
  };
  handleverifyremarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ verifyremarks: fieldValue });
  };

  handlerequeststatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ requeststatus: fieldValue });
  };
  handlerequestedbyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ requestedby: fieldValue });
  };
  handlerequestdateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        requestdate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handlecodedeploymentdateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        codedeploymentdate: formattedDate,
        startDate: new Date(),
      });
    }
  };

  handlerequesttypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ requesttype: fieldValue });
  };
  handlefiledetailsChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ filedetails: fieldValue });
  };
  handlechangesdonebyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ changesdoneby: fieldValue });
  };
  handleissuesresolvedChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ issuesresolved: fieldValue });
  };
  handleefforttimeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ efforttime: fieldValue });
  };
  handleunittestingdonebyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ unittestingdoneby: fieldValue });
  };
  handlecodecommitmentdateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        codecommitmentdate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handlelastgitpulldateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        lastgitpulldate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handlelastgitcommitdateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        lastgitcommitdate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handletestcaseChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ testcase: fieldValue });
  };
  handleremarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ remarks: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };


  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("codedeployment_Id", row.codedeployment_Id);
    fetch(configData.api_url + "C_Codedeployment/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CodedeploymentfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <div className="title_left">
                      <h3
                        className="logoskybluecolor"
                        style={{ textAlign: "start" }}
                      >
                        Code Verification
                      </h3>
                    </div>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      {this.state.isEditing && (
                        <Offcanvas
                          show={this.state.isEditing}
                          onHide={() => this.setState({ isEditing: false })}
                          {...this.props}
                          {...this.props}
                          style={{ width: "500px" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Code Verfication</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container
                              style={{ overflowY: "auto", height: "auto" }}
                            >
                              <Row className="divstyle">
                                <Card>
                                  <Row>
                                    <Form>
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Project Name
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Select
                                            name="projectname"
                                            value={this.state.projectname}
                                            onChange={
                                              this.handleprojectnameChange
                                            }
                                            aria-label="Default select example"
                                            className="form-control"
                                            id="projectname"
                                          >
                                            <option selected>
                                              Select Project Type
                                            </option>
                                            <option value="apwebsite">
                                              Ap-Website
                                            </option>
                                            <option value="playright">
                                              Playright
                                            </option>
                                            <option value="cattleapp">
                                              Cattle App
                                            </option>
                                          </Form.Select>
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.projectname}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Environment
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Select
                                            name="environment"
                                            value={this.state.environment}
                                            onChange={
                                              this.handleenvironmentChange
                                            }
                                            aria-label="Default select example"
                                            className="form-control"
                                            id="environment"
                                          >
                                            <option selected>
                                              Select Environment Type
                                            </option>
                                            <option value="dev">
                                              Development
                                            </option>
                                            <option value="production">
                                              Production
                                            </option>
                                          </Form.Select>
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.environment}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Requested By
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            type="text"
                                            onChange={
                                              this.handlerequestedbyChange
                                            }
                                            className="form-control"
                                            name="requestedby"
                                            value={this.state.requestedby}
                                            required="required"
                                            placeholder="Enter requestedby"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.requestedby}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Request Date
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <DatePicker
                                            selected={this.state.requestdate}
                                            dropdownMode="select"
                                            onChange={(date) =>
                                              this.handlerequestdateChange(date)
                                            }
                                            className="form-control"
                                            name="requestdate"
                                            id="requestdate"
                                            showMonthDropdown
                                            showYearDropdown
                                            dateFormat="dd/MM/yyyy"
                                            required
                                            placeholderText="DD/MM/YYYY"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.requestdate}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Request Type
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Select
                                            name="requesttype"
                                            value={this.state.requesttype}
                                            onChange={
                                              this.handlerequesttypeChange
                                            }
                                            aria-label="Default select example"
                                            className="form-control"
                                            id="requesttype"
                                          >
                                            <option selected>
                                              Select Request Type
                                            </option>
                                            <option value="bugfixing">
                                              Bug-fixing
                                            </option>
                                            <option value="newfinctionality">
                                              New Finctionality
                                            </option>
                                            <option value="changerequest">
                                              Change Request
                                            </option>
                                          </Form.Select>
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.requesttype}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Files Details
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            onChange={
                                              this.handlefiledetailsChange
                                            }
                                            className="form-control"
                                            name="filedetails"
                                            value={this.state.filedetails}
                                            required="required"
                                            placeholder="Enter filedetails"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.filedetails}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Changes Done By
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            type="text"
                                            onChange={
                                              this.handlechangesdonebyChange
                                            }
                                            className="form-control"
                                            name="changesdoneby"
                                            value={this.state.changesdoneby}
                                            required="required"
                                            placeholder="Enter changesdoneby"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.changesdoneby}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Issues Resolved
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            onChange={
                                              this.handleissuesresolvedChange
                                            }
                                            className="form-control"
                                            name="issuesresolved"
                                            value={this.state.issuesresolved}
                                            required="required"
                                            placeholder="Enter issuesresolved"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {
                                            this.state.errors.issuesresolved
                                          }{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Effort Time
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            type="time"
                                            onChange={
                                              this.handleefforttimeChange
                                            }
                                            className="form-control"
                                            name="efforttime"
                                            value={this.state.efforttime}
                                            required="required"
                                            placeholder="Enter efforttime"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.efforttime}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Unit Testing Done By
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            type="text"
                                            onChange={
                                              this.handleunittestingdonebyChange
                                            }
                                            className="form-control"
                                            name="unittestingdoneby"
                                            value={this.state.unittestingdoneby}
                                            required="required"
                                            placeholder="Enter unittestingdoneby"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {
                                            this.state.errors.unittestingdoneby
                                          }{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Code Commitment Date
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <DatePicker
                                            selected={
                                              this.state.codecommitmentdate
                                            }
                                            dropdownMode="select"
                                            onChange={(date) =>
                                              this.handlecodecommitmentdateChange(
                                                date
                                              )
                                            }
                                            className="form-control"
                                            name="codecommitmentdate"
                                            id="codecommitmentdate"
                                            showMonthDropdown
                                            showYearDropdown
                                            dateFormat="dd/MM/yyyy"
                                            required
                                            placeholderText="DD/MM/YYYY"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {
                                            this.state.errors.codecommitmentdate
                                          }{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Last Git Pull Date
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <DatePicker
                                            selected={
                                              this.state.lastgitpulldate
                                            }
                                            dropdownMode="select"
                                            onChange={(date) =>
                                              this.handlelastgitpulldateChange(
                                                date
                                              )
                                            }
                                            className="form-control"
                                            name="lastgitpulldate"
                                            id="lastgitpulldate"
                                            showMonthDropdown
                                            showYearDropdown
                                            dateFormat="dd/MM/yyyy"
                                            required
                                            placeholderText="DD/MM/YYYY"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {
                                            this.state.errors.lastgitpulldate
                                          }{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Last Git Commit Date
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <DatePicker
                                            selected={
                                              this.state.lastgitcommitdate
                                            }
                                            dropdownMode="select"
                                            onChange={(date) =>
                                              this.handlelastgitcommitdateChange(
                                                date
                                              )
                                            }
                                            className="form-control"
                                            name="lastgitcommitdate"
                                            id="lastgitcommitdate"
                                            showMonthDropdown
                                            showYearDropdown
                                            dateFormat="dd/MM/yyyy"
                                            required
                                            placeholderText="DD/MM/YYYY"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {
                                            this.state.errors.lastgitcommitdate
                                          }{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Test Cases
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            as="textarea"
                                            rows={5}
                                            onChange={this.handletestcaseChange}
                                            className="form-control"
                                            name="testcase"
                                            value={this.state.testcase}
                                            required="required"
                                            placeholder="Enter testcase"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.testcase}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            Remarks
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            onChange={this.handleremarksChange}
                                            className="form-control"
                                            name="remarks"
                                            value={this.state.remarks}
                                            required="required"
                                            placeholder="Enter remarks"
                                          />
                                        </Col>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          {this.state.errors.remarks}{" "}
                                        </div>
                                      </Row>
                                      <br />
                                    </Form>
                                  </Row>
                                </Card>

                                <Card className="mt-2">
                                  <br />
                                  <Form>
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Request Date
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <DatePicker
                                          selected={
                                            this.state.codedeploymentdate
                                          }
                                          dropdownMode="select"
                                          onChange={(date) =>
                                            this.handlecodedeploymentdateChange(
                                              date
                                            )
                                          }
                                          className="form-control"
                                          name="codedeploymentdate"
                                          id="codedeploymentdate"
                                          showMonthDropdown
                                          showYearDropdown
                                          dateFormat="dd/MM/yyyy"
                                          required
                                          placeholderText="DD/MM/YYYY"
                                        />
                                      </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Code Verifiedby
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          name="codeverifiedby"
                                          value={this.state.codeverifiedby}
                                          onChange={
                                            this.handlecodeverifiedbyChange
                                          }
                                          aria-label="Default select example"
                                          className="form-control"
                                          id="codeverifiedby"
                                          placeholder="Code Verified Person"
                                        />
                                      </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Bug Reoccured
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          name="bugreoccured"
                                          value={this.state.bugreoccured}
                                          onChange={
                                            this.handlebugreoccuredChange
                                          }
                                          aria-label="Default select example"
                                          className="form-control"
                                          id="bugreoccured"
                                          placeholder="Bug Reoccured"
                                        />
                                      </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Request Status
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Select
                                          aria-label="Default select example"
                                          onChange={
                                            this.handlerequeststatusChange
                                          }
                                          className="form-control"
                                          name="requeststatus"
                                          value={this.state.requeststatus}
                                        >
                                          <option selected>
                                            Select Remark Status
                                          </option>
                                          <option value="codedeployed">
                                            Code Deployed
                                          </option>
                                          <option value="pending">
                                            Pending
                                          </option>
                                          <option value="bug">
                                            Found a Bug
                                          </option>
                                        </Form.Select>
                                      </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Verify Remarks
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          name="verifyremarks"
                                          value={this.state.verifyremarks}
                                          onChange={
                                            this.handleverifyremarksChange
                                          }
                                          aria-label="Default select example"
                                          className="form-control"
                                          id="verifyremarks"
                                          placeholder="Verify Remarks"
                                        />
                                      </Col>
                                    </Row>
                                    <br />
                                  </Form>
                                </Card>
                              </Row>
                              <Row>
                                {" "}
                                <Col
                                  className="d-flex justify-content-start"
                                  style={{
                                    marginRight: "5px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Button
                                    variant="danger"
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "20px",
                                    }}
                                    disabled={this.state.isButtonDisabled}
                                    type="submit"
                                    onClick={(e) => this.handleReject(e, "2")}
                                  >
                                    Reject
                                  </Button>
                                </Col>
                                <Col
                                  className="d-flex justify-content-end"
                                  style={{
                                    marginRight: "5px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <Button
                                    className="button"
                                    variant="success"
                                    onClick={(e) =>
                                      this.CodedeploymentUpdateHandler(e, "3")
                                    }
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                      )}
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
