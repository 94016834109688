import React, { Component } from 'react';
  import { Nav, Tab, Button, Form, Row, Col, Card, Table,  Image} from 'react-bootstrap';
 import configData from '../config.json'
  import "./Bulk.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

  export default class BulkQuoteFileupload extends Component {
    constructor(props) {
      super(props);
      this.state = {
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      bqId: sessionStorage.getItem("bqId"),
      activeTab: 'tab1',
      data: [],
      images:[],
      filePath:"",
      productname:"",
      csvFiles: {},  
      baseproductId:"",
      isDownloadVisible: true,
       requestedDate: new Date().toISOString().split('T')[0],
      
      };
    }
     showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

   handleSubmit = () => {
      this.setState({ isButtonDisabled: true });
    const { filePath,baseproductId } = this.state;

      const formData = new FormData();
       formData.append('filePath',filePath);
       formData.append("sId", this.state.sId);
       formData.append("requestedDate",this.state.requestedDate);
       formData.append("insurerName" ,this.state.productname);
        formData.append("bqId", this.state.bqId);
        formData.append("baseproductId",baseproductId);
        // Send the current date

      fetch(configData.api_url +'/bulkquotes/addrequest', {
        method: 'POST',
        body: formData,
      }).then((response)=>response.json())
      .then((data) =>{
        this.showToast(data.message,data.status)
        if(data.status === "success"){
          this.setState({
            filePath:""

          })
         window.location.href = "/bqdetails";
        }
      })
    
  };



  handleTabChange = (key, baseproductId,productname) => {
    // Set active tab and update baseproductId based on clicked tab
    this.setState({ 
      activeTab: key,
      baseproductId: baseproductId,
      productname:productname
    });
  };
   
handleCSVUpload =(e)=>{
    const files = e.target.files[0];
 this.setState({ filePath: files });
}
    
  handleCSVDownload = () => {
    const { baseproductId } = this.state;
    const csvContent = `${configData.api_url}uploads/bulkquotes/${baseproductId}/bulkquote-caresupreme.xlsx`;

    // Trigger file download
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `bulkquote-${baseproductId}.xlsx`);  // Custom file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
   this.setState({ isDownloadVisible: false });
  };

     componentDidMount() {
    this.BulkquoteslistfetchHandler();
  }
  BulkquoteslistfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "bulkquotes/getInsurers", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
    render() {
      const { activeTab, csvFiles,isDownloadVisible ,showSpinner  } = this.state;
    
      return (
          <div className="tab-component-container">
              <h3  className="logoskybluecolor" style={{marginLeft:"10px"}}>
            Bulk Quotes
            </h3>

        <div className='summaryborders' style={{padding:"10px"}}>

      <Nav
          variant="tabs"
          activeKey={activeTab}
          // onSelect={(key) => this.handleTabChange(key)}
                      onSelect={(key) => 
                        this.handleTabChange(key, this.state.data[key]?.baseproductId,
                          `${this.state.data[key]?.productname}`)}

          className="custom-tabs"
          style={{ borderBottom: '2px solid #ddd' }}
        >
          {this.state.data.map((insurer, index) => (
            <Nav.Item key={index} className="tab-item">
              <Nav.Link
                eventKey={index.toString()}
                style={{
                 
                  border: '1px solid #ddd',
                  borderBottom: activeTab === index.toString() ? '2px solid #007bff' : '1px solid #ddd',
                  borderRadius: '8px 8px 0 0',
                  backgroundColor: activeTab === index.toString() ? '#e0f7ff' : '#f8f9fa',
                  color: activeTab === index.toString() ? '#007bff' : '#555',
                  fontWeight: activeTab === index.toString() ? 'bold' : 'normal',
                  transition: 'background-color 0.3s, color 0.3s',
                }}
              >
                <Image
                src={`${configData.api_url}${insurer.insurerlogo}`}                  
                style={{ width: '100px', height: '50px' }}
                                  alt={insurer.insurername}
                />
              <div>{insurer.productname}<br/>
              <p>{insurer.optional_addons.addONCode}</p>
      
             </div>
              </Nav.Link>
            </Nav.Item>
          ))}
       </Nav>
       <br/>
       <Card className='summaryborders'>
               {this.state.data.map((insurer, index) => (
          <Tab.Content key={index} active={activeTab === index.toString()}
          
          >
            {activeTab === index.toString() && (
              <div className="tab-content mt-0" 
              style={{marginTop:"0px"}}>
               
               <Card.Header> <h4 style={{color: '#007bff' }}>{insurer.productname}</h4></Card.Header>
                <div className='row'>
                  <div className={insurer.optional_addons && insurer.optional_addons.length > 0 ? 'col-6' : 'col-12'}>
                      {insurer.mandatory_addons && insurer.mandatory_addons.length > 0 && (
                <>
                  <h6><b>Mandatory Addons</b></h6>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Add-on</th>
                        <th>Add-on Code</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {insurer.mandatory_addons.map((addon) => (
                        <tr key={addon.caresupremeaddons_Id}>
                          <td>{addon.addOn}</td>
                          <td>{addon.addONCode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
                 )}
                </div>
                  <div  className={insurer.mandatory_addons && insurer.mandatory_addons.length > 0 ? 'col-6' : 'col-12'}>{insurer.optional_addons && insurer.optional_addons.length > 0 && (
                <>
                  <h6><b>Optional Addons</b></h6>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th >Add-on</th>
                        <th>Add-on Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {insurer.optional_addons.map((addon) => (
                        <tr key={addon.caresupremeaddons_Id}>
                          <td>{addon.addOn}</td>
                          <td>{addon.addONCode}</td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}</div>
                </div>
               <Table striped bordered hover>
        <tbody>
          <tr>
            <td className="text-center">
              {isDownloadVisible ? (
                <Button
                  variant="primary"
                  onClick={(e)=>this.handleCSVDownload(e, this.baseproductId)}
                  className="bi bi-download"
                  style={{height:"35px"}}
                >&nbsp;Download CSV
                </Button>
              ) : (
                <Form.Group controlId={`formFile${index + 1}`}>
                  <Row>
                    <Col md={3}>
                      <Form.Label className="bi bi-upload" id="upl">&nbsp; Upload CSV</Form.Label>
                    </Col>
                    <Col>
                       <Form.Control
                        type="file"
                        accept=".csv,.xlsx"
                        onChange={ this.handleCSVUpload}
                      //  className="form-control"
                        name="filePath"
                        required="required"
                        className="file-input bi bi-upload"
                    />
                    </Col>
                    <Col>
                      <Button 
                    style={{marginTop:"0px",height:"35px", fontSize:"16px"}}
                      variant="success"
                      onClick={() => this.handleSubmit(index)}
                    >
                      Submit
                    </Button>
                    </Col>
                  </Row>
              
                  {csvFiles[`tab${index + 1}`] && (
                    <p>Uploaded File: {csvFiles[`tab${index + 1}`].name}</p>
                  )}
                  

                </Form.Group>
              )}
            </td>
         
          </tr>
        </tbody>
      </Table>
              </div>
            )}
               <div className="summaryborders" style={{ padding: '10px' }}>
          {/* Your existing Tab navigation and content */}
          {showSpinner ? this.renderSpinner() : <div>{/* Existing tab content */}</div>}
        </div>
        
          </Tab.Content>
        ))}
       </Card>

          </div>
          </div>
      
      );
    }
 }



