import React, { Component } from "react";
import "./RGIProposerinfo.css";
import { Button, Col, Row, Card, Table, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import configData from "../config.json";

export default class RGIAddOns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      addons: [],
      policiesTable: [],
      mandatoryaddons: [],
      optionaladdons: [],
      defalutaddons: [],
      showExistingSince: {},
      isSameAsPermanent: false,
      dateValues: {},
      checkboxmonth: {},
      isButtonDisabled: false,
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleSubmitClick = (e) => {
    window.location.href = "/rginomineedetails";
  };
  componentDidMount() {
    this.apisfetchHandler();
    //this.CaresupremeaddonsfetchHandler();
  }

  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "rg_Leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ members: data.lead.members });
          this.CaresupremeaddonsfetchHandler();
        }
      });
  };
  CaresupremeaddonsfetchHandler = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "rg_Leads/getproductdetails", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ mandatoryaddons: data.product.mandatory_addons });
          this.setState({ optionaladdons: data.product.optional_addons });
          //this.setState({ addons: data.product.optional_addons });
          this.getDefalutAddOns();
        }
      });
  };

  getDefalutAddOns = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "rg_Leads/getdefaultAddons", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ defalutaddons: data.defalutaddons });
          //var disabledAddons = [];
          var optionaladdons = this.state.optionaladdons;
          var defalutaddons = this.state.defalutaddons;
          optionaladdons.forEach((quote) => {
            quote.isDefault = "no";
            quote.isdisable = "yes";
            quote.isMandatory = "no";
            if (defalutaddons.includes(quote.addONCode)) {
              // quote.isMandatory = "yes";
              // quote.isDefault = "yes";
              quote.isdisable = "no";
              //disabledAddons = quote.disabledAddons.split(",");
            }
          });
          this.setState({ optionaladdons: optionaladdons });
          // this.setState((prevState) => {
          //   const updatedAddonsData = prevState.optionaladdons.map((quote) => {
          //     var isdisable = "no";
          //     if (
          //       disabledAddons.includes(quote.addONCode) ||
          //       defalutaddons.includes(quote.addONCode)
          //     ) {
          //       isdisable = "yes";
          //     }
          //     return { ...quote, isdisable: isdisable };
          //   });
          //   return { optionaladdons: updatedAddonsData };
          // });
        }
      });
  };

  handleInputChange = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };
  handleSubmitBackClick = (e) => {
    window.location.href = "/rginomineedetails";
  };
  handleAddonsCheckboxChange = (
    addONCode,
    caresupremeaddons_Id,
    isChecked,
    selectedValue
  ) => {
    this.setState((prevState) => {
      const updatedAddonsData = prevState.mandatoryaddons.map((quote) => {
        if (quote.addONCode === addONCode) {
          return {
            ...quote,
            isMandatory: isChecked ? "yes" : "no",
          };
        }
        return quote;
      });

      return { mandatoryaddons: updatedAddonsData };
    });
  };
  handleAddonsSelectChange = (
    addONCode,
    caresupremeaddons_Id,
    selectedValue
  ) => {
    this.setState((prevState) => {
      const updatedAddonsData = prevState.mandatoryaddons.map((quote) => {
        if (quote.addONCode === addONCode) {
          return {
            ...quote,
            ans: selectedValue || "",
          };
        }
        return quote;
      });
      return { mandatoryaddons: updatedAddonsData };
    });
  };
  handleOptionalCheckboxChange = (
    addONCode,
    caresupremeaddons_Id,
    isChecked,
    selectedValue
  ) => {
    this.setState((prevState) => {
      const updatedAddonsData = prevState.optionaladdons.map((quote) => {
        if (quote.addONCode === addONCode) {
          return {
            ...quote,
            isMandatory: isChecked ? "yes" : "no",
          };
        }
        return quote;
      });
     
      return { optionaladdons: updatedAddonsData, selectedValue };
    });
  };

  // handleOptionalSelectChange = (
  //   addONCode,
  //   caresupremeaddons_Id,
  //   selectedValue
  // ) => {
  //   this.setState((prevState) => {
  //     const updatedAddonsData = prevState.optionaladdons.map((quote) => {
  //       if (quote.addONCode === addONCode) {
  //         return {
  //           ...quote,
  //           ans: selectedValue || "",
  //         };
  //       }
  //       return quote;
  //     });
  //     return { optionaladdons: updatedAddonsData };
  //   });
  // };
  handleOptionalSelectChange = (
    addONCode,
    caresupremeaddons_Id,
    selectedValue
  ) => {
    this.setState((prevState) => {
      const updatedAddonsData = prevState.optionaladdons.map((quote) => {
        if (quote.caresupremeaddons_Id === caresupremeaddons_Id) {
          return {
            ...quote,
            isMandatory: selectedValue,
          };
        }
        return quote;
      });
      return { optionaladdons: updatedAddonsData };
    });
  };
  handleComplete = (e) => {
    const selectedAddons = [
      ...this.state.mandatoryaddons,
      ...this.state.optionaladdons,
    ];

    const selectedAddonsList = selectedAddons.map((quote) => ({
      aoId: quote.caresupremeaddons_Id,

      aoCode: quote.addONCode,
      isChecked: quote.isMandatory,
    }));

    const formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("addons", JSON.stringify(selectedAddonsList));
    fetch(configData.api_url + "rg_Leads/updateleadaddons/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.createPolicy();
        }
      })
     ;
  };
  createPolicy() {
    const formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("chpiId", this.state.chpiId);
    formData.append("cqrId", this.state.cqrId);
    fetch(configData.api_url + "rg_Leads/createpolicy/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        alert(JSON.stringify(data.policy));
        if (data.status === "success") {
          sessionStorage.setItem("proposalNum", data.policy.Policy.ProposalNo);
          window.location.href = "/rgiproposalsummary";
        }
      })
     
  }
  render() {
    return (
      <div>
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-vcard-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Member Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-heart-pulse"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Medicals</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-pencil-square"
                        onClick={this.handleSubmitClick}
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6
                        style={{ color: "#3d5a95" }}
                        onClick={this.handleSubmitClick}
                      >
                        Nominee Details
                      </h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-clipboard-plus"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Addon's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>
        <div>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <br />
                  <h3>Optional AddOns</h3>
                  <Table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">S No.</th>
                        <th scope="col">Code</th>
                        <th scope="col">Addon Name</th>
                        <th scope="col">Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.optionaladdons.map((quote, index) => (
                        <tr key={index}>
                          <td style={{ width: "10px" }}>{index + 1}</td>
                          <td style={{ width: "50px" }}>
                            {quote.dummyThree} {quote.addONCode}
                          </td>
                          <td style={{ width: "850px" }}>{quote.addOn}</td>
                          <td style={{ width: "200px" }}>
                            {quote.dummyTwo ? (
                              <Form.Select
                                // name={`checkboxmonth-${quote.caresupremeaddons_Id}`}
                                // id={`checkboxmonth-${quote.caresupremeaddons_Id}`}
                                name={`select-${quote.caresupremeaddons_Id}`}
                                id={`select-${quote.caresupremeaddons_Id}`}
                                onChange={(e) =>
                                  this.handleOptionalSelectChange(
                                    quote.addONCode,
                                    quote.caresupremeaddons_Id,
                                    e.target.value
                                  )
                                }
                                aria-label="Default select example"
                                className="form-control"
                                //value={quote.checkboxmonth}
                                value={quote.isMandatory || "Select"}
                              >
                                <option>Select</option>
                                {quote.dummyTwo
                                  .split(",")
                                  .map((option, index) => (
                                    <option key={index} value={option.trim()}>
                                      {option.trim()}
                                    </option>
                                  ))}
                              </Form.Select>
                            ) : (
                              <input
                                type="checkbox"
                                id="consentCheckbox"
                                name="consentCheckbox"
                                onChange={(e) =>
                                  this.handleOptionalCheckboxChange(
                                    quote.addONCode,
                                    quote.caresupremeaddons_Id,
                                    !(quote.isMandatory === "yes"
                                      ? true
                                      : false)
                                  )
                                }
                                disabled={quote.isdisable === "yes"}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
        </div>
        <div className="row">
          <div className="col">
            <Button
              id="getQuotes4"
              onClick={this.handleSubmitClick}
              type="submit"
              style={{ float: "left" }}
            >
              Back
            </Button>
          </div>
          <div className="col" style={{ float: "right" }}>
            <Button
              id="getQuotes4"
              onClick={this.handleComplete}
              type="submit"
              style={{ float: "right" }}
            >
              Finish
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
