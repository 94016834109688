import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Form,
  Col,
  Offcanvas,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";
import DatePicker from "react-datepicker";
export default class Peddeclarationdetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      showOne: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,

      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => i+1,
          sortable: true,
          cell: (row, index) => <span>{index + 1}</span>,
        },
        {
          name: "Request Id",
          selector: (row, i) => row.pedRequestId,
          sortable: true,
          cell: (row) => <span>{row.pedRequestId}</span>,
        },
        {
          name: "Company",
          selector: (row, i) => row.empCompany,
          sortable: true,
          cell: (row) => <span>{row.empCompany}</span>,
        },
        {
          name: "Total",
          selector: (row, i) => row.total,
          sortable: true,
          cell: (row) => <span>{row.total}</span>,
        },
        {
          name: "Initiated",
          selector: (row, i) => row.Added,
          sortable: true,
          cell: (row) => <span>{row.Added}</span>,
        },
        {
          name: "Updated",
          selector: (row, i) => row.Updated,
          sortable: true,
          cell: (row) => <span>{row.Updated}</span>,
        },
        // {
        //   name: "Actions",
        //   sortable: false,
        //   cell: (row) => (
        //     <div>
        //       {/* edit button and delete button */}
        //       {/* <Button
        //         style={{ marginRight: "10px" }}
        //         variant="primary"
        //         onClick={this.handleOffcanvasShow}
        //         className="bi bi-pen"
        //       ></Button>
        //       <Button
        //         variant="danger"
        //         onClick={() => this.handleDelete(row)}
        //         className="bi bi-trash danger"
        //       ></Button> */}
        //     </div>
        //   ),
        // },
      ],
      
      currentPage: 1,
      itemsPerPage: 10,
      filePath: "",
      freezeDate: null,
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  PeddeclarationdetailseditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Peddeclarationdetails_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  PeddeclarationdetailsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Peddeclarationdetails/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  componentDidMount() {
    this.PeddeclarationdetailsfetchHandler();
  }
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
   
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("peddeclarationdetails_Id", row.peddeclarationdetails_Id);
    fetch(configData.api_url + "C_Peddeclarationdetails/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.PeddeclarationdetailsfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };
  // Upload Events
  handleShowOne = () => {
    this.setState({ showOne: true });
  };
  handleCloseOne = () => {
    this.setState({ showOne: false });
  };
  handleCSVUpload = (e) => {
    const files = e.target.files[0];
    this.setState({ filePath: files });
  };
  handleCSVSubmit = (e) => {
    e.preventDefault();
    const { sId, freezeDate } = this.state;
    // var params = {
    //   freezeDate: freezeDate,
    //   sId: sId,
    // };
    const { filePath } = this.state;
    const formData = new FormData();
    formData.append("filePath", filePath);
    formData.append("sId", sId);
    formData.append("freezeDate", freezeDate);
    fetch(configData.api_url + "C_Peddeclarationdetails/bulkEmpUpload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            filePath: "",
            freezeDate: "",
            showOne: false
          });
          this.PeddeclarationdetailsfetchHandler();
        }
      });
  };
  //https://dev-api.apnapolicy.co.in/C_Peddeclarationdetails/bulkEmpUpload
  // https://dev-api.apnapolicy.co.in/uploads/Ped_declaration_details.csv
  handleCSVDownload = () => {
    const csvContent = configData.api_url + "uploads/Peddeclarations.xlsx?v=3";
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", `uploads/Peddeclarations.xlsx?v=3`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  handledatesChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
     
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        freezeDate: formattedDate,
      });
    }
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                dt-bootstrap no-footer"
                >
                  
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{
                          float: "left",
                          height: "35px",
                          marginBottom: "10px",
                        }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />

                      {/* <Button
                        variant="primary"
                        className="bi bi-plus "
                        style={{ float: "right" }}
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button> */}

                      <Button
                        variant="primary"
                        className="me-2 bi bi-download"
                        style={{ float: "right" }}
                        onClick={this.handleCSVDownload}
                      >
                        Download Sample *csv
                      </Button>

                      <Button
                        variant="warning"
                        className="me-2 bi bi-upload"
                        style={{ float: "right" }}
                        onClick={this.handleShowOne}
                      >
                        Upload *csv
                      </Button>

                      {/* Model code */}
                      <div>
                        <Modal
                          show={this.state.showOne}
                          onHide={this.handleCloseOne}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Upload *csv file</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Row>
                              <Col>
                                <Form>
                                  <Form.Label>Employee File</Form.Label>
                                  <Form.Control
                                    type="file"
                                    accept=".csv,.xlsx"
                                    name="filePath"
                                    required="required"
                                    className="file-input"
                                    onChange={this.handleCSVUpload}
                                  />
                                </Form>
                              </Col>
                              <Col>
                                <Form.Label>Freeze-Date</Form.Label>
                                <DatePicker
                                  className="form-control"
                                  selected={this.state.freezeDate}
                                  onChange={(date) =>
                                    this.handledatesChange(date)
                                  }
                                  id="freezeDate"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Freeze Date"
                                  dropdownMode="select"
                                  peekNextMonth
                                  showMonthDropdown
                                  minDate={
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() - 3
                                      )
                                    )
                                  }
                                  showYearDropdown
                                />
                              </Col>
                            </Row>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="success"
                              onClick={this.handleCSVSubmit}
                            >
                              Submit
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
