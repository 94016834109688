import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./PoliciesThankspage.css";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";

export default class PoliciesStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDownload = () => {
    // Create a new jsPDF instance
    const pdfDoc = new jsPDF();

    // Add text to the PDF
    pdfDoc.text("Your payment is done ", 10, 10);

    // Save the PDF to a Blob
    const blob = pdfDoc.output("blob");

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank"; // Open in a new tab if you want
    link.download = "custom_text.pdf"; // Specify the desired file name
    link.click();
  };
  render() {
    return (
      <div>
        <h1>PoliciesThankspage</h1>
        <Row className="mt-3">
          <Col>
            <Card>
              <div className="thank-you">
                <h1 className="thank-you-header">Thank You!</h1>
                <span
                  className="bi bi-hand-thumbs-up-fill"
                  style={{ fontSize: "35px", color: "green" }}
                ></span>
                <br />
                <p className="thank-you-text">policyNumber : </p>
                <br />
                <p className="thank-you-text">Transaction number : </p>
                <br />
                <p className="thank-you-text">uwDecision : </p>
                <br />
                <p className="thank-you-text">
                  Your Transaction is successfull. policy documents will be sent
                  to your email.
                </p>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Card>
              <div className="thank-you">
                <h1 className="thank-you-header">Error</h1>
                <span
                  className="bi bi-exclamation-circle-fill"
                  style={{ fontSize: "35px", color: "red" }}
                ></span>
                <br />
                <p className="thank-you-text">errorFlag : </p>
                <br />
                <p className="thank-you-text">errorMsg : </p>
                <br />
                <p className="thank-you-text">
                  We couldnt process your transaction Please try again.
                </p>
                <br />
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Card>
              <br />
              <div>
                <p>
                  Generate Pdf{" "}
                  <Link onClick={this.handleDownload}>Click here</Link>
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
