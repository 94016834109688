import React, { Component } from "react";
import { toast } from "react-toastify";
import configData from "../config.json";
import { Button, Form, Row, Col, Table, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

export default class PedDeclarationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      peddeclarationdetails_Id: sessionStorage.getItem(
        "peddeclarationdetails_Id"
      ),
      validationErrors: {},
      noPedSelected: false,
      nomembertwoPedSelected: false,
      nomemberthreePedSelected: false,
      nomemberfourPedSelected: false,
      nomemberfivePedSelected: false,
      nomembersixPedSelected: false,
      consentCheckbox: false,
      peddata: {
        empHeight: "",
        empWeight: "",
        membertwoHeight: "",
        membertwoWeight: "",
        memberthreeHeight: "",
        memberthreeWeight: "",
        memberfourHeight: "",
        memberfourWeight: "",
        memberfiveHeight: "",
        memberfiveWeight: "",
        membersixHeight: "",
        membersixWeight: "",
      },
      empPed: "",
      durationofPed: "",
      pedData: {},
      pedDataMember2: {},
      pedDatamemberthree: {},
      pedDatamemberfour: {},
      pedDatamemberfive: {},
      pedDatamembersix: {},
      membertwoPed: "",
      memberdurationofPed: "",
      memberthreePed: "",
      memberthreedurationofPed: "",
      memberfourPed: "",
      memberfourdurationofPed: "",
      memberfivePed: "",
      memberfivedurationofPed: "",
      membersixPed: "",
      membersixdurationofPed: "",
      // others ped text data
      emppedotherdiseases: "",
      membertwopedotherdiseases: "",
      memberthreepedotherdiseases: "",
      memberfourpedotherdiseases: "",
      memberfivepedotherdiseases: "",
      membersixpedotherdiseases: "",
      empId: "",
      empDob: "",
      empDoj: "",
      emppanNumber: "",
      startDate: new Date(),
      showSurgery: {
        memberOne: false,
        memberTwo: false,
        memberThree: false,
        memberFour: false,
        memberFive: false,
        memberSix: false,
      },
      empBmi: "",
      membertwoBmi: "",
      membertwoDob: "",
      memberthreeDob: "",
      memberfourDob: "",
      memberfiveDob: "",
      membersixDob: "",
      memberthreeBmi: "",
      memberfourBmi: "",
      memberfiveBmi: "",
      membersixBmi: "",
      isEmployeeFound: false,
      pedQuestions: [
        { id: 1, question: "Diabetes" },
        { id: 2, question: "Hypertension" },
        { id: 3, question: "Epilepsy" },
        { id: 4, question: "High Cholesterol" },
        { id: 5, question: "Thyroid Disorder" },
        { id: 6, question: "Asthma" },
        { id: 7, question: "Kidney Disorder" },
        { id: 8, question: "Cancer" },
        { id: 9, question: "Heart Disease" },
        { id: 10, question: "Liver Disease" },
        { id: 11, question: "Allergy" },
        { id: 13, question: "Others" },
      ],
      pregnancy:false
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleEmpIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ empId: fieldValue });
  };
  handleEmpDobChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ empDob: fieldValue });
  };

  handleSugeryDateChange = (date) => {
    if (date) {
      const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
      this.setState((prevState) => ({
        peddata: { ...prevState.peddata, surgeryDate: formattedDate },
        // Store formatted date in a separate key
      }));
    }
  };
  handleSugeryDateMembertwoChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    // console.log(formattedDate);
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, membertwosurgeryDate: formattedDate },
      // Store formatted date in a separate key
    }));
  };
  handleSugeryDateMemberthreeChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    // console.log(formattedDate);
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, memberthreesurgeryDate: formattedDate },
      // Store formatted date in a separate key
    }));
  };
  handleSugeryDateMemberfourChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    // console.log(formattedDate);
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, memberfoursurgeryDate: formattedDate },
      // Store formatted date in a separate key
    }));
  };
  handleSugeryDateMembetfiveChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    // console.log(formattedDate);
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, memberfivesurgeryDate: formattedDate },
      // Store formatted date in a separate key
    }));
  };
  handleSugeryDateMembersixChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    // console.log(formattedDate);
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, membersixsurgeryDate: formattedDate },
      // Store formatted date in a separate key
    }));
  };
  handleDChange = (date) => {
    const empDob = moment(new Date(date)).format("YYYY-MM-DD");
    this.setState({ empDob: empDob });
  };
  handleDJChange = (date) => {
    const empDoj = moment(new Date(date)).format("YYYY-MM-DD");
    // console.log(empDoj);
    this.setState({ empDoj: empDoj });
  };

  handlememberTwoDChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, membertwoDob: formattedDate },
      formattedMemberTwoDob: formattedDate, // Store formatted date in a separate key
    }));
    // if (date) {
    //   const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
    //     .toString()
    //     .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    //   // Assuming you want_to_update the 'dob' state with the selected date
    //   this.setState((prevState) => ({
    //     peddata: { ...prevState.peddata, membertwoDob: date },
    //     formattedMemberTwoDob: formattedDate, // Store formatted date in a separate key
    //   }));
    // }
  };
  handlememberThreeDChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, memberthreeDob: formattedDate },
      formattedMemberThreeDob: formattedDate, // Store formatted date in a separate key
    }));
  };
  handlememberFourDChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, memberfourDob: formattedDate },
      formattedMemberFourDob: formattedDate, // Store formatted date in a separate key
    }));
  };
  handlememberFiveDChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, memberfiveDob: formattedDate },
      formattedMemberFiveDob: formattedDate, // Store formatted date in a separate key
    }));
  };
  handlememberSixDChange = (date) => {
    const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
    this.setState((prevState) => ({
      peddata: { ...prevState.peddata, membersixDob: formattedDate },
      formattedMemberSixDob: formattedDate, // Store formatted date in a separate key
    }));
  };
  handleInputChange = (field, value) => {
    this.setState(
      (prevState) => {
        if(field === "empName" || field === "memberstwoName" ||field === "memberthreeName" || field === "memberfourName" ||field === "memberfiveName" || field === "membersixName"){
          if(value === "") value =" ";
        }
        const peddata = { ...prevState.peddata, [field]: value };
        return { peddata };
      },
      () => {
        if (field === "empHeight" || field === "empWeight") {
          this.calculateBMI();
        } else if (field === "membertwoHeight" || field === "membertwoWeight") {
          this.calculateMembertwoBMI();
        } else if (
          field === "memberthreeHeight" ||
          field === "memberthreeWeight"
        ) {
          this.calculateMemberthreeBMI();
        } else if (
          field === "memberfourHeight" ||
          field === "memberfourWeight"
        ) {
          this.calculateMemberfourBMI();
        } else if (
          field === "memberfiveHeight" ||
          field === "memberfiveWeight"
        ) {
          this.calculateMemberfiveBMI();
        } else if (field === "membersixHeight" || field === "membersixWeight") {
          this.calculateMembersixBMI();
        }
      }
    );
  };

  convertHeightToMeters = (heightInCentimeters) => {
    return heightInCentimeters / 100;
  };

  calculateBMI = () => {
    const { peddata } = this.state;
    const heightInMeters = this.convertHeightToMeters(peddata.empHeight);

    if (
      !isNaN(heightInMeters) &&
      !isNaN(peddata.empWeight) &&
      heightInMeters > 0 &&
      peddata.empWeight > 0
    ) {
      const bmiValue = (
        peddata.empWeight /
        (heightInMeters * heightInMeters)
      ).toFixed(2);

      this.setState({ empBmi: bmiValue });
    } else {
      this.setState({ empBmi: "Invalid input" });
    }
  };

  calculateMembertwoBMI = () => {
    const { peddata } = this.state;
    const heightInMeters = this.convertHeightToMeters(peddata.membertwoHeight);

    if (
      !isNaN(heightInMeters) &&
      !isNaN(peddata.membertwoWeight) &&
      heightInMeters > 0 &&
      peddata.membertwoWeight > 0
    ) {
      const bmiValue = (
        peddata.membertwoWeight /
        (heightInMeters * heightInMeters)
      ).toFixed(2);
      if (bmiValue !== this.state.membertwoBmi) {
        // Update BMI only if it changes_to_prevent unnecessary re-renders
        this.setState({ membertwoBmi: bmiValue });
      }
    } else {
      this.setState({ membertwoBmi: "Invalid input" });
    }
  };
  calculateMemberthreeBMI = () => {
    const { peddata } = this.state;
    const heightInMeters = this.convertHeightToMeters(
      peddata.memberthreeHeight
    );

    if (
      !isNaN(heightInMeters) &&
      !isNaN(peddata.memberthreeWeight) &&
      heightInMeters > 0 &&
      peddata.memberthreeWeight > 0
    ) {
      const bmiValue = (
        peddata.memberthreeWeight /
        (heightInMeters * heightInMeters)
      ).toFixed(2);
      if (bmiValue !== this.state.memberthreeBmi) {
        this.setState({ memberthreeBmi: bmiValue });
      }
    } else {
      this.setState({ memberthreeBmi: "Invalid input" });
    }
  };

  calculateMemberfourBMI = () => {
    const { peddata } = this.state;
    const heightInMeters = this.convertHeightToMeters(peddata.memberfourHeight);

    if (
      !isNaN(heightInMeters) &&
      !isNaN(peddata.memberfourWeight) &&
      heightInMeters > 0 &&
      peddata.memberfourWeight > 0
    ) {
      const bmiValue = (
        peddata.memberfourWeight /
        (heightInMeters * heightInMeters)
      ).toFixed(2);
      if (bmiValue !== this.state.memberfourBmi) {
        this.setState({ memberfourBmi: bmiValue });
      }
    } else {
      this.setState({ memberfourBmi: "Invalid input" });
    }
  };
  calculateMemberfiveBMI = () => {
    const { peddata } = this.state;
    const heightInMeters = this.convertHeightToMeters(peddata.memberfiveHeight);

    if (
      !isNaN(heightInMeters) &&
      !isNaN(peddata.memberfiveWeight) &&
      heightInMeters > 0 &&
      peddata.memberfiveWeight > 0
    ) {
      const bmiValue = (
        peddata.memberfiveWeight /
        (heightInMeters * heightInMeters)
      ).toFixed(2);
      if (bmiValue !== this.state.memberfiveBmi) {
        this.setState({ memberfiveBmi: bmiValue });
      }
    } else {
      this.setState({ memberfiveBmi: "Invalid input" });
    }
  };

  calculateMembersixBMI = () => {
    const { peddata } = this.state;
    const heightInMeters = this.convertHeightToMeters(peddata.membersixHeight);

    if (
      !isNaN(heightInMeters) &&
      !isNaN(peddata.membersixWeight) &&
      heightInMeters > 0 &&
      peddata.membersixWeight > 0
    ) {
      const bmiValue = (
        peddata.membersixWeight /
        (heightInMeters * heightInMeters)
      ).toFixed(2);
      if (bmiValue !== this.state.membersixBmi) {
        this.setState({ membersixBmi: bmiValue });
      }
    } else {
      this.setState({ membersixBmi: "Invalid input" });
    }
  };
  // Handle "No PED" selection
  handleNoPedChange = (isChecked) => {
    if (isChecked) {
      this.setState({
        noPedSelected: true,
        pedData: {}, // Clear PED data
        empPed: "No PED",
        durationofPed: "",
      });
    } else {
      this.setState({
        noPedSelected: false,
        empPed: "",
        durationofPed: "",
      });
    }
  };

  // Handle individual PED question checkbox change
  handlePedQuestionChange = (question, isChecked) => {
    this.setState((prevState) => {
      const pedData = { ...prevState.pedData };
      let empPedArray = prevState.empPed.split(",").filter(Boolean);

      if (isChecked) {
        pedData[question] = ""; // Initialize with empty duration
        empPedArray.push(question);
      } else {
        delete pedData[question];
        empPedArray = empPedArray.filter((item) => item !== question);
      }

      return {
        pedData,
        empPed: empPedArray.join(","),
      };
    });
  };

  handleDurationChange = (question, value) => {
    this.setState((prevState) => {
      const pedData = { ...prevState.pedData };
      pedData[question] = value;
      const durations = Object.entries(pedData)
        .filter(([_, duration]) => duration) // Filter non-empty durations
        .map(([ped, duration]) => `${ped} - ${duration}`);
      return {
        pedData,
        durationofPed: durations.join(","), // Update durationofPed with concatenated values
      };
    });
    // console.log(this.state.pedData);
  };

  // Handle "No PED" selection
  handlemembertwoNoPedChange = (isChecked) => {
    if (isChecked) {
      this.setState({
        nomembertwoPedSelected: true,
        pedDataMember2: {}, // Clear PED data
        membertwoPed: "No PED",
        memberdurationofPed: "",
      });
    } else {
      this.setState({
        nomembertwoPedSelected: false,
        membertwoPed: "",
        memberdurationofPed: "",
      });
    }
  };

  // Handle individual PED question checkbox change
  handlemembertwoPedQuestionChange = (question, isChecked) => {
    this.setState((prevState) => {
      const pedDataMember2 = { ...prevState.pedDataMember2 };
      let empPedArray = prevState.membertwoPed.split(",").filter(Boolean);

      if (isChecked) {
        pedDataMember2[question] = ""; // Initialize with empty duration
        empPedArray.push(question);
      } else {
        delete pedDataMember2[question];
        empPedArray = empPedArray.filter((item) => item !== question);
      }

      return {
        pedDataMember2,
        membertwoPed: empPedArray.join(","),
      };
    });
  };

  // Handle duration change for a specific PED
  handlemembertwoDurationChange = (question, value) => {
    this.setState((prevState) => {
      const pedDataMember2 = { ...prevState.pedDataMember2 };
      pedDataMember2[question] = value;
      const durations = Object.entries(pedDataMember2)
        .filter(([_, duration]) => duration) // Filter non-empty durations
        .map(([ped, duration]) => `${ped} - ${duration}`); // Collect non-empty durations
      return {
        pedDataMember2,
        memberdurationofPed: durations.join(","),
      };
    });
  };

  // Handle "No PED" selection
  handlememberthreeNoPedChange = (isChecked) => {
    if (isChecked) {
      this.setState({
        nomemberthreePedSelected: true,
        pedDatamemberthree: {}, // Clear PED data
        memberthreePed: "No PED",
        memberthreedurationofPed: "",
      });
    } else {
      this.setState({
        nomemberthreePedSelected: false,
        memberthreePed: "",
        memberthreedurationofPed: "",
      });
    }
  };

  handlememberthreePedQuestionChange = (question, isChecked) => {
    this.setState((prevState) => {
      const pedDatamemberthree = { ...prevState.pedDatamemberthree };
      let empPedArray = prevState.memberthreePed.split(",").filter(Boolean);

      if (isChecked) {
        pedDatamemberthree[question] = ""; // Initialize with empty duration
        empPedArray.push(question);
      } else {
        delete pedDatamemberthree[question];
        empPedArray = empPedArray.filter((item) => item !== question);
      }

      return {
        pedDatamemberthree,
        memberthreePed: empPedArray.join(","),
      };
    });
  };

  // Handle duration change for a specific PED
  handlememberthreeDurationChange = (question, value) => {
    this.setState((prevState) => {
      const pedDatamemberthree = { ...prevState.pedDatamemberthree };
      pedDatamemberthree[question] = value;
      const durations = Object.entries(pedDatamemberthree)
        .filter(([_, duration]) => duration)
        .map(([ped, duration]) => `${ped} - ${duration}`);
      return {
        pedDatamemberthree,
        memberthreedurationofPed: durations.join(","),
      };
    });
  };

  // Handle "No PED" selection
  handlememberfourNoPedChange = (isChecked) => {
    if (isChecked) {
      this.setState({
        nomemberfourPedSelected: true,
        pedDatamemberfour: {}, // Clear PED data
        memberfourPed: "No PED",
        memberfourdurationofPed: "",
      });
    } else {
      this.setState({
        nomemberfourPedSelected: false,
        memberfourPed: "",
        memberfourdurationofPed: "",
      });
    }
  };

  // Handle individual PED question checkbox change
  handlememberfourPedQuestionChange = (question, isChecked) => {
    this.setState((prevState) => {
      const pedDatamemberfour = { ...prevState.pedDatamemberfour };
      let empPedArray = prevState.memberfourPed.split(",").filter(Boolean);
      if (isChecked) {
        pedDatamemberfour[question] = ""; // Initialize with empty duration
        empPedArray.push(question);
      } else {
        delete pedDatamemberfour[question];
        empPedArray = empPedArray.filter((item) => item !== question);
      }
      return {
        pedDatamemberfour,
        memberfourPed: empPedArray.join(","),
      };
    });
  };

  // Handle duration change for a specific PED
  handlememberfourDurationChange = (question, value) => {
    this.setState((prevState) => {
      const pedDatamemberfour = { ...prevState.pedDatamemberfour };
      pedDatamemberfour[question] = value;
      const durations = Object.entries(pedDatamemberfour)
        .filter(([_, duration]) => duration)
        .map(([ped, duration]) => `${ped} - ${duration}`); // Collect non-empty durations
      return {
        pedDatamemberfour,
        memberfourdurationofPed: durations.join(","),
      };
    });
  };
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  // Handle "No PED" selection
  handlememberfiveNoPedChange = (isChecked) => {
    if (isChecked) {
      this.setState({
        nomemberfivePedSelected: true,
        pedDatamemberfive: {}, // Clear PED data
        memberfivePed: "No PED",
        memberfivedurationofPed: "",
      });
    } else {
      this.setState({
        nomemberfivePedSelected: false,
        memberfivePed: "",
        memberfivedurationofPed: "",
      });
    }
  };
  // Handle individual PED question checkbox change
  handlememberfivePedQuestionChange = (question, isChecked) => {
    this.setState((prevState) => {
      const pedDatamemberfive = { ...prevState.pedDatamemberfive };
      let empPedArray = prevState.memberfivePed.split(",").filter(Boolean);
      if (isChecked) {
        pedDatamemberfive[question] = ""; // Initialize with empty duration
        empPedArray.push(question);
      } else {
        delete pedDatamemberfive[question];
        empPedArray = empPedArray.filter((item) => item !== question);
      }
      return {
        pedDatamemberfive,
        memberfivePed: empPedArray.join(","),
      };
    });
  };

  // Handle duration change for a specific PED
  handlememberfiveDurationChange = (question, value) => {
    this.setState((prevState) => {
      const pedDatamemberfive = { ...prevState.pedDatamemberfive };
      pedDatamemberfive[question] = value;
      const durations = Object.entries(pedDatamemberfive)
        .filter(([_, duration]) => duration)
        .map(([ped, duration]) => `${ped} - ${duration}`); // Collect non-empty durations
      return {
        pedDatamemberfive,
        memberfivedurationofPed: durations.join(","),
      };
    });
  };
  // Handle "No PED" selection
  handlemembersixNoPedChange = (isChecked) => {
    if (isChecked) {
      this.setState({
        nomembersixPedSelected: true,
        pedDatamembersix: {}, // Clear PED data
        membersixPed: "No PED",
        membersixdurationofPed: "",
      });
    } else {
      this.setState({
        nomembersixPedSelected: false,
        membersixPed: "",
        membersixdurationofPed: "",
      });
    }
  };

  // Handle individual PED question checkbox change
  handlemembersixPedQuestionChange = (question, isChecked) => {
    this.setState((prevState) => {
      const pedDatamembersix = { ...prevState.pedDatamembersix };
      let empPedArray = prevState.membersixPed.split(",").filter(Boolean);
      if (isChecked) {
        pedDatamembersix[question] = ""; // Initialize with empty duration
        empPedArray.push(question);
      } else {
        delete pedDatamembersix[question];
        empPedArray = empPedArray.filter((item) => item !== question);
      }
      return {
        pedDatamembersix,
        membersixPed: empPedArray.join(","),
      };
    });
  };

  // Handle duration change for a specific PED
  handlemembersixDurationChange = (question, value) => {
    this.setState((prevState) => {
      const pedDatamembersix = { ...prevState.pedDatamembersix };
      pedDatamembersix[question] = value;
      const durations = Object.entries(pedDatamembersix)
        .filter(([_, duration]) => duration)
        .map(([ped, duration]) => `${ped}-${duration}`); // Collect non-empty durations
      return {
        pedDatamembersix,
        membersixdurationofPed: durations.join(","),
      };
    });
  };

  handleCheckboxChange = (memberKey, surgeryKey, surgeryDateKey) => {
    this.setState(
      (prevState) => {
        const updatedShowSurgery = {
          ...prevState.showSurgery,
          [memberKey]: !prevState.showSurgery[memberKey],
        };

        const updatedPedData = { ...prevState.peddata };
        if (!updatedShowSurgery[memberKey]) {
          // Clear surgery and surgeryDate if unchecked
          updatedPedData[surgeryKey] = "";
          updatedPedData[surgeryDateKey] = "";
        }

        return {
          showSurgery: updatedShowSurgery,
          peddata: updatedPedData,
        };
      },
      () => {
        // Optional: Add a callback_to_handle side effects or validations
        console.log(
          `Checkbox for ${memberKey} toggled. Current state:`,
          this.state
        );
      }
    );
  };

  handlebabyCheckboxChange = (e) => {
    this.setState({ pregnancy: e.target.checked }); // Updates state_to_true/false
  };
  handleficCheckboxChange = (checkboxName) => {
    this.setState((prevState) => ({
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  EmployeesPedfetchHandler = () => {
    const { empId, empDob } = this.state;
    if (!empId || !empDob) {
      this.showToast("Please provide Employee ID and Date of Birth", "warning");
      return;
    }
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("empId", this.state.empId);
    formData.append("empDob", this.state.empDob);

    fetch(configData.api_url + "C_Peddeclarationdetails/retriveByempId", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.status === "success" &&
          data.detail &&
          Object.keys(data.detail).length !== 0
        ) {
          const pedId = data.detail.peddeclarationdetails_Id;
          const freezeDate = new Date(data.detail.freezeDate);
          const isFrozen = freezeDate < new Date();
          const isValidDate = (date) =>
            date && date !== "0000-00-00" && !isNaN(new Date(date).getTime());
          const formatedDate = (date) =>
            isValidDate(date) ? new Date(date) : null;
          var showSurgery = this.state.showSurgery;
          var {
            pedData,
            pedDataMember2,
            pedDatamemberthree,
            pedDatamemberfour,
            pedDatamemberfive,
            pedDatamembersix,
            noPedSelected,
            nomembertwoPedSelected,
            nomemberthreePedSelected,
            nomemberfourPedSelected,
            nomemberfivePedSelected,
            nomembersixPedSelected,
            pregnancy,
          } = this.state;
          var pedQuestions = this.state.pedQuestions;
          var durationofPed = [];
          if (data.detail.durationofPed !== "")
            durationofPed = data.detail.durationofPed.split(",");
          // console.log(durationofPed);
          durationofPed.forEach((element) => {
            var element1 = element.trim();
            var element2 = element1.split("-");
            if (pedQuestions.some((e) => e.question === element2[0].trim())) {
              // pedData[element2[0].trim()] = "";
              if (
                element2.length === 3 &&
                element2[1] !== "" &&
                element2[2] !== ""
              ) {
                const date = new Date(
                  parseInt(element2[1]),
                  parseInt(element2[2]) - 1
                );
                pedData[element2[0].trim()] = moment(date).format("YYYY-MM");
              }
            }
          });
          
          // console.log(pedData);
          var membertwodurationofPed = [];
          if (data.detail.membertwodurationofPed !== "")
            membertwodurationofPed =
              data.detail.membertwodurationofPed.split(",");
          // console.log(membertwodurationofPed);
          membertwodurationofPed.forEach((element) => {
            var element1 = element.trim();
            var element2 = element1.split("-");
            if (pedQuestions.some((e) => e.question === element2[0].trim())) {
              // pedDataMember2[element2[0].trim()] = "";
              if (
                element2.length === 3 &&
                element2[1] !== "" &&
                element2[2] !== ""
              ) {
                const date = new Date(
                  parseInt(element2[1]),
                  parseInt(element2[2]) - 1
                );
                pedDataMember2[element2[0].trim()] =
                  moment(date).format("YYYY-MM");
              }
            }
          });
          var memberthreedurationofPed = [];
          if (data.detail.memberthreedurationofPed !== "")
            memberthreedurationofPed =
              data.detail.memberthreedurationofPed.split(",");
          // console.log(memberthreedurationofPed);
          memberthreedurationofPed.forEach((element) => {
            var element1 = element.trim();
            var element2 = element1.split("-");
            if (pedQuestions.some((e) => e.question === element2[0].trim())) {
              // pedDatamemberthree[element2[0].trim()] = "";
              if (
                element2.length === 3 &&
                element2[1] !== "" &&
                element2[2] !== ""
              ) {
                const date = new Date(
                  parseInt(element2[1]),
                  parseInt(element2[2]) - 1
                );
                pedDatamemberthree[element2[0].trim()] =
                  moment(date).format("YYYY-MM");
              }
            }
          });
          var memberfourdurationofPed = [];
          if (data.detail.memberfourdurationofPed !== "")
            memberfourdurationofPed =
              data.detail.memberfourdurationofPed.split(",");
          memberfourdurationofPed.forEach((element) => {
            var element1 = element.trim();
            var element2 = element1.split("-");
            if (pedQuestions.some((e) => e.question === element2[0].trim())) {
              // pedDatamemberfour[element2[0].trim()] = "";
              if (
                element2.length === 3 &&
                element2[1] !== "" &&
                element2[2] !== ""
              ) {
                const date = new Date(
                  parseInt(element2[1]),
                  parseInt(element2[2]) - 1
                );
                pedDatamemberfour[element2[0].trim()] =
                  moment(date).format("YYYY-MM");
              }
            }
          });
          var memberfivedurationofPed = [];
          if (data.detail.memberfivedurationofPed !== "")
            memberfivedurationofPed =
              data.detail.memberfivedurationofPed.split(",");
          memberfivedurationofPed.forEach((element) => {
            var element1 = element.trim();
            var element2 = element1.split("-");
            if (pedQuestions.some((e) => e.question === element2[0].trim())) {
              // pedDatamemberfive[element2[0].trim()] = "";
              if (
                element2.length === 3 &&
                element2[1] !== "" &&
                element2[2] !== ""
              ) {
                const date = new Date(
                  parseInt(element2[1]),
                  parseInt(element2[2]) - 1
                );
                pedDatamemberfive[element2[0].trim()] =
                  moment(date).format("YYYY-MM");
              }
            }
          });
          var membersixdurationofPed = [];
          if (data.detail.membersixdurationofPed !== "")
            membersixdurationofPed =
              data.detail.membersixdurationofPed.split(",");
          membersixdurationofPed.forEach((element) => {
            var element1 = element.trim();
            var element2 = element1.split("-");
            if (pedQuestions.some((e) => e.question === element2[0].trim())) {
              // pedDatamembersix[element2[0].trim()] = "";
              if (
                element2.length === 3 &&
                element2[1] !== "" &&
                element2[2] !== ""
              ) {
                const date = new Date(
                  parseInt(element2[1]),
                  parseInt(element2[2]) - 1
                );
                pedDatamembersix[element2[0].trim()] =
                  moment(date).format("YYYY-MM");
              }
            }
          });
          if(pedData.length>0) noPedSelected = true;
          if(pedDataMember2.length>0) nomembertwoPedSelected = true;
          if(pedDatamemberthree.length>0) nomemberthreePedSelected = true;
          if(pedDatamemberfour.length>0) nomemberfourPedSelected = true;
          if(pedDatamemberfive.length>0) nomemberfivePedSelected = true;
          if(pedDatamembersix.length>0) nomembersixPedSelected = true;
          const surgeryDate = formatedDate(data.detail.surgeryDate);
          if (surgeryDate && surgeryDate !== undefined && surgeryDate !== "") {
            showSurgery["memberOne"] = true;
          }
          const membertwosurgeryDate = formatedDate(
            data.detail.membertwosurgeryDate
          );
          if (
            membertwosurgeryDate &&
            membertwosurgeryDate !== undefined &&
            membertwosurgeryDate !== ""
          ) {
            showSurgery["memberTwo"] = true;
          }
          const memberthreesurgeryDate = formatedDate(
            data.detail.memberthreesurgeryDate
          );
          if (
            memberthreesurgeryDate &&
            memberthreesurgeryDate !== undefined &&
            memberthreesurgeryDate !== ""
          ) {
            showSurgery["memberThree"] = true;
          }
          const memberfoursurgeryDate = formatedDate(
            data.detail.memberfoursurgeryDate
          );
          if (
            memberfoursurgeryDate &&
            memberfoursurgeryDate !== undefined &&
            memberfoursurgeryDate !== ""
          ) {
            showSurgery["memberFour"] = true;
          }
          const memberfivesurgeryDate = formatedDate(
            data.detail.memberfivesurgeryDate
          );
          if (
            memberfivesurgeryDate &&
            memberfivesurgeryDate !== undefined &&
            memberfivesurgeryDate !== ""
          ) {
            showSurgery["memberFive"] = true;
          }
          const membersixsurgeryDate = formatedDate(
            data.detail.membersixsurgeryDate
          );
          if (
            membersixsurgeryDate &&
            membersixsurgeryDate !== undefined &&
            membersixsurgeryDate !== ""
          ) {
            showSurgery["memberSix"] = true;
          }
          // console.log(showSurgery);
          var empDoj = "";
          if (isValidDate(data.detail.empDoj)) empDoj = data.detail.empDoj;
          var membertwoDob = "";
          if (isValidDate(data.detail.membertwoDob))
            membertwoDob = data.detail.membertwoDob;
          var memberthreeDob = "";
          if (isValidDate(data.detail.memberthreeDob))
            memberthreeDob = data.detail.memberthreeDob;
          var memberfourDob = "";
          if (isValidDate(data.detail.memberfourDob))
            memberfourDob = data.detail.memberfourDob;
          var memberfiveDob = "";
          if (isValidDate(data.detail.memberfiveDob))
            memberfiveDob = data.detail.memberfiveDob;
          var membersixDob = "";
          if (isValidDate(data.detail.membersixDob))
            membersixDob = data.detail.membersixDob;
          var memberfoursurgeryDate1 = "";
          if (isValidDate(data.detail.memberfoursurgeryDate))
            memberfoursurgeryDate1 = data.detail.memberfoursurgeryDate;
          var memberfivesurgeryDate1 = "";
          if (isValidDate(data.detail.memberfivesurgeryDate))
            memberfivesurgeryDate1 = data.detail.memberfivesurgeryDate;
          var membersixsurgeryDate1 = "";
          if (isValidDate(data.detail.membersixsurgeryDate))
            membersixsurgeryDate1 = data.detail.memberfourDob;
          var membertwosurgeryDate1 = "";
          if (isValidDate(data.detail.membertwosurgeryDate))
            membertwosurgeryDate1 = data.detail.membertwosurgeryDate;
          var memberthreesurgeryDate1 = "";
          if (isValidDate(data.detail.memberthreesurgeryDate))
            memberthreesurgeryDate1 = data.detail.memberthreesurgeryDate;
          var surgeryDate1 = "";
          if (isValidDate(data.detail.surgeryDate))
            surgeryDate1 = data.detail.surgeryDate;
          var detail = data.detail;
          detail.surgeryDate = surgeryDate1;
          detail.membertwosurgeryDate = membertwosurgeryDate1;
          detail.memberthreesurgeryDate = memberthreesurgeryDate1;
          detail.memberfoursurgeryDate = memberfoursurgeryDate1;
          detail.memberfivesurgeryDate = memberfivesurgeryDate1;
          detail.membersixsurgeryDate = membersixsurgeryDate1;
          if(detail.pregnancy === "yes") pregnancy = true;
          this.setState(
            {
              empDoj: empDoj,
              membertwoDob: membertwoDob,
              memberthreeDob: memberthreeDob,
              memberfourDob: memberfourDob,
              memberfiveDob: memberfiveDob,
              membersixDob: membersixDob,
              memberfivesurgeryDate: memberfivesurgeryDate,
              membersixsurgeryDate: membersixsurgeryDate,
              memberfoursurgeryDate: memberfoursurgeryDate,
              membertwosurgeryDate: membertwosurgeryDate,
              memberthreesurgeryDate: memberthreesurgeryDate,
              surgeryDate: surgeryDate,
              peddata: data.detail,
              peddeclarationdetails_Id: pedId,
              isEmployeeFound: true,
              isFrozen,
              showSurgery: showSurgery,
              pedData: pedData,
              emppedotherdiseases: detail.emppedotherDiseases,
              membertwopedotherdiseases: detail.membertwopedotherDiseases,
              memberthreepedotherdiseases: detail.memberthreepedotherdiseases,
              memberfourpedotherdiseases: detail.memberfourpedotherdiseases,
              memberfivepedotherdiseases: detail.memberfivepedotherdiseases,
              membersixpedotherdiseases: detail.membersixpedotherdiseases,
              noPedSelected:noPedSelected,
              nomembertwoPedSelected:nomembertwoPedSelected,
              nomemberthreePedSelected:nomemberthreePedSelected,
              nomemberfourPedSelected:nomemberfourPedSelected,
              nomemberfivePedSelected:nomemberfivePedSelected,
              nomembersixPedSelected:nomembersixPedSelected,
              pregnancy:pregnancy
            },
            () => {
              this.calculateBMI();
              this.calculateMembertwoBMI();
              this.calculateMemberthreeBMI();
              this.calculateMemberfourBMI();
              this.calculateMemberfiveBMI();
              this.calculateMembersixBMI();
            }
          );
          sessionStorage.setItem("peddeclarationdetails_Id", pedId);
        } else {
          this.setState({
            peddata: {},
            peddeclarationdetails_Id: null,
            isEmployeeFound: false,
          });
          this.showToast("No data found for the provided Employee ID", "error");
        }
      });
  };

  validateForm = () => {
    const {
      pedData,
      noPedSelected,
      pedDataMember2,
      pedDatamemberthree,
      pedDatamemberfour,
      pedDatamemberfive,
      pedDatamembersix,
      nomembertwoPedSelected,
      nomemberthreePedSelected,
      nomemberfourPedSelected,
      nomemberfivePedSelected,
      nomembersixPedSelected,
    } = this.state;

    let isValid = true;
    let validationErrors = {};

    // Array of members with their data and "No PED" selection state
    const members = [
      {
        name: "Employee",
        data: pedData,
        height1: this.state.peddata.empHeight,
        weight1: this.state.peddata.empWeight,
        noPedSelected: noPedSelected,
        fName:this.state.peddata.empName.trim(),
        shouldDisplay: true, // Always validate for Employee
      },
      {
        name: "Member 2",
        data: pedDataMember2,
        height1: this.state.peddata.membertwoHeight,
        weight1: this.state.peddata.membertwoWeight,
        noPedSelected: nomembertwoPedSelected,
        fName:this.state.peddata.memberstwoName.trim(),
        shouldDisplay: this.state.peddata.memberstwoName, // Validate only if displayed
      },
      {
        name: "Member 3",
        data: pedDatamemberthree,
        height1: this.state.peddata.memberthreeHeight,
        weight1: this.state.peddata.memberthreeWeight,
        noPedSelected: nomemberthreePedSelected,
        fName:this.state.peddata.memberthreeName.trim(),
        shouldDisplay: this.state.peddata.memberthreeName, // Validate only if displayed
      },
      {
        name: "Member 4",
        data: pedDatamemberfour,
        height1: this.state.peddata.memberfourHeight,
        weight1: this.state.peddata.memberfourWeight,
        noPedSelected: nomemberfourPedSelected,
        fName:this.state.peddata.memberfourName.trim(),
        shouldDisplay: this.state.peddata.memberfourName, // Validate only if displayed
      },
      {
        name: "Member 5",
        data: pedDatamemberfive,
        height1: this.state.peddata.memberfiveHeight,
        weight1: this.state.peddata.memberfiveWeight,
        noPedSelected: nomemberfivePedSelected,
        fName:this.state.peddata.memberfiveName.trim(),
        shouldDisplay: this.state.peddata.memberfiveName, // Validate only if displayed
      },
      {
        name: "Member 6",
        data: pedDatamembersix,
        height1: this.state.peddata.membersixHeight,
        weight1: this.state.peddata.membersixWeight,
        noPedSelected: nomembersixPedSelected,
        fName:this.state.peddata.membersixName.trim(),
        shouldDisplay: this.state.peddata.membersixName, // Validate only if displayed
      },
    ];
    // console.log(members);
    // Iterate through each member_to_validate PED data
    for (const member of members) {
      // Check if the member should be displayed (i.e., has valid data in the state)
      if (member.shouldDisplay) {
        if (!member.fName || member.fName === "") {
          validationErrors[
            `${member.name}_Name`
          ] = `Please enter a valid Name for ${member.name}.`;
          isValid = false;
        }
        // console.log(validationErrors);
        if (!member.height1 || isNaN(member.height1) || member.height1 <= 0) {
          validationErrors[
            `${member.name}_Height`
          ] = `Please enter a valid height for ${member.name}.`;
          isValid = false;
        }
        if (!member.weight1 || isNaN(member.weight1) || member.weight1 <= 0) {
          validationErrors[
            `${member.name}_Weight`
          ] = `Please enter a valid weight for ${member.name}.`;
          isValid = false;
        }
        // If "No PED" is NOT selected, validate the member's data
        if (!member.noPedSelected) {
          // Find invalid PEDs (missing durations)
          const invalidPEDs = Object.entries(member.data || {}).filter(
            ([question, duration]) => duration === "" // Check if duration is empty
          );

          // If data is empty and "No PED" checkbox is also not selected, show an error
          if (
            !member.noPedSelected &&
            Object.keys(member.data || {}).length === 0
          ) {
            toast.error("Please Check No PED Check Box or Choose 'Any PED'.");
            return false; // Stop form submission
          }

          // If there are invalid PEDs, mark the form as invalid
          if (invalidPEDs.length > 0) {
            validationErrors[
              member.name
            ] = `Please fill in all selected PED durations for ${member.name}.`;
            isValid = false;
          }
        }
      }
    }
    // console.log(validationErrors);
    // Update the validation errors in the state
    this.setState({ validationErrors:validationErrors });
    // console.log(isValid);
    // Return the overall validity status
    return isValid;
  };

  PeddeclarationdetailsupdateHandler = (e) => {
    e.preventDefault();
    this.setState({ isSaveButtonDisabled: true });
    if (!this.validateForm()) {
      // this.state.validationErrors.join(",");
      // console.log(this.state.validationErrors);
      Object.entries(this.state.validationErrors)
        .map(([key, value]) =>  toast.error(value))
      return;
    }
    const { consentCheckbox, pregnancy } = this.state;

    if (consentCheckbox) {
      var formData = new FormData();

      formData.append(
        "peddeclarationdetails_Id",
        this.state.peddeclarationdetails_Id
      );
      formData.append("empId", this.state.empId);
      formData.append("empName", this.state.peddata.empName);
      formData.append("empDob", this.state.empDob);
      formData.append("empDoj", this.state.empDoj);
      formData.append("pregnancy", pregnancy ? "yes" : "no");
      formData.append("empmobileNumber", this.state.peddata.empmobileNumber);
      formData.append("empemailId", this.state.peddata.empemailId);
      formData.append("empRelation", this.state.peddata.empRelation);
      formData.append("empHeight", this.state.peddata.empHeight);
      formData.append("empWeight", this.state.peddata.empWeight);
      formData.append("empPed", this.state.empPed);
      formData.append("durationofPed", this.state.durationofPed);
      formData.append("surgery", this.state.peddata.surgery);
      formData.append("surgeryDate", this.state.peddata.surgeryDate);
      formData.append("medications", this.state.peddata.medications);
      formData.append("memberstwoName", this.state.peddata.memberstwoName);
      formData.append("membertwoDob", this.state.peddata.membertwoDob);
      formData.append(
        "membertworelation",
        this.state.peddata.membertworelation
      );
      formData.append("membertwoHeight", this.state.peddata.membertwoHeight);
      formData.append("membertwoWeight", this.state.peddata.membertwoWeight);
      formData.append("membertwoPed", this.state.membertwoPed);
      formData.append("membertwodurationofPed", this.state.memberdurationofPed);
      formData.append("membertwosurgery", this.state.peddata.membertwosurgery);
      formData.append(
        "membertwosurgeryDate",
        this.state.peddata.membertwosurgeryDate
      );
      formData.append(
        "membertwomedications",
        this.state.peddata.membertwomedications
      );
      formData.append("memberthreeName", this.state.peddata.memberthreeName);
      formData.append("memberthreeDob", this.state.peddata.memberthreeDob);
      formData.append(
        "memberthreeRelation",
        this.state.peddata.memberthreeRelation
      );
      formData.append(
        "memberthreeHeight",
        this.state.peddata.memberthreeHeight
      );
      formData.append(
        "memberthreeWeight",
        this.state.peddata.memberthreeWeight
      );
      formData.append("memberthreePed", this.state.memberthreePed);
      formData.append(
        "memberthreedurationofPed",
        this.state.memberthreedurationofPed
      );
      formData.append(
        "memberthreesurgery",
        this.state.peddata.memberthreesurgery
      );
      formData.append(
        "memberthreesurgeryDate",
        this.state.peddata.memberthreesurgeryDate
      );
      formData.append(
        "memberthreemedications",
        this.state.peddata.memberthreemedications
      );
      formData.append("memberfourName", this.state.peddata.memberfourName);
      formData.append("memberfourDob", this.state.peddata.memberfourDob);
      formData.append(
        "memberfourRelation",
        this.state.peddata.memberfourRelation
      );
      formData.append("memberfourHeight", this.state.peddata.memberfourHeight);
      formData.append("memberfourWeight", this.state.peddata.memberfourWeight);
      formData.append("memberfourPed", this.state.memberfourPed);
      formData.append(
        "memberfourdurationofPed",
        this.state.memberfourdurationofPed
      );
      formData.append(
        "memberfourSurgery",
        this.state.peddata.memberfourSurgery
      );
      formData.append(
        "memberfoursurgeryDate",
        this.state.peddata.memberfoursurgeryDate
      );
      formData.append(
        "memberfourMedications",
        this.state.peddata.memberfourMedications
      );
      formData.append("memberfiveName", this.state.peddata.memberfiveName);
      formData.append("memberfiveDob", this.state.peddata.memberfiveDob);
      formData.append(
        "memberfiveRelation",
        this.state.peddata.memberfiveRelation
      );
      formData.append("memberfiveHeight", this.state.peddata.memberfiveHeight);
      formData.append("memberfiveWeight", this.state.peddata.memberfiveWeight);
      formData.append("memberfivePed", this.state.peddata.memberfivePed);
      formData.append(
        "memberfivedurationofPed",
        this.state.memberfivedurationofPed
      );
      formData.append(
        "memberfiveSurgery",
        this.state.peddata.memberfiveSurgery
      );
      formData.append(
        "memberfivesurgeryDate",
        this.state.peddata.memberfivesurgeryDate
      );
      formData.append(
        "memberfiveMedications",
        this.state.peddata.memberfiveMedications
      );
      formData.append("membersixName", this.state.peddata.membersixName);
      formData.append("membersixDob", this.state.peddata.membersixDob);
      formData.append(
        "membersixRelation",
        this.state.peddata.membersixRelation
      );
      formData.append("membersixHeight", this.state.peddata.membersixHeight);
      formData.append("membersixWeight", this.state.peddata.membersixWeight);
      formData.append("membersixPed", this.state.peddata.membersixPed);
      formData.append(
        "membersixdurationofPed",
        this.state.membersixdurationofPed
      );
      formData.append("membersixSurgery", this.state.peddata.membersixSurgery);
      formData.append(
        "membersixsurgeryDate",
        this.state.peddata.membersixsurgeryDate
      );
      formData.append(
        "membersixMedications",
        this.state.peddata.membersixMedications
      );
      formData.append("empBmi", this.state.empBmi);
      formData.append("membertwoBmi", this.state.membertwoBmi);
      formData.append("memberthreeBmi", this.state.memberthreeBmi);
      formData.append("memberfourBmi", this.state.memberfourBmi);
      formData.append("memberfiveBmi", this.state.memberfiveBmi);
      formData.append("membersixBmi", this.state.membersixBmi);
      formData.append("empCompany", this.state.peddata.empCompany);
      formData.append("empDesignation", this.state.peddata.empDesignation);
      formData.append("empCity", this.state.peddata.empCity);
      formData.append("empPincode", this.state.peddata.empPincode);
      formData.append("empGender", this.state.peddata.empGender);
      formData.append("empannualIncome", this.state.peddata.empannualIncome);
      formData.append("emppanNumber", this.state.peddata.emppanNumber);
      // console.log();
      //Others ped added for all members
      formData.append("emppedotherDiseases", this.state.emppedotherdiseases);
      formData.append(
        "membertwopedotherDiseases",
        this.state.membertwopedotherdiseases
      );
      formData.append(
        "memberthreepedotherdiseases",
        this.state.memberthreepedotherdiseases
      );
      formData.append(
        "memberfourpedotherdiseases",
        this.state.memberfourpedotherdiseases
      );
      formData.append(
        "memberfivepedotherdiseases",
        this.state.memberfivepedotherdiseases
      );
      formData.append(
        "membersixpedotherdiseases",
        this.state.membersixpedotherdiseases
      );
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      // console.log();
      fetch(configData.api_url + "C_Peddeclarationdetails/update", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            //this.PeddeclarationdetailsfetchHandler();
            //this.handleOffcanvasClose();
            this.setState({
              empId: "",
              empName: "",
              empDob: "",
              empemailId: "",
              empmobileNumber: "",
              empRelation: "",
              empHeight: "",
              empWeight: "",
              empPed: "",
              durationofPed: "",
              surgery: "",
              surgeryDate: "",
              medications: "",
              memberstwoName: "",
              membertwoDob: "",
              membertworelation: "",
              membertwoHeight: "",
              membertwoWeight: "",
              membertwoPed: "",
              memberdurationofPed: "",
              membertwosurgery: "",
              membertwosurgeryDate: "",
              membertwomedications: "",
              memberthreeName: "",
              memberthreeDob: "",
              memberthreeRelation: "",
              memberthreeHeight: "",
              memberthreeWeight: "",
              memberthreePed: "",
              memberthreedurationofPed: "",
              memberthreesurgery: "",
              memberthreesurgeryDate: "",
              memberthreemedications: "",
              memberfourName: "",
              memberfourDob: "",
              memberfourRelation: "",
              memberfourHeight: "",
              memberfourWeight: "",
              memberfourPed: "",
              memberfourdurationofPed: "",
              memberfourSurgery: "",
              memberfoursurgeryDate: "",
              memberfourMedications: "",
              memberfiveName: "",
              memberfiveDob: "",
              memberfiveRelation: "",
              memberfiveHeight: "",
              memberfiveWeight: "",
              memberfivePed: "",
              memberfivedurationofPed: "",
              memberfiveSurgery: "",
              memberfivesurgeryDate: "",
              memberfiveMedications: "",
              membersixName: "",
              membersixDob: "",
              membersixRelation: "",
              membersixHeight: "",
              membersixWeight: "",
              membersixPed: "",
              membersixdurationofPed: "",
              membersixSurgery: "",
              membersixsurgeryDate: "",
              membersixMedications: "",
              empBmi: "",
              membertwoBmi: "",
              memberthreeBmi: "",
              memberfourBmi: "",
              memberfiveBmi: "",
              membersixBmi: "",
              empCompany: "",
              empDesignation: "",
              empCity: "",
              empGender: "",
              empannualIncome: "",
              peddeclarationdetails_Id: "",
              pregnancy: "",
              emppanNumber: "",
            });
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        });
    } else {
      toast.error("Please Check Terms And Conditions.");
    }
  };

  //pan event
  handlepanChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();

    this.setState((prevState) => ({
      [name]: uppercaseValue,
      errors: {
        ...prevState.errors,
        [name]: uppercaseValue,
      },
    }));
  };

  render() {
    const {
      validationErrors,
      pedQuestions,
      noPedSelected,
      pedData,
      nomembertwoPedSelected,
      pedDataMember2,
      nomemberthreePedSelected,
      pedDatamemberthree,
      pedDatamemberfour,
      nomemberfourPedSelected,
      pedDatamemberfive,
      nomemberfivePedSelected,
      pedDatamembersix,
      nomembersixPedSelected,
      showSurgery,
      isEmployeeFound,
    } = this.state;
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month_to_January
    previousYearStart.setDate(1);
    return (
      <div>
        <Container className="bgalphacolor">
          <div>
            <Form className="mt-5 mb-5">
              <h3 className="logoskybluecolor" style={{ textAlign: "start" }}>
                Pre-Existing Disease (PED) Declaration Form
              </h3>
              <Row>
                <Col lg="6">
                  <Row>
                    <Col lg="6" md="4" className="text-end">
                      <Form.Label style={{ paddingTop: "8px" }}>
                        Employee Id
                      </Form.Label>
                    </Col>
                    <Col lg="6" md="4">
                      <Form.Control
                        style={{ width: "100%" }}
                        type="text"
                        onChange={this.handleEmpIdChange}
                        className="form-control"
                        name="empId"
                        value={this.state.empId}
                        required="required"
                        placeholder="Enter Employee Id"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg="6">
                  <Row>
                    <Col lg="6" md="4" className="text-end">
                      <Form.Label style={{ paddingTop: "8px" }}>DOB</Form.Label>
                    </Col>
                    <Col lg="6" md="4" className="DEPCalender">
                      <DatePicker
                        name="empDob"
                        //onChange={this.handledobChange}
                        dateFormat="dd/MM/yyyy"
                        showTimeSelect={false}
                        selected={this.state.empDob}
                        onChange={(date) => this.handleDChange(date)}
                        className="form-control"
                        id="empDob"
                        placeholderText="Select Date of Birth"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // Set the minimum selectable date
                        // // maxDate={endYear} // Set the maximum selectable date
                        // max={
                        //   new Date().toISOString().split("T")[0]
                        // }
                        // minDate={minDate}
                        maxDate={new Date()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg="6">
                  <Row>
                    <Col lg="6" md="4" className="">
                      &nbsp;
                    </Col>
                    <Col lg="6" md="4" className="text-end">
                      <Button
                        className="rounded-0 "
                        variant="primary"
                        onClick={this.EmployeesPedfetchHandler}
                      >
                        Fetch
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <div>
              <p className="fst-italic" style={{ color: "#ff0000" }}>
                <b>Note: </b>
                If You Are unable To Fetch Your Details. Please Contact HR.
              </p>
            </div>
          </div>
          <hr />
          {isEmployeeFound && (
            <>
              <div>
                <h3 className="logoskybluecolor">Employee Details</h3>
                <Row className="mb-1">
                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4" className="mb-1">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Employee Name
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          style={{ width: "215px" }}
                          type="text"
                          onChange={(e) =>
                            this.handleInputChange("empName", e.target.value)
                          }
                          className="form-control"
                          name="empName"
                          value={this.state.peddata.empName || ""}
                          required="required"
                          placeholder="Enter Employee Name"
                          disabled={this.state.isFrozen}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Employee DOJ
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8" className="DEPEmployeeDetails">
                        <DatePicker
                          name="empDoj"
                          //onChange={this.handledobChange}
                          dateFormat="dd/MM/yyyy"
                          showTimeSelect={false}
                          selected={this.state.empDoj}
                          // onChange={(date) => this.setState({ empDoj: date })}
                          onChange={(date) => this.handleDJChange(date)}
                          //onChange={this.handleDOJChange}
                          className="form-control"
                          id="empDoj"
                          placeholderText="Select Date of Joining"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          //   minDate={previousYearStart}
                          dropdownMode="select"
                          maxDate={new Date()}
                          style={{ width: "215px !important" }}
                          disabled={this.state.isFrozen}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Gender
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Select
                          style={{ width: "215px" }}
                          name="empGender"
                          value={
                            this.Capitalize(this.state.peddata.empGender) || ""
                          }
                          onChange={(e) =>
                            this.handleInputChange("empGender", e.target.value)
                          }
                          className="form-control"
                          disabled={this.state.isFrozen}
                        >
                          <option>Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Company Name
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          style={{ width: "215px" }}
                          type="text"
                          onChange={(e) =>
                            this.handleInputChange("empCompany", e.target.value)
                          }
                          className="form-control"
                          name="empCompany"
                          value={this.state.peddata.empCompany || ""}
                          required="required"
                          placeholder="Enter Company Name"
                          disabled={this.state.isFrozen}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Pan Card
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          style={{ width: "215px" }}
                          type="text"
                          onChange={(e) => {
                            const value = e.target.value.toUpperCase(); // Convert_to_uppercase
                            if (value.length <= 10) {
                              this.handleInputChange("emppanNumber", value); // Allow only if length <= 10
                            }
                          }}
                          className="form-control"
                          name="emppanNumber"
                          value={this.state.peddata.emppanNumber}
                          required="required"
                          placeholder="Enter Pan Number"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Designation
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          style={{ width: "215px" }}
                          type="text"
                          onChange={(e) =>
                            this.handleInputChange(
                              "empDesignation",
                              e.target.value
                            )
                          }
                          className="form-contrWeight(kg)	ol"
                          name="empDesignation"
                          value={this.state.peddata.empDesignation || ""}
                          required="required"
                          placeholder="Enter Designation"
                          disabled={this.state.isFrozen}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Annual Income
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Select
                          style={{ width: "215px" }}
                          name="annualincome"
                          value={this.state.peddata.empannualIncome || ""}
                          onChange={(e) =>
                            this.handleInputChange(
                              "empannualIncome",
                              e.target.value
                            )
                          }
                          className="form-control"
                          disabled={this.state.isFrozen}
                        >
                          <option>Select</option>
                          <option value="0lto5l">0 to 5 lakh</option>
                          <option value="5lto10l">5 to 10 lakh</option>
                          <option value="10lto15l">10 to 15 lakh</option>
                          <option value="15lto30l">15 to 30 lakh</option>
                          <option value="30lto50l">30 to 50 lakh</option>
                          <option value="50lto1cr">50 to 1cr</option>
                          <option value="1crto2cr">1 to 2 cr</option>
                          <option value="2crto5cr">2ct to 5 cr</option>
                          <option value="5crto10cr">5cr to 10cr</option>
                          <option value="10crabove">10cr above</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          City
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          style={{ width: "215px" }}
                          type="text"
                          onChange={(e) =>
                            this.handleInputChange("empCity", e.target.value)
                          }
                          className="form-control"
                          name="empCity"
                          value={this.state.peddata.empCity || ""}
                          required="required"
                          placeholder="Enter City"
                          disabled={this.state.isFrozen}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Pincode
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          style={{ width: "215px" }}
                          type="text"
                          onChange={(e) =>
                            this.handleInputChange("empPincode", e.target.value)
                          }
                          className="form-control"
                          name="empPincode"
                          value={this.state.peddata.empPincode || ""}
                          required="required"
                          placeholder="Enter Pincode"
                          disabled={this.state.isFrozen}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
                <h3 className="logoskybluecolor">Contact Details</h3>
                <Row>
                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Personal Mobile No
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          style={{ width: "215px" }}
                          type="number"
                          onChange={(e) =>
                            this.handleInputChange(
                              "empmobileNumber",
                              e.target.value
                            )
                          }
                          className="form-control"
                          name="empmobileNumber"
                          value={this.state.peddata.empmobileNumber || ""}
                          required="required"
                          placeholder="Enter Mobile Number"
                          disabled={this.state.isFrozen}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6">
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label style={{ paddingTop: "8px" }}>
                          Email Id
                        </Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          style={{ width: "215px" }}
                          type="text"
                          onChange={(e) =>
                            this.handleInputChange("empemailId", e.target.value)
                          }
                          className="form-control"
                          name="empemailId"
                          value={this.state.peddata.empemailId || ""}
                          required="required"
                          placeholder="Enter Email Id"
                          disabled={this.state.isFrozen}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <br />
              <div className="mt-2">
                <h3 className="logoskybluecolor">Family Details</h3>
                <div>
                  <Table bordered hover>
                    <thead className="table-light">
                      <tr>
                        <th>#</th>
                        <th>Member 1</th>
                        {this.state.peddata.memberstwoName ? (
                          <th>Member 2</th>
                        ) : (
                          <th style={{ display: "none" }}></th>
                        )}

                        {this.state.peddata.memberthreeName ? (
                          <th>Member 3</th>
                        ) : (
                          <th style={{ display: "none" }}></th>
                        )}

                        {this.state.peddata.memberfourName ? (
                          <th>Member 4</th>
                        ) : (
                          <th style={{ display: "none" }}></th>
                        )}
                        {this.state.peddata.memberfiveName ? (
                          <th>Member 5</th>
                        ) : (
                          <th style={{ display: "none" }}></th>
                        )}
                        {this.state.peddata.membersixName ? (
                          <th>Member 6</th>
                        ) : (
                          <th style={{ display: "none" }}></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>
                          <Form.Control
                            type="text"
                            //style={{ width: "211px" }}
                            name="empName"
                            value={this.state.peddata.empName || ""}
                            placeholder="Enter Name"
                            onChange={(e) =>
                              this.handleInputChange("empName", e.target.value)
                            }
                            disabled={this.state.isFrozen}
                          />
                        </td>
                        {this.state.peddata.memberstwoName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberstwoName"
                              value={this.state.peddata.memberstwoName || ""}
                              placeholder="Enter Name"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberstwoName",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberthreeName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberthreeName"
                              value={this.state.peddata.memberthreeName || ""}
                              placeholder="Enter Name"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberthreeName",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfourName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfourName"
                              value={this.state.peddata.memberfourName || ""}
                              placeholder="Enter Name"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfourName",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfiveName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfiveName"
                              value={this.state.peddata.memberfiveName || ""}
                              placeholder="Enter Name"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfiveName",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.membersixName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="membersixName"
                              value={this.state.peddata.membersixName || ""}
                              placeholder="Enter Name"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "membersixName",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                      {/* DOB Row */}
                      <tr>
                        <td>DOB</td>
                        <td>
                          <DatePicker
                            name="empDob"
                            //onChange={this.handledobChange}
                            dateFormat="dd/MM/yyyy"
                            showTimeSelect={false}
                            selected={this.state.empDob}
                            onChange={(date) => this.handleDChange(date)}
                            className="form-control"
                            id="empDob"
                            placeholderText="Select Date of Birth"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            //   minDate={previousYearStart}
                            dropdownMode="select"
                            maxDate={new Date()}
                            disabled={this.state.isFrozen}
                          />
                        </td>
                        {this.state.peddata.memberstwoName ? (
                          <td>
                            <DatePicker
                              name="membertwoDob"
                              dateFormat="dd/MM/yyyy"
                              showTimeSelect={false}
                              selected={this.state.peddata.membertwoDob}
                              // onChange={this.handlememberTwoDChange}
                              onChange={(date) =>
                                this.handlememberTwoDChange(date)
                              }
                              className="form-control"
                              id="membertwoDob"
                              placeholderText="Select Date of Birth"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={new Date()}
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberthreeName ? (
                          <td>
                            <DatePicker
                              name="memberthreeDob"
                              dateFormat="dd/MM/yyyy"
                              showTimeSelect={false}
                              selected={this.state.peddata.memberthreeDob}
                              // onChange={this.handlememberThreeDChange}
                              onChange={(date) =>
                                this.handlememberThreeDChange(date)
                              }
                              className="form-control"
                              id="memberthreeDob"
                              placeholderText="Select Date of Birth"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={new Date()}
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfourName ? (
                          <td>
                            <DatePicker
                              name="memberfourDob"
                              dateFormat="dd/MM/yyyy"
                              showTimeSelect={false}
                              selected={this.state.peddata.memberfourDob}
                              // onChange={this.handlememberFourDChange}
                              onChange={(date) =>
                                this.handlememberFourDChange(date)
                              }
                              className="form-control"
                              id="memberfourDob"
                              placeholderText="Select Date of Birth"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              disabled={this.state.isFrozen}
                              maxDate={new Date()}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfiveName ? (
                          <td>
                            <DatePicker
                              name="memberfiveDob"
                              dateFormat="dd/MM/yyyy"
                              showTimeSelect={false}
                              selected={this.state.peddata.memberfiveDob}
                              // onChange={this.handlememberFiveDChange}
                              onChange={(date) =>
                                this.handlememberFiveDChange(date)
                              }
                              className="form-control"
                              id="memberfiveDob"
                              placeholderText="Select Date of Birth"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={new Date()}
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.membersixName ? (
                          <td>
                            <DatePicker
                              name="membersixDob"
                              dateFormat="dd/MM/yyyy"
                              showTimeSelect={false}
                              selected={this.state.peddata.membersixDob}
                              // onChange={this.handlememberSixDChange}
                              onChange={(date) =>
                                this.handlememberSixDChange(date)
                              }
                              className="form-control"
                              id="membersixDob"
                              placeholderText="Select Date of Birth"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={new Date()}
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                      {/* Relation Row */}
                      <tr>
                        <td>Relation</td>
                        <td>
                          <Form.Control
                            type="text"
                            name="empRelation"
                            value={this.state.peddata.empRelation || ""}
                            placeholder="Enter Relation"
                            onChange={(e) =>
                              this.handleInputChange(
                                "empRelation",
                                e.target.value
                              )
                            }
                            disabled={this.state.isFrozen}
                          />
                        </td>
                        {this.state.peddata.memberstwoName ? (
                          <td>
                            <Form.Select
                              name="membertworelation"
                              value={this.state.peddata.membertworelation || ""}
                              placeholder="Enter Relation"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "membertworelation",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              disabled={this.state.isFrozen}
                            >
                              <option>Select</option>
                              <option value="Spouse">Spouse</option>
                              <option value="Son">Son</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Mother">Mother</option>
                              <option value="Father">Father</option>
                              <option value="Fatherinlaw">Father in law</option>
                              <option value="Motherinlaw">Mother in law</option>
                            </Form.Select>
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberthreeName ? (
                          <td>
                            <Form.Select
                              name="memberthreeRelation"
                              value={
                                this.state.peddata.memberthreeRelation || ""
                              }
                              placeholder="Enter Relation"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberthreeRelation",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              disabled={this.state.isFrozen}
                            >
                              <option>Select</option>
                              <option value="Spouse">Spouse</option>
                              <option value="Son">Son</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Mother">Mother</option>
                              <option value="Father">Father</option>
                              <option value="Fatherinlaw">Father in law</option>
                              <option value="Motherinlaw">Mother in law</option>
                            </Form.Select>
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfourName ? (
                          <td>
                            <Form.Select
                              name="memberfourRelation"
                              value={
                                this.state.peddata.memberfourRelation || ""
                              }
                              placeholder="Enter Relation"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfourRelation",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              disabled={this.state.isFrozen}
                            >
                              <option>Select</option>
                              <option value="Spouse">Spouse</option>
                              <option value="Son">Son</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Mother">Mother</option>
                              <option value="Father">Father</option>
                              <option value="Fatherinlaw">Father in law</option>
                              <option value="Motherinlaw">Mother in law</option>
                            </Form.Select>
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfiveName ? (
                          <td>
                            <Form.Select
                              name="memberfiveRelation"
                              value={
                                this.state.peddata.memberfiveRelation || ""
                              }
                              placeholder="Enter Relation"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfiveRelation",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              disabled={this.state.isFrozen}
                            >
                              <option>Select</option>
                              <option value="Spouse">Spouse</option>
                              <option value="Son">Son</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Mother">Mother</option>
                              <option value="Father">Father</option>
                              <option value="Fatherinlaw">Father in law</option>
                              <option value="Motherinlaw">Mother in law</option>
                            </Form.Select>
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.membersixName ? (
                          <td>
                            <Form.Select
                              name="membersixRelation"
                              value={this.state.peddata.membersixRelation || ""}
                              placeholder="Enter Relation"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "membersixRelation",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              disabled={this.state.isFrozen}
                            >
                              <option>Select</option>
                              <option value="Spouse">Spouse</option>
                              <option value="Son">Son</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Mother">Mother</option>
                              <option value="Father">Father</option>
                              <option value="Fatherinlaw">Father in law</option>
                              <option value="Motherinlaw">Mother in law</option>
                            </Form.Select>
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                      {/* Height Row */}
                      <tr>
                        <td>Height(cm)</td>
                        <td>
                          <Form.Control
                            type="text"
                            name="empHeight"
                            value={this.state.peddata.empHeight || ""}
                            placeholder="Enter Height"
                            onChange={(e) =>
                              this.handleInputChange(
                                "empHeight",
                                e.target.value
                              )
                            }
                            disabled={this.state.isFrozen}
                          />
                        </td>
                        {this.state.peddata.memberstwoName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="membertwoHeight"
                              value={this.state.peddata.membertwoHeight || ""}
                              placeholder="Enter Height"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "membertwoHeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberthreeName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberthreeHeight"
                              value={this.state.peddata.memberthreeHeight || ""}
                              placeholder="Enter Height"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberthreeHeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfourName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfourHeight"
                              value={this.state.peddata.memberfourHeight || ""}
                              placeholder="Enter Height"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfourHeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfiveName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfiveHeight"
                              value={this.state.peddata.memberfiveHeight || ""}
                              placeholder="Enter Height"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfiveHeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.membersixName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="membersixHeight"
                              value={this.state.peddata.membersixHeight || ""}
                              placeholder="Enter Height"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "membersixHeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                      <tr>
                        <td>Weight(kg)</td>
                        <td>
                          <Form.Control
                            type="text"
                            name="empWeight"
                            value={this.state.peddata.empWeight || ""}
                            placeholder="Enter Weight"
                            onChange={(e) =>
                              this.handleInputChange(
                                "empWeight",
                                e.target.value
                              )
                            }
                            disabled={this.state.isFrozen}
                          />
                        </td>
                        {this.state.peddata.memberstwoName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="membertwoWeight"
                              value={this.state.peddata.membertwoWeight || ""}
                              placeholder="Enter Weight"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "membertwoWeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberthreeName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberthreeWeight"
                              value={this.state.peddata.memberthreeWeight || ""}
                              placeholder="Enter Weight"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberthreeWeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfourName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfourWeight"
                              value={this.state.peddata.memberfourWeight || ""}
                              placeholder="Enter Weight"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfourWeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfiveName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfiveWeight"
                              value={this.state.peddata.memberfiveWeight || ""}
                              placeholder="Enter Weight"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfiveWeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.membersixName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="membersixWeight"
                              value={this.state.peddata.membersixWeight || ""}
                              placeholder="Enter Weight"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "membersixWeight",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                      <tr>
                        <td>BMI</td>
                        <td>
                          <Form.Control
                            type="text"
                            name="empBmi"
                            value={this.state.empBmi || ""} // Display Employee BMI
                            placeholder="Employee BMI"
                            readOnly
                            disabled
                          />
                        </td>
                        {this.state.peddata.memberstwoName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="membertwoBmi"
                              value={this.state.membertwoBmi || ""} // Display Member Two BMI
                              placeholder="Member Two BMI"
                              readOnly
                              disabled
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberthreeName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberthreeBmi"
                              value={this.state.memberthreeBmi || ""} // Display Member Three BMI
                              placeholder="Member Three BMI"
                              readOnly
                              disabled
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfourName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfourBmi"
                              value={this.state.memberfourBmi || ""} // Display Member Four BMI
                              placeholder="Member Four BMI"
                              readOnly
                              disabled
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfiveName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfiveBmi"
                              value={this.state.memberfiveBmi || ""} // Display Member Four BMI
                              placeholder="Member Five BMI"
                              readOnly
                              disabled
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.membersixName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="membersixBmi"
                              value={this.state.membersixBmi || ""} // Display Member Four BMI
                              placeholder="Member Six BMI"
                              readOnly
                              disabled
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                      {/* PED Checkbox Row */}
                      <tr>
                        <td>
                          Pre-Existing Disease Details, Also From Month, Year If
                          Any.
                        </td>
                        <td>
                          {/* member One ped */}
                          {/* "No PED" Checkbox */}
                          <Form.Check
                            type="checkbox"
                            label="No PED"
                            checked={noPedSelected}
                            onChange={(e) =>
                              this.handleNoPedChange(e.target.checked)
                            }
                            disabled={this.state.isFrozen}
                          />
                          {/* Render PED questions if "No PED" is not checked */}
                          {!noPedSelected &&
                            pedQuestions.map((question) => (
                              <div key={question.id}>
                                <Form.Check
                                  type="checkbox"
                                  label={question.question}
                                  disabled={noPedSelected} // Disable if "No PED" is selected
                                  checked={question.question in pedData} // Checked if in pedData
                                  onChange={(e) =>
                                    this.handlePedQuestionChange(
                                      question.question,
                                      e.target.checked
                                    )
                                  }
                                />
                                {/* added others for member1  */}
                                {question.question === "Others" &&
                                  question.question in pedData && (
                                    <div>
                                      <Form.Control
                                        type="text"
                                        name="emppedotherdiseases"
                                        value={this.state.emppedotherdiseases}
                                        onChange={(e) =>
                                          this.setState({
                                            emppedotherdiseases: e.target.value,
                                          })
                                        }
                                        emppedotherDiseases
                                        placeholder="Other Ped details"
                                        disabled={this.state.isFrozen}
                                      />
                                      <br />
                                      <Form.Control
                                        type="month"
                                        value={pedData[question.question] || ""}
                                        onChange={(e) =>
                                          this.handleDurationChange(
                                            question.question,
                                            e.target.value
                                          )
                                        }
                                        max={new Date()
                                          .toISOString()
                                          .slice(0, 7)}
                                        placeholder={`Enter duration for ${question.question}`}
                                        disabled={this.state.isFrozen}
                                      />
                                    </div>
                                  )}
                                {question.question !== "Others" &&
                                  question.question in pedData && (
                                    <Form.Control
                                      type="month"
                                      value={pedData[question.question] || ""}
                                      onChange={(e) =>
                                        this.handleDurationChange(
                                          question.question,
                                          e.target.value
                                        )
                                      }
                                      max={new Date().toISOString().slice(0, 7)}
                                      placeholder={`Enter duration for ${question.question}`}
                                      disabled={this.state.isFrozen}
                                    />
                                  )}

                                {!pedData[question.question] &&
                                  question.question in pedData && (
                                    <Form.Text className="text-danger">
                                      Please enter a valid duration.
                                    </Form.Text>
                                  )}
                              </div>
                            ))}
                        </td>
                        {/* member 2 ped */}
                        {this.state.peddata.memberstwoName ? (
                          <td>
                            {/* "No PED" Checkbox */}
                            <Form.Check
                              type="checkbox"
                              label="No PED"
                              checked={nomembertwoPedSelected}
                              onChange={(e) =>
                                this.handlemembertwoNoPedChange(
                                  e.target.checked
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {/* Render PED questions if "No PED" is not checked */}
                            {!nomembertwoPedSelected &&
                              pedQuestions.map((question) => (
                                <div key={question.id}>
                                  <Form.Check
                                    type="checkbox"
                                    label={question.question}
                                    disabled={nomembertwoPedSelected} // Disable if "No PED" is selected
                                    checked={
                                      question.question in pedDataMember2
                                    } // Checked if in pedData
                                    onChange={(e) =>
                                      this.handlemembertwoPedQuestionChange(
                                        question.question,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {question.question === "Others" &&
                                    question.question in pedDataMember2 && (
                                      <div>
                                        <Form.Control
                                          type="text"
                                          name="membertwopedotherdiseases"
                                          value={
                                            this.state
                                              .membertwopedotherdiseases || ""
                                          }
                                          onChange={(e) =>
                                            this.setState({
                                              membertwopedotherdiseases:
                                                e.target.value,
                                            })
                                          }
                                          placeholder="Other Ped details"
                                          disabled={this.state.isFrozen}
                                        />
                                        <br />

                                        <Form.Control
                                          type="month"
                                          value={
                                            pedDataMember2[question.question] ||
                                            ""
                                          }
                                          onChange={(e) =>
                                            this.handlemembertwoDurationChange(
                                              question.question,
                                              e.target.value
                                            )
                                          }
                                          max={new Date()
                                            .toISOString()
                                            .slice(0, 7)}
                                          placeholder={`Enter duration for ${question.question}`}
                                          disabled={this.state.isFrozen}
                                        />
                                      </div>
                                    )}
                                  {question.question !== "Others" &&
                                    question.question in pedDataMember2 && (
                                      <Form.Control
                                        type="month"
                                        value={
                                          pedDataMember2[question.question] ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          this.handlemembertwoDurationChange(
                                            question.question,
                                            e.target.value
                                          )
                                        }
                                        max={new Date()
                                          .toISOString()
                                          .slice(0, 7)}
                                        placeholder={`Enter duration for ${question.question}`}
                                        disabled={this.state.isFrozen}
                                      />
                                    )}
                                  {!pedDataMember2[question.question] &&
                                    question.question in pedDataMember2 && (
                                      <Form.Text className="text-danger">
                                        Please enter a valid duration.
                                      </Form.Text>
                                    )}
                                  {/* Others data */}
                                </div>
                              ))}
                          </td>
                        ) : (
                          <></>
                        )}
                        {/* member 3 ped */}
                        {this.state.peddata.memberthreeName ? (
                          <td>
                            {/* "No PED" Checkbox */}
                            <Form.Check
                              type="checkbox"
                              label="No PED"
                              checked={nomemberthreePedSelected}
                              onChange={(e) =>
                                this.handlememberthreeNoPedChange(
                                  e.target.checked
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {/* Render PED questions if "No PED" is not checked */}
                            {!nomemberthreePedSelected &&
                              pedQuestions.map((question) => (
                                <div key={question.id}>
                                  <Form.Check
                                    type="checkbox"
                                    label={question.question}
                                    disabled={nomemberthreePedSelected} // Disable if "No PED" is selected
                                    checked={
                                      question.question in pedDatamemberthree
                                    } // Checked if in pedData
                                    onChange={(e) =>
                                      this.handlememberthreePedQuestionChange(
                                        question.question,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {question.question === "Others" &&
                                    question.question in pedDatamemberthree && (
                                      <div>
                                        <Form.Control
                                          type="text"
                                          name="memberthreepedotherdiseases"
                                          value={
                                            this.state
                                              .memberthreepedotherdiseases || ""
                                          }
                                          onChange={(e) =>
                                            this.setState({
                                              memberthreepedotherdiseases:
                                                e.target.value,
                                            })
                                          }
                                          placeholder="Other Ped details"
                                          disabled={this.state.isFrozen}
                                        />
                                        <br />
                                        <Form.Control
                                          type="month"
                                          value={
                                            pedDatamemberthree[
                                              question.question
                                            ] || ""
                                          }
                                          onChange={(e) =>
                                            this.handlememberthreeDurationChange(
                                              question.question,
                                              e.target.value
                                            )
                                          }
                                          placeholder={`Enter duration for ${question.question}`}
                                          disabled={this.state.isFrozen}
                                          max={new Date()
                                            .toISOString()
                                            .slice(0, 7)}
                                        />
                                      </div>
                                    )}
                                  {question.question !== "Others" &&
                                    question.question in pedDatamemberthree && (
                                      <Form.Control
                                        type="month"
                                        value={
                                          pedDatamemberthree[
                                            question.question
                                          ] || ""
                                        }
                                        onChange={(e) =>
                                          this.handlememberthreeDurationChange(
                                            question.question,
                                            e.target.value
                                          )
                                        }
                                        placeholder={`Enter duration for ${question.question}`}
                                        disabled={this.state.isFrozen}
                                        max={new Date()
                                          .toISOString()
                                          .slice(0, 7)}
                                      />
                                    )}
                                  {!pedDatamemberthree[question.question] &&
                                    question.question in pedDatamemberthree && (
                                      <Form.Text className="text-danger">
                                        Please enter a valid duration.
                                      </Form.Text>
                                    )}
                                </div>
                              ))}
                          </td>
                        ) : (
                          <></>
                        )}

                        {/* four ped */}
                        {this.state.peddata.memberfourName ? (
                          <td>
                            {/* "No PED" Checkbox */}
                            <Form.Check
                              type="checkbox"
                              label="No PED"
                              checked={nomemberfourPedSelected}
                              onChange={(e) =>
                                this.handlememberfourNoPedChange(
                                  e.target.checked
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {/* Render PED questions if "No PED" is not checked */}
                            {!nomemberfourPedSelected &&
                              pedQuestions.map((question) => (
                                <div key={question.id}>
                                  <Form.Check
                                    type="checkbox"
                                    label={question.question}
                                    disabled={nomemberfourPedSelected} // Disable if "No PED" is selected
                                    checked={
                                      question.question in pedDatamemberfour
                                    } // Checked if in pedData
                                    onChange={(e) =>
                                      this.handlememberfourPedQuestionChange(
                                        question.question,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {question.question === "Others" &&
                                    question.question in pedDatamemberfour && (
                                      <div>
                                        <Form.Control
                                          type="text"
                                          name="memberfourpedotherdiseases"
                                          value={
                                            this.state
                                              .memberfourpedotherdiseases || ""
                                          }
                                          onChange={(e) =>
                                            this.setState({
                                              memberfourpedotherdiseases:
                                                e.target.value,
                                            })
                                          }
                                          placeholder="Other Ped details"
                                          disabled={this.state.isFrozen}
                                        />
                                        <br />
                                        <Form.Control
                                          type="month"
                                          value={
                                            pedDatamemberfour[
                                              question.question
                                            ] || ""
                                          }
                                          onChange={(e) =>
                                            this.handlememberfourDurationChange(
                                              question.question,
                                              e.target.value
                                            )
                                          }
                                          max={new Date()
                                            .toISOString()
                                            .slice(0, 7)}
                                          placeholder={`Enter duration for ${question.question}`}
                                          disabled={this.state.isFrozen}
                                        />
                                      </div>
                                    )}
                                  {question.question !== "Others" &&
                                    question.question in pedDatamemberfour && (
                                      <Form.Control
                                        type="month"
                                        value={
                                          pedDatamemberfour[
                                            question.question
                                          ] || ""
                                        }
                                        onChange={(e) =>
                                          this.handlememberfourDurationChange(
                                            question.question,
                                            e.target.value
                                          )
                                        }
                                        placeholder={`Enter duration for ${question.question}`}
                                        disabled={this.state.isFrozen}
                                        max={new Date()
                                          .toISOString()
                                          .slice(0, 7)}
                                      />
                                    )}
                                  {!pedDatamemberfour[question.question] &&
                                    question.question in pedDatamemberfour && (
                                      <Form.Text className="text-danger">
                                        Please enter a valid duration.
                                      </Form.Text>
                                    )}
                                </div>
                              ))}
                          </td>
                        ) : (
                          <></>
                        )}
                        {/* member five peda */}
                        {this.state.peddata.memberfiveName ? (
                          <td>
                            {/* "No PED" Checkbox */}
                            <Form.Check
                              type="checkbox"
                              label="No PED"
                              checked={nomemberfivePedSelected}
                              onChange={(e) =>
                                this.handlememberfiveNoPedChange(
                                  e.target.checked
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {/* Render PED questions if "No PED" is not checked */}
                            {!nomemberfivePedSelected &&
                              pedQuestions.map((question) => (
                                <div key={question.id}>
                                  <Form.Check
                                    type="checkbox"
                                    label={question.question}
                                    disabled={nomemberfivePedSelected} // Disable if "No PED" is selected
                                    checked={
                                      question.question in pedDatamemberfive
                                    } // Checked if in pedData
                                    onChange={(e) =>
                                      this.handlememberfivePedQuestionChange(
                                        question.question,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {question.question === "Others" &&
                                    question.question in pedDatamemberfive && (
                                      <div>
                                        <Form.Control
                                          type="text"
                                          name="memberfivepedotherdiseases"
                                          value={
                                            this.state
                                              .memberfivepedotherdiseases || ""
                                          }
                                          onChange={(e) =>
                                            this.setState({
                                              memberfivepedotherdiseases:
                                                e.target.value,
                                            })
                                          }
                                          placeholder="Other Ped details"
                                          disabled={this.state.isFrozen}
                                        />
                                        <br />
                                        <Form.Control
                                          type="month"
                                          value={
                                            pedDatamemberfive[
                                              question.question
                                            ] || ""
                                          }
                                          onChange={(e) =>
                                            this.handlememberfiveDurationChange(
                                              question.question,
                                              e.target.value
                                            )
                                          }
                                          placeholder={`Enter duration for ${question.question}`}
                                          disabled={this.state.isFrozen}
                                          max={new Date()
                                            .toISOString()
                                            .slice(0, 7)}
                                        />
                                      </div>
                                    )}
                                  {question.question !== "Others" &&
                                    question.question in pedDatamemberfive && (
                                      <Form.Control
                                        type="month"
                                        value={
                                          pedDatamemberfive[
                                            question.question
                                          ] || ""
                                        }
                                        onChange={(e) =>
                                          this.handlememberfiveDurationChange(
                                            question.question,
                                            e.target.value
                                          )
                                        }
                                        placeholder={`Enter duration for ${question.question}`}
                                        disabled={this.state.isFrozen}
                                        max={new Date()
                                          .toISOString()
                                          .slice(0, 7)}
                                      />
                                    )}
                                  {!pedDatamemberfive[question.question] &&
                                    question.question in pedDatamemberfive && (
                                      <Form.Text className="text-danger">
                                        Please enter a valid duration.
                                      </Form.Text>
                                    )}
                                </div>
                              ))}
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.membersixName ? (
                          <td>
                            {/* "No PED" Checkbox */}
                            <Form.Check
                              type="checkbox"
                              label="No PED"
                              checked={nomembersixPedSelected}
                              onChange={(e) =>
                                this.handlemembersixNoPedChange(
                                  e.target.checked
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {/* Render PED questions if "No PED" is not checked */}
                            {!nomembersixPedSelected &&
                              pedQuestions.map((question) => (
                                <div key={question.id}>
                                  <Form.Check
                                    type="checkbox"
                                    label={question.question}
                                    disabled={nomembersixPedSelected} // Disable if "No PED" is selected
                                    checked={
                                      question.question in pedDatamembersix
                                    } // Checked if in pedData
                                    onChange={(e) =>
                                      this.handlemembersixPedQuestionChange(
                                        question.question,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  {question.question === "Others" &&
                                    question.question in pedDatamembersix && (
                                      <div>
                                        <Form.Control
                                          type="text"
                                          name="membersixpedotherdiseases"
                                          value={
                                            this.state
                                              .membersixpedotherdiseases || ""
                                          }
                                          onChange={(e) =>
                                            this.setState({
                                              membersixpedotherdiseases:
                                                e.target.value,
                                            })
                                          }
                                          placeholder="Other Ped details"
                                          disabled={this.state.isFrozen}
                                        />
                                        <br />
                                        <Form.Control
                                          type="month"
                                          value={
                                            pedDatamembersix[
                                              question.question
                                            ] || ""
                                          }
                                          onChange={(e) =>
                                            this.handlemembersixDurationChange(
                                              question.question,
                                              e.target.value
                                            )
                                          }
                                          placeholder={`Enter duration for ${question.question}`}
                                          disabled={this.state.isFrozen}
                                          max={new Date()
                                            .toISOString()
                                            .slice(0, 7)}
                                        />
                                      </div>
                                    )}
                                  {question.question !== "Others" &&
                                    question.question in pedDatamembersix && (
                                      <Form.Control
                                        type="month"
                                        value={
                                          pedDatamembersix[question.question] ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          this.handlemembersixDurationChange(
                                            question.question,
                                            e.target.value
                                          )
                                        }
                                        placeholder={`Enter duration for ${question.question}`}
                                        disabled={this.state.isFrozen}
                                        max={new Date()
                                          .toISOString()
                                          .slice(0, 7)}
                                      />
                                    )}
                                  {!pedDatamembersix[question.question] &&
                                    question.question in pedDatamembersix && (
                                      <Form.Text className="text-danger">
                                        Please enter a valid duration.
                                      </Form.Text>
                                    )}
                                </div>
                              ))}
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                      <tr>
                        <td>
                          Have you undergone any surgery? Please enter the
                          surgery Name & Surgery Date.
                        </td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            checked={showSurgery.memberOne}
                            onChange={() =>
                              this.handleCheckboxChange(
                                "memberOne",
                                "surgery",
                                "surgeryDate"
                              )
                            }
                            disabled={this.state.isFrozen}
                          />
                          {showSurgery.memberOne && (
                            <Form.Control
                              type="text"
                              name="surgery"
                              value={this.state.peddata.surgery || ""}
                              placeholder="Enter Surgery Details"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "surgery",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                              //style={{ marginTop: "10px" }}
                              // Optional: Add spacing for better layout
                            />
                          )}
                          {showSurgery.memberOne ? (
                            <DatePicker
                              name="surgeryDate"
                              dateFormat="dd/MM/yyyy"
                              showTimeSelect={false}
                              selected={
                                this.state.peddata.surgeryDate
                                  ? new Date(this.state.peddata.surgeryDate)
                                  : null
                              }
                              max={new Date().toISOString().split("T")[0]}
                              onChange={this.handleSugeryDateChange}
                              className="form-control mt-1"
                              id="surgeryDate"
                              placeholderText="Select Date of Joining"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              maxDate={new Date()}
                              style={{ width: "215px" }}
                              disabled={this.state.isFrozen}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        {this.state.peddata.memberstwoName ? (
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={showSurgery.memberTwo}
                              onChange={() =>
                                this.handleCheckboxChange(
                                  "memberTwo",
                                  "membertwosurgery",
                                  "membertwosurgeryDate"
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {showSurgery.memberTwo && (
                              <Form.Control
                                type="text"
                                name="membertwosurgery"
                                value={
                                  this.state.peddata.membertwosurgery || ""
                                }
                                placeholder="Enter Surgery Details"
                                onChange={(e) =>
                                  this.handleInputChange(
                                    "membertwosurgery",
                                    e.target.value
                                  )
                                }
                                disabled={this.state.isFrozen}
                              />
                            )}
                            {this.state.peddata.memberstwoName ? (
                              <>
                                {showSurgery.memberTwo ? (
                                  <DatePicker
                                    name="membertwosurgeryDate"
                                    //onChange={this.handledobChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    selected={
                                      this.state.peddata.membertwosurgeryDate
                                    }
                                    onChange={(date) =>
                                      this.handleSugeryDateMembertwoChange(date)
                                    }
                                    className="form-control mt-1"
                                    id="membertwosurgeryDate"
                                    placeholderText="Select Date of Joining"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    //   minDate={previousYearStart}
                                    dropdownMode="select"
                                    max={new Date().toISOString().split("T")[0]}
                                    // maxDate={new Date()}
                                    style={{ width: "215px !important" }}
                                    disabled={this.state.isFrozen}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberthreeName ? (
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={showSurgery.memberThree}
                              onChange={() =>
                                this.handleCheckboxChange(
                                  "memberThree",
                                  "memberthreesurgery",
                                  "memberthreesurgeryDate"
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {showSurgery.memberThree && (
                              <Form.Control
                                type="text"
                                name="memberthreesurgery"
                                value={
                                  this.state.peddata.memberthreesurgery || ""
                                }
                                placeholder="Enter Surgery Details"
                                onChange={(e) =>
                                  this.handleInputChange(
                                    "memberthreesurgery",
                                    e.target.value
                                  )
                                }
                                disabled={this.state.isFrozen}
                              />
                            )}

                            {this.state.peddata.memberthreeName ? (
                              <>
                                {showSurgery.memberThree ? (
                                  <DatePicker
                                    name="memberthreesurgeryDate"
                                    //onChange={this.handledobChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    selected={
                                      this.state.peddata.memberthreesurgeryDate
                                    }
                                    onChange={(date) =>
                                      this.handleSugeryDateMemberthreeChange(
                                        date
                                      )
                                    }
                                    className="form-control mt-1"
                                    id="memberthreesurgeryDate"
                                    placeholderText="Select Date of Joining"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    max={new Date().toISOString().split("T")[0]}
                                    //   minDate={previousYearStart}
                                    dropdownMode="select"
                                    // maxDate={new Date()}
                                    style={{ width: "215px !important" }}
                                    disabled={this.state.isFrozen}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfourName ? (
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={showSurgery.memberFour}
                              onChange={() =>
                                this.handleCheckboxChange(
                                  "memberFour",
                                  "memberfourSurgery",
                                  "memberfoursurgeryDate"
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {showSurgery.memberFour && (
                              <Form.Control
                                type="text"
                                name="memberfourSurgery"
                                value={
                                  this.state.peddata.memberfourSurgery || ""
                                }
                                placeholder="Enter Surgery Details"
                                onChange={(e) =>
                                  this.handleInputChange(
                                    "memberfourSurgery",
                                    e.target.value
                                  )
                                }
                                disabled={this.state.isFrozen}
                              />
                            )}
                            {this.state.peddata.memberfourName ? (
                              <>
                                {showSurgery.memberFour ? (
                                  <DatePicker
                                    name="memberfoursurgeryDate"
                                    //onChange={this.handledobChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    selected={
                                      this.state.peddata.memberfoursurgeryDate
                                    }
                                    onChange={(date) =>
                                      this.handleSugeryDateMemberfourChange(
                                        date
                                      )
                                    }
                                    max={new Date().toISOString().split("T")[0]}
                                    className="form-control mt-1"
                                    id="memberfoursurgeryDate"
                                    placeholderText="Select Date of Joining"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    //   minDate={previousYearStart}
                                    dropdownMode="select"
                                    //maxDate={new Date()}
                                    style={{ width: "215px !important" }}
                                    disabled={this.state.isFrozen}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfiveName ? (
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={showSurgery.memberFive}
                              onChange={() =>
                                this.handleCheckboxChange(
                                  "memberFive",
                                  "memberfiveSurgery",
                                  "memberfivesurgeryDate"
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {showSurgery.memberFive && (
                              <Form.Control
                                type="text"
                                name="memberfiveSurgery"
                                value={
                                  this.state.peddata.memberfiveSurgery || ""
                                }
                                placeholder="Enter Surgery Details"
                                onChange={(e) =>
                                  this.handleInputChange(
                                    "memberfiveSurgery",
                                    e.target.value
                                  )
                                }
                                disabled={this.state.isFrozen}
                              />
                            )}
                            {this.state.peddata.memberfiveName ? (
                              <>
                                {showSurgery.memberFive ? (
                                  <DatePicker
                                    name="memberfivesurgeryDate"
                                    //onChange={this.handledobChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    selected={
                                      this.state.peddata.memberfivesurgeryDate
                                    }
                                    onChange={(date) =>
                                      this.handleSugeryDateMembetfiveChange(
                                        date
                                      )
                                    }
                                    className="form-control mt-1"
                                    id="memberfivesurgeryDate"
                                    placeholderText="Select Date of Joining"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    //   minDate={previousYearStart}
                                    dropdownMode="select"
                                    max={new Date().toISOString().split("T")[0]}
                                    //maxDate={new Date()}
                                    style={{ width: "215px !important" }}
                                    disabled={this.state.isFrozen}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.membersixName ? (
                          <td>
                            <Form.Check
                              type="checkbox"
                              //checked={this.state.peddata()}
                              checked={showSurgery.memberSix}
                              onChange={() =>
                                this.handleCheckboxChange(
                                  "memberSix",
                                  "membersixSurgery",
                                  "membersixsurgeryDate"
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {showSurgery.memberSix && (
                              <Form.Control
                                type="text"
                                name="membersixSurgery"
                                value={
                                  this.state.peddata.membersixSurgery || ""
                                }
                                placeholder="Enter Surgery Details"
                                onChange={(e) =>
                                  this.handleInputChange(
                                    "membersixSurgery",
                                    e.target.value
                                  )
                                }
                                disabled={this.state.isFrozen}
                              />
                            )}
                            {this.state.peddata.membersixName ? (
                              <>
                                {showSurgery.memberSix ? (
                                  <DatePicker
                                    name="membersixsurgeryDate"
                                    //onChange={this.handledobChange}
                                    dateFormat="dd/MM/yyyy"
                                    showTimeSelect={false}
                                    selected={
                                      this.state.peddata.membersixsurgeryDate
                                    }
                                    onChange={(date) =>
                                      this.handleSugeryDateMembersixChange(date)
                                    }
                                    className="form-control mt-1"
                                    id="membersixsurgeryDate"
                                    placeholderText="Select Date of Joining"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    //   minDate={previousYearStart}
                                    dropdownMode="select"
                                    max={new Date().toISOString().split("T")[0]}
                                    //maxDate={new Date()}
                                    style={{ width: "215px !important" }}
                                    disabled={this.state.isFrozen}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>

                      <tr>
                        <td>Any Medications</td>
                        <td>
                          <Form.Control
                            type="text"
                            name="medications"
                            value={this.state.peddata.medications || ""}
                            placeholder="Enter Medications"
                            onChange={(e) =>
                              this.handleInputChange(
                                "medications",
                                e.target.value
                              )
                            }
                            disabled={this.state.isFrozen}
                          />
                        </td>
                        {this.state.peddata.memberstwoName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="membertwomedications"
                              value={
                                this.state.peddata.membertwomedications || ""
                              }
                              placeholder="Enter Medications"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "membertwomedications",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberthreeName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberthreemedications"
                              value={
                                this.state.peddata.memberthreemedications || ""
                              }
                              placeholder="Enter Medications"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberthreemedications",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                            {validationErrors.memberthree && (
                              <Form.Text className="text-danger">
                                {validationErrors.memberthree}
                              </Form.Text>
                            )}
                          </td>
                        ) : (
                          <></>
                        )}

                        {this.state.peddata.memberfourName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfourMedications"
                              value={
                                this.state.peddata.memberfourMedications || ""
                              }
                              placeholder="Enter Medications"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfourMedications",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.memberfiveName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="memberfiveMedications"
                              value={
                                this.state.peddata.memberfiveMedications || ""
                              }
                              placeholder="Enter Medications"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "memberfiveMedications",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {this.state.peddata.membersixName ? (
                          <td>
                            <Form.Control
                              type="text"
                              name="membersixMedications"
                              value={
                                this.state.peddata.membersixMedications || ""
                              }
                              placeholder="Enter Medications"
                              onChange={(e) =>
                                this.handleInputChange(
                                  "membersixMedications",
                                  e.target.value
                                )
                              }
                              disabled={this.state.isFrozen}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div>
                <Form className="mt-2 mb-2">
                  <br />
                  <Form.Group className="form-group d-flex ">
                    <Form.Check
                      type="checkbox"
                      name="pregnancy"
                      id="pregnancy"
                      checked={this.state.pregnancy}
                      onChange={this.handlebabyCheckboxChange}
                      aria-describedby="pregnancy"
                      className="me-2"
                      disabled={this.state.isFrozen}
                    />
                    <Form.Label className="control-label fst-italic">
                      Are You Expecting Baby This Year ?
                    </Form.Label>
                  </Form.Group>
                </Form>
              </div>
              <div>
                <Form className="mt-2 mb-2">
                  <br />
                  <Form.Group className="form-group d-flex ">
                    <Form.Check
                      type="checkbox"
                      name="consentCheckbox"
                      id="consentCheckbox"
                      checked={this.state.consentCheckbox}
                      onChange={() =>
                        this.handleficCheckboxChange("consentCheckbox")
                      }
                      aria-describedby="consentCheckbox"
                      className="me-2"
                      disabled={this.state.isFrozen}
                    />
                    <Form.Label className="control-label fst-italic">
                      I hereby declare that the information provided above is
                      true, complete, and accurate to the best of my knowledge
                      and belief. I understand that any misrepresentation or
                      omission of facts in this declaration may affect insurance
                      claims.
                    </Form.Label>
                  </Form.Group>

                  <br />
                  <div className="text-end">
                    <Button
                      variant="success"
                      //   className="bi bi-pen"
                      className="rounded-0"
                      onClick={this.PeddeclarationdetailsupdateHandler}
                      disabled={this.state.isFrozen}
                    >
                      Submit
                    </Button>
                    {this.state.isFrozen && (
                      <p className="text-danger mt-2">
                        Your Freeze date is completed, you're unable to update
                        your details
                      </p>
                    )}
                  </div>
                </Form>
              </div>
            </>
          )}
        </Container>
      </div>
    );
  }
}
