import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Chihealthplansintegrated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.chihealthplansintegrated_Id,
          sortable: true,
          cell: (row) => <span>{row.chihealthplansintegrated_Id}</span>,
        },
        {
          name: "Insurer name",
          selector: (row, i) => row.insurername,
          sortable: true,
          cell: (row) => <span>{row.insurername}</span>,
        },
        {
          name: "insurer logo",
          selector: (row, i) => row.insurerlogo,
          sortable: true,
          cell: (row) => <span>{row.insurerlogo}</span>,
        },
        {
          name: "product name",
          selector: (row, i) => row.productname,
          sortable: true,
          cell: (row) => <span>{row.productname}</span>,
        },
        {
          name: "product code",
          selector: (row, i) => row.productcode,
          sortable: true,
          cell: (row) => <span>{row.productcode}</span>,
        },
        {
          name: "sum insured",
          selector: (row, i) => row.suminsured,
          sortable: true,
          cell: (row) => <span>{row.suminsured}</span>,
        },
        {
          name: "si code",
          selector: (row, i) => row.sicode,
          sortable: true,
          cell: (row) => <span>{row.sicode}</span>,
        },
        {
          name: "premium api security key",
          selector: (row, i) => row.premiumapisecuritykey,
          sortable: true,
          cell: (row) => <span>{row.premiumapisecuritykey}</span>,
        },
        {
          name: "auth security key",
          selector: (row, i) => row.covertype,
          sortable: true,
          cell: (row) => <span>{row.covertype}</span>,
        },
        {
          name: "partner id",
          selector: (row, i) => row.partnerid,
          sortable: true,
          cell: (row) => <span>{row.partnerid}</span>,
        },
        {
          name: "global coverage",
          selector: (row, i) => row.globalcoverahe,
          sortable: true,
          cell: (row) => <span>{row.globalcoverahe}</span>,
        },
        {
          name: "nationality",
          selector: (row, i) => row.nationality,
          sortable: true,
          cell: (row) => <span>{row.nationality}</span>,
        },
        {
          name: "plan type",
          selector: (row, i) => row.plantype,
          sortable: true,
          cell: (row) => <span>{row.plantype}</span>,
        },
        {
          name: "customer type",
          selector: (row, i) => row.customertype,
          sortable: true,
          cell: (row) => <span>{row.customertype}</span>,
        },
        {
          name: "partner id for tokens",
          selector: (row, i) => row.apikeyfortokens,
          sortable: true,
          cell: (row) => <span>{row.apikeyfortokens}</span>,
        },
        {
          name: "security key for tokens",
          selector: (row, i) => row.securitykeyfortokens,
          sortable: true,
          cell: (row) => <span>{row.securitykeyfortokens}</span>,
        },
        {
          name: "ncb",
          selector: (row, i) => row.ncb,
          sortable: true,
          cell: (row) => <span>{row.ncb}</span>,
        },
        {
          name: "room rent",
          selector: (row, i) => row.roomrent,
          sortable: true,
          cell: (row) => <span>{row.roomrent}</span>,
        },
        {
          name: "network hospital select",
          selector: (row, i) => row.networkhospitalselect,
          sortable: true,
          cell: (row) => <span>{row.networkhospitalselect}</span>,
        },
        {
          name: "co payment waiver",
          selector: (row, i) => row.copaymentwaiver,
          sortable: true,
          cell: (row) => <span>{row.copaymentwaiver}</span>,
        },
        {
          name: "care shield",
          selector: (row, i) => row.careshield,
          sortable: true,
          cell: (row) => <span>{row.careshield}</span>,
        },
        {
          name: "air ambulance",
          selector: (row, i) => row.airambulance,
          sortable: true,
          cell: (row) => <span>{row.airambulance}</span>,
        },
        {
          name: "reduction ped",
          selector: (row, i) => row.reductionped,
          sortable: true,
          cell: (row) => <span>{row.reductionped}</span>,
        },
        {
          name: "deductible",
          selector: (row, i) => row.deductible,
          sortable: true,
          cell: (row) => <span>{row.deductible}</span>,
        },
        {
          name: "api response status",
          selector: (row, i) => row.apiresponsestatus,
          sortable: true,
          cell: (row) => <span>{row.apiresponsestatus}</span>,
        },
        {
          name: "api response remarks",
          selector: (row, i) => row.apiresponseremarks,
          sortable: true,
          cell: (row) => <span>{row.apiresponseremarks}</span>,
        },
        {
          name: "token usage status",
          selector: (row, i) => row.tokenusagestatus,
          sortable: true,
          cell: (row) => <span>{row.tokenusagestatus}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={this.handleOffcanvasShow}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      insurername: "Care Health Insurance",
      insurerlogo: "",
      productname: "",
      productcode: "",
      suminsured: "",
      sicode: "",
      premiumapisecuritykey: "UtDiyi50odj_49onj7fWo51Wv2qICKOD",
      partnerid: "457",
      globalcoverahe: "Not Opted",
      nationality: "Resident of India",
      plantype: "",
      covertype: "1Jpma@85iLuq",
      customertype: "",
      apikeyfortokens: "754325",
      securitykeyfortokens:
        "dkpBQ0Q3cGVGb1NXVnNsWW1EaERWb0ErQVFyTGFhSytNZCtrVzdzRGtrOW1DWktaTDdwWHRWdVZoYnpyV1JseA==",
      ncb: "No",
      roomrent: "No",
      networkhospitalselect: "Yes",
      copaymentwaiver: "Yes",
      careshield: "No",
      airambulance: "No",
      reductionped: "No",
      deductible: "No",
      apiresponsestatus: "",
      apiresponseremarks: "",
      tokenusagestatus: "",
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  ChihealthplansintegratededitHandler = (e) => {
    e.preventDefault();
   
  };

  ChihealthplansintegratedfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Chihealthplansintegrated/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  ChihealthplansintegratedsubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("insurername", this.state.insurername);
    formData.append("insurerlogo", this.state.insurerlogo);
    formData.append("productname", this.state.productname);
    formData.append("productcode", this.state.productcode);
    formData.append("suminsured", this.state.suminsured);
    formData.append("sicode", this.state.sicode);
    formData.append("premiumapisecuritykey", this.state.premiumapisecuritykey);
    formData.append("partnerid", this.state.partnerid);
    formData.append("globalcoverahe", this.state.globalcoverahe);
    formData.append("nationality", this.state.nationality);
    formData.append("plantype", this.state.plantype);
    formData.append("covertype", this.state.covertype);
    formData.append("customertype", this.state.customertype);
    formData.append("apikeyfortokens", this.state.apikeyfortokens);
    formData.append("securitykeyfortokens", this.state.securitykeyfortokens);
    formData.append("ncb", this.state.ncb);
    formData.append("roomrent", this.state.roomrent);
    formData.append("networkhospitalselect", this.state.networkhospitalselect);
    formData.append("copaymentwaiver", this.state.copaymentwaiver);
    formData.append("careshield", this.state.careshield);
    formData.append("airambulance", this.state.airambulance);
    formData.append("reductionped", this.state.reductionped);
    formData.append("deductible", this.state.deductible);
    formData.append("apiresponsestatus", this.state.apiresponsestatus);
    formData.append("apiresponseremarks", this.state.apiresponseremarks);
    formData.append("tokenusagestatus", this.state.tokenusagestatus);

    fetch(configData.api_url + "C_Chihealthplansintegrated/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ChihealthplansintegratedfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            insurername: "",
            insurerlogo: "",
            productname: "",
            productcode: "",
            suminsured: "",
            sicode: "",
            premiumapisecuritykey: "",
            partnerid: "",
            globalcoverahe: "",
            nationality: "",
            plantype: "",
            covertype: "",
            customertype: "",
            apikeyfortokens: "",
            securitykeyfortokens: "",
            ncb: "",
            roomrent: "",
            networkhospitalselect: "",
            copaymentwaiver: "",
            careshield: "",
            airambulance: "",
            reductionped: "",
            deductible: "",
            apiresponsestatus: "",
            apiresponseremarks: "",
            tokenusagestatus: "",
          });
        }
      });
  };
  ChihealthplansintegratedupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("insurername", this.state.insurername);
    formData.append("insurerlogo", this.state.insurerlogo);
    formData.append("productname", this.state.productname);
    formData.append("productcode", this.state.productcode);
    formData.append("suminsured", this.state.suminsured);
    formData.append("sicode", this.state.sicode);
    formData.append("premiumapisecuritykey", this.state.premiumapisecuritykey);
    formData.append("partnerid", this.state.partnerid);
    formData.append("globalcoverahe", this.state.globalcoverahe);
    formData.append("nationality", this.state.nationality);
    formData.append("plantype", this.state.plantype);
    formData.append("covertype", this.state.covertype);
    formData.append("customertype", this.state.customertype);
    formData.append("apikeyfortokens", this.state.apikeyfortokens);
    formData.append("securitykeyfortokens", this.state.securitykeyfortokens);
    formData.append("ncb", this.state.ncb);
    formData.append("roomrent", this.state.roomrent);
    formData.append("networkhospitalselect", this.state.networkhospitalselect);
    formData.append("copaymentwaiver", this.state.copaymentwaiver);
    formData.append("careshield", this.state.careshield);
    formData.append("airambulance", this.state.airambulance);
    formData.append("reductionped", this.state.reductionped);
    formData.append("deductible", this.state.deductible);
    formData.append("apiresponsestatus", this.state.apiresponsestatus);
    formData.append("apiresponseremarks", this.state.apiresponseremarks);
    formData.append("tokenusagestatus", this.state.tokenusagestatus);

    fetch(configData.api_url + "C_Chihealthplansintegrated/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ChihealthplansintegratedfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.ChihealthplansintegratedfetchHandler();
  }
  handleinsurernameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ insurername: fieldValue });
  };
  handleinsurerlogoChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ insurerlogo: fieldValue });
  };
  handleproductnameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productname: fieldValue });
  };
  handleproductcodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productcode: fieldValue });
  };
  handlesuminsuredChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ suminsured: fieldValue });
  };
  handlesicodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ sicode: fieldValue });
  };
  handlepremiumapisecuritykeyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ premiumapisecuritykey: fieldValue });
  };
  handlepartneridChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ partnerid: fieldValue });
  };
  handleglobalcoveraheChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ globalcoverahe: fieldValue });
  };
  handlenationalityChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ nationality: fieldValue });
  };
  handleplantypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ plantype: fieldValue });
  };
  handlecovertypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ covertype: fieldValue });
  };
  handlecustomertypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ customertype: fieldValue });
  };
  handleapikeyfortokensChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ apikeyfortokens: fieldValue });
  };
  handlesecuritykeyfortokensChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ securitykeyfortokens: fieldValue });
  };
  handlencbChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ncb: fieldValue });
  };
  handleroomrentChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ roomrent: fieldValue });
  };
  handlenetworkhospitalselectChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ networkhospitalselect: fieldValue });
  };
  handlecopaymentwaiverChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ copaymentwaiver: fieldValue });
  };
  handlecareshieldChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ careshield: fieldValue });
  };
  handleairambulanceChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ airambulance: fieldValue });
  };
  handlereductionpedChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ reductionped: fieldValue });
  };
  handledeductibleChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ deductible: fieldValue });
  };
  handleapiresponsestatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ apiresponsestatus: fieldValue });
  };
  handleapiresponseremarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ apiresponseremarks: fieldValue });
  };
  handletokenusagestatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ tokenusagestatus: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append(
      "chihealthplansintegrated_Id",
      row.chihealthplansintegrated_Id
    );
    fetch(configData.api_url + "C_Chihealthplansintegrated/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ChihealthplansintegratedfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>CHI Health Plans Integrated</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Chihealthplansintegrated page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleinsurernameChange}
                                        className="form-control"
                                        name="insurername"
                                        value={this.state.insurername}
                                        required="required"
                                        placeholder="Enter insurername"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurername}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        insurer logo
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="file"
                                        onChange={this.handleinsurerlogoChange}
                                        className="form-control"
                                        name="insurerlogo"
                                        value={this.state.insurerlogo}
                                        required="required"
                                        placeholder="Enter insurerlogo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurerlogo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductnameChange}
                                        className="form-control"
                                        name="productname"
                                        value={this.state.productname}
                                        required="required"
                                        placeholder="Enter productname"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productname}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductcodeChange}
                                        className="form-control"
                                        name="productcode"
                                        value={this.state.productcode}
                                        required="required"
                                        placeholder="Enter productcode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productcode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        sum insured
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlesuminsuredChange}
                                        className="form-control"
                                        name="suminsured"
                                        value={this.state.suminsured}
                                        required="required"
                                        placeholder="Enter suminsured"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.suminsured}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        si code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlesicodeChange}
                                        className="form-control"
                                        name="sicode"
                                        value={this.state.sicode}
                                        required="required"
                                        placeholder="Enter sicode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.sicode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        auth security key
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecovertypeChange}
                                        className="form-control"
                                        name="covertype"
                                        value={this.state.covertype}
                                        required="required"
                                        placeholder="Enter covertype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.covertype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        premium api security key
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlepremiumapisecuritykeyChange
                                        }
                                        className="form-control"
                                        name="premiumapisecuritykey"
                                        value={this.state.premiumapisecuritykey}
                                        required="required"
                                        placeholder="Enter premiumapisecuritykey"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.premiumapisecuritykey
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        partner id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepartneridChange}
                                        className="form-control"
                                        name="partnerid"
                                        value={this.state.partnerid}
                                        required="required"
                                        placeholder="Enter partnerid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.partnerid}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        global coverage
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleglobalcoveraheChange
                                        }
                                        className="form-control"
                                        name="globalcoverahe"
                                        value={this.state.globalcoverahe}
                                        required="required"
                                        placeholder="Enter globalcoverahe"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.globalcoverahe}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        nationality
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlenationalityChange}
                                        className="form-control"
                                        name="nationality"
                                        value={this.state.nationality}
                                        required="required"
                                        placeholder="Enter nationality"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.nationality}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        plan type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleplantypeChange}
                                        className="form-control"
                                        name="plantype"
                                        value={this.state.plantype}
                                        required="required"
                                        placeholder="Enter plantype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.plantype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        customer type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecustomertypeChange}
                                        className="form-control"
                                        name="customertype"
                                        value={this.state.customertype}
                                        required="required"
                                        placeholder="Enter customertype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.customertype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        partner id for tokens
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleapikeyfortokensChange
                                        }
                                        className="form-control"
                                        name="apikeyfortokens"
                                        value={this.state.apikeyfortokens}
                                        required="required"
                                        placeholder="Enter apikeyfortokens"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.apikeyfortokens}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        security key for tokens
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlesecuritykeyfortokensChange
                                        }
                                        className="form-control"
                                        name="securitykeyfortokens"
                                        value={this.state.securitykeyfortokens}
                                        required="required"
                                        placeholder="Enter securitykeyfortokens"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.securitykeyfortokens
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        ncb
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlencbChange}
                                        className="form-control"
                                        name="ncb"
                                        value={this.state.ncb}
                                        required="required"
                                        placeholder="Enter ncb"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.ncb}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        room rent
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleroomrentChange}
                                        className="form-control"
                                        name="roomrent"
                                        value={this.state.roomrent}
                                        required="required"
                                        placeholder="Enter roomrent"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.roomrent}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        network hospital select
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlenetworkhospitalselectChange
                                        }
                                        className="form-control"
                                        name="networkhospitalselect"
                                        value={this.state.networkhospitalselect}
                                        required="required"
                                        placeholder="Enter networkhospitalselect"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.networkhospitalselect
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        co payment waiver
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlecopaymentwaiverChange
                                        }
                                        className="form-control"
                                        name="copaymentwaiver"
                                        value={this.state.copaymentwaiver}
                                        required="required"
                                        placeholder="Enter copaymentwaiver"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.copaymentwaiver}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        care shield
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecareshieldChange}
                                        className="form-control"
                                        name="careshield"
                                        value={this.state.careshield}
                                        required="required"
                                        placeholder="Enter careshield"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.careshield}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        air ambulance
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleairambulanceChange}
                                        className="form-control"
                                        name="airambulance"
                                        value={this.state.airambulance}
                                        required="required"
                                        placeholder="Enter airambulance"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.airambulance}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        reduction ped
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlereductionpedChange}
                                        className="form-control"
                                        name="reductionped"
                                        value={this.state.reductionped}
                                        required="required"
                                        placeholder="Enter reductionped"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.reductionped}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        deductible
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledeductibleChange}
                                        className="form-control"
                                        name="deductible"
                                        value={this.state.deductible}
                                        required="required"
                                        placeholder="Enter deductible"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.deductible}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        api response status
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleapiresponsestatusChange
                                        }
                                        className="form-control"
                                        name="apiresponsestatus"
                                        value={this.state.apiresponsestatus}
                                        required="required"
                                        placeholder="Enter apiresponsestatus"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.apiresponsestatus}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        api response remarks
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleapiresponseremarksChange
                                        }
                                        className="form-control"
                                        name="apiresponseremarks"
                                        value={this.state.apiresponseremarks}
                                        required="required"
                                        placeholder="Enter apiresponseremarks"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.apiresponseremarks
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        token usage status
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handletokenusagestatusChange
                                        }
                                        className="form-control"
                                        name="tokenusagestatus"
                                        value={this.state.tokenusagestatus}
                                        required="required"
                                        placeholder="Enter tokenusagestatus"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.tokenusagestatus}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.ChihealthplansintegratedsubmitHandler
                                  }
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        paginationRowsPerPageOptions={[10, 20, 30, 50,60,70,80,90, 100]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
