import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import configData from "../config.json";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
export default class BulkQuoteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      bqId: sessionStorage.getItem("bqId"),
      show: false,
      showOne: false,
      data: [],
      filereadStatus:"",
      filteredData: null,
      isEditing: false,
      isNewEntry: false,
      bqName: "",
     tableClms: [
        {
          name: "S.NO",
          width: "75px",
          id: "bqId",
          selector: (row) => row.bqId, // Make sure the data has 'bqId'
          sortable: true,
          cell: (row) => <span>{row.bqId}</span>,
        },
        {
          name: "Insurer Name",
          id: "insurerName",
          selector: (row) => row.insurerName, // Ensure 'insurerName' exists in the data
          sortable: true,
          cell: (row) => <span>{row.insurerName}</span>,
        },
        {
          name: "Total Records",
          id: "totalRecords",
          selector: (row) => row.totalRecords, // Ensure 'totalRecords' exists in the data
          sortable: true,
          cell: (row) => <span>{row.totalRecords}</span>,
        },
        {
          name: "Success Count",
          id: "completedRecords",
          selector: (row) => row.completedRecords, // Ensure 'completedRecords' exists in the data
          sortable: true,
          cell: (row) => <span>{row.completedRecords}</span>,
        },
        {
          name: "Failed Count",
          id: "failedRecords",
          selector: (row) => row.failedRecords, // Ensure 'failedRecords' exists in the data
          sortable: true,
          cell: (row) => <span>{row.failedRecords}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            
           <div className="row">
      {row.filereadStatus === "5" && ( // Ensure proper comparison
        <div>
          <Button
            variant="success"
            
         onClick={() => this.handleDownload(row.completedPath)}
            className="bi bi-download mt-0"
            style={{ marginRight: "3px" }}
          ></Button>
          <Button
            variant="danger"
             onClick={() => this.handleDownload(row.errorPath)}
            className="bi bi-download"
            style={{ marginRight: "3px" }}
          ></Button>
        </div>
      )}
    </div>

          ),
        },
      ],
    };
  }

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleDownload = (filePath) => {
  const fullPath = `${configData.api_url}/${filePath}`;
  const link = document.createElement("a");
  link.href = fullPath;
  link.download = filePath.split("/").pop(); // Use the file name from the path
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  componentDidMount() {
    this.BulkquotesfetchHandler();
    this.BulkQuoteretrivefetchHandler();
    
  }
   BulkQuoteretrivefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("bqId", this.state.bqId);
    fetch(configData.api_url + "bulkquotes/retrive", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          
          this.setState({
             bqName: data.detail.bqName,
          
          });
        }
      });
  };
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      bqName: row.bqName,
      bqDescription: row.bqDescription,
      bqId: row.bqId,
      failedCound: row.failedCound,
      successCount: row.successCount,
      isEditing: true,
    });
  };

  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;

    if (!data["bqName"]) {
      formIsValid = false;
      toast.info("*Please Enter Bulk Quotes.");
    } else if (!data["bqDescription"]) {
      formIsValid = false;
      toast.info("*Please Enter Bulk Description.");
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  //   BulkquotesfetchHandler = () => {
  //     var formData = new FormData();
  //     formData.append("sId", this.state.sId);
  //     formData.append("uId", this.state.uId);
  //     formData.append("bqId", this.state.bqId);
  //     fetch(configData.api_url + "bulkquotes/listbybqId", {
  //       method: "POST",
  //       mode: "cors",
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.status === "success") {
  //           //   this.setState({ data: data.list });
  //           this.setState({ data: Array.isArray(data.list) ? data.list : [] });
  //         }
  //       });
  //   };
  BulkquotesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("bqId", this.state.bqId);

    fetch(configData.api_url + "bulkquotes/listbybqId", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data); // Log the response to see the structure
        if (data.status === "success" && data.list) {
          // Check if data.list is an object and wrap it inside an array
          const listData = Array.isArray(data.list) ? data.list : [data.list];
          this.setState({ data: listData }); // Update the state with the list data
        } else {
          console.log("No data found in the response.");
          this.setState({ data: [] }); // Ensure that the data is set to an empty array if no data
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
        this.setState({ data: [] }); // Handle error by setting data to an empty array
      });
  };

  handleEnterViewMode = (row) => {
    const employees_Id = row.employees_Id;
    sessionStorage.setItem("employees_Id", employees_Id);
    sessionStorage.setItem("bqId", row.bqId);
    window.location.href = "/bqfileupload";
    // Replace '/profile' with the actual URL for the profile page
  };
  handleEnternewMode = (row) => {
    const employees_Id = row.employees_Id;
    sessionStorage.setItem("employees_Id", employees_Id);
    window.location.href = "/bqfileupload";
    // Replace '/profile' with the actual URL for the profile page
  };

  handlebqNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ bqName: fieldValue });
  };
  handlebqDescriptionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ bqDescription: fieldValue });
  };
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false, showOne: false });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h3 className="logoskybluecolor">Bulk Quote Details - {this.state.bqName}</h3>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleEnternewMode}
                      >
                        New
                      </Button>
                    </Col>

                  </Row>
                  




                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />

                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}