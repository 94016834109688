import React, {  Component } from "react";
import "./PoliciesComparePage.css";
import { Accordion, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import configData from "../config.json";
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

 class PoliciesComparePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      policy:[],
      proposalId: sessionStorage.getItem("proposalId"),
      leadId: sessionStorage.getItem("leadId"),
      chpiIds: sessionStorage.getItem("chpiIds"),

      policies: [],
      highlights: [],
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
 componentDidMount() {
  
    const { chpiIds } = this.state;
    if (!chpiIds || chpiIds.length === 0) {
    this.showToast("No chpiIds provided", "error");
    return;
  }
  
    var fd = new FormData();
    fd.append("chpiIds", chpiIds);
     fd.append("proposalId",this.state.proposalId);
    fetch(configData.api_url + "leads/comparepremiums", {
      method: "POST",
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ policies: data.quotes });
        }
        
      });
   
    
  } 
  handleBackPasswordClick = (quote) => {
    const chpiId = quote.chpiId;
    const cqrId = quote.cqrId;
    const baseproductId = quote.baseproductId;
    const insurername = quote.insurername;
    const productcode = quote.productcode;

  
    const { proposalId } = this.state;
    sessionStorage.setItem("chpiId", chpiId);
    sessionStorage.setItem("cqrId", cqrId);
    sessionStorage.setItem("baseproductId", baseproductId);
    var fd = new FormData();
    fd.append("proposalId", proposalId);
    fd.append("cqrId", cqrId);
    fd.append("chpiId", chpiId);
    fd.append("baseproductId", baseproductId);

    if (insurername === "Care") {
      window.location.href = "/policieskycform"; // Assign new URL to window.location.href
    }
    if (productcode === "10401") {
      // Navigate to PoliciesThankesPage
      window.location.href = "/goproposerinfo"; // Assign new URL to window.location.href
    }
    if (productcode === "2868") {
      // Navigate to PoliciesThankesPage
      window.location.href = "/rgipolicieskyc"; // Assign new URL to window.location.href
    }
    if (baseproductId === "1522") {
      window.location.href = "/icicipolicieskycpage"; // Assign new URL to window.location.href
    }
    if (productcode === "2824") {
      window.location.href = "/rgipolicieskyc"; // Assign new URL to window.location.href
    }
    if (productcode === "2876") {
      window.location.href = "/rgipolicieskyc"; // Assign new URL to window.location.href
    }
    fetch(configData.api_url + "leads/updateproposal/", {
      method: "POST",
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ policiesCondition: true });
        }
      });
  };
  render() {
    let className = "col-md-4";
    if (this.state.policies.length > 2) {
      className = "col-md-3";
    }
    return (
      <div>
        <h3 className="bgalphacolor">Policies Compare</h3>
        <div>
          <div className="container thm-container bgColorContainer">
            <div className="row comparePolicyScreenTopHeading">
              <div className="col-md-3">
                <Link to="/policiespage">
                  <span className="bi bi-chevron-left"></span>Back to Policies
                </Link>
              </div>

            </div>
            <div className="row">
              <br />
              <input type="hidden" />
              <input type="hidden" />
            </div>
            <div className="compareMainDiv">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>
                      <div className="row comparediv">
                        <div className={className + " compareHeading"}>
                          <h2>Company</h2>
                        </div>
                        {this.state.policies.map((policy, index) => (
                          <div className="col" key={"sa" + index}>
                            <Image
                            style={{width:"75%",justifyContent:"center"}}
                              src={
                                configData.static_url +
                                policy.insurerlogo
                              }
                              alt="policy.insurername"
                            ></Image>
                          </div>
                        ))}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="row comapreboxs">
                        <div className={className + " compareHeading"}>
                          Plan Name
                        </div>
                        {this.state.policies.map((policy, index) => (
                          <div className="col"key={"sa" + index}>
                            {policy.productname}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row comapreboxs">
                        <div className={className + " compareHeading"}>
                          Sum Insured
                        </div>
                        {this.state.policies.map((policy, index) => (
                          <div className="col" key={"sa" + index}>
                            Rs. {policy.suminsured}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row comapreboxs">
                        <div className={className + " compareHeading"}>
                          Tenure
                        </div>
                        {this.state.policies.map((policy, index) => (
                          <div className="col" key={"sa" + index}>
                            {policy.tenure}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                <tr>
                    <td>
                      <div className="row comapreboxs">
                        <div className={className + " compareHeading"}>
                          Covertype
                        </div>
                        {this.state.policies.map((policy, index) => (
                          <div className="col" key={"sa" + index}>
                            {policy.covertype}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                    
                  <tr>
                    <td>
                      <div className="row comapreboxs">
                        <div className={className + " compareHeading"}>
                          Premium
                        </div>
                        {this.state.policies.map((policy, index) => (
                          <div className="col" key={"sa" + index}>
                            Rs. {policy.premium}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row comapreboxs">
                        <div className={className + " compareHeading"}>
                          Features
                        </div>
                        {this.state.policies.map((policy, index) => (
                          <div className="col" key={"sa" + index}>
                            <Accordion>
                              <Accordion.Header> See More...
                              </Accordion.Header>
                              <Accordion.Body>
                            <ul className="list-group">
                             {policy.features.map((feature, featureIndex) => (
                                <li key={`feature-${featureIndex}`} style={{"listStyle":"none"}} 
                                className="list-group-item">
                                  {feature.featuressubcategory}
                                </li>
                              ))}
                            </ul>
                            </Accordion.Body>
           </Accordion>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row comapreboxs">
                        <div className={className + " compareHeading"}>
                          Purchase
                        </div>
                        {this.state.policies.map((policy, index) => (
                          <div className="col">
                          <button
                        onClick={() => this.handleBackPasswordClick(policy)}
                         className="btn btn-primary"
                        style={{ color: "#ffffff" }}
                      >
                        RS. {policy.premium}/-
                      </button>
                          </div>
                            ))}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withParams(PoliciesComparePage); 