import React, { Component } from "react";
import { Bar, Radar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { PolarArea } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  RadialLinearScale,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  BarElement,
  Legend,
  ArcElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Card, Col, Row } from "react-bootstrap";
import configData from "../config.json";

// import Areachart from './Charts/Areachart';
// import Horizontalchart from './Charts/Horizontalchart';
ChartJS.register(
  LinearScale,
  RadialLinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  BarElement,
  Legend,
  ArcElement,
  Title,
  Tooltip,
  Filler
);

export default class DashboardCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [],
        sId: sessionStorage.getItem("sessionId"),
        uId: sessionStorage.getItem("uId"),
      },
    };
  }
  componentDidMount() {
    let apiName = [];
    let apiComp = [];
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    axios
      .get(configData.api_url + "apis/")
      .then((res) => {
        //console.log(res.data.list);
        for (const dataObj of res.data.list) {
          apiName.push(dataObj.aName);
          apiComp.push(parseInt(dataObj.aId));
          // empEmail.push(parseInt(dataObj.email));
        }
        //console.log(apiName);
        //console.log(apiComp);

        // Update the state with the new data
        this.setState({
          data: {
            labels: apiName,
            datasets: [
              {
                label: "level of thicceness",
                data: apiComp,
                fill: false,
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                ],
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                ],
                // tension: 0.1,
                borderWidth: 1,
                hoverOffset: 20,
              },
            ],
          },
        });
      })
      .catch((err) => {
        //console.log(err);
      });
  }
  render() {
    return (
      <div>
        <Row>
          <Col xs="6" lg="4">
            <Card>
              <Bar
                data={this.state.data}
                style={{ width: "250px", height: "250px" }}
              />
            </Card>
            <p className="text-center p-2">Bar chart</p>
          </Col>
          <Col xs="6" lg="4">
            <Card>
              <Pie
                data={this.state.data}
                style={{ width: "250px", height: "250px" }}
              />
            </Card>
            <p className="text-center p-2">Pie chart</p>
          </Col>
          <Col xs="6" lg="4">
            <Card>
              <Doughnut
                data={this.state.data}
                style={{ width: "250px", height: "250px" }}
              />
            </Card>
            <p className="text-center p-2">Doughnut chart</p>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            <Card style={{ height: "204px" }}>
              {/* <Nav.Link to="/Horizontalchart"><Horizontalchart/></Nav.Link> */}
            </Card>
            <p className="text-center p-2">Horizontal chart</p>
          </Col>
          <Col xs="6" sm="4">
            <Card>
              <Line data={this.state.data} />
            </Card>
            <p className="text-center p-2">Line chart</p>
          </Col>
          <Col xs="6" sm="4">
            {/* <Nav.Link to="/Areachart"><Areachart/></Nav.Link> */}
            <p className="text-center p-2">Area chart</p>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            <Card>
              <PolarArea data={this.state.data} />
            </Card>
          </Col>
          <Col xs="6" sm="4">
            <Card>
              <Radar data={this.state.data} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
