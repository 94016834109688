import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";
import "./Itsupporttickets.css";
export default class Itsupporttickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.itsupporttickets_Id,
          sortable: true,
          cell: (row) => <span>{row.itsupporttickets_Id}</span>,
        },
        {
          name: "Raised By",
          selector: (row, i) => row.raisedby,
          sortable: true,
          cell: (row) => <span>{row.raisedby}</span>,
        },
        {
          name: "Ticket Id",
          selector: (row, i) => row.ticketid,
          sortable: true,
          cell: (row) => <span>{row.ticketid}</span>,
        },
        {
          name: "Date of Request",
          selector: (row, i) => row.dateofrequest,
          sortable: true,
          cell: (row) => <span>{row.dateofrequest}</span>,
        },
        {
          name: "Type Of Request",
          selector: (row, i) => row.typeofrequest,
          sortable: true,
          cell: (row) => <span>{row.typeofrequest}</span>,
        },
        {
          name: "Remarks",
          selector: (row, i) => row.remarks,
          sortable: true,
          cell: (row) => <span>{row.remarks}</span>,
        },
        {
          name: "Screen shot",
          selector: (row, i) => row.screenshot,
          sortable: true,
          cell: (row) => <span>{row.screenshot}</span>,
        },
        {
          name: "Ticket Status",
          selector: (row, i) => row.ticketstatus,
          sortable: true,
          cell: (row) => <span>{row.ticketstatus}</span>,
        },
        {
          name: "Date Of Closed",
          selector: (row, i) => row.dateofclosed,
          sortable: true,
          cell: (row) => <span>{row.dateofclosed}</span>,
        },
        {
          name: "Closed Remarks",
          selector: (row, i) => row.closedremarks,
          sortable: true,
          cell: (row) => <span>{row.closedremarks}</span>,
        },
        {
          name: "Closed By",
          selector: (row, i) => row.closedby,
          sortable: true,
          cell: (row) => <span>{row.closedby}</span>,
        },
        {
          name: "Re Open Date",
          selector: (row, i) => row.reopendate,
          sortable: true,
          cell: (row) => <span>{row.reopendate}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={this.handleOffcanvasShow}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      raisedby: "",
      ticketid: "",
      dateofrequest: "",
      requesttypemaster: [],
      typeofrequest: "",
      remarks: "",
      screenshot: "",
      ticketstatus: "",
      dateofclosed: "",
      closedremarks: "",
      closedby: "",
      reopendate: "",
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  ItsupportticketseditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Itsupporttickets_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  ItsupportticketsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Itsupporttickets/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  ItsupportticketssubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("raisedby", this.state.raisedby);
    formData.append("ticketid", this.state.ticketid);
    formData.append("dateofrequest", this.state.dateofrequest);
    formData.append("typeofrequest", this.state.typeofrequest);
    formData.append("remarks", this.state.remarks);
    formData.append("screenshot", this.state.screenshot);
    formData.append("ticketstatus", this.state.ticketstatus);
    formData.append("dateofclosed", this.state.dateofclosed);
    formData.append("closedremarks", this.state.closedremarks);
    formData.append("closedby", this.state.closedby);
    formData.append("reopendate", this.state.reopendate);

    fetch(configData.api_url + "C_Itsupporttickets/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ItsupportticketsfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            raisedby: "",
            ticketid: "",
            dateofrequest: "",
            requesttypemaster: [],
            typeofrequest: "",
            remarks: "",
            screenshot: "",
            ticketstatus: "",
            dateofclosed: "",
            closedremarks: "",
            closedby: "",
            reopendate: "",
          });
        }
      });
  };
  ItsupportticketsupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("raisedby", this.state.raisedby);
    formData.append("ticketid", this.state.ticketid);
    formData.append("dateofrequest", this.state.dateofrequest);
    formData.append("typeofrequest", this.state.typeofrequest);
    formData.append("remarks", this.state.remarks);
    formData.append("screenshot", this.state.screenshot);
    formData.append("ticketstatus", this.state.ticketstatus);
    formData.append("dateofclosed", this.state.dateofclosed);
    formData.append("closedremarks", this.state.closedremarks);
    formData.append("closedby", this.state.closedby);
    formData.append("reopendate", this.state.reopendate);

    fetch(configData.api_url + "C_Itsupporttickets/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ItsupportticketsfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.ItsupportticketsfetchHandler();
    this.requesttypemasterfetchHandler();
  }
  handleraisedbyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ raisedby: fieldValue });
  };
  handleticketidChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ticketid: fieldValue });
  };
  handledateofrequestChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dateofrequest: fieldValue });
  };
  handletypeofrequestChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ typeofrequest: fieldValue });
  };
  requesttypemasterfetchHandler = () => {
    fetch(configData.api_url + "C_Requesttypemaster/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ requesttypemaster: data.list });
        }
      });
  };
  handleremarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ remarks: fieldValue });
  };
  handlescreenshotChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ screenshot: fieldValue });
  };
  handleticketstatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ticketstatus: fieldValue });
  };
  handledateofclosedChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dateofclosed: fieldValue });
  };
  handleclosedremarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ closedremarks: fieldValue });
  };
  handleclosedbyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ closedby: fieldValue });
  };
  handlereopendateChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ reopendate: fieldValue });
  };
  handledummyoneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyone: fieldValue });
  };
  handledummytwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummytwo: fieldValue });
  };
  handledummythreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummythree: fieldValue });
  };
  handledummyfourChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyfour: fieldValue });
  };
  handledummyfiveChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyfive: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };


  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("itsupporttickets_Id", row.itsupporttickets_Id);
    fetch(configData.api_url + "C_Itsupporttickets/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ItsupportticketsfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>IT Support Tickets</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>IT Support Tickets</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Raised By
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleraisedbyChange}
                                        className="form-control"
                                        name="raisedby"
                                        value={this.state.raisedby}
                                        required="required"
                                        placeholder="Enter raisedby"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.raisedby}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Ticket Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleticketidChange}
                                        className="form-control"
                                        name="ticketid"
                                        value={this.state.ticketid}
                                        required="required"
                                        placeholder="Enter ticketid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.ticketid}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Date of Request
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={
                                          this.handledateofrequestChange
                                        }
                                        className="form-control"
                                        name="dateofrequest"
                                        value={this.state.dateofrequest}
                                        required="required"
                                        placeholder="Enter dateofrequest"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dateofrequest}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Type Of Request
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <select
                                        className="form-select"
                                        name="tableName"
                                        onChange={
                                          this.handletypeofrequestChange
                                        }
                                      >
                                        <option defaultValue>
                                          {" "}
                                          Select Type Of Request{" "}
                                        </option>
                                        {this.state.requesttypemaster.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={api.requestType}
                                            >
                                              {" "}
                                              {api.requestType}{" "}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.typeofrequest}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Remarks
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleremarksChange}
                                        className="form-control"
                                        name="remarks"
                                        value={this.state.remarks}
                                        required="required"
                                        placeholder="Enter remarks"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.remarks}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Screen shot
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="file"
                                        onChange={this.handlescreenshotChange}
                                        className="form-control"
                                        name="screenshot"
                                        value={this.state.screenshot}
                                        required="required"
                                        placeholder="Enter screenshot"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.screenshot}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Ticket Status
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleticketstatusChange}
                                        className="form-control"
                                        name="ticketstatus"
                                        value={this.state.ticketstatus}
                                        required="required"
                                        placeholder="Enter ticketstatus"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.ticketstatus}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Date Of Closed
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={this.handledateofclosedChange}
                                        className="form-control"
                                        name="dateofclosed"
                                        value={this.state.dateofclosed}
                                        required="required"
                                        placeholder="Enter dateofclosed"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dateofclosed}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Closed Remarks
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleclosedremarksChange
                                        }
                                        className="form-control"
                                        name="closedremarks"
                                        value={this.state.closedremarks}
                                        required="required"
                                        placeholder="Enter closedremarks"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.closedremarks}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Closed By
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleclosedbyChange}
                                        className="form-control"
                                        name="closedby"
                                        value={this.state.closedby}
                                        required="required"
                                        placeholder="Enter closedby"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.closedby}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Re Open Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={this.handlereopendateChange}
                                        className="form-control"
                                        name="reopendate"
                                        value={this.state.reopendate}
                                        required="required"
                                        placeholder="Enter reopendate"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.reopendate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy One
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyoneChange}
                                        className="form-control"
                                        name="dummyone"
                                        value={this.state.dummyone}
                                        required="required"
                                        placeholder="Enter dummyone"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyone}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummytwoChange}
                                        className="form-control"
                                        name="dummytwo"
                                        value={this.state.dummytwo}
                                        required="required"
                                        placeholder="Enter dummytwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummytwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummythreeChange}
                                        className="form-control"
                                        name="dummythree"
                                        value={this.state.dummythree}
                                        required="required"
                                        placeholder="Enter dummythree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummythree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyfourChange}
                                        className="form-control"
                                        name="dummyfour"
                                        value={this.state.dummyfour}
                                        required="required"
                                        placeholder="Enter dummyfour"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyfour}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Five
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyfiveChange}
                                        className="form-control"
                                        name="dummyfive"
                                        value={this.state.dummyfive}
                                        required="required"
                                        placeholder="Enter dummyfive"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyfive}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.ItsupportticketssubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
